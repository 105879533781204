//DEFINE THE ACTIONS TO TRIGGER
export const GET_ABMKPIS_LOADING = 'GET_ABMKPIS_LOADING';
export const GET_ABMKPIS_FAIL = 'GET_ABMKPIS_FAIL';
export const GET_ABMKPIS_SUCCESS = 'GET_ABMKPIS_SUCCESS';


export type ABMKpisType =  {
    kpi_id: number,
    kpi_name: string,
    kpi_host: string,
    kpi_field: string,
    kpi_type: string,
    kpi_transform: boolean,
    kpi_endpoint_id: string,
    kpi_group_id: number,
    kpi_threshold: boolean,
    kpi_title: string,
    kpi_title_key: string,
    kpi_time_gap: number,
    kpi_linechart_label: string,
    kpi_created_by:string,
    kpi_updated_by: string,
    kpi_group_name: string,
    service_name: string
};

export interface GetABMKpisLoading {
    type: typeof GET_ABMKPIS_LOADING
}

export interface GetABMKpisFail {
    type: typeof GET_ABMKPIS_FAIL
}

export interface GetABMKpisSuccess {
    type: typeof GET_ABMKPIS_SUCCESS,
    payload: ABMKpisType[] | undefined
}

export type GetABMKPISDispatchTypes = GetABMKpisLoading | GetABMKpisFail | GetABMKpisSuccess;