import ApiRequestor from "../../api_access/ApiRequestor";
import { Dispatch } from 'redux';
import { GET_CLIENTSINMAINTENANCE_FAIL, GET_CLIENTSINMAINTENANCE_LOADING, GET_CLIENTSINMAINTENANCE_SUCCESS, GetClientsInMaintenanceDispatchTypes } from './clientInMaintenanceActionsTypes';

export const GetClientsInMaintenance = () => async (dispatch: Dispatch<GetClientsInMaintenanceDispatchTypes>) => {
    try {
        dispatch({
            type:GET_CLIENTSINMAINTENANCE_LOADING
        })
        const response : any = await ApiRequestor.requestToApi("/swt-admin/clients-maintenance", "GET");
        dispatch({
            type: GET_CLIENTSINMAINTENANCE_SUCCESS,
            payload: response.data
        })
    } catch(error){
        dispatch({
            type: GET_CLIENTSINMAINTENANCE_FAIL
        })
    }
}   