import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { IonCard, IonCardTitle, IonCardContent, IonCardHeader, IonProgressBar, IonCol, IonRow, IonLabel, IonGrid, IonIcon, IonText } from "@ionic/react";
import { radioButtonOnOutline, ellipse, chevronDownOutline, chevronUpOutline, construct, settingsOutline, constructOutline } from "ionicons/icons";

import { SessionDefaultStateI } from "../../store/session/SessionReducer";
import { CardsDefaultStateI } from "../../store/mainInfo/cardsReducer";
import { GetCards } from "../../store/mainInfo/mainInfoActions";
import { CardsType } from "../../store/mainInfo/mainInfoActionsTypes";

import "./Card.css";

type CardDataType = {
  alert: string;
  appAlert: string;
  appKey: string;
  appVal: number;
  cantAlert: string;
  cantKey: string;
  cantVal: number;
  gap: number;
  titleKey: string;
  legend: string;
};

interface Props extends StateTypes, dispatchTypes {
  clientSelected?: any;
  collapsed?: boolean;
  collapsedTitularidad?:boolean;
}

const Cards: React.FC<Props> = (props) => {
  const [cardsInfo, setCardsInfo] = useState<any>([])
  const [cardsOpen, setCardsOpen] = useState(true);
  const [cardsOpenTitularidad, setCardsOpenTitularidad] = useState(false);
  const bank_id_category = props.session.loginData.getIdToken().decodePayload()["custom:category"];
  const client_category = sessionStorage.getItem("client");
  const client_user = props.session.loginData.getIdToken().decodePayload()["custom:bank_id"];

  useEffect(() => {
    if (props.collapsed) {
      setCardsOpen(!props.collapsed)
    }
  }, []);

  useEffect(() => {
    if (props.collapsedTitularidad) {
      setCardsOpenTitularidad(!props.collapsedTitularidad)
    }
  }, []);

  useEffect(() => {
    let cardsData = []
    if (bank_id_category !== "ADMIN") {
      if (props.cards.loading === false && props.cards.cards) {
        cardsData = props.cards.cards
      }
    } else {
      if (props.cards.loading === false && props.cards.cards && props.clientSelected !== '') {
        cardsData = props.cards.cards.filter((card: CardsType) => card.bank_id == props.clientSelected)
      }
    }
    setCardsInfo(cardsData)
  }, [props.clientSelected, props.cards.loading, props.cards.cards])

  const getBgAlert = (alertCode: string) => {
    type alertItems = {
      color: string;
      icon: any;
    };

    let alertNumber: number = parseInt(alertCode);
    let items: alertItems = {
      color: "",
      icon: null,
    };
    switch (alertNumber) {
      case 0:
        items.color = "success";
        items.icon = (
          <IonIcon className="card-status-icon" icon={radioButtonOnOutline} color={items.color} />
        );
        return items; //success
      case 1:
        items.color = "warning";
        items.icon = (
          <IonIcon className="card-status-icon" icon={radioButtonOnOutline} color={items.color} />
        );
        return items; //warning
      case 2:
        items.color = "danger";
        items.icon = (
          <IonIcon className="card-status-icon" icon={radioButtonOnOutline} color={items.color} />
        );
        return items; //alert
      case 3:
        items.color = "secondary";
        items.icon = (
          <IonIcon className="card-status-icon" icon={radioButtonOnOutline} color={items.color} />
        );
        return items; //secondary
      default:
        items.color = "secondary";
        items.icon = (
          <IonIcon className="card-status-icon" icon={radioButtonOnOutline} color={items.color} />
        );
        return items;
    }
  };

  const getBgAlertTitularidad = (alertCode: string) => {
    type alertItems = {
      color: string;
      icon: any;
    };

    let alertNumber: number = parseInt(alertCode);
    let items: alertItems = {
      color: "",
      icon: null,
    };
    switch (alertNumber) {
      case 0:
        items.color = "success";
        items.icon = (
          <IonIcon className="card-status-icon-titularidad" icon={radioButtonOnOutline} color={items.color} />
        );
        return items; //success
      case 1:
        items.color = "warning";
        items.icon = (
          <IonIcon className="card-status-icon-titularidad" icon={radioButtonOnOutline} color={items.color} />
        );
        return items; //warning
      case 2:
        items.color = "danger";
        items.icon = (
          <IonIcon className="card-status-icon-titularidad" icon={radioButtonOnOutline} color={items.color} />
        );
        return items; //alert
      case 3:
        items.color = "secondary";
        items.icon = (
          <IonIcon className="card-status-icon-titularidad" icon={radioButtonOnOutline} color={items.color} />
        );
        return items; //secondary
      default:
        items.color = "secondary";
        items.icon = (
          <IonIcon className="card-status-icon-titularidad" icon={radioButtonOnOutline} color={items.color} />
        );
        return items;
    }
  };

  const getCardAlert = (cardInfo: any) => {
    if (cardInfo.appAlert === "2" || cardInfo.cantAlert === "2") {
      return "2";
    }
    if (cardInfo.appAlert >= cardInfo.cantAlert) {
      return cardInfo.appAlert;
    }
    return cardInfo.cantAlert;
  };

  const getCardByTitleKey = (cardsArray: any, element: any) => {
    let card = cardsArray.find(
      (arrayElement: any) => element.titleKey === arrayElement.titleKey
    );

    //Don't make a new card with information that has no titleKey
    if (!card && element.titleKey) {
      card = { titleKey: element.titleKey };
    }

    return card;
  };

  const getCardIndex = (cardsArray: any, element: any) => {
    //FindIndex returns -1 if it doesn't find an element, indicating it's a new card
    return cardsArray.findIndex(
      (arrayElement: any) => element.titleKey === arrayElement.titleKey
    );
  };

  const groupValues = (array: any[]): any => {
    const cardsArray: any[] = [];

    array.forEach((element) => {
      try {
        let card = getCardByTitleKey(cardsArray, element);
        let index = getCardIndex(cardsArray, element);
        //Modify card with the data
        if (
          element.key.includes("Aprobadas") ||
          element.key.includes("APROBADAS")
        ) {
          card.appKey = element.key;
          card.appVal = element.value;
          card.appAlert = element.alert;
        } else {
          card.cantKey = element.key;
          card.cantVal = element.value;
          card.cantAlert = element.alert;
        }
        card.gap = element.timeGap;
        card.legend = element.legend;
        card.error = element.error;

        if (index === -1) {
          cardsArray.push(card);
        } else {
          cardsArray[index] = card;
        }
      } catch (err) {
        console.log(`No card found for: ${element.key}`);
      }
    });

    cardsArray.forEach((cardInfo) => {
      cardInfo.alert = getCardAlert(cardInfo);
    });

    return cardsArray;
  };

  const getDistribution = (cardQty: number) => {
    if (cardQty % 4 === 0 || cardQty % 3 === 1 || cardQty == 6) {
      return {
        xs: "6",
        sm: "6",
        md: "4",
        lg: "3",
      };
    }
    return {
      xs: "6",
      sm: "6",
      md: "4",
      lg: "3",
    };
  };

  const Card = (cardData: CardDataType, service: string) => {
    const bgAlert = getBgAlert(cardData.alert);
    // const cardLegendMap = getFooterText(service, cardData.titleKey);
    const percentage = cardData.cantVal && cardData.appVal ? cardData.appVal : 0;
    const title = cardData.titleKey;
    const handleClickCard = () => {
      //When clicked, move to the corresponding graph inside the view
      const graph = document.getElementById(title);
      if (graph) {
        graph.scrollIntoView({ behavior: "smooth" });
      }
    };

    return (
      <IonCard className="content-card">
        <IonCardHeader className="summary-card-header" onClick={() => handleClickCard()}>
          {bgAlert.icon}
          <IonCardTitle className="summary-card-title">{title}</IonCardTitle>
        </IonCardHeader>
        <IonCardContent className="content-card-ion card-center" >
          <IonRow>
            <IonCol>
              <IonText className="card-text">
                {cardData.cantVal ? "\nTRX: " + cardData.cantVal : "\nTRX: " + 0}
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <div>
                <IonText className="card-text">
                  {cardData.appVal ? cardData.appVal + "%" : "0%"}
                </IonText>
                <IonProgressBar
                  color={bgAlert.color}
                  value={percentage / 100}
                ></IonProgressBar>
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonText className="card-text-minutes"> Últimos {cardData.gap} minutos </IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonText className="card-text-alternative"> {cardData.legend}
              </IonText>
            </IonCol>
          </IonRow>
        </IonCardContent>
      </IonCard>
    );
  };

  const CardError = (cardData: CardDataType, service: string) => {
    const title = cardData.titleKey;
    const handleClickCard = () => {
      //When clicked, move to the corresponding graph inside the view
      const graph = document.getElementById(title);
      if (graph) {
        graph.scrollIntoView({ behavior: "smooth" });
      }
    };

    return (
      <IonCard className="content-card">
        <IonCardHeader className="summary-card-header" onClick={() => handleClickCard()}>
          <IonCardTitle className="summary-card-title">{title}</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonRow className="mantenimiento-card-content">
            <IonCol className="card-center">
              <IonCardTitle className="card-text-maintenance">Métrica en Mantenimiento</IonCardTitle>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="card-center">
              <IonIcon className="card-img-maintenance" icon={construct} />
            </IonCol>
          </IonRow>
        </IonCardContent>
      </IonCard>
    );
  };

  const renderCards = (): any => {
    if (cardsInfo.length > 0) {
      const service = cardsInfo[0].bank_id;
      const cardsData = groupValues(cardsInfo[0].cardsInfo);
      const cardDistribution = getDistribution(cardsData.length);
      return cardsData?.map((info: any) => {
        if ((service == "MUSIMUNDO" || service == "PLUSPAGOS") && info.titleKey !== "GENERAL" && info.titleKey !== "VISA") {
          if (info.error == true) {
            return (
              <IonCol className="card-col-general"
                key={info.titleKey}
                sizeLg={cardDistribution.lg}
                sizeMd={cardDistribution.md}
                sizeSm={cardDistribution.sm}
                sizeXs={cardDistribution.xs}>
                {CardError(info, service)}
              </IonCol>
            )
          } else if (info.error == false) {
            return (
              <IonCol className="card-col-general"
                key={info.titleKey}
                sizeLg={cardDistribution.lg}
                sizeMd={cardDistribution.md}
                sizeSm={cardDistribution.sm}
                sizeXs={cardDistribution.xs}>
                {Card(info, service)}
              </IonCol>
            );
          }
        } else if (service == "HSBC") {
          if (info.titleKey !== "CONSULTA DE TITULARIDAD LINK" && info.titleKey !== "CONSULTA DE TITULARIDAD COELSA" && info.titleKey !== "CONSULTA DE TITULARIDAD BANELCO") {
            if (info.error == true) {
              return (
                <IonCol className="card-col-general"
                  key={info.titleKey}
                  sizeLg={cardDistribution.lg}
                  sizeMd={cardDistribution.md}
                  sizeSm={cardDistribution.sm}
                  sizeXs={cardDistribution.xs}>
                  {CardError(info, service)}
                </IonCol>
              )
            } else if (info.error == false) {
              return (
                <IonCol className="card-col-general"
                  key={info.titleKey}
                  sizeLg={cardDistribution.lg}
                  sizeMd={cardDistribution.md}
                  sizeSm={cardDistribution.sm}
                  sizeXs={cardDistribution.xs}>
                  {Card(info, service)}
                </IonCol>
              );
            }
          }
        } else if (service !== "MUSIMUNDO" && service !== "PLUSPAGOS") {
          if (info.error == true) {
            return (
              <IonCol className="card-col-general"
                key={info.titleKey}
                sizeLg={cardDistribution.lg}
                sizeMd={cardDistribution.md}
                sizeSm={cardDistribution.sm}
                sizeXs={cardDistribution.xs}>
                {CardError(info, service)}
              </IonCol>
            )
          } else if (info.error == false) {
            return (
              <IonCol className="card-col-general"
                key={info.titleKey}
                sizeLg={cardDistribution.lg}
                sizeMd={cardDistribution.md}
                sizeSm={cardDistribution.sm}
                sizeXs={cardDistribution.xs}>
                {Card(info, service)}
              </IonCol>
            );
          }
        }
      });
    } else {
    }
  };

  const renderCardsMusimundo = (): any => {
    if (cardsInfo.length > 0) {
      const service = cardsInfo[0].bank_id;
      const cardsData = groupValues(cardsInfo[0].cardsInfo);
      const cardDistribution = getDistribution(cardsData.length);
      return cardsData?.map((info: any) => {
        if ((service == "MUSIMUNDO" || service == "PLUSPAGOS") && (info.titleKey == "GENERAL" || info.titleKey == "VISA")) {
          if (info.error == true) {
            return (
              <IonCol className="card-col-general"
                key={info.titleKey}
                sizeLg={cardDistribution.lg}
                sizeMd={cardDistribution.md}
                sizeSm={cardDistribution.sm}
                sizeXs={cardDistribution.xs}>
                {CardError(info, service)}
              </IonCol>
            )
          } else if (info.error == false) {
            return (
              <IonCol className="card-col-general"
                key={info.titleKey}
                sizeLg={cardDistribution.lg}
                sizeMd={cardDistribution.md}
                sizeSm={cardDistribution.sm}
                sizeXs={cardDistribution.xs}>
                {Card(info, service)}
              </IonCol>
            );
          }
        }
      });
    }
  };

  const renderTitularidad = (): any => {
    
    if(client_category == "HSBC" || client_user == "HSBC"){
      return (
        <><IonCardHeader className="cards-container-header-titularidad"
          onClick={() => setCardsOpenTitularidad(!cardsOpenTitularidad)}>
          <IonCardTitle className="card-title-titularidad">
            Consulta de Titularidad
          </IonCardTitle>
          <IonIcon className="displayCards-icon-titularidad" icon={cardsOpenTitularidad ? chevronUpOutline : chevronDownOutline} />
        </IonCardHeader><IonCardContent className={cardsOpenTitularidad ? `cards-content` : `cards-content-hidden`}>
            <IonGrid className="card-row">
              {renderCardsTitularidad()}
            </IonGrid>
          </IonCardContent></>
      );
    }
  }

  const renderCardsTitularidad = (): any => {
    if (cardsInfo.length > 0) {
      const service = cardsInfo[0].bank_id;
      const cardsData = groupValues(cardsInfo[0].cardsInfo);
      const cardDistribution = getDistribution(cardsData.length);
      return cardsData?.map((info: any) => {
        if (service == "HSBC" && (info.titleKey == "CONSULTA DE TITULARIDAD LINK" || info.titleKey == "CONSULTA DE TITULARIDAD COELSA" || info.titleKey == "CONSULTA DE TITULARIDAD BANELCO")) {
          if (info.error == true) {
            return (
              <IonCol className="card-col-general"
                key={info.titleKey}
                sizeLg={cardDistribution.lg}
                sizeMd={cardDistribution.md}
                sizeSm={cardDistribution.sm}
                sizeXs={cardDistribution.xs}>
                {CardError(info, service)}
              </IonCol>
            )
          } else if (info.error == false) {
            return (
              <IonCol className="card-col-general"
                key={info.titleKey}
                sizeLg={cardDistribution.lg}
                sizeMd={cardDistribution.md}
                sizeSm={cardDistribution.sm}
                sizeXs={cardDistribution.xs}>
                {CardTitularidad(info, service)}
              </IonCol>
            );
          }
        }
      })
    }
  };

  const CardTitularidad = (cardData: CardDataType, service: string) => {
    const bgAlert = getBgAlertTitularidad(cardData.alert);
    // const cardLegendMap = getFooterText(service, cardData.titleKey);
    const percentage = cardData.cantVal && cardData.appVal ? cardData.appVal : 0;
    const title = cardData.titleKey;
    const handleClickCard = () => {
      //When clicked, move to the corresponding graph inside the view
      const graph = document.getElementById(title);
      if (graph) {
        graph.scrollIntoView({ behavior: "smooth" });
      }
    };

    return (
      <IonCard className="content-card-titularidad">
        <IonCardHeader className="summary-card-header" onClick={() => handleClickCard()}>
          {bgAlert.icon}
          <IonCardTitle className="summary-card-title-titularidad">{title}</IonCardTitle>
        </IonCardHeader>
        <IonCardContent className="content-card-ion card-center" >
          <IonRow>
            <IonCol>
              <IonText className="card-text">
                {cardData.cantVal ? "\nTRX: " + cardData.cantVal : "\nTRX: " + 0}
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <div>
                <IonText className="card-text">
                  {cardData.appVal ? cardData.appVal + "%" : "0%"}
                </IonText>
                <IonProgressBar
                  color={bgAlert.color}
                  value={percentage / 100}
                ></IonProgressBar>
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonText className="card-text-minutes"> Últimos {cardData.gap} minutos </IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonText className="card-text-alternative">
              </IonText>
            </IonCol>
          </IonRow>
        </IonCardContent>
      </IonCard>
    );
  };

  return (
    <><IonCard className="cards-container">
      <IonCardHeader className="cards-container-header"
        onClick={() => setCardsOpen(!cardsOpen)}>
        <IonCardTitle className="card-title">
          {cardsOpen ? `Ocultar Cards` : `Mostrar Cards`}
        </IonCardTitle>
        <IonIcon className="displayCards-icon" icon={cardsOpen ? chevronUpOutline : chevronDownOutline} />
      </IonCardHeader>
      <IonCardContent className={cardsOpen ? `cards-content` : `cards-content-hidden`}>
        <IonGrid className="card-row">
          {renderCardsMusimundo()}
        </IonGrid>
        <IonGrid className="card-row">
          {renderCards()}
        </IonGrid>
      </IonCardContent>
    </IonCard>
      <IonCard className="cards-container">
        {renderTitularidad()}
      </IonCard>
    </>
  );
};

//Redux state and actions dispatchers

interface StateTypes {
  session: SessionDefaultStateI;
  cards: CardsDefaultStateI;
}

const mapStateToProps = (state: StateTypes) => {
  return {
    session: state.session,
    cards: state.cards,
  };
};

interface dispatchTypes {
  getCards: () => void;
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getCards: () => dispatch(GetCards()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cards);