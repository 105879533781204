import React, { useEffect, useState } from 'react';
import { IonCard, IonCardContent, IonCol, IonGrid, IonRow } from '@ionic/react';

import { Chart } from 'chart.js';

import './userStatistics.css';

interface Data {
    activeUsers_bank: number;
    activeUsers_retail: number;
    activeUsers_intern: number;
    activeUsers_admin: number;
    inactiveUsers: number;
}

interface Props {
    data: Data
}

const UsersGraphicsGeneral: React.FC<Props> = (props): JSX.Element => {
    let total = props.data.activeUsers_bank + props.data.activeUsers_retail + props.data.activeUsers_intern + props.data.activeUsers_admin + props.data.inactiveUsers;
    const graphID = `graphUser-generalStatistics`
    const [totalUsers, setTotalUsers] = useState<Number>(0)
    const [totalUsersActive, setTotalUsersActive] = useState<Number>(0)

    useEffect(() => {
        const sum = Object.values(props.data).reduce((a: any, b: any) => a + b)
        setTotalUsers(sum)
        setTotalUsersActive(props.data.activeUsers_bank + props.data.activeUsers_retail + props.data.activeUsers_intern + props.data.activeUsers_admin)
        renderGraphicBar()
    }, [props.data])

    const renderGraphicBar = () => {

        const data: any = {
            labels: [
                "Usuarios Activos Newpay", "Usuarios Activos Payway",
                "Usuarios Activos Prisma", "Usuarios inactivos"
            ],
            datasets: [{
                label: " ",
                data: [props.data.activeUsers_bank, props.data.activeUsers_retail,
                props.data.activeUsers_intern + props.data.activeUsers_admin, props.data.inactiveUsers],
                backgroundColor: [
                    '#20BABB',
                    '#FED902',
                    '#E63558',
                    '#C4C4C4'
                ],
                hoverOffset: 1,
                borderWidth: 0,
            }]
        }

        const tooltip = {
            displayColors: false,
            callbacks: {
                label: function (item: any) {
                    const values = myPieChart.data.datasets[0].data[item.dataIndex];
                    const percentage = Math.round((values * 100 / total))
                    return `${percentage}%`
                }
            }
        }

        const options: any = {
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: tooltip
            },
        };

        const canvas = document.getElementById(graphID) as HTMLCanvasElement;
        const myPieChart: any = new Chart(canvas, {
            type: "pie",
            data: data,
            options: options,
            plugins: [
                {
                    id: "chart",
                    beforeInit: function (myPieChart: any, args: any, options: any) {
                        if (myPieChart.canvas.id === graphID) {
                            const ul = document.createElement("ul");
                            myPieChart.data.labels.forEach((label: any, i: string | number) => {
                                ul.innerHTML += `
                                    <li>
                                    <span style="background-color: ${myPieChart.data.datasets[0].backgroundColor[i]}"></span>
                                    ${label}: ${myPieChart.data.datasets[0].data[i]}
 
                                    </li>
                                  `;
                            });
                            return document.getElementById(`canvas-content-${graphID}`)?.appendChild(ul);
                        }
                    },
                },
            ],
        });
    }

    return (
        <IonCardContent className="content-card-user-general">
            <IonRow>
                <IonCol sizeXl='12' sizeLg='12' sizeSm="5" sizeXs="12">
                    <IonGrid className="user-content-canvas-graphic">
                        <canvas id={graphID}></canvas>
                    </IonGrid>
                </IonCol>
                <IonCol className="user-content-canvas-legend general" id={`canvas-content-${graphID}`}>
                </IonCol>
                <IonCol sizeXl='12' sizeLg='12' sizeMd='12' sizeSm="12" sizeXs="12">
                    <IonCard className="user-table-card user-table-tables">
                        <IonCardContent>
                            <IonRow >
                                <IonCol size="12" className='user-table-title-tables user-general'>USUARIOS</IonCol>
                            </IonRow>
                            <IonRow className="user-table-rows user-general">
                                <IonCol size="9">Total de Usuarios</IonCol>
                                <IonCol size="3" >{totalUsers}</IonCol>
                            </IonRow>
                            <IonRow className="user-table-rows user-general">
                                <IonCol size="9">Cant. de Usuarios Activos</IonCol>
                                <IonCol size="3" >{totalUsersActive}</IonCol>
                            </IonRow>
                            <IonRow className='user-general'>
                                <IonCol size="9">Cant. de Usuarios Inactivos</IonCol>
                                <IonCol size="3" >{props.data.inactiveUsers}</IonCol>
                            </IonRow>
                        </IonCardContent>
                    </IonCard>
                </IonCol>
            </IonRow>
        </IonCardContent>
    )
}

export default UsersGraphicsGeneral