import { ABMClientsType, GetABMCLIENTSDispatchTypes, GET_ABMCLIENTS_FAIL, GET_ABMCLIENTS_LOADING, GET_ABMCLIENTS_SUCCESS } from './ABMClientsActionTypes';


export interface ABMClientsDefaultStateI {
    loading : boolean,
    abmclients? : ABMClientsType[] | undefined
}


const defaultState : ABMClientsDefaultStateI = {
    loading : false
};


const ABMClientsReducer = (state: ABMClientsDefaultStateI = defaultState, action:GetABMCLIENTSDispatchTypes): ABMClientsDefaultStateI =>{
    switch (action.type) {
        case GET_ABMCLIENTS_FAIL:
            return {
                loading:false
            }
            break;
        case GET_ABMCLIENTS_LOADING:
            return {
                loading:true
            }
            break;
        case GET_ABMCLIENTS_SUCCESS:
            return {
                loading:false,
                abmclients:action.payload
            }
            break;   
        default:
            return state
            break;
    }
    return state;
}


export default ABMClientsReducer;