import { combineReducers } from "redux";

import sessionReducer from "./session/SessionReducer"
import graphicsReducer from './mainInfo/graphicsReducer'
import cardsReducer from './mainInfo/cardsReducer'
import messagesPrismaReducer from './mainInfo/messagesPrismaReducer'
import kpiGroupsMenuReducer from './mainInfo/kpiGroupsForMenuReducer'
import alertsTwoHoursReducer from './alerts/alertsTwoHoursReducer'
import alertsLastMinuteReducer from './alerts/alertsLastMinuteReducer'
import clientImageReducer from './mainInfo/clientImageReducer'
import userInfoReducer from './userInfo/userInfoReducer'
import lastMessagesReducer from "./lastMessagesPrisma/lastMessagesReducer";
import clientMaintenanceReducer from './mainInfo/clientMaintenanceReducer';
import ABMClientsReducer from "./ABMClients/ABMClientsReducer";
import showUmbralsReducer from './showUmbrals/showUmbralsReducer';
import ABMUsersReducer from './ABMUsers/ABMUsersReducer';
import clientsInMaintenanceReducer from './clientsInMaintenance/clientInMaintenanceReducer';
import clientNotificationsReducer from "./clientNotifications/clientNotificationsReducer";
import userStatisticsCardReducer from "./userStatistics/userStatisticsCard/userStatisticsCardsReducer";
import userSpecifyCardReducer from "./userStatistics/userSpecifyCard/userSpecifyCardReducer";
import userStatisticsClientReducer from "./userStatistics/userStatisticsClient/userStatisticsClientReducer";
import userDeleteClientReducer from "./userStatistics/userDeleteClient/userDeleteClientReducer";
import internosCardsReducer from "./Internos/InternosCards/internosCardsReducer";
import internosGraphicsReducer from "./Internos/InternosGraphics/internosGraphicsReducer";
import internosAlertsReducer from "./Internos/InternosAlerts/internosAlertsReducer";
import ABMInternReducer from "./ABMMetric/ABMIntern/ABMInternReducer";
import ABMKpisGroupsReducer from "./ABMMetric/ABMKpisGroups/ABMKpisGroupsReducer";
import ABMKpisReducer from "./ABMMetric/ABMKpis/ABMKpisReducer";
import changeStateReducer from "./changeState/changeStateReducer";

const RootReducer = combineReducers({
    session:sessionReducer,
    graphics: graphicsReducer,
    cards: cardsReducer,
    messagesPrisma: messagesPrismaReducer,
    kpiGroupsMenu: kpiGroupsMenuReducer,
    alertsTwoHours: alertsTwoHoursReducer,
    alertsLastMinute: alertsLastMinuteReducer,
    clientImage: clientImageReducer,
    userInfo: userInfoReducer,
    lastMessages: lastMessagesReducer,
    clientMaintenance: clientMaintenanceReducer,
    ABMClients: ABMClientsReducer,
    ABMUsers: ABMUsersReducer,
    showUmbrals: showUmbralsReducer,
    clientsInMaintenance: clientsInMaintenanceReducer,
    clientNotifications: clientNotificationsReducer,
    userStatisticsCard: userStatisticsCardReducer,
    userSpecifyCard: userSpecifyCardReducer,
    userStatisticsClient : userStatisticsClientReducer,
    userDeleteClient : userDeleteClientReducer,
    internCards: internosCardsReducer,
    internGraphics: internosGraphicsReducer,
    internAlerts: internosAlertsReducer,
    ABMKpis:ABMKpisReducer,
    ABMKpisGroups: ABMKpisGroupsReducer,
    ABMIntern: ABMInternReducer,
    changeState: changeStateReducer,
});

export type RootStore = ReturnType<typeof RootReducer>;

export default RootReducer;
