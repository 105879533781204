import React, { useState } from "react";
import { IonCard, IonCardHeader, IonCol, IonIcon, IonRow, IonText } from "@ionic/react";
import { chevronDownOutline, chevronUpOutline, ellipse } from "ionicons/icons";

import InternGraphics from "../Internos/InternGraphics";

import './Debin.css';

interface Props {
    titleCardGraphic: string,
    graphicInfo: any
    cardData?: any,
    type?: string
}

const DebinCardGraphics: React.FC<Props> = (props): JSX.Element => {

    const [graphicOpen, setGraphicOpen] = useState(false);

    const renderCardGraphics = () => {
        switch (props.type) {
            case 'reversedGeneral':
                return (
                    <IonCard>
                        <InternGraphics
                            key={props.graphicInfo[0].key}
                            internData={props.graphicInfo[0]}
                            id={props.graphicInfo[0].intern_title_key}
                            alerted={false}
                            type={props.type}
                        />
                    </IonCard>
                )
            case 'combined':
                return (
                    <IonCard>
                        <InternGraphics
                            key={props.graphicInfo[0].key}
                            internData={props.graphicInfo[0]}
                            internDataCoelsa={props.graphicInfo}
                            id={props.graphicInfo[0].intern_title_key + props.graphicInfo[1].intern_title_key}
                            title={props.graphicInfo[0].intern_title_key + "(LAPP-PRPM588 / LAPP-PRPM589 / LAPP-PRPM1105 / LAPP-PRPM1106)"}
                            alerted={false}
                            type={props.type}
                        />
                    </IonCard>
                )
            case 'tiempoJPOS':
                return (
                    <IonCard>
                        <InternGraphics
                            key={props.graphicInfo[0].key}
                            internData={props.graphicInfo[0]}
                            internDataTime={props.graphicInfo}
                            id={props.graphicInfo[0].intern_title_key}
                            alerted={false}
                            title={"AVG-TIME-150001 / AVG-TIME-150100 / AVG-TIME-151100"}
                            type={props.type}
                        />
                    </IonCard>
                )
            case 'tiempoApache':
                return (
                    <IonCard>
                        <InternGraphics
                            key={props.graphicInfo[0].key}
                            internData={props.graphicInfo[0]}
                            id={props.graphicInfo[0].intern_title_key}
                            alerted={false}
                            type={props.type}
                        />
                    </IonCard>
                )
                case 'lifespan':
                return (
                    <IonCard>
                        <InternGraphics
                            key={props.graphicInfo[0].key}
                            internData={props.graphicInfo[0]}
                            internDataTime={props.graphicInfo}
                            id={props.graphicInfo[0].intern_title_key}
                            alerted={false}
                            title={"LIFESPAN TOTAL 588 / LIFESPAN TOTAL 589"}
                            type={props.type}
                        />
                    </IonCard>
                )
            case 'entities':
                if (props.cardData) {
                    return (
                        <IonCard className={graphicOpen ? `debin-graphics-content` : `debin-graphics-content-hidden`}>
                            <IonRow>
                                <IonCol sizeXl="6" sizeLg="6" sizeMd="12" sizeXs='12'>
                                    <InternGraphics
                                        key={props.graphicInfo[0].key}
                                        title={props.graphicInfo[0].intern_title_key}
                                        internData={props.graphicInfo[0]}
                                        id={props.graphicInfo[0].intern_title_key}
                                        alerted={props.cardData.alert !== "0" ? true : false}
                                        type={props.type} />
                                </IonCol>
                            </IonRow>
                        </IonCard>
                    )
                }
        }
    }

    const cssLifespan = () => {
        if(props.type == 'lifespan'){
            if(props.cardData[0].alert == 0 && props.cardData[1].alert == 0){
                return ("lifespan-icon")
            }else if ( props.cardData[0].alert !== 0 || props.cardData[1].alert !== 0){
                return("lifespan-icon-alerted")
            }
        }else{
            return ("lifespan-icon-undefined")
        }
    }

    return (
        <IonCard>
            {
                props.titleCardGraphic.includes("ENTIDADES") ?
                    <IonCardHeader className='intern-header' onClick={() => setGraphicOpen(!graphicOpen)}>
                        {props.titleCardGraphic}
                        <IonIcon icon={graphicOpen ? chevronUpOutline : chevronDownOutline} className="displayGraphics-icon" />
                    </IonCardHeader>
                    :
                    props.titleCardGraphic.includes("LIFESPAN") ?
                    <IonCardHeader className='intern-header debin-padding'>
                        <IonIcon  icon={ellipse} className={cssLifespan()}/>
                        <IonText>{props.titleCardGraphic}</IonText>
                    </IonCardHeader>
                    :
                    <IonCardHeader className='intern-header debin-padding'>
                        <IonText>{props.titleCardGraphic}</IonText>
                    </IonCardHeader>
            }
            {
                props.graphicInfo && props.graphicInfo.length > 0 && renderCardGraphics()
            }
        </IonCard>
    )
}

export default DebinCardGraphics;