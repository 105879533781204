export const GET_USERSTATISTICSCARD_LOADING = 'GET_USERSTATISTICSCARD_LOADING';
export const GET_USERSTATISTICSCARD_FAIL = 'GET_USERSTATISTICSCARD_FAIL';
export const GET_USERSTATISTICSCARD_SUCCESS = 'GET_USERSTATISTICSCARD_SUCCESS';



export type UserStatisticsCardRow ={
    activeUsers_bank: number;
    activeUsers_retail:number;
    activeUsers_intern:number;
    activeUsers_admin:number;
    inactiveUsers:number;
};


export interface GetUserStatisticsCardLoading{
    type: typeof GET_USERSTATISTICSCARD_LOADING
}

export interface GetUserStatisticsCardFail{
    type: typeof GET_USERSTATISTICSCARD_FAIL
}

export interface GetUserStatisticsCardSuccess{
    type: typeof GET_USERSTATISTICSCARD_SUCCESS,
    payload: UserStatisticsCardRow
}



export type GetUserStatisticsCardDispatchTypes = GetUserStatisticsCardLoading | GetUserStatisticsCardFail | GetUserStatisticsCardSuccess;