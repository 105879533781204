//DEFINE THE ACTIONS TO TRIGGER
export const GET_USER_INFO_LOADING = 'GET_USER_INFO_LOADING';
export const GET_USER_INFO_FAIL = 'GET_USER_INFO_FAIL';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';


export type UserInfoType =  {
    username: string;
    bank_id: string;
    email: string;
    first_name: string;
    last_name: string;
    notifications: string[];
    password: string;
    phone_number: string;
};


export interface GetUserInfoLoading {
    type: typeof GET_USER_INFO_LOADING
}

export interface GetUserInfoFail {
    type: typeof GET_USER_INFO_FAIL
}

export interface GetUserInfoSuccess {
    type: typeof GET_USER_INFO_SUCCESS,
    payload:UserInfoType
}

export type GetUserInfoDispatchTypes = GetUserInfoLoading | GetUserInfoFail | GetUserInfoSuccess;