import { ABMKpisType, GetABMKPISDispatchTypes, GET_ABMKPIS_FAIL, GET_ABMKPIS_LOADING, GET_ABMKPIS_SUCCESS } from './ABMKpisActionsTypes';

export interface ABMKpisDefaultStateI {
    loading : boolean,
    abmkpis? : ABMKpisType[] | undefined
}

const defaultState : ABMKpisDefaultStateI = {
    loading : false
};

const ABMKpisReducer = (state: ABMKpisDefaultStateI = defaultState, action:GetABMKPISDispatchTypes): ABMKpisDefaultStateI =>{

    switch (action.type) {
        case GET_ABMKPIS_FAIL:
            return {
                loading:false
            }
            break;
        case GET_ABMKPIS_LOADING:
            return {
                loading:true
            }
            break;
        case GET_ABMKPIS_SUCCESS:
            return {
                loading:false,
                abmkpis:action.payload
            }
            break;
        default:
            return state
            break;
    }
    return state;
}

export default ABMKpisReducer;