import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ApiRequestor from '../../api_access/ApiRequestor';
import { IonGrid } from '@ionic/react';

import { SessionDefaultStateI } from '../../store/session/SessionReducer';
import { MessagesPrismaDefaultStateI } from '../../store/mainInfo/messagesPrismaReducer';
import { GetMessagesPrisma } from '../../store/mainInfo/mainInfoActions';
import { MessagesPrismaType } from '../../store/mainInfo/mainInfoActionsTypes';

import Message from './Message';

import './MessagesPrisma.css';
import Loading from '../Loading/Loading';

interface Props extends StateTypes, dispatchTypes {
    scrolling?: boolean
    hasMessages?: any
    handleOpenMsg?: any
    notifColor?: any
}

const MessagesPrisma: React.FC<Props> = (props): JSX.Element => {
    const [messagesInfo, setMessagesInfo] = useState<any>([])
    const bank_id = props.session.loginData.getIdToken().decodePayload()["custom:bank_id"].toUpperCase()
    const bank_id_category = props.session.loginData.getIdToken().decodePayload()["custom:category"]

    useEffect(() => {
        props.getMessagesPrisma()
    }, [sessionStorage.getItem("client")])

    useEffect(() => {
        let messagesData: any = []
        props.hasMessages(false)
        if (bank_id_category !== 'ADMIN') {
            if (props.messagesPrisma.loading == false && props.messagesPrisma.messagesPrisma) {
                messagesData = props.messagesPrisma.messagesPrisma[0].messages
            }
        } else {
            if (sessionStorage.getItem("client") && sessionStorage.getItem("client") !== "POS") {
                if (props.messagesPrisma.loading == false && props.messagesPrisma.messagesPrisma) {
                    if (sessionStorage.getItem("client") && sessionStorage.getItem("client") !== '') {
                        let messages = props.messagesPrisma.messagesPrisma.filter((message: MessagesPrismaType) => message.bank_id == sessionStorage.getItem("client"))
                        messagesData = messages[0].messages
                    }
                }
            }
        }
        if (messagesData.length > 0) {
            props.hasMessages(true)
        }
        setMessagesInfo(messagesData)
    }, [sessionStorage.getItem("client"), props.messagesPrisma.loading, props.messagesPrisma.messagesPrisma])

    const closeMessage = async (messageId: number) => {
        let serviceName: any = ''
        if (bank_id_category !== 'ADMIN') {
            serviceName = bank_id
        } else {
            serviceName = sessionStorage.getItem("client")
        }
        let data: any = { body: { message_id: messageId, service: serviceName } }
        const response = await ApiRequestor.requestToApi(
            "/main/messages-prisma/closed",
            "POST",
            data)
        props.hasMessages(false)
    }

    return (
        <IonGrid className={props.scrolling ? "messages-with-scroll" : "messages-without-scroll"}>
            {messagesInfo.length > 0 &&
                messagesInfo.map((message: any) => {
                    return (
                        <Message key={message.message_swt_id} message={message} scrolling={props.scrolling} closeMessage={closeMessage} handleOpenMsg={props.handleOpenMsg} notifColor={props.notifColor} />
                    )
                })
            }
        </IonGrid>
    )
}

//Redux state and actions dispatchers

interface StateTypes {
    session: SessionDefaultStateI,
    messagesPrisma: MessagesPrismaDefaultStateI,
}

const mapStateToProps = (state: StateTypes) => {
    return {
        session: state.session,
        messagesPrisma: state.messagesPrisma
    };
};

interface dispatchTypes {
    getMessagesPrisma: () => void
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getMessagesPrisma: () => dispatch(GetMessagesPrisma()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MessagesPrisma);