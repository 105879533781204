import ApiRequestor from "../../../api_access/ApiRequestor";
import {Dispatch} from "redux"
import {GetUserDeleteClientDispatchTypes, GET_USERDELETECLIENT_FAIL, GET_USERDELETECLIENT_LOADING, GET_USERDELETECLIENT_SUCCESS} from './userDeleteClientActionsTypes'


export const GetUserDeleteClient = () => async (dispatch: Dispatch<GetUserDeleteClientDispatchTypes>) => {
    try {
        dispatch({
            type: GET_USERDELETECLIENT_LOADING
        })
        const response: any = await ApiRequestor.requestToApi("/user/deleted-user","GET");
        dispatch({
            type: GET_USERDELETECLIENT_SUCCESS,
            payload: response.data
        })
    }
    
    catch(error){
        dispatch({
            type: GET_USERDELETECLIENT_FAIL
        })
    }
}
