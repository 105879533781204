import { ClientNotificationsType, GetClientNotificationsDispatchTypes, GET_CLIENTNOTIFICATIONS_FAIL, GET_CLIENTNOTIFICATIONS_LOADING, GET_CLIENTNOTIFICATIONS_SUCCESS } from './clientNotificationsActionTypes';


export interface ClientNotificationsDefaultStateI{
    loading: boolean,
    clientNotifications?: ClientNotificationsType[] | any
}

const defaultState: ClientNotificationsDefaultStateI = {
    loading:false
}

const clientNotificationsReducer = (state: ClientNotificationsDefaultStateI = defaultState,action: GetClientNotificationsDispatchTypes ): ClientNotificationsDefaultStateI =>{
    switch (action.type) {
        case GET_CLIENTNOTIFICATIONS_FAIL:
            return {
                loading:false
            }
            break;
        case GET_CLIENTNOTIFICATIONS_LOADING:
            return {
                loading:true
            }
            break;
        case GET_CLIENTNOTIFICATIONS_SUCCESS:
            return {
                loading:false,
                clientNotifications:action.payload
            }
            break;
        default:
            return state
            break;
    }
    return state;
}


export default clientNotificationsReducer;