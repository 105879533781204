import { IonToggle } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';

import './ClientsInMaintenance.css';

interface Props {
  check: boolean,
  parentCallback: (check: boolean) => void
}

const ToggleMaintenance: React.FC<Props> = ({ check, parentCallback }) => {
  const [checked, setChecked] = useState<boolean>(check)
  const prevChecked = usePrevious(checked)
  const handleChange = () => {
    setChecked(!checked)
    window.location.reload()
  }

  if (prevChecked !== undefined && prevChecked !== checked) {
    parentCallback(checked)
  }

  return (
    <IonToggle color="secondary" checked={checked} onIonChange={handleChange} slot="start" mode="ios" className="toggle-size-maintenance ion-margin-top" />
  );
};

export default ToggleMaintenance;

function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}