import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  IonHeader, IonToolbar, IonItem, IonThumbnail, IonButtons, IonMenuButton, IonImg, IonTitle,
  IonIcon, IonButton
} from '@ionic/react';
import { peopleCircle, personCircle } from 'ionicons/icons';

import { ClientImageDefaultStateI } from '../../store/mainInfo/clientImageReducer';
import { SessionDefaultStateI } from '../../store/session/SessionReducer';
import { GetClientImage } from '../../store/mainInfo/mainInfoActions';
import { ClientImageMenuType } from '../../store/mainInfo/mainInfoActionsTypes';

import TimerComponent from '../timer/Timer';
import StyleSwitch from '../StyleSwitch/StyleSwitch'
import TimerDecidir from '../timer/TimerDecidir';

import './Header.css';
import TimerInternos from '../timer/TimerInternos';

interface Props extends StateTypes, dispatchTypes {
  serviceLogo?: any,
  headerText?: string,
  timer?: boolean,
  parentCallback?: any,
  clientSelected?: string,
}

const Header: React.FC<Props> = (props): JSX.Element => {
  const title = props.headerText ? props.headerText : "Estado General"
  const bank_id = props.session.loginData.getIdToken().decodePayload()["custom:bank_id"].toUpperCase()
  const bank_id_category = props.session.loginData.getIdToken().decodePayload()["custom:category"]
  const [imageInfo, setImageInfo] = useState<any>()

  useEffect(() => {
    if (props.session.loading === false && props.session.loginData ) {
      if(!props.clientImage.clientImage){
        props.getClientImage()
      }
    }
  }, [])

  useEffect(() => {
    let imageData
    if (bank_id_category !== "ADMIN") {
      if (props.clientImage.loading === false && props.clientImage.clientImage) {
        imageData = props.clientImage.clientImage[0].service_image
      }
    } else if (props.clientImage.loading === false && props.clientImage.clientImage && sessionStorage.getItem("client") && sessionStorage.getItem("client") !== '') {
      let image = props.clientImage.clientImage.filter((clientImage: ClientImageMenuType) => clientImage.service_name == sessionStorage.getItem("client"))
      imageData = image[0].service_image
    }
    setImageInfo(imageData)
  }, [sessionStorage.getItem("client"), props.clientImage.loading, props.clientImage.clientImage])

  return (
    <IonHeader className="main-header">
      <IonToolbar className="toolbar-container" mode="md" >
        <IonButtons slot="start" style={{ padding: 0, "--inner-border-width": 0 }}>
          {window.screen.width < 992 ? <IonMenuButton /> : <div style={{ display: "none" }} />}
        </IonButtons>
        <IonItem className="content-items" style={{ padding: 0, "--inner-border-width": 0 }}>
        {
          title === 'Newpay' ?
          <IonTitle className="ion-no-padding titleHeader" size="small">PULL/PUSH</IonTitle>
          :
          <IonTitle className="ion-no-padding titleHeader" size="small">{title}</IonTitle>
        }

          <IonThumbnail slot="start" className="header-thumbnail">
            {imageInfo && (window.location.pathname.includes("kpigroup") || window.location.pathname.includes("pos") ||
              window.location.pathname.includes("reports") || window.location.pathname.includes("messagesHistorial")) ?
              <IonImg className="image-header" src={`data:${"image/png"};base64,${Buffer.from(imageInfo).toString("base64")}`} />
              :
              <IonIcon icon={personCircle} className="icon-header" />
            }
          </IonThumbnail>
          {(bank_id_category == "ADMIN") &&
            window.screen.width >= 768 && (window.location.pathname.includes("kpigroup") ||
              window.location.pathname.includes("reports") || window.location.pathname.includes("messagesHistorial")) &&
            <IonButton title="Seleccionar cliente" fill="clear" type="button" className="header-button" onClick={() => props.parentCallback()}> <IonIcon icon={peopleCircle} className="header-icon-select-client" /></IonButton>
          }
          {
             (props.timer && (bank_id == "POS" || sessionStorage.getItem("client") == "POS" || bank_id == "RESOLUTORES" || sessionStorage.getItem("client") == "RESOLUTORES" || bank_id == "DEBIN" || sessionStorage.getItem("client") == "DEBIN" || bank_id == "NEWPAY" || sessionStorage.getItem("client") == "NEWPAY" ))  ?
             <TimerInternos clientSelected={sessionStorage.getItem("client") && sessionStorage.getItem("client") !== '' ? sessionStorage.getItem("client") : ''}/> :

             (props.timer && (bank_id == "DECIDIR" || sessionStorage.getItem("client") == "DECIDIR"))  ?
             <TimerDecidir clientSelected={sessionStorage.getItem("client") && sessionStorage.getItem("client") !== '' ? sessionStorage.getItem("client") : ''}/> :

            //  (props.timer && (bank_id == "RESOLUTORES" || sessionStorage.getItem("client") == "RESOLUTORES"))  ?
            //  <TimerInternos clientSelected={sessionStorage.getItem("client") && sessionStorage.getItem("client") !== '' ? sessionStorage.getItem("client") : ''}/> :
             
            //  (props.timer && (bank_id == "DEBIN" || sessionStorage.getItem("client") == "DEBIN"))  ?
            //  <TimerInternos clientSelected={sessionStorage.getItem("client") && sessionStorage.getItem("client") !== '' ? sessionStorage.getItem("client") : ''}/> :

             props.timer && <TimerComponent clientSelected={sessionStorage.getItem("client") && sessionStorage.getItem("client") !== '' ? sessionStorage.getItem("client") : ''}/>
             }
          
          <StyleSwitch />
        </IonItem>
      </IonToolbar>
    </IonHeader>
  )
}

//Redux state and actions dispatchers
interface StateTypes {
  session: SessionDefaultStateI,
  clientImage: ClientImageDefaultStateI
}

const mapStateToProps = (state: StateTypes) => {
  return {
    session: state.session,
    clientImage: state.clientImage,
  };
};

interface dispatchTypes {
  getClientImage: () => void;
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getClientImage: () => dispatch(GetClientImage()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header)