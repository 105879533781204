import { GetUserSpecifyCardDispatchTypes, GET_USERSPECIFYCARD_FAIL, GET_USERSPECIFYCARD_LOADING, GET_USERSPECIFYCARD_SUCCESS, UserSpecifyCardType } from './userSpecifyCardActionsTypes';

export interface UserSpecifyCardDefaultStateI {
    loading:boolean,
    userSpecifyCard?: UserSpecifyCardType[]
}

const defaultState : UserSpecifyCardDefaultStateI = {
    loading:false
}; 

const userSpecifyCardReducer = (state: UserSpecifyCardDefaultStateI = defaultState, action: GetUserSpecifyCardDispatchTypes) : UserSpecifyCardDefaultStateI =>{
    switch (action.type) {
        case GET_USERSPECIFYCARD_FAIL:
            return {
                loading : false
            }
            break;
        case GET_USERSPECIFYCARD_LOADING:
            return {
                loading : true
            }
            break;
        case GET_USERSPECIFYCARD_SUCCESS:
            return {
                loading : false,
                userSpecifyCard : action.payload
            }
            break;
        default:
            return state
            break;
    }
    return state;
}


export default userSpecifyCardReducer;
