//DEFINE THE ACTIONS TO TRIGGER
export const GET_ALERTS_TWO_HOURS_LOADING = 'GET_ALERTS_TWO_HOURS_LOADING';
export const GET_ALERTS_TWO_HOURS_FAIL = 'GET_ALERTS_TWO_HOURS_FAIL';
export const GET_ALERTS_TWO_HOURS_SUCCESS = 'GET_ALERTS_TWO_HOURS_SUCCESS';

export const GET_ALERTS_LAST_MINUTE_LOADING = 'GET_ALERTS_LAST_MINUTE_LOADING';
export const GET_ALERTS_LAST_MINUTE_FAIL = 'GET_ALERTS_LAST_MINUTE_FAIL';
export const GET_ALERTS_LAST_MINUTE_SUCCESS = 'GET_ALERTS_LAST_MINUTE_SUCCESS';


type GraphicInfo = {
    kpi_id: number;
    kpi_name: string;
    kpi_host: string;
    kpi_field: string;
    kpi_type: string;
    kpi_transform: boolean;
    kpi_endpoint_id: string;
    kpi_group_id: number;
    kpi_title: string;
    kpi_title_key: string;
    kpi_time_gap: number;
    kpi_linechart_label: string;
    kpi_threshold: boolean;
    kpi_group_name: string;
    service_name: string;
    date_alert?:Date;
    timeGap_alert?:number;
    keys?: Date[];
    values?: number[];
    limit_values?: number[]
}

type Alert = {
    timestamp: Date;
    name: string;
    value: number;
    graphic:GraphicInfo;
}

export type AlertsType =  {
    bank_id: string;
    alertsInfo: Alert[];
};

//TWO HOURS ALERTS
export interface GetAlertsTwoHoursLoading {
    type: typeof GET_ALERTS_TWO_HOURS_LOADING
}

export interface GetAlertsTwoHoursFail {
    type: typeof GET_ALERTS_TWO_HOURS_FAIL
}

export interface GetAlertsTwoHoursSuccess {
    type: typeof GET_ALERTS_TWO_HOURS_SUCCESS,
    payload:AlertsType[]
}

//LAST MINUTE ALERTS
export interface GetAlertsLastMinuteLoading {
    type: typeof GET_ALERTS_LAST_MINUTE_LOADING
}

export interface GetAlertsLastMinuteFail {
    type: typeof GET_ALERTS_LAST_MINUTE_FAIL
}

export interface GetAlertsLastMinuteSuccess {
    type: typeof GET_ALERTS_LAST_MINUTE_SUCCESS,
    payload:AlertsType[]
}

export type GetAlertsDispatchTypes = GetAlertsTwoHoursLoading | GetAlertsTwoHoursFail | 
                                       GetAlertsTwoHoursSuccess | GetAlertsLastMinuteLoading |
                                       GetAlertsLastMinuteFail | GetAlertsLastMinuteSuccess;
