import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCol, IonIcon, IonRow, IonText } from "@ionic/react";
import { ellipse } from "ionicons/icons";

import { InternosGraphicsDefaultStateI } from "../../store/Internos/InternosGraphics/internosGraphicsReducer";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

import ResolutoresMinCard from "./ResolutoresMinCard";
import InternGraphics from "../Internos/InternGraphics";

import "./Resolutores.css";

interface Props extends StateTypes, dispatchTypes {
    cardData: any;
    graphicsData: any;
}

const ResolutoresCard: React.FC<Props> = (props): JSX.Element => {

    const [sizePercentage, setSizePercentage] = useState<number>(50)

    const sizes: { [key: number]: number } = {
        360: 100,
        750: 110,
        1920: 120,
        2560: 140,
        3800: 200
    }

    useEffect(() => {
        for (let key of Object.keys(sizes)) {
            if (parseInt(key) <= window.screen.width) {
                setSizePercentage(sizes[parseInt(key)])
            }
        }
    }, [window.screen.width])

    // const sizeTimer = window.screen.width < 992 ? 100 : window.screen.width < 2000 ? 150 : 200;
    const [varUmbral, setVarUmbral] = useState(0)
    const [varUmbralTRX, setVarUmbralTRX] = useState(0)
    const [cardInfo, setCardInfo] = useState<any>([])

    useEffect(() => {
        if (props.graphicsData.length > 0) {
            let graphicsFilter: any = props.graphicsData.filter((graphic: any) => graphic.intern_title_key == props.cardData.titleKey)
            setCardInfo(graphicsFilter)
        }
    }, [props.graphicsData, props.cardData])

    useEffect(() => {
        if (cardInfo.length > 0) {
            cardInfo.map((graphic: any) => {
                if (graphic.intern_title.includes("APROBADAS") || graphic.intern_title.includes("Aprobadas")) {
                    const umbral = graphic.limit_values[graphic.limit_values.length - 1]
                    setVarUmbral(umbral)
                } else {
                    const umbralTRX = graphic.limit_values[graphic.limit_values.length - 1]
                    setVarUmbralTRX(umbralTRX)
                }
            })
        }
    }, [props.graphicsData, props.cardData, cardInfo])

    return (
        <IonCard className="resolutor-card-content">
            <IonCardHeader className="intern-header">
                <IonRow>
                    <IonIcon className={props.cardData.cantAlert !== "0" ? "intern-icon-alerted" : "intern-icon"} icon={ellipse} />
                    <IonText>{props.cardData.titleKey}</IonText>
                </IonRow>
            </IonCardHeader>
            <IonCardContent class="resolutor-padding">
                <IonRow className='ion-align-items-center'>
                    <div className="intern-content-card">
                        <IonCol className="intern-group resolutor-group-content">
                            <IonCardSubtitle className="intern-approved">% Aprobadas</IonCardSubtitle>
                            <div className="intern-percentage">
                                <CountdownCircleTimer
                                    isPlaying={false}
                                    duration={100}
                                    colors={props.cardData.appAlert !== "0" ? "#e73557" : "#29b2b7"}
                                    initialRemainingTime={props.cardData.appVal}
                                    rotation={"counterclockwise"}
                                    strokeWidth={8}
                                    size={sizePercentage} >
                                    {({ remainingTime }) => {
                                        const time = remainingTime
                                        return (
                                            <div>
                                                <IonText className="intern-percentage-text"> {time} %</IonText>
                                            </div>
                                        )
                                    }}
                                </CountdownCircleTimer>
                            </div>
                            <IonCardSubtitle  className="intern-refresh">Ult. Refresh {props.cardData.ultRefresh.slice(11,)} Hs</IonCardSubtitle >
                        </IonCol>
                        <IonCol className="intern-group resolutor-group-content">
                            <IonRow className="ion-align-items-center intern-row-card">
                                <IonCol size="8" className="intern-subtitulos">Total TRX últimos 5'</IonCol>
                                <IonCol size="4" >{props.cardData.cantVal}</IonCol>
                            </IonRow>
                            <IonRow className="ion-align-items-center intern-row-card">
                                <IonCol size="8" className="intern-subtitulos">Total TRX Aprobadas</IonCol>
                                <IonCol size="4">{Math.round((props.cardData.cantVal * props.cardData.appVal) / 100)}</IonCol>
                            </IonRow>
                            <IonRow className="ion-align-items-center intern-row-card">
                                <IonCol size="8" className="intern-subtitulos">Umbral TRX</IonCol>
                                <IonCol size="4">{varUmbralTRX}</IonCol>
                            </IonRow>
                            <IonRow className="ion-align-items-center intern-row-card">
                                <IonCol size="8" className="intern-subtitulos">Umbral %</IonCol>
                                <IonCol size="4">{varUmbral}%</IonCol>
                            </IonRow>
                            {
                                props.cardData.responseTime ?
                                    <IonRow className="ion-align-items-center intern-row-card">
                                        <IonCol size="8" className="intern-subtitulos">Tiempo de Respuesta</IonCol>
                                        <IonCol size="4"> {props.cardData.responseTime.toFixed(2)} Seg.</IonCol>
                                    </IonRow> :
                                    <IonRow className="ion-align-items-center intern-row-card">
                                        <IonCol size="8" className="intern-subtitulos">Tiempo de Respuesta</IonCol>
                                        <IonCol size="4"> - Seg.</IonCol>
                                    </IonRow>
                            }
                        </IonCol>
                    </div>
                    {
                        props.graphicsData.length > 0 &&
                        props.graphicsData.map((graph: any, index: any) => {
                            let statusCant = false;
                            let statusPorc = false;
                            if (props.cardData.appKey === graph.intern_title && props.cardData.appAlert !== "0") {
                                if(graph.intern_title.includes("APROBADAS")) statusPorc = true
                            }
                            if (props.cardData.cantKey === graph.intern_title && props.cardData.cantAlert !== "0") {
                                if(!graph.intern_title.includes("APROBADAS")) statusCant = true
                                
                            }
                            if (graph.intern_title_key === props.cardData.titleKey) {
                                return (
                                    props.internGraphics.internosGraphics &&
                                    <IonCol key={graph.intern_id + "resolutores"} sizeLg="3.5" sizeMd="6" sizeSm="9" sizeXs="12" className="resolutor-group-content">
                                        <InternGraphics key={graph.intern_id} internData={graph} id={graph.intern_title_key} alerted={graph.intern_title.includes("APROBADAS") ? statusPorc : statusCant} title={window.screen.width >= 1920 && graph.intern_title.includes("APROBADAS") ? "APROBADAS" : window.screen.width >= 1920 && !graph.intern_title.includes("APROBADAS") ? "CANTIDAD" : graph.intern_title} />
                                    </IonCol>
                                )
                            }
                        })
                    }
                </IonRow>
                <IonRow>
                    {
                        props.cardData.hasOwnProperty("appValMini") && <ResolutoresMinCard cardData={props.cardData} />
                    }
                </IonRow>
            </IonCardContent>
        </IonCard>
    )
}

//Redux state and actions dispatchers
interface StateTypes {
    internGraphics: InternosGraphicsDefaultStateI;
}

const mapStateProps = (state: StateTypes) => {
    return {
        internGraphics: state.internGraphics,
    }
}

interface dispatchTypes { }

const mapDispatchToProps = (dispatch: any) => {
    return {}
}

export default connect(mapStateProps, mapDispatchToProps)(ResolutoresCard);