import {GetUserInfoDispatchTypes, GET_USER_INFO_FAIL, GET_USER_INFO_LOADING, GET_USER_INFO_SUCCESS, UserInfoType} from './userInfoActionTypes'


export interface UserInfoDefaultStateI {
    loading:boolean,
    userInfo?: any
}

const defaultState:UserInfoDefaultStateI = {
    loading:false
};


const userInfoReducer = (state: UserInfoDefaultStateI = defaultState,action:GetUserInfoDispatchTypes):UserInfoDefaultStateI =>{
    switch (action.type) {
        case GET_USER_INFO_FAIL:
            return {
                loading:false
            }
            break;
        case GET_USER_INFO_LOADING:
            return {
                loading:true
            }
            break;
        case GET_USER_INFO_SUCCESS:
            return {
                loading:false,
                userInfo:action.payload
            }
            break;
        default:
            return state
            break;
    }
    return state;
}


export default userInfoReducer;