import React, { useState, useEffect } from 'react';
import { IonCard, IonCardHeader, IonCol, IonIcon, IonRow, IonText } from '@ionic/react';
import { ellipse } from 'ionicons/icons';

import { CountdownCircleTimer } from 'react-countdown-circle-timer';

import dayjs from 'dayjs';

import './Decidir.css';

interface Props {
    cardData: any;
    graphicsData: any;
    cardDataPersistor: any;
}

const DecidirPrisma: React.FC<Props> = (props): JSX.Element => {

    //const sizeTimer = window.screen.width < 992 ? 100 : window.screen.width >= 1920 ? 100 : 100;
    const [varUmbral, setVarUmbral] = useState(0)
    const [varUmbralTRX, setVarUmbralTRX] = useState(0)
    const [refreshDate, setRefreshDate] = useState<any>()
    const [sizePercentage, setSizePercentage] = useState<number>(50)

    const sizes: {[key: number] : number}= {
        300 : 90,
        720 : 110,
        2560 : 120,
        3840 : 200,
        4096 : 300,
    }
    useEffect(()=>{
        for(let key of Object.keys(sizes)){
            if(parseInt(key) <= window.screen.width){ //para resoluciones MAYORES
                setSizePercentage(sizes[parseInt(key)])
            }
        }

    },[window.screen.width])

    useEffect(() => {
        if (props.graphicsData.length > 0) {
            props.graphicsData.map((graphic: any) => {
                if (graphic.intern_title.includes("APROBADAS") || graphic.intern_title.includes("Aprobadas")) {
                    const umbral = graphic.limit_values[graphic.limit_values.length - 1]
                    setVarUmbral(umbral)
                    const date = graphic.keys.at(-1)
                    const final_date = dayjs(date).format("HH:mm:ss")
                    setRefreshDate(final_date)
                } else {
                    const umbralTRX = graphic.limit_values[graphic.limit_values.length - 1]
                    setVarUmbralTRX(umbralTRX)
                }
            })
        }
    }, [props.graphicsData, props.cardData])

    const checkInfo = () => {
        if(props.cardData[0].appAlert == undefined){
            return "intern-icon-undefined"
        }else if(props.cardData[0].cantAlert !== "0"){
            return "intern-icon-alert"
        }else{
            return "intern-icon"
        }
    }

    const calcTotalTRXAprobados = () => {
        if(props.cardData[0].cantVal == undefined || props.cardData[0].appVal == undefined){
            return "---";
        }else{
            return Math.round((props.cardData[0].cantVal * props.cardData[0].appVal) / 100);
        }
    }

    return (
        <IonCard className='decidir-card-decidir' key={props.graphicsData[0].intern_id}>
            <IonCardHeader className='intern-header'>
                <IonRow>
                    <IonIcon className={checkInfo()} icon={ellipse} />
                    <IonCol className='decidir-padding'>TOTALES DECIDIR</IonCol>
                    <IonCol className={props.cardDataPersistor.cantAlert == '0' ? 'decidir-persistor' : 'decidir-persistor-alerted'} sizeXl="3" sizeLg="4" sizeMd="3" sizeSm="4" sizeXs="5">PERSISTOR</IonCol>
                </IonRow>
            </IonCardHeader>
            <IonCol className='decidir-align' sizeXl="12" sizeLg="12" sizeMd="12" sizeSm="12" sizeXs="12">
                <IonRow className='decidir-styles-tables decidir-table decidir-table-align decidir-align-row'>
                    <IonCol class='decidir-group' sizeXl="5" sizeLg="5" sizeMd="5" sizeSm="5" sizeXs="12">
                        <IonRow className='intern-totals'>% Totales Decidir</IonRow>
                        <IonCol>
                            <div>
                                <div className="intern-percentage" key={props.cardData[0].appVal}>
                                    <CountdownCircleTimer
                                        isPlaying={false}
                                        duration={100}
                                        colors={props.cardData[0].appVal == undefined ? `#595959` : `#29b2b7`}
                                        initialRemainingTime={props.cardData[0].appVal}
                                        rotation={"counterclockwise"}
                                        strokeWidth={7}
                                        size={sizePercentage}>
                                        {({ remainingTime }) => {
                                            const time = remainingTime
                                            return (
                                                <div>
                                                    <IonText className={props.cardData[0].appVal == undefined ? "decidir-text-percentage undefined" : "decidir-text-percentage"}> {time}%</IonText>
                                                </div>
                                            )
                                        }}
                                    </CountdownCircleTimer>
                                </div>
                                <div className='intern-refresh'>
                                    <IonText >Ult. Refresh: {refreshDate}Hs</IonText>
                                </div>
                            </div>
                        </IonCol>
                    </IonCol>
                    <IonCol className='intern-group' sizeXl="7" sizeLg="7" sizeMd="7" sizeSm="7" sizeXs="12">
                        <IonRow className='ion-align-items-center  intern-row-card'>
                            <IonCol className='intern-subtitulos'>Total TRX últimos 5'</IonCol>
                            <IonCol>{props.cardData[0].cantVal}</IonCol>
                        </IonRow>
                        <IonRow className='ion-align-items-center intern-row-card'>
                            <IonCol className='intern-subtitulos'>Total TRX Aprobados</IonCol>
                            <IonCol>{calcTotalTRXAprobados()}</IonCol>
                        </IonRow>
                        <IonRow className='ion-align-items-center intern-row-card'>
                            <IonCol className='intern-subtitulos'>Umbral TRX</IonCol>
                            <IonCol>{varUmbralTRX == undefined ? "--" : varUmbralTRX}</IonCol>
                        </IonRow>
                        <IonRow className='ion-align-items-center intern-row-card' >
                            <IonCol className='intern-subtitulos' >Umbral %</IonCol>
                            <IonCol>{varUmbral}%</IonCol>
                        </IonRow>
                    </IonCol>
                </IonRow>
            </IonCol>
        </IonCard>
    );
}

export default DecidirPrisma;