import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

import { GetGraphicsAndCards } from "../../store/mainInfo/mainInfoActions";
import { SessionDefaultStateI } from "../../store/session/SessionReducer";
import { GraphicsDefaultStateI } from "../../store/mainInfo/graphicsReducer";
import { CardsDefaultStateI } from "../../store/mainInfo/cardsReducer";
import { AlertsTwoHoursDefaultStateI } from "../../store/alerts/alertsTwoHoursReducer";
import { LastMessagesDefaultStateI } from "../../store/lastMessagesPrisma/lastMessagesReducer";

import "./Timer.css";

interface Props extends StateTypes, dispatchTypes { clientSelected?: any }

const TimerComponent: React.FC<Props> = (props): JSX.Element => {
  const bank_id_category = props.session.loginData.getIdToken().decodePayload()["custom:category"]
  const [startTimer, setStartTimer] = useState<any>(false);

  const [sizePercentage, setSizePercentage] = useState<number>(50)

  const sizes: {[key: number] : number}= {
      1920 : 45,
      2560 : 50,
      3840 : 70,
      4096 : 80,
  }
  useEffect(()=>{
      for(let key of Object.keys(sizes)){
          if(parseInt(key) <= window.screen.width){ //para resoluciones MAYORES
              setSizePercentage(sizes[parseInt(key)])
          }
      }

  },[window.screen.width])

  useEffect(() => {
    if (bank_id_category !== "ADMIN") {
      if (props.cards.cards) {
        if (props.graphics.graphics || props.alertsTwoHours.alertsTwoHours || props.lastMessages.lastMessages) {
          setStartTimer(true)
        }
      }
    } else {
      if (props.clientSelected && props.clientSelected !== '' && props.cards.cards) {
        if (props.graphics.graphics || props.alertsTwoHours.alertsTwoHours || props.lastMessages.lastMessages) {
          setStartTimer(true)
        }
      }
    }
  }, [props.clientSelected, props.graphics.graphics, props.cards.cards, props.alertsTwoHours.alertsTwoHours, props.lastMessages.lastMessages])

  //Define color based on css variable
  const timerColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--cust-color-decorator");

  const handleOnComplete = () => {
    props.getGraphicsAndCards();
  };

  const formatTime = (_: number, minutes: number, seconds: number) => {
    const formatedMinutes = minutes >= 10 ? minutes : "0" + minutes;
    const formatedSeconds = seconds >= 10 ? seconds : "0" + seconds;
    return `${formatedMinutes}:${formatedSeconds}`;
  };

  return (
    <div className="banks-timer">
      {startTimer ?
        <CountdownCircleTimer
          isPlaying
          duration={300}
          colors={`#29b2b7`}
          size={sizePercentage}
          strokeWidth={3}
          onComplete={() => {
            // do your stuff here
            window.location.reload()
            handleOnComplete();
            return [true, 500]; // repeat animation in 1.5 seconds
          }} >
          {({ remainingTime }) => {
            if (remainingTime) {
              const hours = Math.floor(remainingTime / 3600);
              const minutes = Math.floor(remainingTime / 60);
              const seconds = remainingTime % 60;
              return (
                <div className="timer">
                  <div className="value">
                    {formatTime(hours, minutes, seconds)}
                  </div>
                </div>
              );
            }
          }}
        </CountdownCircleTimer>
        :
        <CountdownCircleTimer
          isPlaying={false}
          duration={300}
          colors={`#29b2b7`}
          size={sizePercentage}
          strokeWidth={3}  >
          {({ remainingTime }) => {
            if (remainingTime) {
              const hours = Math.floor(remainingTime / 3600);
              const minutes = Math.floor(remainingTime / 60);
              const seconds = remainingTime % 60;
              return (
                <div className="timer">
                  <div className="value">{formatTime(hours, minutes, seconds)}</div>
                </div>
              );
            }
          }}
        </CountdownCircleTimer>
      }
    </div>
  )
};

//Redux state and actions dispatchers

interface StateTypes {
  session: SessionDefaultStateI;
  graphics: GraphicsDefaultStateI;
  cards: CardsDefaultStateI;
  alertsTwoHours: AlertsTwoHoursDefaultStateI;
  lastMessages: LastMessagesDefaultStateI;
}

const mapStateToProps = (state: StateTypes) => {
  return {
    session: state.session,
    graphics: state.graphics,
    cards: state.cards,
    alertsTwoHours: state.alertsTwoHours,
    lastMessages: state.lastMessages,
  };
};

interface dispatchTypes {
  getGraphicsAndCards: () => void;
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getGraphicsAndCards: () => dispatch(GetGraphicsAndCards()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TimerComponent);