import { clientsClaim } from "workbox-core";
import ApiRequestor from "../../api_access/ApiRequestor";
import { Dispatch } from "redux";
import {
  GetMainInfoDispatchTypes,
  GET_GRAPHICS_LOADING,
  GET_GRAPHICS_FAIL,
  GET_GRAPHICS_SUCCESS,
  GET_CARDS_LOADING,
  GET_CARDS_FAIL,
  GET_CARDS_SUCCESS,
  GET_MESSAGES_PRISMA_LOADING,
  GET_MESSAGES_PRISMA_FAIL,
  GET_MESSAGES_PRISMA_SUCCESS,
  GET_KPI_GROUPS_MENU_LOADING,
  GET_KPI_GROUPS_MENU_FAIL,
  GET_KPI_GROUPS_MENU_SUCCESS,
  GET_CLIENT_IMAGE_FAIL,
  GET_CLIENT_IMAGE_LOADING,
  GET_CLIENT_IMAGE_SUCCESS,
  GET_CLIENT_MAINTENANCE_FAIL, GET_CLIENT_MAINTENANCE_LOADING, GET_CLIENT_MAINTENANCE_SUCCESS
} from "./mainInfoActionsTypes";

export const GetGraphicsAndCards =
  () => async (dispatch: Dispatch<GetMainInfoDispatchTypes>) => {
    try {
      dispatch({
        type: GET_GRAPHICS_LOADING,
      });
      dispatch({
        type: GET_CARDS_LOADING,
      });
      const response: any = await ApiRequestor.requestToApi(
        "/main/main-view/refresh",
        "GET"
      );
      dispatch({
        type: GET_GRAPHICS_SUCCESS,
        payload: response.data.graphics,
      });

      dispatch({
        type: GET_CARDS_SUCCESS,
        payload: response.data.cards,
      });
    } catch (error) {
      dispatch({
        type: GET_GRAPHICS_FAIL,
      });

      dispatch({
        type: GET_CARDS_FAIL,
      });
    }
  };

export const GetGraphics =
  () => async (dispatch: Dispatch<GetMainInfoDispatchTypes>) => {
    try {
      dispatch({
        type: GET_GRAPHICS_LOADING,
      });

      const response: any = await ApiRequestor.requestToApi(
        "/main/main-view/graphics",
        "GET"
      );
 
      dispatch({
        type: GET_GRAPHICS_SUCCESS,
        payload: response.data.graphics,
      });
    } catch (error) {
      dispatch({
        type: GET_GRAPHICS_FAIL,
      });
    }
  };

export const GetCards =
  () => async (dispatch: Dispatch<GetMainInfoDispatchTypes>) => {
    try {
      dispatch({
        type: GET_CARDS_LOADING,
      });
      const response: any = await ApiRequestor.requestToApi(
        "/main/main-view/cards",
        "GET"
      );
      dispatch({
        type: GET_CARDS_SUCCESS,
        payload: response.data.cards,
      });
    } catch (error) {
      dispatch({
        type: GET_CARDS_FAIL,
      });
    }
  };

export const GetKpiGroupsMenu =
  () => async (dispatch: Dispatch<GetMainInfoDispatchTypes>) => {
    try {
      dispatch({
        type: GET_KPI_GROUPS_MENU_LOADING,
      });
      const response: any = await ApiRequestor.requestToApi(
        "/main/main-view/menuInfo",
        "GET"
      );
      dispatch({
        type: GET_KPI_GROUPS_MENU_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_KPI_GROUPS_MENU_FAIL,
      });
    }
  };

export const GetMessagesPrisma =
  () => async (dispatch: Dispatch<GetMainInfoDispatchTypes>) => {
    try {
      dispatch({
        type: GET_MESSAGES_PRISMA_LOADING,
      });

      const response: any = await ApiRequestor.requestToApi(
        "/main/main-view/messagesPrisma",
        "GET"
      );

      dispatch({
        type: GET_MESSAGES_PRISMA_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_MESSAGES_PRISMA_FAIL,
      });
    }
  };

export const GetClientImage =
  () => async (dispatch: Dispatch<GetMainInfoDispatchTypes>) => {
    try {
        dispatch({
            type: GET_CLIENT_IMAGE_LOADING
        })
        const response:any = await ApiRequestor.requestToApi("/main/main-view/clientImage", "GET")
        dispatch({
            type: GET_CLIENT_IMAGE_SUCCESS,
            payload: response.data
        })
      
    }
    catch(error){
        dispatch({
            type: GET_CLIENT_IMAGE_FAIL
        })
    }
  };

  export const GetClientMaintenance =
  () => async (dispatch: Dispatch<GetMainInfoDispatchTypes>) => {
    try {
        dispatch({
            type: GET_CLIENT_MAINTENANCE_LOADING
        })

        const response:any = await ApiRequestor.requestToApi("/main/main-view/clientMaintenance", "GET")

        dispatch({
            type: GET_CLIENT_MAINTENANCE_SUCCESS,
            payload: response.data
        })
      
    }
    catch(error){
        dispatch({
            type: GET_CLIENT_MAINTENANCE_FAIL
        })
    }
  };
