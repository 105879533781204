import {Auth} from "@aws-amplify/auth";
import API from "./api";
import { CONFIG } from "../config";

const data_service = CONFIG.backend;
const user_service = CONFIG.user_service;

const doRequestToApi = async (url_path, action, data = null,service = "DATA", isForm = false) => {

    //Referencia de parametro data
    /* const dataaaaaazo = {
        params:[],
        body:{},
        queryStringParams:{
        }
    } */
    
    let server;
    switch(service){
        case "DATA":
            server = data_service;
            break;
        case "USER":
            server = user_service;
            break;
        default:
            server = data_service;
            break;
    }
    let authResponse
    try{
        authResponse = await Auth.currentSession()
    }catch(error){
        console.log("Not signed in...")
    }

    

    const headers = {headers: { 'X-COGNITO-AUTH': authResponse.getIdToken().getJwtToken() }};


    let apiRes

    switch(action.toUpperCase()){
        case "GET":
            apiRes = await API.get(server,url_path, headers, data) 
            break;
        case "POST":
            apiRes = await API.post(server,url_path, headers, data,isForm)
            break;
        case "PUT":
            apiRes = await API.put(server,url_path, headers, data,isForm) 
            break;
        case "PATCH":
            apiRes = await API.patch(server,url_path, headers, data,isForm)
            break;
        case "DELETE":
            apiRes = await API.delete(server,url_path, headers, data)
            break;
        default:
            console.log("Incorrect API method specified")
    }


    return apiRes

}

const doLogin = async (username, password) => {
    
    try{
        const res = await Auth.signIn({
            username: username,
            password: password,
        })
        
        return res
    }catch(err){
        throw err
    }       
}

const doLogout = async () => {
    
    try{
        const res = await Auth.signOut()

        return res
    }catch(err){
        throw err
    }       
}

const doAuthRequestToApi = (url_path, action , callbackOK, callbackERR, data = null, service = "DATA", isForm = false) => {

    //Referencia de parametro data
    /* const dataaaaaazo = {
        params:[],
        body:{},
        queryStringParams:{
        }
    } */
    let server;
    switch(service){
        case "DATA":
            server = data_service;
            break;
        case "USER":
            server = user_service;
            break;
        default:
            server = data_service;
            break;
    }

    switch(action.toUpperCase()){
        case "GET":
            API.get(server,url_path, null, callbackOK, callbackERR, data) 
            break;
        case "POST":
            API.post(server,url_path, null, data, callbackOK, callbackERR,isForm)
            break;
        case "PUT":
            API.put(server,url_path, null, data, callbackOK, callbackERR,isForm) 
            break;
        case "PATCH":
            API.patch(server,url_path, null, data, callbackOK, callbackERR,isForm)
            break;
        case "DELETE":
            API.delete(server,url_path, null, data, callbackOK, callbackERR)
            break;
        default:
            console.log("Incorrect API method specified")
    }      
       
}
const doAuthRequestToUserService = async (url_path, action, data = null,service = "DATA", isForm = false) => {

    //Referencia de parametro data
    /* const dataaaaaazo = {
        params:[],
        body:{},
        queryStringParams:{
        }
    } */
    
    let server;
    switch(service){
        case "DATA":
            server = data_service;
            break;
        case "USER":
            server = user_service;
            break;
        default:
            server = data_service;
            break;
    }
   

    let apiRes

    switch(action.toUpperCase()){
        case "GET":
            apiRes = await API.get(server,url_path, null, data) 
            break;
        case "POST":
            apiRes = await API.post(server,url_path, null, data,isForm)
            break;
        case "PUT":
            apiRes = await API.put(server,url_path, null, data,isForm) 
            break;
        case "PATCH":
            apiRes = await API.patch(server,url_path, null, data,isForm)
            break;
        case "DELETE":
            apiRes = await API.delete(server,url_path, null, data)
            break;
        default:
            console.log("Incorrect API method specified")
    }


    return apiRes

}


const ApiRequestor = {
    requestToApi:doRequestToApi,
    authRequestToApi:doAuthRequestToApi,
    login:doLogin,
    logout: doLogout,
    authRequestToUser: doAuthRequestToUserService,
}



export default ApiRequestor;