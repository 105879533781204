import React, { useRef, useState, useEffect } from 'react';
import { IonButton } from '@ionic/react';

const Upload = ({ initialImage, imageSelectCallback }) => {
  const [preview, setPreview] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (initialImage && initialImage.data) {
      try {
        const base64String = btoa(String.fromCharCode(...new Uint8Array(initialImage.data)));
        setPreview("data:image/png;base64," + base64String);
      } catch (err) {
        console.log("No initial Image or invalid image data");
        setPreview(null);
      }
    } else {
      setPreview(null);
    }
  }, [initialImage]);

  const handleChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      imageSelectCallback(file);
      setPreview(URL.createObjectURL(file));
    } else {
      imageSelectCallback(null);
      setPreview(null);
    }
  };

  const clearFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setPreview(null);
    imageSelectCallback(null);
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <input 
        type="file" 
        ref={fileInputRef}
        id="img" 
        name="img" 
        accept="image/*" 
        onChange={handleChange} 
        className="input-img" 
      />
      {preview && (
        <>
          <img src={preview} width="80" height="80" style={{ marginLeft: "40px" }} alt="serviceLogo" />
          <IonButton onClick={clearFile}>Clear</IonButton>
        </>
      )}
    </div>
  );
};

export default Upload;