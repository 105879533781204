import {GetAlertsDispatchTypes,GET_ALERTS_LAST_MINUTE_FAIL, GET_ALERTS_LAST_MINUTE_LOADING,GET_ALERTS_LAST_MINUTE_SUCCESS, AlertsType} from './alertsActionsTypes'

export interface AlertsLastMinuteDefaultStateI {
    loading:boolean,
    alertsLastMinute?: AlertsType[]
}

const defaultState:AlertsLastMinuteDefaultStateI = {
    loading:false
};


const AlertsLastMinuteReducer = (state: AlertsLastMinuteDefaultStateI = defaultState,action:GetAlertsDispatchTypes):AlertsLastMinuteDefaultStateI =>{
    switch (action.type) {
        case GET_ALERTS_LAST_MINUTE_FAIL:
            return {
                loading:false
            }
            break;
        case GET_ALERTS_LAST_MINUTE_LOADING:
            return {
                loading:true
            }
            break;
        case GET_ALERTS_LAST_MINUTE_SUCCESS:
            return {
                loading:false,
                alertsLastMinute:action.payload
            }
            break;
        default:
            return state
            break;
    }
    return state;
}


export default AlertsLastMinuteReducer;