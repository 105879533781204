import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IonButton, IonCard, IonCardHeader, IonCardSubtitle, IonCol, IonContent, IonGrid, IonIcon, IonPage, IonRouterLink, IonRow, IonSpinner, IonText, IonTitle } from '@ionic/react';
import { addCircleOutline, checkmarkCircleOutline, closeCircleOutline, eye, pencil, trash } from 'ionicons/icons';

import { GetABMKpis } from '../../store/ABMMetric/ABMKpis/ABMKpisActions';
import { ABMKpisDefaultStateI } from '../../store/ABMMetric/ABMKpis/ABMKpisReducer';

import Header from '../header/Header';
import Footer from '../Footer/Footer';
import AddABMKpis from './AddABMKpis';
import ModifyABMKpis from './ModifyABMKpis';
import ApiRequestor from '../../api_access/ApiRequestor';

import './ABMMetric.css';
import Modal from '../Modal/Modal';

interface Kpi {
    kpi_id: number,
    kpi_name: string,
    kpi_host: string,
    kpi_field: string,
    kpi_type: string,
    kpi_transform: boolean,
    kpi_endpoint_id: string,
    kpi_group_id: number,
    kpi_threshold: boolean,
    kpi_title: string,
    kpi_title_key: string,
    kpi_time_gap: number,
    kpi_linechart_label: string,
    kpi_created_by: string,
    kpi_updated_by: string,
    kpi_group_name: string,
    service_name: string
}

interface DataKpiGroup {
    kpi_group_id: number,
    kpi_group_name: string,
    kpi_group_created_by: string,
    kpi_group_updated_by: string,
    kpi_legend: string,
    service_id: number,
    service_name: string
}

interface Props extends StateTypes, dispatchTypes {
    kpiGroupData: DataKpiGroup
    backToKpiGroupTable: () => void
}

const SeeABMKpis: React.FC<Props> = (props): JSX.Element => {
    const [seeAbmKpis, setSeeAbmKpis] = useState<Kpi[]>([]);
    const [renderViews, setRenderViews] = useState({
        renderKpiTable: true,
        renderKpiCreate: false,
        renderKpiModify: false
    })
    interface SeeData {
        show: boolean,
        kpiData: Kpi
    }
    const [modalSee, setModalSee] = useState<SeeData>({
        show: false,
        kpiData: {
            kpi_id: 0, kpi_name: '', kpi_host: '', kpi_field: '', kpi_type: '', kpi_transform: false,
            kpi_endpoint_id: '', kpi_group_id: 0, kpi_threshold: false, kpi_title: '', kpi_title_key: '',
            kpi_time_gap: 0, kpi_linechart_label: '', kpi_created_by: '', kpi_updated_by: '',
            kpi_group_name: '', service_name: ''
        }
    });
    const [modifyData, setModifyData] = useState<Kpi>({
        kpi_id: 0, kpi_name: '', kpi_host: '', kpi_field: '', kpi_type: '', kpi_transform: false,
        kpi_endpoint_id: '', kpi_group_id: 0, kpi_threshold: false, kpi_title: '', kpi_title_key: '',
        kpi_time_gap: 0, kpi_linechart_label: '', kpi_created_by: '', kpi_updated_by: '',
        kpi_group_name: '', service_name: ''
    });
    const [showModalRemove, setShowModalRemove] = useState(false);
    const [dataForDelete, setDataForDelete] = useState({
        id: 0,
        name: ''
    })
    const [showSipnner, setShowSpinner] = useState(false)
    const [showModalExit, setShowModalExit] = useState(false);

    if (props.ABMKpis.loading === false && props.ABMKpis.abmkpis) { }

    useEffect(() => {
        props.getSeeABMKpis();
    }, []);

    useEffect(() => {
        if (props.ABMKpis.loading === false && props.ABMKpis.abmkpis) {
            const kpis: Kpi[] = props.ABMKpis.abmkpis.filter((kpi: Kpi) => kpi.kpi_group_id == props.kpiGroupData.kpi_group_id)
            setSeeAbmKpis(kpis)
        }
    }, [props.ABMKpis.loading, props.ABMKpis.abmkpis]);

    const backToKpisTable = () => {
        setRenderViews({
            renderKpiTable: true,
            renderKpiCreate: false,
            renderKpiModify: false
        })
    }

    const createKpi = () => {
        setRenderViews({
            renderKpiTable: false,
            renderKpiCreate: true,
            renderKpiModify: false
        })
    }

    const modifyKpi = (kpi: Kpi) => {
        setRenderViews({
            renderKpiTable: false,
            renderKpiCreate: false,
            renderKpiModify: true
        })
        setModifyData(kpi)
    }

    const onDeleteKpi = (kpi_id: number, kpi_name: string) => {
        setDataForDelete({ id: kpi_id, name: kpi_name })
        setShowModalRemove(true);
    }

    const deleteKpi = async () => {
        setShowModalRemove(false)
        setShowSpinner(true)
        let data: any = {
            params: [
                dataForDelete.id
            ]
        }

        const response = await ApiRequestor.requestToApi('/swt-admin/kpis', 'DELETE', data)
        if (response && response.status == 200) {
            setShowSpinner(false)
            setShowModalExit(true)
        }
    }

    const onDismissModal = () => {
        setShowModalExit(false)
        props.getSeeABMKpis()
    }


    const returnChildrenModal = (): JSX.Element => {

        return (
            <IonCard className="ABMKpisCardModal">
                <IonGrid className="">
                    <IonRow className="ion-justify-content-center">
                        <IonCardSubtitle className="cardSubtitleModal"> Detalles del Kpi </IonCardSubtitle>
                    </IonRow>
                    <IonRow className="kpisRow">
                        <IonCol sizeXl="4" sizeXs='6' className='centerKpi mobile-hidden-kpis fontSizeKpi'>Cliente</IonCol>
                        <IonCol sizeXl="8" sizeXs='6' className='mobile-hidden-kpis fontSizeKpi'>{modalSee.kpiData.service_name}</IonCol>
                    </IonRow>
                    <IonRow className="kpisRow">
                        <IonCol sizeXl="4" sizeXs='6' className='centerKpi colorKpi'>Nombre del Grupo de Kpis</IonCol>
                        <IonCol sizeXl="8" sizeXs='6' className='colorKpi'>{modalSee.kpiData.kpi_group_name}</IonCol>
                    </IonRow>
                    <IonRow className="kpisRow">
                        <IonCol sizeXl="4" sizeXs='6' className='centerKpi'>Host</IonCol>
                        <IonCol sizeXl="8" sizeXs='6'>{modalSee.kpiData.kpi_host}</IonCol>
                    </IonRow>
                    <IonRow className="kpisRow">
                        <IonCol sizeXl="4" sizeXs='6' className='centerKpi colorKpi'>Nombre</IonCol>
                        <IonCol sizeXl="8" sizeXs='6' className='colorKpi'>{modalSee.kpiData.kpi_name}</IonCol>
                    </IonRow>
                    <IonRow className="kpisRow">
                        <IonCol sizeXl="4" sizeXs='6' className='centerKpi'>Título</IonCol>
                        <IonCol sizeXl="8" sizeXs='6'>{modalSee.kpiData.kpi_title}</IonCol>
                    </IonRow>
                    <IonRow className="kpisRow">
                        <IonCol sizeXl="4" sizeXs='6' className='centerKpi colorKpi'>Key título</IonCol>
                        <IonCol sizeXl="8" sizeXs='6' className='colorKpi'>{modalSee.kpiData.kpi_title_key}</IonCol>
                    </IonRow>
                    <IonRow className="kpisRow">
                        <IonCol sizeXl="4" sizeXs='6' className='centerKpi'>Campo</IonCol>
                        <IonCol sizeXl="8" sizeXs='6'>{modalSee.kpiData.kpi_field}</IonCol>
                    </IonRow>
                    <IonRow className="kpisRow">
                        <IonCol sizeXl="4" sizeXs='6' className='centerKpi colorKpi'>Type</IonCol>
                        <IonCol sizeXl="8" sizeXs='6' className='colorKpi'>{modalSee.kpiData.kpi_type}</IonCol>
                    </IonRow>
                    <IonRow className="kpisRow">
                        <IonCol sizeXl="4" sizeXs='6' className='centerKpi'>Transform</IonCol>
                        <IonCol sizeXl="8" sizeXs='6'>{(modalSee.kpiData.kpi_transform).toString()}</IonCol>
                    </IonRow>
                    <IonRow className="kpisRow">
                        <IonCol sizeXl="4" sizeXs='6' className='centerKpi colorKpi'>Endpoint</IonCol>
                        <IonCol sizeXl="8" sizeXs='6' className='colorKpi'>{modalSee.kpiData.kpi_endpoint_id}</IonCol>
                    </IonRow>
                    <IonRow className="kpisRow">
                        <IonCol sizeXl="4" sizeXs='6' className='centerKpi'>Tiempo de recarga (min)</IonCol>
                        <IonCol sizeXl="8" sizeXs='6'>{modalSee.kpiData.kpi_time_gap}</IonCol>
                    </IonRow>
                    <IonRow className="kpisRow">
                        <IonCol sizeXl="4" sizeXs='6' className='centerKpi colorKpi'>Linechart label</IonCol>
                        <IonCol sizeXl="8" sizeXs='6' className='colorKpi'>{modalSee.kpiData.kpi_linechart_label}</IonCol>
                    </IonRow>
                    <IonRow className="kpisRow">
                        <IonCol sizeXl="4" sizeXs='6' className='centerKpi mobile-hidden-kpis'>Creado por</IonCol>
                        <IonCol sizeXl="8" sizeXs='6' className='mobile-hidden-kpis'>{modalSee.kpiData.kpi_created_by}</IonCol>
                    </IonRow>
                    <IonRow className="kpisRow-ultimo">
                        <IonCol sizeXl="4" sizeXs='6' className='centerKpi mobile-hidden-kpis colorKpi'>Modificado por</IonCol>
                        <IonCol sizeXl="8" sizeXs='6' className='mobile-hidden-kpis colorKpi'>{modalSee.kpiData.kpi_updated_by}</IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonButton type="button" className="buttonkpi" fill="outline" onClick={() => setModalSee({ show: false, kpiData: modalSee.kpiData })}>
                            Cerrar
                        </IonButton>
                    </IonRow>
                </IonGrid>
            </IonCard>
        )

    }

    return (
        <IonPage>
            <Header headerText="ABM Kpis" />
            {
                renderViews.renderKpiCreate ? <AddABMKpis backToKpisTable={backToKpisTable} kpiGroupData={props.kpiGroupData} /> :
                    renderViews.renderKpiModify ? <ModifyABMKpis kpiData={modifyData} backToKpisTable={backToKpisTable} /> :
                        renderViews.renderKpiTable &&
                        <IonContent fullscreen>
                            <IonGrid className="ABMKpis-content" id="ion-content-main">
                                <IonCard>
                                    <IonCardHeader>
                                    <IonTitle className="ABMKpiTitleKpi">Ver Grupo de Kpis</IonTitle>
                                    <div className="divIconAdd ABMKpisZoom">
                                        <IonIcon className="ABMKpisIconPositionSee" icon={addCircleOutline} color="secondary" size="large" onClick={createKpi} />
                                    </div>
                                    </IonCardHeader>
                                    
                                    <IonGrid>
                                        <IonCardSubtitle className="ABMKpisCardSubtitle"> Cliente: {props.kpiGroupData.service_name} </IonCardSubtitle>
                                        <IonCardSubtitle className="ABMKpisCardSubtitle"> Nombre del Grupo de Kpis: {props.kpiGroupData.kpi_group_name}  </IonCardSubtitle>
                                        {
                                            props.kpiGroupData.kpi_legend && props.kpiGroupData.kpi_legend.length > 0 &&
                                            <IonCardSubtitle className='ABMKpisCardSubtitle'> Leyenda del Grupo de Kpis: {props.kpiGroupData.kpi_legend}</IonCardSubtitle>
                                        }
                                        
                                    </IonGrid>
                                    <IonGrid className="ABMKpisGrid">
                                        <IonCard className="ABMKpisCard">
                                            <IonRow className="ABMKpisRow">
                                                <IonCol sizeXl="2.5" sizeMd='3' className='mobile-hidden-kpis tablet-hidden-kpis'> Host </IonCol>
                                                <IonCol sizeXl="4" sizeMd='3' className='mobile-hidden-kpis'> Nombre </IonCol>
                                                <IonCol sizeXl="2.5" sizeMd='3' sizeXs='3.7'> Título </IonCol>
                                                <IonCol sizeXl="1" sizeMd='2' sizeXs='3'> Ver Más </IonCol>
                                                <IonCol sizeXl="1" sizeMd='2' sizeXs='2.8'> Modificar </IonCol>
                                                <IonCol sizeXl="1" sizeMd='2' sizeXs='2.5'> Eliminar </IonCol>
                                            </IonRow>
                                            {
                                                seeAbmKpis.length > 0 && seeAbmKpis.map((kpi: Kpi) => {
                                                    return (
                                                        <IonRow className="ion-align-items-center ABMKpisCol">
                                                            <IonCol sizeXl="2.5" sizeMd='3' className='mobile-hidden-kpis tablet-hidden-kpis'>{kpi.kpi_host}</IonCol>
                                                            <IonCol sizeXl="4" sizeMd='3' className='mobile-hidden-kpis'>{kpi.kpi_name}</IonCol>
                                                            <IonCol sizeXl="2.5" sizeMd='3' sizeXs='3.7'>{kpi.kpi_title}</IonCol>
                                                            <IonCol sizeXl="1" sizeMd='2' sizeXs='3'>
                                                                <IonButton type="button" fill="clear" onClick={() => setModalSee({ show: true, kpiData: kpi })} id={kpi.kpi_title + "VER"}>
                                                                    <IonIcon icon={eye} color="secondary" className='ABMKpiButton'/>
                                                                </IonButton>
                                                            </IonCol>
                                                            <IonCol sizeXl="1" sizeMd='2' sizeXs='2.8'>
                                                                <IonButton type="button" fill="clear" onClick={() => modifyKpi(kpi)} id={kpi.kpi_title + "MODIFICAR"}>
                                                                    <IonIcon icon={pencil} color="secondary" className='ABMKpiButton'/>
                                                                </IonButton>
                                                            </IonCol >
                                                            <IonCol sizeXl="1" sizeMd='2' sizeXs='2.5'>
                                                                <IonButton type="button" fill="clear" onClick={() => onDeleteKpi(kpi.kpi_id, kpi.kpi_name)} id={kpi.kpi_title + "ELIMINAR"}>
                                                                    <IonIcon icon={trash} color="danger" className='ABMKpiButton'/>
                                                                </IonButton>
                                                            </IonCol>
                                                        </IonRow>
                                                    )
                                                })}
                                        </IonCard>
                                    </IonGrid>
                                    <IonGrid className="ABMKpisButtonContainer">
                                        <IonButton type="button" className="buttonkpi" fill="outline" onClick={props.backToKpiGroupTable}>
                                            Volver Atrás
                                        </IonButton>
                                    </IonGrid>
                                </IonCard>
                            </IonGrid>

                            <Modal
                                modalChildren={
                                    {
                                        isOpen: modalSee.show,
                                        onDidDismiss: () => setModalSee({ show: false, kpiData: modalSee.kpiData }),
                                        id: "modal-See-Kpi",
                                        styles: "kpis-modal",
                                        children: returnChildrenModal()
                                    }
                                }
                                modalPrincipal={
                                    {
                                        textPrincipal: `¿Desea Eliminar el Kpi ${dataForDelete.name}?`,
                                        isOpen: showModalRemove,
                                        onDidDismiss: () => setShowModalRemove(false),
                                        onClickCloseModal: () => setShowModalRemove(false),
                                        onClick: deleteKpi,
                                        nameIcon: "warning",
                                        colorIcon: "danger",

                                    }
                                }
                                modalConfirmation={
                                    {
                                        textConfirmation: "Kpi Eliminado Correctamente",
                                        onDidDimissConfirmModal: onDismissModal,
                                        isOpenModalConfirm: showModalExit,
                                        onClickConfirmModal: onDismissModal,
                                    }
                                }
                            />

                            {showSipnner &&
                                <IonCol size="12" className="ion-padding">
                                    <IonSpinner color="tertiary" name="lines" style={{ position: "fixed", top: "50%", left: "50%" }} />
                                </IonCol>
                            }
                        </IonContent>}
            <Footer />
        </IonPage>
    )
}

//Redux state and actions dispatchers
interface StateTypes {
    ABMKpis: ABMKpisDefaultStateI;
}

const mapStatetoProps = (state: StateTypes) => {
    return {
        ABMKpis: state.ABMKpis,
    }
}

interface dispatchTypes {
    getSeeABMKpis: () => void;
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getSeeABMKpis: () => dispatch(GetABMKpis())
    };
}

export default connect(mapStatetoProps, mapDispatchToProps)(SeeABMKpis);