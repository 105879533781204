import {GetMainInfoDispatchTypes, GET_CLIENT_IMAGE_FAIL,GET_CLIENT_IMAGE_LOADING,GET_CLIENT_IMAGE_SUCCESS, ClientImageMenuType} from './mainInfoActionsTypes'

export interface ClientImageDefaultStateI {
    loading:boolean,
    clientImage?: ClientImageMenuType[]
}

const defaultState:ClientImageDefaultStateI = {
    loading:false
};


const clientImageReducer = (state: ClientImageDefaultStateI = defaultState,action:GetMainInfoDispatchTypes):ClientImageDefaultStateI =>{
    switch (action.type) {
        case GET_CLIENT_IMAGE_FAIL:
            return {
                loading:false
            }
            break;
        case GET_CLIENT_IMAGE_LOADING:
            return {
                loading:true
            }
            break;
        case GET_CLIENT_IMAGE_SUCCESS:
            return {
                loading:false,
                clientImage:action.payload
            }
            break;
        default:
            return state
            break;
    }
    return state;
}


export default clientImageReducer;