import { ChangeStateType, GetChangeStateDispatchTypes, GET_CHANGESTATE_FAIL, GET_CHANGESTATE_LOADING, GET_CHANGESTATE_SUCCESS } from './changeStateActionsTypes';


export interface ChangeStateDefaultStateI{
    loading: boolean,
    changeState?: ChangeStateType[] | any
}

const defaultState: ChangeStateDefaultStateI = {
    loading:false
}

const changeStateReducer = (state: ChangeStateDefaultStateI = defaultState, action: GetChangeStateDispatchTypes ): ChangeStateDefaultStateI =>{
    switch (action.type) {
        case GET_CHANGESTATE_FAIL:
            return {
                loading:false
            }
            break;
        case GET_CHANGESTATE_LOADING:
            return {
                loading:true
            }
            break;
        case GET_CHANGESTATE_SUCCESS:
            return {
                loading:false,
                changeState:action.payload
            }
            break;
        default:
            return state
            break;
    }
    return state;
}


export default changeStateReducer;