import {GetMainInfoDispatchTypes, GET_CARDS_FAIL, GET_CARDS_LOADING, GET_CARDS_SUCCESS, CardsType} from './mainInfoActionsTypes'

export interface CardsDefaultStateI {
    loading:boolean,
    cards?: CardsType[] | any
}

const defaultState:CardsDefaultStateI = {
    loading:false
};


const cardsReducer = (state: CardsDefaultStateI = defaultState,action:GetMainInfoDispatchTypes):CardsDefaultStateI =>{
    switch (action.type) {
        case GET_CARDS_FAIL:
            return {
                loading:false
            }
            break;
        case GET_CARDS_LOADING:
            return {
                loading:true
            }
            break;
        case GET_CARDS_SUCCESS:
            return {
                loading:false,
                cards:action.payload
            }
            break;
        default:
            return state
            break;
    }
    return state;
}


export default cardsReducer;