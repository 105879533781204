import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

import { SessionDefaultStateI } from "../../store/session/SessionReducer";
import { InternosCardsDefaultStateI } from "../../store/Internos/InternosCards/internosCardsReducer";
import { InternosGraphicsDefaultStateI } from "../../store/Internos/InternosGraphics/internosGraphicsReducer";
import { InternosAlertsDefaultStateI } from "../../store/Internos/InternosAlerts/internosAlertsReducer";
import { GetInternosCards } from "../../store/Internos/InternosCards/internosCardsActions";
import { GetInternosGraphics } from "../../store/Internos/InternosGraphics/internosGraphicsActions";
import { GetInternosAlerts } from "../../store/Internos/InternosAlerts/internosAlertsActions";

import "./Timer.css";

interface Props extends StateTypes, dispatchTypes { clientSelected?: any }

const TimerInternos: React.FC<Props> = (props): JSX.Element => {
  const bank_id_category = props.session.loginData.getIdToken().decodePayload()["custom:category"]
  const [startTimer, setStartTimer] = useState<any>(false)

  const [sizePercentage, setSizePercentage] = useState<number>(50)

  const sizes: {[key: number] : number}= {
      1920 : 45,
      2560 : 50,
      3840 : 70,
      4096 : 80,
  }
  useEffect(()=>{
      for(let key of Object.keys(sizes)){
          if(parseInt(key) <= window.screen.width){ //para resoluciones MAYORES
              setSizePercentage(sizes[parseInt(key)])
          }
      }

  },[window.screen.width])

  useEffect(() => {
    if (bank_id_category !== "ADMIN") {
      if (props.internCards.internosCards && props.internGraphics.internosGraphics && props.internAlerts.internosAlerts) {
        setStartTimer(true)
      }
    } else {
      if (props.clientSelected && props.clientSelected !== '' && props.internCards.internosCards && props.internGraphics.internosGraphics) {
        setStartTimer(true)
      }
    }
  }, [props.clientSelected, props.internCards.internosCards, props.internGraphics.internosGraphics, props.internAlerts.internosAlerts])

  useEffect(() => {
    if(!props.internAlerts.internosAlerts){
      props.getInternosAlerts()
    }
    if (!props.internCards.internosCards){
      props.getInternosCards()
    }
    if (!props.internGraphics.internosGraphics){
      props.getInternosGraphics()
    }
  },[])

  //Define color based on css variable
  const timerColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--cust-color-decorator");

  const formatTime = (_: number, minutes: number, seconds: number) => {
    const formatedMinutes = minutes >= 10 ? minutes : "0" + minutes;
    const formatedSeconds = seconds >= 10 ? seconds : "0" + seconds;
    return `${formatedMinutes}:${formatedSeconds}`;
  };

  return (
    <div className="banks-timer">
      {startTimer ?
        <CountdownCircleTimer
          isPlaying
          duration={300}
          colors={`#29b2b7`}  
          size={sizePercentage}
          strokeWidth={3}
          onComplete={() => {
            // do your stuff here
            window.location.reload()
            return [true, 500]; // repeat animation in 1.5 seconds
          }} >
          {({ remainingTime }) => {
            if (remainingTime) {
              const hours = Math.floor(remainingTime / 3600);
              const minutes = Math.floor(remainingTime / 60);
              const seconds = remainingTime %60;
              return (
                <div className="timer">
                  <div className="value">
                    {formatTime(hours, minutes, seconds)}
                  </div>
                </div>
              );
            }
          }}
        </CountdownCircleTimer>
        :
        <CountdownCircleTimer
          isPlaying={false}
          duration={300}
          colors={`#29b2b7`}
          size={sizePercentage}
          strokeWidth={3} >
          {({ remainingTime }) => {
            if (remainingTime) {
              const hours = Math.floor(remainingTime / 3600);
              const minutes = Math.floor(remainingTime / 60);
              const seconds = remainingTime % 60;
              return (
                <div className="timer">
                  <div className="value">{formatTime(hours, minutes, seconds)}</div>
                </div>
              );
            }
          }}
        </CountdownCircleTimer>
      }
    </div>
  )
};

//Redux state and actions dispatchers

interface StateTypes {
  session: SessionDefaultStateI;
  internCards: InternosCardsDefaultStateI;
  internGraphics: InternosGraphicsDefaultStateI;
  internAlerts: InternosAlertsDefaultStateI;
}

const mapStateToProps = (state: StateTypes) => {
  return {
    session: state.session,
    internCards: state.internCards,
    internGraphics: state.internGraphics,
    internAlerts: state.internAlerts,
  };
};

interface dispatchTypes {
  getInternosCards: () => void;
  getInternosGraphics: () => void;
  getInternosAlerts: () => void;
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getInternosCards: () => dispatch(GetInternosCards()),
    getInternosGraphics: () => dispatch(GetInternosGraphics()),
    getInternosAlerts: () => dispatch(GetInternosAlerts())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TimerInternos);