import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ApiRequestor from '../../api_access/ApiRequestor';
import {
    IonButton, IonCard, IonCardHeader, IonCardTitle, IonCheckbox, IonCol, IonContent, IonGrid,
    IonIcon, IonInput, IonItem, IonPage, IonRouterLink, IonRow, IonText, IonTextarea,
} from '@ionic/react';
import { checkmarkCircleOutline, closeCircleOutline, mailUnreadOutline } from 'ionicons/icons';

import { SessionDefaultStateI } from '../../store/session/SessionReducer';
import { ClientsInMaintenanceDefaultStateI } from '../../store/clientsInMaintenance/clientInMaintenanceReducer';
import { GetClientsInMaintenance } from '../../store/clientsInMaintenance/clientInMaintenanceActions';
import { DateTime } from "luxon";

import Footer from '../Footer/Footer';
import Header from '../header/Header';
import TogglePrismaMessages from './TogglePrismaMessages';
import ABMPrismaMessages from './ABMPrismaMessages';
import ModifyABMPrismaMessages from './ModifyABMPrismaMessages';

import './ABMPrismaMessages.css';
import Modal from '../Modal/Modal';


interface Message {
    message: string;
    services: any;
    date: string;
    hour: string;
    closeOption: boolean;
    id: number;
}

interface MessageModify {
    message_swt_id: number;
    message_swt_message: string;
    message_swt_services: string[];
    message_swt_date: string;
    message_swt_close: boolean
}

interface Props extends StateTypes, dispatchTypes { }

const AddABMPrismaMessages: React.FC<Props> = (props): JSX.Element => {

    const [dataMessage, setDataMessage] = useState<Message>({
        message: "",
        services: [],
        date: "",
        hour: "",
        closeOption: false,
        id: 0
    });
    const [checkAll, setCheckAll] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [textModal, setTextModal] = useState('')
    const [dateInvalid, setDateInvalid] = useState(false);
    const [messageInvalid, setMessageInvalid] = useState(false);
    const [hourInvalid, setHourInvalid] = useState(false);
    const [renderViews, setRenderViews] = useState({
        showCreate: true,
        showModify: false,
        showTable: false
    })
    const [modifyData, setModifyData] = useState<MessageModify>({
        message_swt_id: 0,
        message_swt_message: '',
        message_swt_services: [],
        message_swt_date: '',
        message_swt_close: false,
    })

    useEffect(() => {
        if (props.session.loading === false && props.session.loginData) {
            if(!props.clientsInMaintenance.clientsInMaintenance){
                props.getClientsInMaintenance();
            }
        }
    }, []);

    const getDataForm = (e: any) => {
        setDataMessage({
            ...dataMessage,
            [e.target.name]: e.target.value!
        })
    }
    const getCheckedForm = (e: any) => {
        setDataMessage({
            message: dataMessage.message,
            services: dataMessage.services,
            date: dataMessage.date,
            hour: dataMessage.hour,
            closeOption: !dataMessage.closeOption,
            id: dataMessage.id,
        })
    }

    const getToggleForm = (check: boolean, id: string) => {
        const filteredArray: any = dataMessage.services.filter((client: any) => {
            return client.name == id
        })
        if (filteredArray.length < 1) {
            dataMessage.services.push({ name: id, toggle: check })
        } else {
            filteredArray[0].toggle = check
        }
    }

    const toggleSelectorAll = (check: boolean) => {
        props.clientsInMaintenance.clientsInMaintenance?.map((clientData: any) => {
            const filteredArrayAll: any = dataMessage.services.filter((client: any) => {
                return client.name == clientData.service_name
            })
            if (filteredArrayAll.length < 1) {
                dataMessage.services.push({ name: clientData.service_name, toggle: check })
            } else {
                filteredArrayAll[0].toggle = check
            }
        })
        setCheckAll(check);
    }

    const createMessage = async (e: any) => {
        e.preventDefault();
        let valid = false
        if (dataMessage.message.length > 0 && dataMessage.date.length > 0 && dataMessage.hour.length > 0) {
            valid = true;
        }

        if (valid) {
            let services: string[] = [];

            dataMessage.services.forEach((element: any) => {
                if (element.toggle) {
                    services.push(element.name)
                }
            });
            if (services.length < 1) {
                setTextModal('Debe seleccionar al menos un cliente para enviar el mensaje')
                setShowModal(true)
                return;
            }

            let dateTime: any = `${dataMessage.date} ${dataMessage.hour}:00`
            let dateConf: any = (DateTime.fromFormat(dateTime, 'yyyy-MM-dd HH:mm:ss')).toString()
            let dateToday: any = DateTime.now().toISO()


            if (dateConf < dateToday) {
                setTextModal('Debe seleccionar una fecha y hora posterior a la actual')
                setShowModal(true)
                return;
            }

            dateTime = DateTime.fromFormat(dateTime, 'yyyy-MM-dd HH:mm:ss').setZone('utc')
            dateTime = dateTime.toString()

            let data: any = {
                body: {
                    "message_swt_message": dataMessage.message,
                    "message_swt_services": services,
                    "message_swt_date": dateTime,
                    "message_swt_close": dataMessage.closeOption,
                    "message_swt_id": dataMessage.id,
                }
            };
            const response: any = await ApiRequestor.requestToApi("/swt-admin/swt-messages", "POST", data);
            if (response && response.status == 200) {
                setTextModal('Mensaje Creado Correctamente')
                setShowModal(true);
            }
        } else {
            validationMessage();
            validationHour();
            validationDate();
        }

    }
    const validationMessage = () => {
        if (dataMessage.message.length <= 1) {
            setMessageInvalid(true);
        } else {
            setMessageInvalid(false);
        }
    }
    const validationHour = () => {
        if (dataMessage.hour.length <= 1) {
            setHourInvalid(true);
        } else {
            setHourInvalid(false);
        }
    }
    const validationDate = () => {
        if (dataMessage.date.length <= 1) {
            setDateInvalid(true);
        } else {
            setDateInvalid(false);
        }
    }

    const renderTable = () => {
        setRenderViews({
            showCreate: false,
            showModify: false,
            showTable: true
        })
        setDataMessage({
            message: '',
            services: [],
            date: '',
            hour: '',
            closeOption: false,
            id: 0,
        })
    }

    const renderCreate = () => {
        setRenderViews({
            showCreate: true,
            showModify: false,
            showTable: false
        })
    }

    const renderModify = (messageData: MessageModify) => {
        setRenderViews({
            showCreate: false,
            showModify: true,
            showTable: false
        })
        setModifyData(messageData)
    }

    const onDismissModal = () => {
        if (textModal.includes('cliente') || textModal.includes('fecha')) {
            setShowModal(false)
        } else {
            setShowModal(false)
            renderTable()
        }
    }

    return (
        <IonPage>
            <Header headerText='Notificaciones a Clientes' />
            {renderViews.showTable ? <ABMPrismaMessages backToCreate={renderCreate} renderModify={renderModify} /> :
                renderViews.showModify ? <ModifyABMPrismaMessages messageData={modifyData} backToTable={renderTable} /> :
                    renderViews.showCreate &&
                    <IonContent fullscreen>
                        <IonCard className="ABMPrismaMessagesFormCard">
                            <IonRow>
                                <IonCol sizeXl='11' sizeXs='9'>
                                    <IonCardTitle className="ABMPrismaMessagesFormTitle">
                                        Mensajes de Prisma
                                    </IonCardTitle>
                                </IonCol>
                                <IonCol sizeXl='1' sizeXs='3'>
                                    <IonButton fill="clear" className="ABMPrismaMessagesIcon" onClick={renderTable}>
                                        <IonIcon icon={mailUnreadOutline} color="secondary" className="" />
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                            <IonGrid>
                                <IonRow>
                                    <IonCol sizeXl='8' sizeLg='8'>
                                        <IonCard className="ABMPrismaMessagesCardClients">
                                            <IonRow>
                                                <IonCol sizeXs='12'>
                                                    <TogglePrismaMessages
                                                        text='Seleccionar Todos'
                                                        id='SelectAll'
                                                        check={checkAll}
                                                        parentCallback={toggleSelectorAll} />

                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                {
                                                    props.clientsInMaintenance.clientsInMaintenance &&
                                                    props.clientsInMaintenance.loading === false &&
                                                    props.clientsInMaintenance.clientsInMaintenance.map((client: any) => {
                                                        return (
                                                            <IonCol sizeMd='4' sizeLg='3' sizeXs='6' key={client.service_name} className='ABMPrismaClients'>
                                                                <TogglePrismaMessages
                                                                    text={client.service_display_name}
                                                                    id={client.service_name}
                                                                    check={false}
                                                                    parentCallback={getToggleForm}
                                                                    disabled={checkAll} />
                                                            </IonCol>
                                                        )
                                                    })
                                                }
                                            </IonRow>
                                        </IonCard>
                                    </IonCol>
                                    <IonCol sizeXl='4' sizeXs='12'>
                                        <IonRow>
                                            <IonCol sizeXl='12' sizeXs='12' className='AMBPrismaMessagesContainer'>
                                                <IonText className="ABMPrismaMessagesInputTitles">
                                                    Fecha de Expiración del Mensaje
                                                </IonText>
                                                <IonItem className="ABMPrismaMessagesItem" lines='none'>
                                                    <IonInput
                                                        type="date"
                                                        name='date'
                                                        className="ABMPrismaMessagesInput"
                                                        value={dataMessage.date}
                                                        onIonChange={(e) => getDataForm(e)} />
                                                </IonItem>
                                                <IonText>
                                                    <p className={dateInvalid ? "ABMPrismaMessagesValidText" : "ion-hide"}> La fecha está vacía</p>
                                                </IonText>
                                            </IonCol>
                                            <IonCol sizeXl='12' sizeXs='12' className='AMBPrismaMessagesContainer'>
                                                <IonText className="ABMPrismaMessagesInputTitles">
                                                    Hora de Expiración del Mensaje
                                                </IonText>
                                                <IonItem className="ABMPrismaMessagesItem" lines='none'>
                                                    <IonInput
                                                        className="ABMPrismaMessagesInput"
                                                        type='time'
                                                        name='hour'
                                                        value={dataMessage.hour}
                                                        onIonChange={(e) => getDataForm(e)} />
                                                </IonItem>
                                                <IonText>
                                                    <p className={hourInvalid ? "ABMPrismaMessagesValidText" : "ion-hide"}> La hora está vacía</p>
                                                </IonText>
                                            </IonCol>
                                            <IonCol sizeXl='12' sizeXs='12' className='AMBPrismaMessagesContainerDescartar'>
                                                <IonText className="ABMPrismaMessagesInputTitles">
                                                    Permitir Descartar
                                                </IonText>
                                                <div className="ABMPrismaMessagesCheckbox">
                                                    <IonCheckbox
                                                        color="secondary"
                                                        name='close'
                                                        checked={dataMessage.closeOption}
                                                        onIonChange={(e) => getCheckedForm(e)} />
                                                </div>

                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonText className="ABMPrismaMessagesInputTitles ABMMensaje">
                                                Mensaje
                                            </IonText>
                                            <IonItem className="ABMPrismaMessagesItemTextarea" lines='none'>
                                                <IonTextarea
                                                    className="ABMPrismaMessagesTextarea"
                                                    placeholder="Escriba el mensaje aquí"
                                                    name='message'
                                                    value={dataMessage.message}
                                                    onIonChange={(e) => getDataForm(e)} />
                                                <IonText>
                                                    <p className={messageInvalid ? "ABMPrismaMessagesValidText" : "ion-hide"}>El mensaje está vacío</p>
                                                </IonText>
                                            </IonItem>
                                        </IonRow>
                                        <IonRow className="AMBButtonMensajeContainer">
                                            <IonButton type="submit" fill="clear" onClick={createMessage}>
                                                <IonIcon icon={checkmarkCircleOutline} color="success" className="ion-padding ion-no-padding-bottom ABMPrismaMessagesIconButton" />
                                            </IonButton>
                                            <IonRouterLink href='/page/prismamessages'>
                                                <IonButton fill="clear">
                                                    <IonIcon icon={closeCircleOutline} color="danger" className="ion-padding ion-no-padding-bottom ABMPrismaMessagesIconButton" />
                                                </IonButton>
                                            </IonRouterLink>
                                        </IonRow>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </IonCard>

                        <Modal
                            modalConfirmation={
                                {
                                    isOpenModalConfirm: showModal,
                                    onDidDimissConfirmModal: () => onDismissModal(),
                                    textConfirmation: textModal,
                                    onClickConfirmModal: () => onDismissModal(),
                                }
                            }
                        />

                    </IonContent>
            }
            <Footer />
        </IonPage>
    )
}

//Redux state and actions dispatchers
interface StateTypes {
    session: SessionDefaultStateI,
    clientsInMaintenance: ClientsInMaintenanceDefaultStateI
}

const mapStateToProps = (state: StateTypes) => {
    return {
        session: state.session,
        clientsInMaintenance: state.clientsInMaintenance,
    };
};

interface dispatchTypes {
    getClientsInMaintenance: () => void;
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getClientsInMaintenance: () => dispatch(GetClientsInMaintenance())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddABMPrismaMessages);