import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    IonButton, IonCard, IonCardTitle, IonCol, IonContent, IonGrid, IonIcon, IonInput,
    IonItem, IonLabel, IonRow, IonSelect, IonSelectOption, IonText, IonToggle
} from '@ionic/react';
import { checkmarkCircleOutline, closeCircleOutline } from 'ionicons/icons';

import { GetABMClients } from '../../store/ABMClients/ABMClientsActions';

import ApiRequestor from '../../api_access/ApiRequestor';
import Upload from './Upload';

import './ABMClients.css';
import Modal from '../Modal/Modal';


interface Data {
    service_name: string;
    service_display_name: string;
    service_maintenance: boolean;
    service_image: any;
    service_created_by: string;
    service_updated_by: string;
    service_category: string;
}

interface Props extends StateTypes, dispatchTypes {
    clientData: Data;
    backToTable: () => void
}

const ModifyABMClients: React.FC<Props> = (props): JSX.Element => {

    const [showModal, setShowModal] = useState(false);
    const [dataClient, setDataClient] = useState({
        name: '',
        id: '',
        maintenance: false,
        image: null,
        category: ''
    });
    const [nameInvalid, setNameInvalid] = useState(false);

    useEffect(() => {
        setDataClient({
            name: props.clientData.service_display_name,
            id: props.clientData.service_name,
            maintenance: props.clientData.service_maintenance,
            image: props.clientData.service_image,
            category: props.clientData.service_category
        })
    }, []);

    let { name, id, maintenance, image, category } = dataClient;

    const getDataForm = (e: any) => {
        setDataClient({
            ...dataClient,
            [e.target.name]: e.detail.value!
        })
    }
    const getToggleForm = (e: any) => {
        setDataClient({
            name: dataClient.name,
            id: dataClient.id,
            maintenance: !dataClient.maintenance,
            image: dataClient.image,
            category: dataClient.category
        })
    }
    const onImageUpload = (image: any) => {
        setDataClient({
            name: dataClient.name,
            id: dataClient.id,
            maintenance: dataClient.maintenance,
            image: image,
            category: dataClient.category
        })
    }
    const validationName = () => {
        if (name.length <= 1) {
            setNameInvalid(true);
        } else {
            setNameInvalid(false);
        }
    }
    const modifyClient = async (e: any) => {
        e.preventDefault();
        let valid = false
        if (name.length > 0) {
            valid = true;
        }
        if (valid) {
            let data: any = {
                body: {
                    "service_name": dataClient.id,
                    "service_display_name": dataClient.name,
                    "service_maintenance": dataClient.maintenance,
                    "service_category": dataClient.category,
                    "service_image": dataClient.image
                }
            }

            const response: any = await ApiRequestor.requestToApi("/swt-admin/clients", "PUT", data, "DATA", true);
            if (response && response.status == 200) {
                setShowModal(true)
            }
        } else {
            validationName();
        }

    }
    const dismissModal = () => {
        setShowModal(false);
        props.getABMClients()
        props.backToTable()
    }
    return (
        <IonContent fullscreen className="ion-padding">
            <IonCard className="ABMClientsFormCard">
                <IonCardTitle className="ion-text-center ABMClientsFormTitle">
                    MODIFICAR
                </IonCardTitle>
                {dataClient.id !== '' &&
                    <IonGrid className="ion-margin ion-no-margin-top">
                        <IonRow>
                            <IonCol className="ABMClientsFormCol" sizeLg='6' sizeXs='12'>
                                <IonLabel className="ion-margin-vertical ABMClientsFormLabel">
                                    Nombre
                                </IonLabel>
                                <IonItem className="ABMClientsFormItem" lines='none'>
                                    <IonInput
                                        type="text"
                                        placeholder='Ingrese el nombre'
                                        className="ion-padding ABMClientsFormInput"
                                        name="name"
                                        value={dataClient.name}
                                        onIonChange={(e) => {
                                            validationName();
                                            getDataForm(e)
                                        }}>
                                    </IonInput>
                                </IonItem>
                                <IonText><p className={nameInvalid ? "login-valid-text" : "ion-hide"}> Longitud menor a 2 caracteres</p></IonText>
                            </IonCol>
                            <IonCol sizeLg='6' sizeXs='12' className="ABMClientsFormCol">
                                <IonLabel className="ion-margin-vertical ABMClientsFormLabel">
                                    ID
                                </IonLabel>
                                <IonItem className="ABMClientsFormItem" lines='none'>
                                    <IonInput
                                        type="text"
                                        placeholder="Ingrese el ID"
                                        className="ion-padding ABMClientsFormInput"
                                        name="id"
                                        value={dataClient.id}
                                        onIonChange={getDataForm}
                                        disabled>
                                    </IonInput>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol sizeLg='6' sizeXs='12' className="ABMClientsFormCol">
                                <IonLabel className="ion-margin-vertical ABMClientsFormLabel">
                                    Categoria
                                </IonLabel>
                                <IonItem className="ABMClientsFormItem" lines='none'>
                                    <IonSelect
                                        interface="popover"
                                        placeholder="Seleccione la categoria"
                                        name="category"
                                        className="ABMClientsFormInput"
                                        value={dataClient.category}
                                        onIonChange={(e) => { getDataForm(e) }}>
                                        <IonSelectOption value="BANCOS">
                                            NEWPAY
                                        </IonSelectOption>
                                        <IonSelectOption value="RETAIL">
                                            PAYWAY
                                        </IonSelectOption>
                                        <IonSelectOption value="INTERNO">
                                            PRISMA
                                        </IonSelectOption>
                                        <IonSelectOption value="ADMIN">
                                            ADMIN
                                        </IonSelectOption>
                                    </IonSelect>
                                </IonItem>
                            </IonCol>
                            <IonCol sizeLg='6' sizeXs='12' className="ABMClientsFormCol">
                                <IonLabel className="ion-margin-vertical ABMClientsFormLabel">
                                    Mantenimiento
                                </IonLabel>
                                <IonToggle
                                    className="ion-padding-start"
                                    name="maintenance"
                                    mode="ios"
                                    color="secondary"
                                    checked={maintenance}
                                    onIonChange={getToggleForm}>
                                </IonToggle>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol sizeLg='6' sizeXs='7' className="ABMClientsFormCol mobile-hidden">
                                <IonLabel className="ion-margin-vertical ABMClientsFormLabel">
                                    Imagen
                                </IonLabel>
                                <Upload
                                    initialImage={image}
                                    imageSelectCallback={onImageUpload}/>
                            </IonCol>
                            <IonCol sizeLg='6' sizeXs='12' className="ABMClientsFormCol">
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-margin-horizontal ion-margin-top ion-no-margin-bottom ion-justify-content-center container-buttons">
                            <IonButton className="container-button" type="submit" fill="clear" onClick={modifyClient}>
                                <IonIcon icon={checkmarkCircleOutline} color="success" className="ion-padding ion-no-padding-bottom AMBClientsButton"/>
                            </IonButton>
                            <IonButton className="container-button" fill="clear" onClick={props.backToTable}>
                                <IonIcon icon={closeCircleOutline} color="danger" className="ion-padding ion-no-padding-bottom AMBClientsButton"/>
                            </IonButton>
                        </IonRow>
                    </IonGrid>
                }
            </IonCard>

            <Modal
                modalConfirmation={{
                    textConfirmation: 'Cliente Modificado Correctamente',
                    onDidDimissConfirmModal: () => dismissModal(),
                    isOpenModalConfirm: showModal,
                    onClickConfirmModal: () => dismissModal()
                }}

            />

        </IonContent>
    );
}

//Redux state and actions dispatchers
interface StateTypes {

}

const mapStatetoProps = (state: StateTypes) => {
    return {

    }
};
interface dispatchTypes {
    getABMClients: () => void;
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getABMClients: () => dispatch(GetABMClients())
    };
}

export default connect(mapStatetoProps, mapDispatchToProps)(ModifyABMClients);

