import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  IonCard, IonContent, IonPage, IonGrid, IonRow, IonCol, IonCardHeader, IonCardTitle,
  IonCardContent,
  getPlatforms
} from "@ionic/react";

import { SessionDefaultStateI } from "../../store/session/SessionReducer";
import { UserInfoDefaultStateI } from "../../store/userInfo/userInfoReducer";
import { GetUserInfo } from "../../store/userInfo/userInfoActions";

import Header from "../header/Header";
import Footer from "../Footer/Footer";
import ChangePassword from "./ChangePassword";
import UserData from "./UserData";

import "./UserProfile.css";

interface Props extends StateTypes, dispatchTypes { }


const UserProfile: React.FC<Props> = (props): JSX.Element => {

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const desktop = getPlatforms().includes("desktop")

  useEffect(() => {

    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
      setShouldRefresh(true);
    }

    if(desktop){
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
    
  }, []);

  useEffect(() => {
    if (shouldRefresh) {
      setShouldRefresh(false);
      window.location.reload();
    }
  }, [shouldRefresh]);
  useEffect(() => {
    if (props.session.loading === false && props.session.loginData) {
      props.getUserInfo(
        props.session.loginData.getIdToken().decodePayload()["cognito:username"]
      );
    }
  }, []);

  return (
    <IonPage>
      <Header headerText="Mi Perfil" />
      {props.userInfo.loading === false && props.userInfo.userInfo && (
        <IonContent fullscreen className="profile-content">
          <IonGrid className="ion-justifiy-content-center">
            <IonRow className="ion-justifiy-content-center container-userProfile">
              <IonCol size-lg="4" size-md="4" size-sm="12" size-xs="12">
                <UserData />
              </IonCol>
              <IonCol size-lg="8" size-md="8" size-sm="12" size-xs="12" >
                <IonCard className="profile-card">
                  <IonCardHeader className="ion-text-center">
                    <IonCardTitle className="profile-title-config-user" mode="md"> Configuraciones de Usuario </IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <ChangePassword />
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      )}
      <Footer />
    </IonPage>
  );
};

//Redux state and actions dispatchers
interface StateTypes {
  session: SessionDefaultStateI;
  userInfo: UserInfoDefaultStateI;
}

const mapStateToProps = (state: StateTypes) => {
  return {
    session: state.session,
    userInfo: state.userInfo,
  };
};

interface dispatchTypes {
  getUserInfo: (username: string) => void;
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getUserInfo: (username: string) => dispatch(GetUserInfo(username)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);