import { IonApp } from '@ionic/react';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import { withAuthenticator } from 'aws-amplify-react';
import '@aws-amplify/ui/dist/style.css';
import Routes from './Routes';
import './theme/variables.css'
import './components/Menu/Menu.css'

// Amplify.configure({
//   Auth: {
//     identityPoolId: "us-east-1:06a9363e-f104-4569-9885-17a9b84d96f9",
//     region: "us-east-1",
//     userPoolId: "us-east-1_HzKBtNQLP",
//     userPoolWebClientId: "ra5lqnh7cvcgnlfpk7m64jaj8",
//     RoleArn: "arn:aws:cognito-idp:us-east-1:162838978339:userpool/us-east-1_HzKBtNQLP"
//   }
// })

Amplify.configure({
  Auth: {
    identityPoolId: "us-east-1:27206b3d-8bb3-4310-b873-48769bcf4caa",
    region: "us-east-1",
    userPoolId: "us-east-1_VRitv6czN",
    userPoolWebClientId: "3gcinnqo05n46ol2huoldnnjov",
    RoleArn: "arn:aws:cognito-idp:us-east-1:162838978339:userpool/us-east-1_VRitv6czN"
  }
})

const App: React.FC = () => {
  return (
    <IonApp>
      <Routes/>
    </IonApp>
  );
};

export default App;
