import ApiRequestor from "../../../api_access/ApiRequestor";
import {Dispatch} from "redux"
import { GetABMKPISDispatchTypes, GET_ABMKPIS_LOADING, GET_ABMKPIS_SUCCESS, GET_ABMKPIS_FAIL } from './ABMKpisActionsTypes';


export const GetABMKpis = () => async (dispatch: Dispatch<GetABMKPISDispatchTypes>) => {
    try {
        dispatch({
            type: GET_ABMKPIS_LOADING
        })
        const response : any = await ApiRequestor.requestToApi("/swt-admin/kpis", "GET");
        dispatch({
            type: GET_ABMKPIS_SUCCESS,
            payload: response.data
        })
    } catch(error) {
        console.log(error);
        dispatch({
            type: GET_ABMKPIS_FAIL
        })
    }
   
}
