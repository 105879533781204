export const GET_ABMKPISGROUPS_LOADING = 'GET_ABMKPISGROUPS_LOADING';
export const GET_ABMKPISGROUPS_FAIL = 'GET_ABMKPISGROUPS_FAIL';
export const GET_ABMKPISGROUPS_SUCCESS = 'GET_ABMKPISGROUPS_SUCCESS';

export type ABMKpisGroupsType = {
    kpi_group_id: number,
    kpi_group_name: string,
    kpi_group_created_by: string,
    kpi_group_updated_by: string,
    service_id: number,
    service_name: string
}

export interface GetABMKpisGroupsLoading{
    type: typeof GET_ABMKPISGROUPS_LOADING
}

export interface GetABMKpisGroupsFail{
    type: typeof GET_ABMKPISGROUPS_FAIL
}

export interface GetABMKpisGroupsSuccess{
    type: typeof GET_ABMKPISGROUPS_SUCCESS,
    payload: ABMKpisGroupsType[] | undefined
}

export type GetABMKPISGROUPSDispatchTypes =  GetABMKpisGroupsLoading | GetABMKpisGroupsFail | GetABMKpisGroupsSuccess;