import React, { useEffect } from "react";
import { IonCard, IonCardContent, IonCardHeader, IonCol, IonGrid, IonIcon, IonRow, IonText } from "@ionic/react";
import { personCircle } from "ionicons/icons";

import { Chart } from 'chart.js';

import "./userStatistics.css"

interface Data {
    bank_id: string;
    category: string;
    total: number;
    unverified: number;
    inactive: number;
}

interface Props {
    data: Data
}

const UsersSpecifyGraphics: React.FC<Props> = (props): JSX.Element => {
    let activos = props.data.total - props.data.inactive - props.data.unverified;
    const graphID = `graphUser-${props.data.bank_id}`;

    useEffect(() => {
        renderGraphicBar()
    }, [])

    const renderGraphicBar = () => {
        if (props.data.total > 0) {
            const data: any = {
                labels: [
                    "Usuarios Activos", "Usuarios Inactivos", "Usuarios No verificados"
                ],
                datasets: [{
                    label: " ",
                    data: [activos, props.data.inactive, props.data.unverified],
                    backgroundColor: [
                        'rgba(54,162,235,1)',
                        'rgba(255,99,132,1)',
                        'rgba(255,206,86,1)',
                    ],
                    hoverOffset: 1,
                    borderWidth: 0,
                }]
            }

            const tooltip = {
                displayColors: false,
                callbacks: {
                    label: function (item: any) {
                        const values = myPieChart.data.datasets[0].data[item.dataIndex];
                        const percentage = Math.round((values * 100 / props.data.total))
                        return `${percentage}%`
                    }
                }
            }

            const options: any = {
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: tooltip
                },
            };
            const canvas = document.getElementById(graphID) as HTMLCanvasElement;
            const myPieChart: any = new Chart(canvas, {
                type: "doughnut",
                data: data,
                options: options,
                plugins: [
                    {
                        id: "chart",
                        beforeInit: function (myPieChart: any, args: any, options: any) {
                            if (myPieChart.canvas.id === graphID) {
                                const ul = document.createElement("ul");
                                myPieChart.data.labels.forEach((label: any, i: string | number) => {
                                    ul.innerHTML += `
                                        <li>
                                        <span style="background-color: ${myPieChart.data.datasets[0].backgroundColor[i]}"></span>
                                        ${label}: ${myPieChart.data.datasets[0].data[i]}
                                        
                                        </li>
                                      `;
                                });
                                return document.getElementById(`canvas-content-${graphID}`)?.appendChild(ul);
                            }
                        },
                    },
                ],
            });
        }
    }

    const returnCardNoUser = () => {
        return (
            <IonCardContent className="content-NoUser-card">
            <div className="user-legends user-NoUser content-card-user-general">

                <IonText className="user-NoUser-internal">El Cliente no tiene Usuarios Registrados</IonText>
                <IonIcon className="user-NoUser-icon" icon={personCircle} color="secondary" />
            </div>
            </IonCardContent>
        )
    }

    const returnCard = () => {
        return (
            <>
                <IonCardContent className="content-specify-card">
                    <IonText className="user-title-graphic">Usuarios Registrados: {props.data.total}</IonText>
                    <IonRow className="user-content-canvas">
                        <IonCol sizeXl='3.3' sizeLg="4.5" sizeMd="4.5" sizeSm="12" sizeXs="12"  >
                            <IonGrid className="user-content-canvas-graphics" >
                                <canvas id={graphID} />
                            </IonGrid>
                        </IonCol>
                        <IonCol sizeXl='8.5' sizeLg="7.5" sizeMd="7.5" sizeSm="8" sizeXs="12" className="user-legends user-content-canvas-legend" id={`canvas-content-${graphID}`} />
                    </IonRow>
                </IonCardContent>
            </>
        )
    }

    return (
        <IonCard>
            <IonCardHeader className='user-card-header-specify'>{props.data.bank_id}</IonCardHeader>

            {props.data.total > 0 ?
                returnCard() : returnCardNoUser()
            }
        </IonCard >
    )
}

export default UsersSpecifyGraphics