import {GetLastMessagesDispatchTypes, GET_LAST_MESSAGES_FAIL, GET_LAST_MESSAGES_LOADING, GET_LAST_MESSAGES_SUCCESS,LastMessagesType} from './lastMessagesActionsTypes'

export interface LastMessagesDefaultStateI {
    loading:boolean,
    lastMessages?: LastMessagesType[]
}

const defaultState:LastMessagesDefaultStateI = {
    loading:false
};


const lastMessagesReducer = (state: LastMessagesDefaultStateI = defaultState,action:GetLastMessagesDispatchTypes):LastMessagesDefaultStateI =>{
    switch (action.type) {
        case GET_LAST_MESSAGES_FAIL:
            return {
                loading:false
            }
            break;
        case GET_LAST_MESSAGES_LOADING:
            return {
                loading:true
            }
            break;
        case GET_LAST_MESSAGES_SUCCESS:
            return {
                loading:false,
                lastMessages:action.payload
            }
            break;
        default:
            return state
            break;
    }
    return state;
}


export default lastMessagesReducer;