import React, { useEffect, useState } from "react";
import { IonText } from "@ionic/react";

import { CountdownCircleTimer } from "react-countdown-circle-timer";

import '../../Internos/Intern.css';

interface Props {
    percentageCard: any;
    status: boolean;
}

const PaystorePercentage: React.FC<Props> = (props): JSX.Element => {
    const [sizePercentage, setSizePercentage] = useState<number>(50)

    const sizes: { [key: number]: number } = {
        360: 100,
        750: 110,
        1920: 120,
        2560: 140,
        3800: 200
    }

    useEffect(() => {
        for (let key of Object.keys(sizes)) {
            if (parseInt(key) <= window.screen.width) {
                setSizePercentage(sizes[parseInt(key)])
            }
        }
    }, [window.screen.width])

    return (
        <div className="intern-percentage" key={props.percentageCard}>
            <CountdownCircleTimer
                isPlaying={false}
                duration={100}
                colors={props.status ? "#e73557" : "#29b2b7"}
                initialRemainingTime={props.percentageCard}
                rotation={"counterclockwise"}
                strokeWidth={8}
                size={sizePercentage} >
                {({ remainingTime }) => {
                    const time = remainingTime
                    return (
                        <div>
                            <IonText className="intern-percentage-text"> {time} %</IonText>
                        </div>
                    )
                }}
            </CountdownCircleTimer>
        </div>
    )
}

export default PaystorePercentage;