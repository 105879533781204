import React, { useEffect, useState } from 'react';
import { getPlatforms, IonCard, IonCardContent, IonCardHeader, IonCol, IonIcon } from '@ionic/react';
import { save } from 'ionicons/icons';

import Chart from 'chart.js/auto';
import dayjs from 'dayjs';

import './Intern.css';
import { dataGraphic, colorsGraphics, colors } from './graphicInternosUtils';

interface colorsBorderAndFill {
    border: string,
    fill: any
}

type dataInternType = {
    label: string,
    data: number[],
    fill: boolean,
    backgroundColor: string,
    borderColor: string
}
type chartDataInternType = {
    labels: string[],
    datasets: dataInternType[]
}
interface GraphicInternData {
    intern_id: string,
    intern_title: string,
    intern_threshold: boolean,
    keys: string[],
    values: number[],
    limit_values: number[]
}
export type InternGraf = {
    intern_id: string,
    intern_type: string,
    intern_title: string,
    intern_title_key: string,
    intern_linechart_label: string,
    intern_threshold: boolean,
    keys: [],
    values: number[],
    valuesReverse?: number[],
    limit_values: number[]
};
interface Props {
    internData: InternGraf
    internDataCoelsa?: Array<InternGraf>
    internDataTime?: Array<InternGraf>
    id?: string
    alerted: boolean
    graphId?: string
    title?: string
    valuesReverse?: any
    type?: string
}

const InternGraphics: React.FC<Props> = (props): JSX.Element => {

    const typeGraphics = ['combined', 'reversedGeneral', 'entities', 'lineReverse', 'evolution', 'tiempoJPOS', 'tiempoApache', 'reports', 'lifespan']


    const [showSaveIcon, setShowSaveIcon] = useState(false)
    const graphID = props.graphId ? props.graphId : `graphResolutores-${props.internData.intern_id}`
    const textColor = localStorage.getItem('styleMode') === 'dark' ? 'white' : 'black'
    const umbralColor = getComputedStyle(document.documentElement).getPropertyValue('--cust-color-umbral-rgb')
    const quantityColor = getComputedStyle(document.documentElement).getPropertyValue('--cust-color-quantity-rgb')
    const percentageColor = getComputedStyle(document.documentElement).getPropertyValue('--cust-color-percentage-rgb')
    const alertedColor = getComputedStyle(document.documentElement).getPropertyValue('--cust-color-alerted-rgb')

    useEffect(() => {
        GraphicIntern()
    }, [])

    useEffect(() => {
        const mobile = getPlatforms().includes("mobile")
        const mobileWeb = getPlatforms().includes("mobileweb")
        const desktop = getPlatforms().includes("desktop")
        if (mobile && !mobileWeb) {
            setShowSaveIcon(false)
        }
        if (mobileWeb || desktop) {
            setShowSaveIcon(true)
        }
    }, [])

    const getLabels = (internKey: GraphicInternData): string[] => {
        const formattedData = internKey.keys.map((el: any) => {
            return dayjs(el).format("HH:mm")
        })
        return formattedData;
    };

    const getGradient = (ctx: any, color: string) => {
        const gradient = ctx.createLinearGradient(0, 0, 0, 300);
        const points: number = props.internData.values.length;
        const inc: number = 1 / points;
        let acumGrad: number = 0
        let acumAlpha: number = 1

        for (let i = 0; i < points; i++) {
            gradient.addColorStop(acumGrad, `rgba(${color}, ${acumAlpha})`);
            acumGrad += inc
            acumAlpha -= inc
        }

        return gradient;
    };

    const getDatasets = (intern: InternGraf): dataInternType[] => {
        const canvas = document.getElementById(graphID) as HTMLCanvasElement;
        const ctx: any = canvas.getContext('2d');
        const colour = intern.intern_type === "QUANTITY" ? quantityColor : percentageColor
        const color = props.alerted ? alertedColor : colour

        const gradientUmbral = ctx.createLinearGradient(0, 0, 0, 300);
        gradientUmbral.addColorStop(0, `rgba(${umbralColor}, 0.3)`);
        gradientUmbral.addColorStop(0.5, `rgba(${umbralColor}, 0.3)`);
        gradientUmbral.addColorStop(1, `rgba(${umbralColor}, 0.3)`);

        const gradientValues = getGradient(ctx, color);

        let datasets = intern.intern_threshold ? [
            {
                label: "Umbral",
                data: intern.limit_values,
                fill: true,
                backgroundColor: gradientUmbral,
                borderColor: `rgb(${umbralColor})`
            },
            {
                label: intern.intern_linechart_label,
                data: intern.values,
                fill: true,
                backgroundColor: gradientValues,
                borderColor: `rgb(${color})`
            },
        ] :
            [
                {
                    label: intern.intern_linechart_label,
                    data: intern.values,
                    fill: true,
                    backgroundColor: gradientValues,
                    borderColor: `rgb(${color})`
                },
            ]

        switch (props.type) {
            case 'lineReverse':
                const dataLineReverse: dataGraphic = {
                    data: [{ ...intern, values: intern.valuesReverse! }],
                    transpareciaColor: '0.3'
                }
                datasets.push(buildDataSets(dataLineReverse, ctx, true, colorsGraphics.lineReverse, ['Reverso'])[0])
                break;
            case 'reversedGeneral':
                const dataReversedGeneral: dataGraphic = {
                    data: [{ ...intern, values: intern.values }],
                    transpareciaColor: '0.3'
                }
                return datasets = buildDataSets(dataReversedGeneral, ctx, false, colorsGraphics.reversedGeneral)

            case 'tiempoApache':
                const dataTiempoApache: dataGraphic = {
                    data: [{ ...intern, values: intern.limit_values }, { ...intern, values: intern.values }],
                    transpareciaColor: '0.1'
                }
                return datasets = buildDataSets(dataTiempoApache, ctx, true, colorsGraphics.tiempoApache, ['Umbral', 'TIEMPO REPUESTA APACHE-DEBIN-COELSA']).reverse()

            case 'combined':

                const titlesCoelsa: Array<string> = [
                    'TIMED OUT LAPP-PRPM588',
                    'TIMED OUT LAPP-PRPM589',
                    'TIMED OUT LAPP-PRPM1105',
                    'TIMED OUT LAPP-PRPM1106'
                ]

                const dataCoelsa: dataGraphic = {
                    data: props.internDataCoelsa!,
                    transpareciaColor: '0.3'
                }
                return datasets = buildDataSets(dataCoelsa, ctx, true, colorsGraphics.coelsa, titlesCoelsa)

            case 'tiempoJPOS':

                const dataJ: any = []
                props.internDataTime!.map((graphic: any) => {
                    if (graphic.intern_title == "AVG-TIME-150001") {
                        dataJ.push(
                            { ...graphic, intern_title: 'Umbral', values: graphic.limit_values },
                            { ...graphic, values: graphic.values }
                        )
                    } else {
                        dataJ.push({ ...graphic, values: graphic.values })
                    }
                })

                const dataJPOS: dataGraphic = {
                    data: dataJ,
                    transpareciaColor: '0.1'
                }

                return datasets = buildDataSets(dataJPOS, ctx, true, colorsGraphics.tiempoJPOS).reverse()

            case 'lifespan':

                const dataL: any = []
                props.internDataTime!.map((graphic: any) => {
                    if (graphic.intern_title == "LIFESPAN TOTAL 588") {
                        dataL.push(
                            { ...graphic, intern_title: 'Umbral', values: graphic.limit_values },
                            { ...graphic, values: graphic.values }
                        )
                    } else {
                        dataL.push({ ...graphic, values: graphic.values })
                    }
                })

                const dataLIFESPAN: dataGraphic = {
                    data: dataL,
                    transpareciaColor: '0.1'
                }

                return datasets = buildDataSets(dataLIFESPAN, ctx, true, colorsGraphics.lifeSpan).reverse()
        }
        return datasets;
    };

    const returnTitle = (hasLabel: boolean, customTitle: Array<string>, indexTitle: number, titleGraphic: string) => {

        if (hasLabel && customTitle.length !== 0) {
            return customTitle[indexTitle]
        }

        if (!hasLabel) {
            return 'no-title'
        }

        return titleGraphic
    }
    const buildDataSets = (data: dataGraphic, ctx: any, hasLabel: boolean = true, color: { [title: string]: colors }, customTitle?: Array<string>) => {
        const datasets: dataInternType[] = [];
        let colorsBorderAndFill: colorsBorderAndFill = {
            border: '',
            fill: ''
        };


        data.data.map((graphic: any, index: number) => {

            const title = returnTitle(hasLabel, customTitle ?? [], index, graphic.intern_title)

            colorsBorderAndFill = buildColor(title, ctx, data.transpareciaColor, color, graphic)!;
            datasets.push({
                label: hasLabel ? customTitle !== undefined ? customTitle[index] : graphic.intern_title : '',
                data: graphic.values,
                fill: true,
                backgroundColor: colorsBorderAndFill?.fill,
                borderColor: colorsBorderAndFill?.border
            })
        })
        return datasets
    }


    const buildColor = (title: string, ctx: any, transparenciaColor: string, colors: { [title: string]: colors }, graphic: any) => {
        const colour = graphic.intern_type === "QUANTITY" ? quantityColor : percentageColor
        const color = props.alerted ? alertedColor : colour

        if (title in colors) {
            const umbralColor = getComputedStyle(document.documentElement).getPropertyValue(colors[title].border.trim());
            const gradientColor = getComputedStyle(document.documentElement).getPropertyValue(colors[title].gradient?.trim());
            const gradient = ctx.createLinearGradient(0, 0, 0, 300);
            gradient.addColorStop(0, `rgba(${gradientColor}, ${transparenciaColor})`);
            gradient.addColorStop(0.5, `rgba(${gradientColor}, ${transparenciaColor})`);
            gradient.addColorStop(1, `rgba(${gradientColor}, ${transparenciaColor})`);

            return (
                {
                    border: `rgb(${umbralColor})`,
                    fill: gradient
                }
            );
        }
        return ({
            border: `rgb(${color})`,
            fill: getGradient(ctx, color)
        });
    }

    const saveChart = () => {
        const url_base64 = document.getElementById(graphID) as HTMLCanvasElement;
        const link = document.getElementById(`download-${props.internData.intern_title}`) as HTMLLinkElement;
        link.href = url_base64.toDataURL('image/png');
    };

    const GraphicIntern = () => {
        const data: chartDataInternType = {
            labels: getLabels(props.internData),
            datasets: getDatasets(props.internData),
        };
        const options: any = {
            responsive: true,
            maintainAspectRatio: typeGraphics.includes(props.type!) ? false : true,
            elements: {
                line: {
                    // A higher value makes the line look skewed at this ratio.
                    tension: 0.4,
                    borderWidth: 1
                },
                point: {
                    radius: 0
                }
            },
            scales: {
                x: {
                    grid: {
                        color: "rgba(127, 131, 137,0.2)",
                        borderDash: [4, 2],
                    },
                    ticks: {
                        color: "#929ba8",
                        maxTicksLimit: props.type == "reversedGeneral" ? 50 : 8,
                        font: {
                            size: 10,
                        }
                    },
                },
                y:
                {
                    grid: {
                        color: "rgba(127, 131, 137,0.2)",
                        borderDash: [4, 2],
                    },
                    beginAtZero: true,
                    ticks: {
                        color: "#929ba8",
                        maxTicksLimit: 8,
                        font: {
                            size: 10,
                        }
                    },
                }
            },
            interaction: {
                mode: 'nearest',
                intersect: false
            },
            plugins: {
                zoom: {
                    pan: {
                        enabled: true,
                        mode: 'xy',
                        threshold: 10,
                    },
                    zoom: {
                        wheel: {
                            enabled: true
                        }
                    }
                },
                legend: {
                    display: props.type == "reversedGeneral" ? false : true,
                    align: "center",
                    reverse: true,
                    labels: {
                        color: textColor,
                        usePointStyle: true,
                        pointStyle: 'rectRounded',
                        padding: 15,
                        font: {
                            size: 8,

                        }
                    },
                }
            },
            layout: {
                padding: {
                    right: 15
                }
            }
        };

        const content: any = document.getElementById(`canvas-content-${graphID}`)
        content.style.minwidth = '100%'
        content.style.minheight = '100%'


        const canvas = document.getElementById(graphID) as HTMLCanvasElement;
        const ctx: any = canvas.getContext('2d');



        const myChart = new Chart(ctx, {
            type: 'line',
            data: data,
            options: options
        })
        //const content: any = document.getElementById(`canvas-content-${graphID}`)
        if (content) {
            content.style.display = "none"
        }
    };

    setTimeout(function () {
        const content: any = document.getElementById(`canvas-content-${graphID}`)
        if (content) {
            content.style.display = "block"
        }
    }.bind(this), 2000);

    const cssGraphics = () => {

        const style: { [key: string]: string } = {
            'lineReverse': 'graph-content-lineReverse',
            'evolution': 'graph-content-evolution',
            'tiempoJPOS': 'graph-content-jpos',
            'lifespan': 'graph-content-lifespan',
            'reports': 'graph-intern-content'
        }

        return typeGraphics.includes(props.type!) ?
            style[props.type!] ?? 'graph-content-combinedCoelsaReverse' :
            'graph-intern-content';

    }

    const cssGraphicsTitle = () => {

        const style: { [key: string]: string } = {
            'reports': 'graph-intern-reports'
        }

        return typeGraphics.includes(props.type!) ?
            style[props.type!] ?? 'graph-intern' :
            'graph-intern';

    }

    return (
        <IonCard className={cssGraphicsTitle()} id={props.internData.intern_id}>
            <IonCardHeader className="graph-intern-header">
                <IonCol className=' ion-text-center graph-intern-title'>
                    {props.title}
                </IonCol>
                {showSaveIcon &&
                    <a className="download-button"
                        id={`download-${props.internData.intern_title}`}
                        download={`${props.internData.intern_title}`}
                        onClick={saveChart}>
                        <IonIcon icon={save} className="graph-intern-icon" id="iconSave" />
                    </a>}
            </IonCardHeader>
            <IonCardContent id={`canvas-content-${graphID}`} className={cssGraphics()}>
                <canvas className='graphic' id={graphID}></canvas>
            </IonCardContent>
        </IonCard>
    )
}

export default InternGraphics;