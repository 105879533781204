import React from 'react'
import { IonCard, IonPage } from '@ionic/react';
import { connect } from 'react-redux';

import { SessionDefaultStateI } from '../../store/session/SessionReducer';

import PaystoreMainView from '../Pos/Paystore/PaystoreMainView';
import DecidirMainView from '../Decidir/DecidirMainView';
import ResolutoresMainView from '../Resolutores/ResolutoresMainView';
import DebinMainView from '../Debin/DebinMainView';
import NewpayMainView from '../Newpay/NewpayMainView';

interface Props extends StateTypes, dispatchTypes { }

const Interno: React.FC<Props> = (props): JSX.Element => {
  const bank_id = props.session.loginData.getIdToken().decodePayload()["custom:bank_id"].toUpperCase()

  return (
    <IonPage>
      {
        bank_id == "POS" || sessionStorage.getItem("client") == "POS" ?
          <PaystoreMainView /> :
          bank_id == "DECIDIR" || sessionStorage.getItem("client") == "DECIDIR" ?
            <DecidirMainView /> :
            bank_id == "RESOLUTORES" || sessionStorage.getItem("client") == "RESOLUTORES" ?
              <ResolutoresMainView /> :
              bank_id == "DEBIN" || sessionStorage.getItem("client") == "DEBIN" ?
                <DebinMainView /> :
                bank_id == "NEWPAY" || sessionStorage.getItem("client") == "NEWPAY" ? 
                  <NewpayMainView /> :
                <IonCard></IonCard>
      }
    </IonPage>
  )
}

//Redux state and actions dispatchers
interface StateTypes {
  session: SessionDefaultStateI,
}

const mapStateToProps = (state: StateTypes) => {
  return {
    session: state.session,
  };
};

interface dispatchTypes {
}

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Interno)