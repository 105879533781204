import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonIcon, IonPage, IonRow, IonText, getPlatforms } from "@ionic/react";
import { arrowUp, arrowUpCircle, notifications, notificationsCircle } from "ionicons/icons";

import { SessionDefaultStateI } from "../../../store/session/SessionReducer";
import { ClientMaintenanceDefaultStateI } from "../../../store/mainInfo/clientMaintenanceReducer";
import { InternosCardsDefaultStateI } from "../../../store/Internos/InternosCards/internosCardsReducer";
import { InternosGraphicsDefaultStateI } from "../../../store/Internos/InternosGraphics/internosGraphicsReducer";
import { GetClientImage } from "../../../store/mainInfo/mainInfoActions";

import Header from "../../header/Header";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import PaystoreCard from "./PaystoreCard";
import MaintenanceComponent from "../../Maintenance/MaintenanceComponent";
import MessagesPrisma from "../../MessagesPrisma/MessagesPrisma";
import SwtListClientsModal from "../../SwtListClientsModal/SwtListClientsModal";
import Footer from "../../Footer/Footer";

import '../../Internos/Intern.css';
import { ChangeStateDefaultStateI } from "../../../store/changeState/changeStateReducer";
import { GetChangeState } from "../../../store/changeState/changeStateActions";
import Loading from "../../Loading/Loading";

interface Props extends StateTypes, dispatchTypes { }

const PaystoreMainView: React.FC<Props> = (props): JSX.Element => {
  const [cardInfo, setCardInfo] = useState([])
  const [reversalCardInfo, setReversalCardInfo] = useState([])
  const [pendienteCardInfo, setPendienteCardInfo] = useState([])
  const [scrolling, setScrolling] = useState(false)
  const [hasMessages, setHasMessages] = useState(false)
  const [notifColor, setNotifColor] = useState(false)
  const [showModal, setShowModal] = useState(false);
  const [clientSelected, setClientSelected] = useState('')
  const [categorySelected, setCategorySelected] = useState('');
  const [maintenance, setMaintenance] = useState(false);
  const [inactive, setInactive] = useState(false);
  const bank_id_category = props.session.loginData.getIdToken().decodePayload()["custom:category"]
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const desktop = getPlatforms().includes("desktop")

  useEffect(() => {

    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
      setShouldRefresh(true);
    }

    if(desktop){
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
    
  }, []);

  useEffect(() => {
    if (shouldRefresh) {
      setShouldRefresh(false);
      window.location.reload();
    }
  }, [shouldRefresh]);

  useEffect(() => {
    if (props.session.loading === false && props.session.loginData) {
      props.getChangeState()
      if (!sessionStorage.getItem("client")) {
        sessionStorage.setItem("client", '')
      }
      if (bank_id_category == "ADMIN") {
        props.getClients()
        if (sessionStorage.getItem("client") == '') {
          setShowModal(true)
        }
      }
    }
  }, [])

  useEffect(() => {
    if (sessionStorage.getItem("client") !== '') {
      setShowModal(false)
    }
  }, [sessionStorage.getItem("client")])

  useEffect(() => {
    if (bank_id_category !== 'ADMIN') {
      if (props.changeState.loading == false && props.changeState.changeState) {
        if (props.changeState.changeState[0].service_state == "inactive") {
          setInactive(true)
        } else {
          if (props.clientMaintenance.loading == false && props.clientMaintenance.clientMaintenance &&
            props.clientMaintenance.clientMaintenance[0].service_maintenance) {
            setMaintenance(true)
          }
        }
      }
    } else {
      if (props.clientMaintenance.loading == false && props.clientMaintenance.clientMaintenance && sessionStorage.getItem("client") && sessionStorage.getItem("client") != '') {
        const maintenance = props.clientMaintenance.clientMaintenance.filter(client => client.service_name == sessionStorage.getItem("client"))[0].service_maintenance
        if (maintenance) {
          setMaintenance(true)
        } else {
          setMaintenance(false)
        }
      }
    }
  }, [props.changeState.loading, props.changeState.changeState, props.clientMaintenance.loading, props.clientMaintenance.clientMaintenance, sessionStorage.getItem("client")])

  useEffect(() => {
    if (props.internCards.loading === false && props.internCards.internosCards) {
      const cardFilter = props.internCards.internosCards.filter((card: any) => {
        if (card.bank_id.includes("POS")) {
          const cardNoReversal = card.cardsInfo.filter((cards: any) => {
            return !cards.key.includes("REVERSO") && !cards.key.includes("PENDIENTE")
          })
          const paystoreCardData = groupValues(cardNoReversal)
          setCardInfo(paystoreCardData);
          const reversalFilter = card.cardsInfo.filter((card: any) => {
            return card.key.includes("REVERSO")
          })
          const paystoreReversalCardData = groupValues(reversalFilter)
          setReversalCardInfo(paystoreReversalCardData);
          const pendienteFilter = card.cardsInfo.filter((card: any) => {
            return card.key.includes("PENDIENTE")
          })
          const paystorePendienteCardData = groupValues(pendienteFilter)
          setPendienteCardInfo(paystorePendienteCardData)
        }
      })
    }
  }, [props.internCards.loading, props.internCards.internosCards])

  if (inactive && bank_id_category !== 'ADMIN') {
    return <Redirect to="/page/inactive" />
  }

  if (maintenance && bank_id_category !== 'ADMIN') {
    return <Redirect to="/page/maintenance" />
  }

  const getCardAlert = (cardInfo: any) => {
    if (cardInfo.appAlert === "2" || cardInfo.cantAlert === "2") {
      return "2";
    }
    if (cardInfo.appAlert >= cardInfo.cantAlert) {
      return cardInfo.appAlert;
    }
    return cardInfo.cantAlert;
  };

  const getCardByTitleKey = (cardsArray: any, element: any) => {
    let card = cardsArray.find(
      (arrayElement: any) => element.titleKey === arrayElement.titleKey
    );
    //Don't make a new card with information that has no titleKey
    if (!card && element.titleKey) {
      card = { titleKey: element.titleKey };
    }
    return card;
  };

  const getCardIndex = (cardsArray: any, element: any) => {
    //FindIndex returns -1 if it doesn't find an element, indicating it's a new card
    return cardsArray.findIndex(
      (arrayElement: any) => element.titleKey === arrayElement.titleKey
    );
  };

  const groupValues = (array: any[]): any => {
    const cardsArray: any[] = [];

    array.forEach((element) => {
      try {
        let card = getCardByTitleKey(cardsArray, element);
        let index = getCardIndex(cardsArray, element);
        //Modify card with the data
        if (element.key.includes("Aprobadas") || element.key.includes("APROBADAS")) {
          card.appKey = element.key;
          card.appVal = element.value;
          card.appAlert = element.alert;
        } else {
          card.cantKey = element.key;
          card.cantVal = element.value;
          card.cantAlert = element.alert;
        }
        card.gap = element.timeGap;
        card.date = element.date;

        if (index === -1) {
          cardsArray.push(card);
        } else {
          cardsArray[index] = card;
        }
      } catch (err) {
        console.log(`No card found for: ${element.key}`);
      }
    });

    cardsArray.forEach((cardInfo) => {
      cardInfo.alert = getCardAlert(cardInfo);
    });

    return cardsArray;
  };

  const doHasMessages = (bool: boolean) => {
    setHasMessages(bool)
  }

  const handleOpenMessage = () => {
    setScrolling(false)
  }

  const notifIconColor = (bool: boolean) => {
    setNotifColor(bool)
  }

  const showIcon = (e: any) => {
    const iconElement: any = document.getElementById("icon-scrolling")
    const iconMsgElement: any = document.getElementById("icon-msg-scrolling")
    const iconElementCircle: any = document.getElementById("icon-scrolling-circle")
    const iconMsgElementCircle: any = document.getElementById("icon-msg-scrolling-circle")

    if (e.target.scrollTop < 100) {
      iconElement.style.display = "none"
      iconElementCircle.style.display = "none"
    } else {
      iconElement.style.display = "block"
      iconElementCircle.style.display = "block"
    }
    if (hasMessages) {
      if (e.target.scrollTop < 100) {
        iconMsgElement.style.display = "none"
        iconMsgElementCircle.style.display = "none"
      } else {
        iconMsgElement.style.display = "block"
        iconMsgElementCircle.style.display = "block"
      }
    } else {
      iconMsgElement.style.display = "none"
      iconMsgElementCircle.style.display = "none"
    }
  }

  const handleClickUp = () => {
    const contentElement = document.getElementById("ion-content-main")
    contentElement?.scrollIntoView({ behavior: "smooth" })
  }

  const showModalFunc = () => {
    setShowModal(true)
  }

  const closeModalFunc = () => {
    setShowModal(false)
  }

  const selectClient = (clientName: string, categoryName: string) => {
    sessionStorage.setItem("client", clientName)
    sessionStorage.setItem("category", categoryName)
    setClientSelected(clientName)
    setCategorySelected(categoryName)
  }

  const renderCards = () => {
    if (cardInfo.length > 0) {
      return cardInfo.map((payData: any) => {
        return (

          <PaystoreCard
            key={payData.titleKey}
            payStoreData={payData}
            reversalData={payData.titleKey.includes("PHOEBUS") ? reversalCardInfo[0] : ""}
            pendienteData={payData.titleKey.includes("PHOEBUS") ? pendienteCardInfo[0] : ""} />
        )
      })
    } 
  }

  return (
    <IonPage>
      <Header headerText='Paystore' parentCallback={(bank_id_category == "ADMIN") && showModalFunc} timer />
      { cardInfo.length > 0 ?
        <IonContent fullscreen onScroll={(e) => { setScrolling(true); showIcon(e) }}>
          <IonGrid id="ion-content-main">
            {
              (bank_id_category == "ADMIN") && window.screen.width < 768 &&
              <IonRow>
                <IonCol className="ion-no-padding" onClick={() => showModalFunc()}>
                  <IonCard className="ion-no-margin">
                    <IonText><h5 className="ion-text-center ion-no-margin ion-padding">Seleccionar cliente</h5></IonText>
                  </IonCard>
                </IonCol>
              </IonRow>
            }
            {
              maintenance && (bank_id_category == "ADMIN") ?
                <MaintenanceComponent /> :
                <IonCol>
                  <MessagesPrisma scrolling={scrolling} hasMessages={doHasMessages} handleOpenMsg={handleOpenMessage} notifColor={notifIconColor} />
                  <div className="paystore-card">
                    {
                      renderCards()
                    }
                  </div>
                </IonCol>
            }
          </IonGrid>
          <IonIcon icon={notificationsCircle} className={scrolling ? "icon-msgs-up-circle" : "ion-hide"} id="icon-msg-scrolling-circle" onClick={() => { handleClickUp() }} />
          <IonIcon icon={notifications} className={scrolling ? (notifColor ? "icon-msgs-up color-true" : "icon-msgs-up color-false") : "ion-hide"} id="icon-msg-scrolling" onClick={() => { handleClickUp() }} />
          <IonIcon icon={arrowUpCircle} className={scrolling ? "icon-scroll-up-circle" : "ion-hide"} id="icon-scrolling-circle" onClick={() => { handleClickUp() }} />
          <IonIcon icon={arrowUp} className={scrolling ? "icon-scroll-up" : "ion-hide"} id="icon-scrolling" onClick={() => { handleClickUp() }} />
          <SwtListClientsModal parentCallback={selectClient} showModal={showModal} closeModalCallback={closeModalFunc} />
        </IonContent>

        : <Loading />

      }
      <Footer />
    </IonPage>
  )
}

interface StateTypes {
  session: SessionDefaultStateI;
  clientMaintenance: ClientMaintenanceDefaultStateI;
  internCards: InternosCardsDefaultStateI;
  internGraphics: InternosGraphicsDefaultStateI;
  changeState: ChangeStateDefaultStateI;
}

const mapStateProps = (state: StateTypes) => {
  return {
    session: state.session,
    clientMaintenance: state.clientMaintenance,
    internCards: state.internCards,
    internGraphics: state.internGraphics,
    changeState: state.changeState,
  }
}

interface dispatchTypes {
  getClients: () => void;
  getChangeState: () => void;
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getClients: () => dispatch(GetClientImage()),
    getChangeState: () => dispatch(GetChangeState())
  }
}

export default connect(mapStateProps, mapDispatchToProps)(PaystoreMainView)