import React, { useState } from "react";
import { IonCard, IonCardHeader, IonCardTitle, IonIcon, IonCardContent, IonCol } from "@ionic/react";
import { ellipse } from "ionicons/icons";

import ToggleMaintenance from "./ToggleMaintenance";
import ApiRequestor from "../../api_access/ApiRequestor";

import "./ClientsInMaintenance.css";

interface Props {
  serviceName: string,
  serviceDisplayName: string,
  serviceMaintenance: boolean,
}

const CardClient: React.FC<Props> = (props): JSX.Element => {

  const [check, setCheck] = useState(props.serviceMaintenance);
  const checkToggle = async (check: boolean) => {
    let data: any = {
      body: {
        service_name: props.serviceName,
        service_maintenance: check,
        service_display_name: props.serviceDisplayName,
      }
    }
    await ApiRequestor.requestToApi('/swt-admin/clients-maintenance', "PATCH", data);
    setCheck(check);
  }

  return (
      <IonCard className="client-card">
        <IonCardHeader className="client-card-header">
          {
            check ?
              <IonIcon icon={ellipse} className="clientMaintenance-status-icon clientMaintenance-status-icon-not-checked" />
              :
              <IonIcon icon={ellipse} className="clientMaintenance-status-icon card-status-icon-checked" />
          }
          <IonCardTitle className="client-card-title">
            {props.serviceDisplayName}
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent className="client-card-content-alignment">
          <IonCardTitle className="client-card-content-title">
            Servicio en Mantenimiento
          </IonCardTitle>
          <ToggleMaintenance
            check={props.serviceMaintenance}
            parentCallback={checkToggle} />
        </IonCardContent>
      </IonCard>
  );
};

export default CardClient;
