import {GetMainInfoDispatchTypes, GET_GRAPHICS_LOADING, GET_GRAPHICS_FAIL, GET_GRAPHICS_SUCCESS, GraphicsType} from './mainInfoActionsTypes'

export interface GraphicsDefaultStateI {
    loading:boolean,
    graphics?: GraphicsType[] | any
}

const defaultState:GraphicsDefaultStateI = {
    loading:false
};


const graphicsReducer = (state: GraphicsDefaultStateI = defaultState,action:GetMainInfoDispatchTypes):GraphicsDefaultStateI =>{
    switch (action.type) {
        case GET_GRAPHICS_FAIL:
            return {
                loading:false
            }
            break;
        case GET_GRAPHICS_LOADING:
            return {
                loading:true
            }
            break;
        case GET_GRAPHICS_SUCCESS:
            return {
                loading:false,
                graphics:action.payload
            }
            break;
        default:
            return state
            break;
    }
    return state;
}


export default graphicsReducer;