import React from 'react';
import { connect } from 'react-redux';
import {
    IonCard, IonGrid, IonRow, IonCol, IonCardHeader, IonCardTitle, IonCardContent, IonIcon,
    IonText
} from '@ionic/react';
import { personSharp } from 'ionicons/icons';

import { SessionDefaultStateI } from '../../store/session/SessionReducer';
import { UserInfoDefaultStateI } from '../../store/userInfo/userInfoReducer';

import './UserProfile.css';

interface Props extends StateTypes { }

const UserData: React.FC<Props> = (props): JSX.Element => {

    return (
        <IonGrid className="ion-no-padding" >
            {props.userInfo.loading === false && props.userInfo.userInfo &&
                <IonCard className="ion-text-center profile-card pofile-card-fixed">
                    <IonCardHeader mode="md" className="profile-card-header">
                        <IonRow className="ion-align-items-center ion-justify-content-center">
                            <IonCol size-lg="12" size-md="12" size-sm="5" size-xs="5">
                                <IonIcon icon={personSharp} className="person-icon" />
                            </IonCol>
                            <IonCol size-lg="12" size-md="12" size-sm="7" size-xs="7">
                                <IonCardTitle className="profile-userName" mode="md">{props.userInfo.userInfo.first_name.toUpperCase()} {props.userInfo.userInfo.last_name.toUpperCase()} </IonCardTitle>
                                <IonText mode="md" >
                                    <span className="profile-userName">{props.userInfo.userInfo.username}</span>
                                </IonText>
                                <IonText mode="md">
                                    <span className="text-profile">{props.userInfo.userInfo.email}</span>
                                </IonText>
                            </IonCol>
                        </IonRow>
                    </IonCardHeader>
                    <IonCardContent>
                        <IonText className='text-profile'>
                            <span className="profile-text profile-text-bold text-profile">Servicio: {props.userInfo.userInfo.bank_id}</span>
                        </IonText>
                        <IonText className='text-profile'>
                            <span className="profile-text">Celular registrado: {props.userInfo.userInfo.phone_number}</span>
                        </IonText>
                        <IonText className='text-profile'>
                            <span className="profile-text-bold">Descripción</span>
                        </IonText>
                        <IonText>
                            <span className="text-profile">El usuario no ha cargado una descripción de su perfil</span>
                        </IonText>
                    </IonCardContent>
                </IonCard>
            }
        </IonGrid>
    )
}

//Redux state and actions dispatchers
interface StateTypes {
    session: SessionDefaultStateI,
    userInfo: UserInfoDefaultStateI,
}

const mapStateToProps = (state: StateTypes) => {
    return {
        session: state.session,
        userInfo: state.userInfo
    };
};

export default connect(mapStateToProps)(UserData);