import {InternosAlertsType, GetInternosAlertsDispatchTypes, GET_INTERNOS_ALERTS_FAIL, GET_INTERNOS_ALERTS_LOADING, GET_INTERNOS_ALERTS_SUCCESS} from './internosAlertsActionsTypes'

export interface InternosAlertsDefaultStateI{
    loading: boolean,
    internosAlerts? : InternosAlertsType[] | undefined
}

const defaultState : InternosAlertsDefaultStateI = {
    loading:false
}

const internosAlertsReducer = (state:InternosAlertsDefaultStateI = defaultState, action:GetInternosAlertsDispatchTypes):
InternosAlertsDefaultStateI =>{
    switch(action.type){
        case GET_INTERNOS_ALERTS_FAIL:
            return{
                loading:false
            }
            break;
        case GET_INTERNOS_ALERTS_LOADING:
            return{
                loading:true
            }
            break;
        case GET_INTERNOS_ALERTS_SUCCESS:
            return{
                loading:false,
                internosAlerts:action.payload
            }
            break;
        default:
            return state
            break;
    }
    return state;
}

export default internosAlertsReducer;