import { GetShowUmbralsDispatchTypes, GET_SHOWUMBRALS_FAIL, GET_SHOWUMBRALS_LOADING, GET_SHOWUMBRALS_SUCCESS, ShowUmbralsType } from './showUmbralsActionsTypes';

export interface ShowUmbralsDefaultStateI {
    loading:boolean,
    showUmbrals?: ShowUmbralsType
}

const defaultState : ShowUmbralsDefaultStateI = {
    loading:false
}; 

const showUmbralsReducer = (state: ShowUmbralsDefaultStateI = defaultState, action: GetShowUmbralsDispatchTypes) : ShowUmbralsDefaultStateI =>{
    switch (action.type) {
        case GET_SHOWUMBRALS_FAIL:
            return {
                loading : false
            }
            break;
        case GET_SHOWUMBRALS_LOADING:
            return {
                loading : true
            }
            break;
        case GET_SHOWUMBRALS_SUCCESS:
            return {
                loading : false,
                showUmbrals : action.payload
            }
            break;
        default:
            return state
            break;
    }
    return state;
}


export default showUmbralsReducer;
