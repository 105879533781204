//Define the Actions
export const GET_CLIENTSINMAINTENANCE_LOADING = 'GET_CLIENTSINMAINTENANCE_LOADING';
export const GET_CLIENTSINMAINTENANCE_FAIL = 'GET_CLIENTSINMAINTENANCE_FAIL';
export const GET_CLIENTSINMAINTENANCE_SUCCESS = 'GET_CLIENTSINMAINTENANCE_SUCCESS';


export type ClientsInMaintenanceType =  {
    name: string,
    displayName: string,
    maintenance: boolean,
    id:number,
};


export interface GetClientsInMaintenanceLoading {
    type: typeof GET_CLIENTSINMAINTENANCE_LOADING
}

export interface GetClientsInMaintenanceFail {
    type: typeof GET_CLIENTSINMAINTENANCE_FAIL
}

export interface GetClientsInMaintenanceSuccess {
    type: typeof GET_CLIENTSINMAINTENANCE_SUCCESS,
    payload:ClientsInMaintenanceType[]
}

export type GetClientsInMaintenanceDispatchTypes = GetClientsInMaintenanceLoading | GetClientsInMaintenanceFail | GetClientsInMaintenanceSuccess;