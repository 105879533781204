const dotenv = require('dotenv');

dotenv.config();

const env = process.env.REACT_APP_ENV || 'dev';

console.log('ENVIRONMENT '+ env)

const DEFAULT_BACKEND_SERVER = 'https://gb92seselh.execute-api.us-east-1.amazonaws.com/develop';

const server = {
    dev: {
        backend: 'https://gb92seselh.execute-api.us-east-1.amazonaws.com/develop',
        user_service: 'https://gb92seselh.execute-api.us-east-1.amazonaws.com/develop',
        refreshTime: 300,
    },
    prod: {
        backend: "https://4hza1ct5xh.execute-api.us-east-1.amazonaws.com/sandbox",
        user_service: "https://4hza1ct5xh.execute-api.us-east-1.amazonaws.com/sandbox",
        refreshTime: 300,
    },
    other: {
        backend: process.env.REACT_APP_BACKEND || DEFAULT_BACKEND_SERVER,
        user_service: process.env.REACT_APP_USER_SERVICE || DEFAULT_BACKEND_SERVER,
        refreshTime: 300
    }
};

export const CONFIG = server[env];

export const REFRESH_TIME = CONFIG.refreshTime;
