import React, { useEffect, useState } from 'react';
import { IonCard, IonCardTitle, IonCardContent, IonCardHeader, IonIcon, getPlatforms } from '@ionic/react';
import { save } from 'ionicons/icons';
import { GraphicsType } from '../../store/mainInfo/mainInfoActionsTypes';
import dayjs from 'dayjs';
import Chart from 'chart.js/auto';

import './LineChart.css';

type datasetsTypes = {
  label: string,
  data: number[],
  fill: boolean,
  backgroundColor: string,
  borderColor: string
}

type chartDataTypes = {
  labels: string[],
  datasets: datasetsTypes[]
}

interface Props {
  chartData: GraphicsType
  alerted: boolean
  id?: string
  graphId?: string
  type?: string 
}

const LineChart: React.FC<Props> = (props) => {

  const [showSaveIcon, setShowSaveIcon] = useState(false)
  const typeGraphics = ['reports']

  //Define graph id
  const graphID = props.graphId ? props.graphId : `graph-${props.chartData.kpi_id}`

  //Define color based on css variable
  const textColor = localStorage.getItem('styleMode') === 'dark' ? 'white' : 'black'
  const umbralColor = getComputedStyle(document.documentElement).getPropertyValue('--cust-color-umbral-rgb')
  const quantityColor = getComputedStyle(document.documentElement).getPropertyValue('--cust-color-quantity-rgb')
  const percentageColor = getComputedStyle(document.documentElement).getPropertyValue('--cust-color-percentage-rgb')
  const alertedColor = getComputedStyle(document.documentElement).getPropertyValue('--cust-color-alerted-rgb')

  useEffect(() => {
    LineChart()
  }, [])

  useEffect(() => {
    const mobile = getPlatforms().includes("mobile")
    const mobileWeb = getPlatforms().includes("mobileweb")
    const desktop = getPlatforms().includes("desktop")
    if (mobile && !mobileWeb) {
      setShowSaveIcon(false)
    }
    if (mobileWeb || desktop) {
      setShowSaveIcon(true)
    }
  }, [])

  const getLabels = (kpi: GraphicsType): string[] => {
    const formattedData = kpi.keys.map((el: any) => {
      return dayjs(el).format("HH:mm")
    })
    return formattedData;
  };

  const getGradient = (ctx: any, color: string) => {

    const gradient = ctx.createLinearGradient(0, 0, 0, 300);
    const points: number = props.chartData.values.length;
    const inc: number = 1 / points;
    let acumGrad: number = 0
    let acumAlpha: number = 1

    for (let i = 0; i < points; i++) {
      gradient.addColorStop(acumGrad, `rgba(${color}, ${acumAlpha})`);
      acumGrad += inc
      acumAlpha -= inc
    }
    return gradient;
  };

  const getDatasets = (kpi: GraphicsType): datasetsTypes[] => {

    const canvas = document.getElementById(graphID) as HTMLCanvasElement;
    const ctx: any = canvas.getContext('2d');

    //Select between quantity color or percentage color
    const colour = kpi.kpi_type === "QUANTITY" ? quantityColor : percentageColor;
    const color = props.alerted ? alertedColor : colour
    const gradientUmbral = ctx.createLinearGradient(0, 0, 0, 300);
    gradientUmbral.addColorStop(0, `rgba(${umbralColor}, 0.3)`);
    gradientUmbral.addColorStop(0.5, `rgba(${umbralColor}, 0.3)`);
    gradientUmbral.addColorStop(1, `rgba(${umbralColor}, 0.3)`);
    const gradientValues = getGradient(ctx, color)

    const datasets = kpi.kpi_threshold ? [
      {
        label: "Umbral",
        data: kpi.limit_values,
        fill: true,
        backgroundColor: gradientUmbral,
        borderColor: `rgb(${umbralColor})`
      },
      {
        label: kpi.kpi_linechart_label,
        data: kpi.values,
        fill: true,
        backgroundColor: gradientValues,
        borderColor: `rgb(${color})`
      },
    ] :
      [
        {
          label: kpi.kpi_linechart_label,
          data: kpi.values,
          fill: true,
          backgroundColor: gradientValues,
          borderColor: `rgb(${color})`
        },
      ]
    return datasets;
  };

  const saveChart = () => {
    const url_base64 = document.getElementById(graphID) as HTMLCanvasElement;
    const link = document.getElementById(`download-${props.chartData.kpi_title}`) as HTMLLinkElement;
    link.href = url_base64.toDataURL('image/png');
  };

  const LineChart = () => {

    const data: chartDataTypes = {
      labels: getLabels(props.chartData),
      datasets: getDatasets(props.chartData),
    };

    const options: any = {
      responsive: true,
      elements: {
        line: {
          // A higher value makes the line look skewed at this ratio.
          tension: 0.4,
          borderWidth: 1
        },
        point: {
          radius: 0
        }
      },
      scales: {
        x: {
          grid: {
            color: "rgba(127, 131, 137,0.2)",
            borderDash: [4, 2],
          },
          ticks: {
            color: "#929ba8",
            maxTicksLimit: 8,
            font: {
              size: 10,
            }
          },
        },
        y:
        {
          grid: {
            color: "rgba(127, 131, 137,0.2)",
            borderDash: [4, 2],
          },
          beginAtZero: true,
          ticks: {
            color: "#929ba8",
            maxTicksLimit: 8,
            font: {
              size: 10
            }
          },
        }
      },
      interaction: {
        mode: 'nearest',
        intersect: false
      },
      plugins: {
        legend: {
          align: "center",
          reverse: true,
          labels: {
            color: textColor,
            usePointStyle: true,
            pointStyle: 'rectRounded',
            padding: 15,
            font: {
              size: 10,
            }
          },
        }
      },
      layout: {
        padding: {
          right: 15
        }
      }
    };

    const canvas = document.getElementById(graphID) as HTMLCanvasElement;
    const ctx: any = canvas.getContext('2d')

    const myChart = new Chart(ctx, {
      type: 'line',
      data: data,
      options: options
    })
    const content: any = document.getElementById(`canvas-content-${graphID}`)
    if (content) {
      content.style.display = "none"
    }
  };

  setTimeout(function () {
    const content: any = document.getElementById(`canvas-content-${graphID}`)
    if (content) {
      content.style.display = "block"
    }
  }.bind(this), 1000);

  const cssGraphics = () => {
    if(typeGraphics.includes(props.type!)){
      if(props.type! == "reports"){
        return(
          'graph-card-reports'
        )
      } else {
        return(
          'graph-card'
        )
      }
    } else {
      return(
        'graph-card'
      )}
  }

  const cssGraphicsTitle = () =>{
    if(typeGraphics.includes(props.type!)){
      if(props.type! == "reports"){
        return(
          'ion-text-center graph-card-title-reports'
        )
      } else {
        return(
          'ion-text-center graph-card-title'
        )
      }
    }else {
      return(
        'ion-text-center graph-card-title'
      )
    }
  }

  const cssGraphicsIcon = () =>{
    if(typeGraphics.includes(props.type!)){
      if(props.type! == "reports"){
        return(
          'save-icon-reports'
        )
      } else {
        return(
          'save-icon'
        )
      }
    }else {
      return(
        'save-icon'
      )
    }
  }

  return (
    <IonCard className={cssGraphics()} id={props.id}>
      <IonCardHeader className="graph-card-header">
        <IonCardTitle className={cssGraphicsTitle()}>{props.chartData.kpi_title}</IonCardTitle>
        {showSaveIcon &&
          <a className="download-button"
            id={`download-${props.chartData.kpi_title}`}
            download={`${props.chartData.kpi_title}`}
            onClick={saveChart}>
            <IonIcon icon={save} className={cssGraphicsIcon()} id="iconSave" />
          </a>}
      </IonCardHeader>
      <IonCardContent id={`canvas-content-${graphID}`} className="graph-card-content">
        <canvas id={graphID}></canvas>
      </IonCardContent>
    </IonCard>
  );
};

export default LineChart;