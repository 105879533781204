export const setStyleMode = () => {

  const isoDark = document.getElementById("isoDark");
  const isoLight = document.getElementById("isoLight");
  
  const logoDark = document.getElementById("logoDark");
  const logoLight = document.getElementById("logoLight");

  //Check localstorage for user preference
  const userPref = localStorage.getItem("styleMode");

  //Set user preference
  if (userPref) {
    const removeClass = userPref === "dark" ? "light" : "dark";
    document.body.classList.remove(removeClass);
    document.body.classList.add(userPref);
  } else {
    const prefersDark = window.matchMedia("(prefers-color-scheme: dark)");

    if (prefersDark.matches) {
      document.body.classList.add("dark");
      localStorage.setItem("styleMode", "dark");
    } else {
      document.body.classList.add("light");
      localStorage.setItem("styleMode", "light");
    }
  }

  if (isoDark && isoLight && logoDark && logoLight) {
    if (localStorage.getItem("styleMode") === "dark") {
      isoLight.style.display = "none";
      logoLight.style.display = "none";

      isoDark.style.display = "block";
      logoDark.style.display = "block";

    } else {
      isoLight.style.display = "block";
      logoLight.style.display = "block";

      isoDark.style.display = "none";
      logoDark.style.display = "none";
    }
  }
};
