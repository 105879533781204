//Define the Actions
export const GET_CLIENTNOTIFICATIONS_LOADING = 'GET_CLIENTNOTIFICATIONS_LOADING';
export const GET_CLIENTNOTIFICATIONS_FAIL = 'GET_CLIENTNOTIFICATIONS_FAIL';
export const GET_CLIENTNOTIFICATIONS_SUCCESS = 'GET_CLIENTNOTIFICATIONS_SUCCESS';



export type ClientNotificationsType =  {
    message_swt_message: string,
    message_swt_services: any,
    message_swt_date: string,
    message_swt_close: false
    message_swt_id: number,
};


export interface GetClientNotificationsLoading {
    type: typeof GET_CLIENTNOTIFICATIONS_LOADING
}

export interface GetClientNotificationsFail {
    type: typeof GET_CLIENTNOTIFICATIONS_FAIL
}

export interface GetClientNotificationsSuccess {
    type: typeof GET_CLIENTNOTIFICATIONS_SUCCESS,
    payload: ClientNotificationsType[]
}

export type GetClientNotificationsDispatchTypes = GetClientNotificationsLoading | GetClientNotificationsFail | GetClientNotificationsSuccess;
