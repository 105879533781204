import ApiRequestor from "../../api_access/ApiRequestor";
import {Dispatch} from "redux"
import {GetUserInfoDispatchTypes, GET_USER_INFO_FAIL, GET_USER_INFO_LOADING, GET_USER_INFO_SUCCESS} from './userInfoActionTypes'


export const GetUserInfo = (username:string) => async (dispatch: Dispatch<GetUserInfoDispatchTypes>) => {
    try {
        dispatch({
            type: GET_USER_INFO_LOADING
        })
        const response:any = await ApiRequestor.requestToApi(`/user/${username}`, "GET", null, "USER")
        dispatch({
            type: GET_USER_INFO_SUCCESS,
            payload: response.data
        })
     
      
    }
    catch(error){
        dispatch({
            type: GET_USER_INFO_FAIL
        })
    }
}
