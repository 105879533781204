import axios from "axios";

const concatParams = (data) => {
    let paramString = ''
    if(data && data.params && data.params.length >= 1){
        data.params.forEach(param => paramString += "/" + param)
    }

    return paramString
}

const createFormData = (data) => {
    let formData = new FormData()

    Object.keys(data.body).forEach(key => {
        formData.append(key,data.body[key])
    })
    return formData
}

const buildConfig = (data,headers) =>{

    //Add headers to returning object
    const config = {...headers}

    //Add queryStringParams to returning object
    if(data){
        if(Object.keys(data).some(e => e ==="queryStringParams")){
            config.params =  data.queryStringParams;
        }
    }
    
    return config
};

const get = async (server,path, headers, data) => {
    try {
        const res = await axios.get(`${server}${path}${concatParams(data)}`,buildConfig(data,headers));
        return res;
    } catch (error) {
        throw error;
    }
}

const post = async (server,path, headers, data, isForm) => {
    if(isForm){
        data.body = createFormData(data)
    }
    let resp
    try {
        const res = await axios.post(`${server}${path}${concatParams(data)}`, data.body, headers);
        resp = res
    } catch (error) {
        resp = error.message
    }
    return resp
}

const put = async (server,path, headers, data, isForm) => {

    if(isForm){
        data.body = createFormData(data)
    }
    try {
        const res = await axios.put(`${server}${path}${concatParams(data)}`, data.body, headers);
        return res;
    } catch (error) {
        throw error;
    }
}

const patch = async (server,path, headers, data, isForm) => {
    if(isForm){
        data.body = createFormData(data)
    }
    try {
        const res = await axios.patch(`${server}${path}${concatParams(data)}`, data.body, headers);
        return res;
    } catch (error) {
        throw error;
    }
}

const delete_request = async (server,path, headers, data) => {
    
    try {
        const res = await axios.delete(`${server}${path}${concatParams(data)}`, headers);
        return res;
    } catch (error) {
        throw error;
    }
}

const API = {
    get: get,
    post: post,
    put:put,
    patch: patch,
    delete: delete_request
}

export default API;