//DEFINE THE ACTIONS TO TRIGGER
export const GET_LAST_MESSAGES_LOADING = 'GET_LAST_MESSAGES_LOADING';
export const GET_LAST_MESSAGES_FAIL = 'GET_LAST_MESSAGES_FAIL';
export const GET_LAST_MESSAGES_SUCCESS = 'GET_LAST_MESSAGES_SUCCESS';

type MessageHistorial ={
    message_swt_id: number;
    message_swt_message: string;
    message_swt_date: Date;
    message_swt_created_at: Date;
} 

export type LastMessagesType =  {
    bank_id: string;
    messages: MessageHistorial[];
};


export interface GetLastMessagesLoading {
    type: typeof GET_LAST_MESSAGES_LOADING
}

export interface GetLastMessagesFail {
    type: typeof GET_LAST_MESSAGES_FAIL
}

export interface GetLastMessagesSuccess {
    type: typeof GET_LAST_MESSAGES_SUCCESS,
    payload:LastMessagesType[]
}

export type GetLastMessagesDispatchTypes = GetLastMessagesLoading | GetLastMessagesFail | GetLastMessagesSuccess;