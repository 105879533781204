import ApiRequestor from "../../../api_access/ApiRequestor";
import {Dispatch} from "redux"
import { GetUserSpecifyCardDispatchTypes, GET_USERSPECIFYCARD_LOADING, GET_USERSPECIFYCARD_FAIL, GET_USERSPECIFYCARD_SUCCESS } from './userSpecifyCardActionsTypes';

export const GetUserSpecifyCard = () => async (dispatch: Dispatch<GetUserSpecifyCardDispatchTypes>) => {
    try {
        dispatch({
            type: GET_USERSPECIFYCARD_LOADING
        })
        const response : any = await ApiRequestor.requestToApi("/user/specific", "GET");
        dispatch({
            type: GET_USERSPECIFYCARD_SUCCESS,
            payload: response.data
        })
           
    }
    catch(error){
        dispatch({
            type: GET_USERSPECIFYCARD_FAIL
        })
    }
}