import React, { useEffect, useRef, useState } from 'react';
import { IonToggle, IonCol, IonModal, IonRow, IonText, IonButton, IonIcon } from '@ionic/react';
import { checkmarkCircleOutline, closeCircleOutline, warningOutline } from 'ionicons/icons';

import "./ChangeState.css";
import Modal from '../Modal/Modal';


interface Props {
  serviceName: string,
  check: boolean,
  parentCallback: (check: boolean) => void,
  ModalInactive: () => void
  ModalActive: () => void
  showModalInactive: boolean
}

const ToggleChangeState: React.FC<Props> = ({ check, parentCallback, serviceName, ModalInactive, ModalActive, showModalInactive }) => {

  const [checked, setChecked] = useState<boolean>(check)
  const [showModalSuccessChange, setShowModalSuccessChange] = useState<boolean>(false);

  const [text, setText] = useState<string>('')

  const prevChecked = usePrevious(checked)

  const handleChange = () => {
    setChecked(!checked)
  }

  if (prevChecked !== undefined && prevChecked !== checked) {
    parentCallback(checked)
  }


  const setShowModalSuccess = async () => {
    window.location.reload()
  }

  const onChangeToggle = () => {
    if(check){
      ModalActive()
      setText(`¿Desea cambiar el estado a Inactivo de ${serviceName}?`)
    }else{
      ModalActive()
      setText(`Una vez activado el cliente ${serviceName}, las métricas podrían presentarse con intermitencias por las próximas 2 horas`)
    }
  }

  return (
    <>
      <IonToggle color="secondary" checked={checked} onIonChange={onChangeToggle} slot="start" mode="ios" className="toggle-size-change ion-margin-top" />

      <Modal 
        modalPrincipal={
          {
            textPrincipal: text, 
            isOpen: showModalInactive,
            onDidDismiss: ModalInactive, 
            onClickCloseModal: ModalInactive, 
            onClick: () => {
              handleChange()
              setShowModalSuccessChange(true)
            }, 
            nameIcon: "warningOutline", 
            colorIcon: "warning"
          }
        }

        modalConfirmation={
          {
            isOpenModalConfirm: showModalSuccessChange,
            textConfirmation: "Se ha cambiado el Estado del Cliente con Éxito",
            onClickConfirmModal: setShowModalSuccess
          }
        }
      />
    </>
  );
};

export default ToggleChangeState;

function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}