import { IonCol, IonImg, IonRow, IonSpinner, IonText} from "@ionic/react"
import './Loading.css'

import prismaIso from '../../assets/prismaIso.png'
import prismaIsoDark from '../../assets/prismaIsoDark.png'
import prismaLogo from '../../assets/prismaLogo.png'
import prismaLogoDark from '../../assets/prismaLogoDark.png'
import newpayIso from '../../assets/newpayIconoColor.png'
import newpayIsoDark from '../../assets/newpayIconoDark.png'
import newpayLogo from '../../assets/newpayLogoColor.png'
import newpayLogoDark from '../../assets/newpayLogoDark.png'
import paywayIso from '../../assets/paywayIconoColor.png'
import paywayIsoDark from '../../assets/paywayIconoDark.png'
import paywayLogo from '../../assets/paywayLogoColor.png'
import paywayLogoDark from '../../assets/paywayLogoDark.png'

import { useEffect, useState } from "react"


const Loading: React.FC = (): JSX.Element => {

    const [imagen, setImagen] = useState<string>('');

    const styleSelected: any = localStorage.getItem("styleMode");
    const category: any = sessionStorage.getItem("category");

    useEffect(()=>{
        if(window.screen.width < 768){
            if( category == 'BANCOS'){
                styleSelected == 'dark' ? setImagen(newpayIsoDark) : setImagen(newpayIso)
            } else if( category == 'RETAIL'){
                styleSelected == 'dark' ? setImagen(paywayIsoDark) : setImagen(paywayIso)
            } else{
            styleSelected == 'dark' ? setImagen(prismaIsoDark) : setImagen(prismaIso)
            }
        }else{
            if( category == 'BANCOS'){
                styleSelected == 'dark' ? setImagen(newpayLogoDark) : setImagen(newpayLogo)
            } else if( category == 'RETAIL'){
                styleSelected == 'dark' ? setImagen(paywayLogoDark) : setImagen(paywayLogo)
            } else{
                styleSelected == 'dark' ? setImagen(prismaLogoDark) : setImagen(prismaLogo)
            }
        }
    },[window.screen.width])


    return (
        <IonCol className="content-loading">
            <IonRow>
                <IonImg className="ion-image-loading" src={imagen} alt="loading" />
            </IonRow>
            <IonRow>
                <IonText className="ion-text-loading">Aguarde mientras cargamos la información</IonText>
            </IonRow>
            <IonRow>
                <IonSpinner  duration={1000} className="ion-spinner-loading" name="dots"></IonSpinner>
            </IonRow>
        </IonCol>
    )
}

export default Loading;