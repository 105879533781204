import { IonButton, IonCard, IonCardContent, IonCardHeader, IonText } from "@ionic/react";
import React from "react";

import './Debin.css';

interface Props {
    buttonInfo: any,
    title: string
}

const DebinButton: React.FC<Props> = (props): JSX.Element => {

    const renderButtons = () => {
        if (props.buttonInfo.length > 0) {
            return props.buttonInfo.map((info: any, index: number) => {
                let status = false
                
                if ((info.key.includes("AVG-TIME") || info.key.includes("APACHE")) && info.alert !== "0") {
                    status = true
                } else if (info.key.includes("MAU") && info.value !== 0) {
                    status = true
                }
                return (
                    <IonCard key={info.key || index} className="debin-button-card">
                        <IonCardHeader className="debin-button-title">{info.key}</IonCardHeader>
                        <IonCardContent className={status ? "debin-button-content-alert" : "debin-button-content"}>
                            {
                                status?
                                <IonText className="debin-button-text">NOT OK</IonText>:
                                <IonText className="debin-button-text"> OK</IonText>
                            }
                        </IonCardContent>
                    </IonCard>
                )
            })
        }
    }

    return (
        <IonCard>
            <IonCardHeader className="intern-header">{props.title}</IonCardHeader>
            <IonCardContent className="content-button-cards">
                {
                    renderButtons()
                }
            </IonCardContent>
        </IonCard>
    )
}
export default DebinButton; 