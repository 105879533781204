//DEFINE THE ACTIONS TO TRIGGER
export const GET_USERDELETECLIENT_LOADING = 'GET_USERDELETECLIENT_LOADING';
export const GET_USERDELETECLIENT_FAIL = 'GET_USERDELETECLIENT_FAIL';
export const GET_USERDELETECLIENT_SUCCESS = 'GET_USERDELETECLIENT_SUCCESS';


export type UserDeleteClientType =  {
    username: string;
    service_name: string;
    deleted_by: string;
    deleted_at: string;
};


export interface GetUserDeleteClientLoading {
    type: typeof GET_USERDELETECLIENT_LOADING
}

export interface GetUserDeleteClientFail {
    type: typeof GET_USERDELETECLIENT_FAIL
}

export interface GetUserDeleteClientSuccess {
    type: typeof GET_USERDELETECLIENT_SUCCESS,
    payload:UserDeleteClientType
}

export type GetUserDeleteClientDispatchTypes = GetUserDeleteClientLoading | GetUserDeleteClientFail | GetUserDeleteClientSuccess;