import {GetUserStatisticsClientDispatchTypes, GET_USERSTATISTICSCLIENT_FAIL, GET_USERSTATISTICSCLIENT_LOADING, GET_USERSTATISTICSCLIENT_SUCCESS, UserStatisticsClientType} from './userStatisticsClientActionsTypes'


export interface UserStatisticsClientDefaultStateI {
    loading:boolean,
    userStatisticsClient?: any
}

const defaultState:UserStatisticsClientDefaultStateI = {
    loading:false
};


const userStatisticsClientReducer = (state: UserStatisticsClientDefaultStateI = defaultState,action:GetUserStatisticsClientDispatchTypes):UserStatisticsClientDefaultStateI =>{
    switch (action.type) {
        case GET_USERSTATISTICSCLIENT_FAIL:
            return {
                loading:false
            }
            break;
        case GET_USERSTATISTICSCLIENT_LOADING:
            return {
                loading:true
            }
            break;
        case GET_USERSTATISTICSCLIENT_SUCCESS:
            return {
                loading:false,
                userStatisticsClient:action.payload
            }
            break;
        default:
            return state
            break;
    }
    return state;
}


export default userStatisticsClientReducer;