import {GetMainInfoDispatchTypes, GET_CLIENT_MAINTENANCE_FAIL, GET_CLIENT_MAINTENANCE_LOADING, GET_CLIENT_MAINTENANCE_SUCCESS, ClientMaintenanceType} from './mainInfoActionsTypes'

export interface ClientMaintenanceDefaultStateI {
    loading:boolean,
    clientMaintenance?: ClientMaintenanceType[]
}

const defaultState:ClientMaintenanceDefaultStateI = {
    loading:false
};


const clientMaintenanceReducer = (state: ClientMaintenanceDefaultStateI = defaultState,action:GetMainInfoDispatchTypes):ClientMaintenanceDefaultStateI =>{
    switch (action.type) {
        case GET_CLIENT_MAINTENANCE_FAIL:
            return {
                loading:false
            }
            break;
        case GET_CLIENT_MAINTENANCE_LOADING:
            return {
                loading:true
            }
            break;
        case GET_CLIENT_MAINTENANCE_SUCCESS:
            return {
                loading:false,
                clientMaintenance:action.payload
            }
            break;
        default:
            return state
            break;
    }
    return state;
}


export default clientMaintenanceReducer;