//Define the actions to the trigger
export const GET_SHOWUMBRALS_LOADING = "GET_SHOWUMBRALS_LOADING";
export const GET_SHOWUMBRALS_FAIL = "GET_SHOWUMBRALS_FAIL";
export const GET_SHOWUMBRALS_SUCCESS = "GET_SHOWUMBRALS_SUCCESS";


export type ShowUmbralsTypeRow = {
    metric_id: number,
    metric_name: string,
    metric_threshold: true,
    metric_title: string,
    service_name: string,
    service_display_name: string
}


export type ShowUmbralsType =  {
    metricKpi: ShowUmbralsTypeRow[];
    metricIntern: ShowUmbralsTypeRow[];
};

export interface GetShowUmbralsLoading {
    type: typeof GET_SHOWUMBRALS_LOADING
}

export interface GetShowUmbralsFail {
    type: typeof GET_SHOWUMBRALS_FAIL
}

export interface GetShowUmbralsSuccess {
    type: typeof GET_SHOWUMBRALS_SUCCESS,
    payload:ShowUmbralsType
}

export type GetShowUmbralsDispatchTypes = GetShowUmbralsLoading | GetShowUmbralsFail | GetShowUmbralsSuccess;