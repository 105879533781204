//Define the Actions
export const GET_CHANGESTATE_LOADING = 'GET_CHANGESTATE_LOADING';
export const GET_CHANGESTATE_FAIL = 'GET_CHANGESTATE_FAIL';
export const GET_CHANGESTATE_SUCCESS = 'GET_CHANGESTATE_SUCCESS';


export type ChangeStateType =  {
    name: string,
    state: string,
    id:number,
};


export interface GetChangeStateLoading {
    type: typeof GET_CHANGESTATE_LOADING
}

export interface GetChangeStateFail {
    type: typeof GET_CHANGESTATE_FAIL
}

export interface GetChangeStateSuccess {
    type: typeof GET_CHANGESTATE_SUCCESS,
    payload:ChangeStateType[]
}

export type GetChangeStateDispatchTypes = GetChangeStateLoading | GetChangeStateFail | GetChangeStateSuccess;