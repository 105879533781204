import React, { useEffect, useState } from "react";
import { IonCard, IonCardHeader, IonCol, IonIcon, IonRow, IonText } from "@ionic/react";
import { ellipse } from "ionicons/icons";

import { CountdownCircleTimer } from "react-countdown-circle-timer";

import dayjs from "dayjs";

import '../Internos/Intern.css';
import './Decidir.css';

interface Props {
    cardData: any;
    graphicsData: any;
}

const DecidirCards: React.FC<Props> = (props): JSX.Element => {

    //const sizeTimer = window.screen.width < 992 ? 100 : window.screen.width >= 1920 ? window.screen.width >= 3840 ? 200 : 100 : 100;
    const [graphicsInfo, setGraphicsInfo] = useState<any>([])
    const [varUmbral, setVarUmbral] = useState(0)
    const [varUmbralTRX, setVarUmbralTRX] = useState(0)
    const [refreshDate, setRefreshDate] = useState<any>()
    const [sizePercentage, setSizePercentage] = useState<number>(50)
    const [cantAlert, setCantAlert] = useState<boolean>(false);
    const [appAlert, setAppAlert] = useState<boolean>(false);



    const sizes: { [key: number]: number } = {
        300: 90,
        720: 110,
        2560: 120,
        3840: 200,
        4096: 300,
    }

    useEffect(() => {
        for (let key of Object.keys(sizes)) {
            if (parseInt(key) <= window.screen.width) { //para resoluciones MAYORES
                setSizePercentage(sizes[parseInt(key)])
            }
        }

    }, [window.screen.width])

    useEffect(() => {
        if (props.graphicsData.length > 0) {
            let graphicsFilter: any = props.graphicsData.filter((graphic: any) => graphic.intern_title_key == props.cardData.titleKey)
            setGraphicsInfo(graphicsFilter)
        }
    }, [props.graphicsData, props.cardData])

    useEffect(() => {
        if (graphicsInfo.length > 0) {
            graphicsInfo.map((graphic: any) => {
                if (graphic.intern_title.includes("APROBADAS") || graphic.intern_title.includes("Aprobadas")) {
                    const umbral = graphic.limit_values[graphic.limit_values.length - 1]
                    setVarUmbral(umbral)
                    const date = graphic.keys.at(-1)
                    const final_date = dayjs(date).format("HH:mm:ss")
                    setRefreshDate(final_date)
                } else {
                    const umbralTRX = graphic.limit_values[graphic.limit_values.length - 1]
                    setVarUmbralTRX(umbralTRX)
                }
            })
        }
    }, [props.graphicsData, props.cardData, graphicsInfo])

    useEffect(() => {
        if (props.cardData.titleKey) {
            props.cardData.appAlert !== "0" ? setAppAlert(true) : setAppAlert(false)
            props.cardData.cantAlert !== "0" ? setCantAlert(true) : setCantAlert(false)
        }
    }, [props.cardData])

    return (
        <IonRow>
            {props.graphicsData.length > 0 &&
                <IonCol>
                    <IonCard className="decidir-styles-card">
                        <IonCardHeader class="decidir-styles-header">
                            <IonRow>
                                <IonIcon className={cantAlert ? "decidir-icon-alerted" : "decidir-icon"} icon={ellipse} />
                                <IonText className="decidir-header-title">{props.cardData.titleKey}</IonText>
                            </IonRow>
                        </IonCardHeader>
                        <IonRow className='ion-align-items-center'>
                            <IonCol className="decidir-group" sizeXl="6" sizeLg="6" sizeMd="7" sizeSm="6" sizeXs="7">
                                <IonRow className="ion-align-items-center decidir-row-card-subtitles intern-row-card">
                                    <IonCol className="intern-subtitulos" size="8">Total TRX</IonCol>
                                    <IonCol size="4" >{props.cardData.cantVal}</IonCol>
                                </IonRow>
                                <IonRow className="ion-align-items-center decidir-row-card-subtitles intern-row-card">
                                    <IonCol className="intern-subtitulos" size="8">Total TRX Aprobadas</IonCol>
                                    <IonCol size="4">{Math.round((props.cardData.cantVal * props.cardData.appVal) / 100)}</IonCol>
                                </IonRow>
                                <IonRow className="ion-align-items-center decidir-row-card-subtitles intern-row-card">
                                    <IonCol className="intern-subtitulos" size="8">Umbral TRX</IonCol>
                                    <IonCol size="4">{varUmbralTRX}</IonCol>
                                </IonRow>
                                <IonRow className="ion-align-items-center decidir-row-card-subtitles intern-row-card">
                                    <IonCol className="intern-subtitulos" size="8">Umbral %</IonCol>
                                    <IonCol size="4">{varUmbral}%</IonCol>
                                </IonRow>
                            </IonCol>
                            <IonCol sizeXl="6" sizeLg="6" sizeMd="5" sizeSm="6" sizeXs="5">
                                <div className="decidir-timer-center">
                                    <div className="intern-percentage" key={props.cardData.appVal}>
                                        <CountdownCircleTimer
                                            isPlaying={false}
                                            duration={100}
                                            colors={appAlert ? "#e73557" : "#29b2b7"}
                                            initialRemainingTime={props.cardData.appVal}
                                            rotation={"counterclockwise"}
                                            strokeWidth={8}
                                            size={sizePercentage}>
                                            {({ remainingTime }) => {
                                                const time = remainingTime
                                                return (
                                                    <div>
                                                        <IonText className="decidir-text-percentage-cards"> {time}%</IonText>
                                                    </div>
                                                )
                                            }}
                                        </CountdownCircleTimer>
                                    </div>
                                    <div className='intern-refresh'>
                                        <IonText >Ult. Refresh: {refreshDate} Hs</IonText>
                                    </div>
                                </div>
                            </IonCol>
                        </IonRow>
                    </IonCard>
                </IonCol>}
        </IonRow>
    );
}

export default DecidirCards;