import ApiRequestor from "../../api_access/ApiRequestor";
import {Dispatch} from "redux"
import { GetShowUmbralsDispatchTypes, GET_SHOWUMBRALS_LOADING, GET_SHOWUMBRALS_FAIL, GET_SHOWUMBRALS_SUCCESS } from './showUmbralsActionsTypes';

export const GetShowUmbrals = () => async (dispatch: Dispatch<GetShowUmbralsDispatchTypes>) => {
    try {
        dispatch({
            type: GET_SHOWUMBRALS_LOADING
        })
        const response : any = await ApiRequestor.requestToApi("/swt-admin/clients-threshold", "GET");
        dispatch({
            type: GET_SHOWUMBRALS_SUCCESS,
            payload: response.data
        })
           
    }
    catch(error){
        dispatch({
            type: GET_SHOWUMBRALS_FAIL
        })
    }
}