import React, { useState } from "react";
import { IonCard, IonCardContent, IonCardHeader, IonCol, IonIcon, IonRow } from "@ionic/react";
import { chevronDownOutline, chevronUpOutline } from "ionicons/icons";

import "./userStatistics.css";

interface Data {
    bank_id: string;
    total: number;
    unverified: number;
    inactive: number;
    activeToday: number;
    activeYesterday: number;
    activeLastDaysWeb: number;
    activeLastDaysMobile: number;
}

interface Props {
    userData: Data[];
}

const UserSpecifyCard: React.FC<Props> = (props): JSX.Element => {

    return (
        <IonCard className="mobile-hidden">
            <IonCardHeader className='user-card-header'>Cantidad de Usuarios por Cliente
            </IonCardHeader>
            <IonCardContent className='user-table-tables user-table-card user-table-margin-card'>
                <IonRow className='user-table-rows'>
                    <IonCol className='user-table-title-tables'>Cliente</IonCol>
                    <IonCol className='user-table-title-tables'>Total</IonCol>
                    <IonCol className='user-table-title-tables'>Activos Últ. Mes Web</IonCol>
                    <IonCol className='user-table-title-tables'>Activos Últ. Mes Mobile</IonCol>
                    <IonCol className='user-table-title-tables'>Activos Ayer</IonCol>
                    <IonCol className='user-table-title-tables'>Activos Hoy</IonCol>
                    <IonCol className='user-table-title-tables'>Inactivos</IonCol>
                    <IonCol className='user-table-title-tables'>No Verificados</IonCol>
                </IonRow>
                {
                    props.userData.map((user: any) => {
                        return (
                            <IonRow className='user-table-rows' key={user.bank_id}>
                                <IonCol>{user.bank_id}</IonCol>
                                <IonCol>{user.total}</IonCol>
                                <IonCol>{user.activeLastDaysWeb}</IonCol>
                                <IonCol>{user.activeLastDaysMobile}</IonCol>
                                <IonCol>{user.activeYesterday}</IonCol>
                                <IonCol>{user.activeToday}</IonCol>
                                <IonCol>{user.inactive}</IonCol>
                                <IonCol>{user.unverified}</IonCol>
                            </IonRow>
                        )
                    })
                }
            </IonCardContent>
        </IonCard >
    )
}

export default UserSpecifyCard;