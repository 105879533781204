import {Dispatch} from "redux"
import { SessionDispatchTypes, SESSION_FAIL,SESSION_SUCCESS, SESSION_LOADING } from "./SessionActionTypes"
import ApiRequestor from '../../api_access/ApiRequestor'
import {Auth} from "@aws-amplify/auth";


export const DoLogin  = (username:string,password:string) => async (dispatch: Dispatch<SessionDispatchTypes>) => {
    try {
        dispatch({
            type:SESSION_LOADING
        })

        let loginRes = await ApiRequestor.login(username.trim(),password)
        let res = await Auth.currentSession()
        
        dispatch({
            type:SESSION_SUCCESS,
            payload:res,
            loggedIn: true
        })
        return res

    } catch (error) {
        
        dispatch({
            type:SESSION_FAIL
        })
        return error
    }
}

export const GetLoggedIn  = () => async (dispatch: Dispatch<SessionDispatchTypes>) => {
    try {
        dispatch({
            type:SESSION_LOADING
        })

        let res
        try{
            res = await Auth.currentSession()
        }catch(err){
            throw err
        }

        dispatch({
            type:SESSION_SUCCESS,
            payload:res,
            loggedIn: true
        })

    } catch (error) {
        dispatch({
            type:SESSION_FAIL
        })
    }
}

export const DoLogout  = () => async (dispatch: Dispatch<SessionDispatchTypes>) => {
    try {
        dispatch({
            type:SESSION_LOADING
        })

        await Auth.signOut()
    
        dispatch({
            type:SESSION_SUCCESS,
            payload: [],
            loggedIn: false
        })

    } catch (error) {
        dispatch({
            type:SESSION_FAIL
        })
    }
}
