import ApiRequestor from "../../api_access/ApiRequestor";
import { Dispatch } from "redux"
import { GetAlertsDispatchTypes, GET_ALERTS_LAST_MINUTE_FAIL, GET_ALERTS_LAST_MINUTE_LOADING, 
    GET_ALERTS_LAST_MINUTE_SUCCESS, GET_ALERTS_TWO_HOURS_FAIL, GET_ALERTS_TWO_HOURS_LOADING, 
    GET_ALERTS_TWO_HOURS_SUCCESS } from './alertsActionsTypes'



export const GetTwoHoursAlerts = () => async (dispatch: Dispatch<GetAlertsDispatchTypes>) => {
    try {
        dispatch({
            type: GET_ALERTS_TWO_HOURS_LOADING
        })
        const response:any = await ApiRequestor.requestToApi("/main/alerts/120", "GET")

        dispatch({
            type: GET_ALERTS_TWO_HOURS_SUCCESS,
            payload: response.data
        })
    }
    catch(error){
        dispatch({
            type: GET_ALERTS_TWO_HOURS_FAIL
        })
    }
}

export const GetLastMinuteAlerts = () => async (dispatch: Dispatch<GetAlertsDispatchTypes>) => {
    try {
        dispatch({
            type: GET_ALERTS_LAST_MINUTE_LOADING
        })
        const response:any = await ApiRequestor.requestToApi("/main/alerts/1", "GET")
        dispatch({
            type: GET_ALERTS_LAST_MINUTE_SUCCESS,
            payload: response.data
        })
    }
    catch(error){
        dispatch({
            type: GET_ALERTS_LAST_MINUTE_FAIL
        })
    }
}
