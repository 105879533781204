import {GetUserDeleteClientDispatchTypes, GET_USERDELETECLIENT_FAIL, GET_USERDELETECLIENT_LOADING, GET_USERDELETECLIENT_SUCCESS, UserDeleteClientType} from './userDeleteClientActionsTypes'


export interface UserDeleteClientDefaultStateI {
    loading:boolean,
    userDeleteClient?: any
}

const defaultState:UserDeleteClientDefaultStateI = {
    loading:false
};


const userDeleteClientReducer = (state: UserDeleteClientDefaultStateI = defaultState,action:GetUserDeleteClientDispatchTypes):UserDeleteClientDefaultStateI =>{
    switch (action.type) {
        case GET_USERDELETECLIENT_FAIL:
            return {
                loading:false
            }
            break;
        case GET_USERDELETECLIENT_LOADING:
            return {
                loading:true
            }
            break;
        case GET_USERDELETECLIENT_SUCCESS:
            return {
                loading:false,
                userDeleteClient:action.payload
            }
            break;
        default:
            return state
            break;
    }
    return state;
}


export default userDeleteClientReducer;