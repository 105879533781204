import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { IonCard, IonRow, IonCol, IonIcon, IonLabel } from '@ionic/react';
import { chevronDownOutline, chevronUpOutline, closeOutline } from 'ionicons/icons';

import { SessionDefaultStateI } from '../../store/session/SessionReducer';
import { MessagesPrismaDefaultStateI } from '../../store/mainInfo/messagesPrismaReducer';
import { GetMessagesPrisma } from '../../store/mainInfo/mainInfoActions';

import './MessagesPrisma.css';

interface Props extends StateTypes, dispatchTypes {
  message: any,
  scrolling?: boolean,
  closeMessage?: any,
  handleOpenMsg?: any,
  notifColor?: any
}

const Message: React.FC<Props> = (props): JSX.Element => {
  useEffect(() => {
    props.notifColor(props.message.message_swt_close)
  }, [props.message.message_swt_id])

  const closeMessage = (messageId: number) => {
    props.closeMessage(messageId)
    const cardElement: any = document.getElementById(`msgCard${messageId}`)
    cardElement.style.display = "none"
  }

  const openMsg = (messageId: number) => {
    const cardElement: any = document.getElementById(`msgCard${messageId}`)
    if (cardElement.classList.contains("card-scroll")) {
      cardElement.classList.replace("card-scroll", "card-no-scroll")
    } else {
      cardElement.classList.replace("card-no-scroll", "card-scroll")
    }
    props.handleOpenMsg()
  }

  return (
    <IonCard  id={`msgCard${props.message.message_swt_id}`} className={props.scrolling ? (props.message.message_swt_close ? "card-true card-scroll margin-card" : "card-false card-scroll margin-card") : (props.message.message_swt_close ? "card-true card-no-scroll margin-card" : "card-false card-no-scroll margin-card")}>
      {props.message.message_swt_close ?
        <IonRow onClick={() => {openMsg(props.message.message_swt_id);}} className={window.screen.width >= 992 ? "ion-align-items-center" : ""}>
          <IonCol size="11" className="ion-text-center message-prisma-col">
            <IonLabel className="ion-text-wrap messange">{props.message.message_swt_message}</IonLabel>
          </IonCol>
          <IonCol size="1" className="ion-text-end">
            <IonIcon icon={closeOutline} onClick={() => closeMessage(props.message.message_swt_id)} />
          </IonCol>
        </IonRow>
        :
        <IonRow onClick={() => {openMsg(props.message.message_swt_id);}}>
          <IonCol size="12" className="ion-text-center message-prisma-col">
            <IonLabel className="messange">{props.message.message_swt_message}</IonLabel>
          </IonCol>
        </IonRow>
      }
    </IonCard>
  )
}

//Redux state and actions dispatchers

interface StateTypes {
  session: SessionDefaultStateI,
  messagesPrisma: MessagesPrismaDefaultStateI,
}

const mapStateToProps = (state: StateTypes) => {
  return {
    session: state.session,
    messagesPrisma: state.messagesPrisma
  };
};

interface dispatchTypes {
  getMessagesPrisma: () => void
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getMessagesPrisma: () => dispatch(GetMessagesPrisma()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Message);