import {GetMainInfoDispatchTypes,GET_KPI_GROUPS_MENU_FAIL, GET_KPI_GROUPS_MENU_LOADING, GET_KPI_GROUPS_MENU_SUCCESS, KpiGroupsMenuType} from './mainInfoActionsTypes'

export interface KpiGroupsMenuDefaultStateI {
    loading:boolean,
    kpiGroupsMenu?: KpiGroupsMenuType[]
}

const defaultState:KpiGroupsMenuDefaultStateI = {
    loading:false
};


const kpiGroupsMenuReducer = (state: KpiGroupsMenuDefaultStateI = defaultState,action:GetMainInfoDispatchTypes):KpiGroupsMenuDefaultStateI =>{
    switch (action.type) {
        case GET_KPI_GROUPS_MENU_FAIL:
            return {
                loading:false
            }
            break;
        case GET_KPI_GROUPS_MENU_LOADING:
            return {
                loading:true
            }
            break;
        case GET_KPI_GROUPS_MENU_SUCCESS:
            return {
                loading:false,
                kpiGroupsMenu:action.payload
            }
            break;
        default:
            return state
            break;
    }
    return state;
}


export default kpiGroupsMenuReducer;