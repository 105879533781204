import React from 'react';
import { IonCard, IonCardContent, IonCardHeader, IonCol, IonIcon, IonRow, IonText } from '@ionic/react';
import { ellipse } from 'ionicons/icons';

import './Resolutores.css';

interface Props {
    cardData: any,
}

const ResolutoresMinCard: React.FC<Props> = (props): JSX.Element => {

    const returnTitle = () => {
        if (props.cardData.titleMini.includes("DEBITOS TC")) return "Débitos TC"
        else if (props.cardData.titleMini.includes(("PURA"))) return "Naranja Pura"
    }

    const returnMinCardStandin = () => {
        return (
            <IonCard className='resolutoresMini-card'>
                <IonCardHeader className='resolutoresCardMini-header'>
                    <IonRow>
                        <IonIcon className={props.cardData.cantAlertStandin !== "0" ? "resolutores-icon-mini-alerted" : "resolutores-icon-mini"} icon={ellipse} />
                        <IonText>Stand In - Pura</IonText>
                    </IonRow>
                </IonCardHeader>
                <IonCardContent>
                    <IonRow className='resolutoresMinCard-position-row'>
                        <IonCol>
                            <IonText className='resolutores-styles-title-tables'>% Aprobadas </IonText>
                            <IonText>{props.cardData.cantValStandin}%</IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow className='resolutoresMinCard-position-row-center'>
                        <IonText className='resolutores-styles-title-tables'>Ult. Refresh: {props.cardData.ultRefreshMiniStandin.slice(11,)} Hs</IonText>
                    </IonRow>
                </IonCardContent>
            </IonCard>
        )
    }

    const returnMinCard = () => {
        return (
            <IonCard className='resolutoresMini-card'>
                <IonCardHeader className='resolutoresCardMini-header'>
                    <IonRow>
                        <IonIcon className={props.cardData.cantAlertMini !== "0" ? "resolutores-icon-mini-alerted" : "resolutores-icon-mini"} icon={ellipse} />
                        <IonText>{returnTitle()}</IonText>
                    </IonRow>
                </IonCardHeader>
                <IonCardContent>
                    <IonRow className='resolutoresMinCard-position-row'>
                        <IonCol sizeXl="4.75" sizeLg="7" sizeMd="6" sizeSm="12" sizeXs="6">
                            <IonText className='resolutores-styles-title-tables'>% Aprobadas </IonText>
                            <IonText>{props.cardData.appValMini}%</IonText>
                        </IonCol>
                        <IonCol sizeXl="3.25" sizeLg="7" sizeMd="6" sizeSm="12" sizeXs="6">
                            <IonText className='resolutores-styles-title-tables'>TRX TOT </IonText>
                            <IonText>{props.cardData.cantValMini}</IonText>
                        </IonCol>
                        <IonCol sizeXl="3" sizeLg="7" sizeMd="12" sizeSm="12" sizeXs="12">
                            <IonText className='resolutores-styles-title-tables'>TRX OK </IonText>
                            <IonText>{Math.round((props.cardData.cantValMini * props.cardData.appValMini) / 100)}</IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow className='resolutoresMinCard-position-row-center'>
                        <IonText className='resolutores-styles-title-tables'>Ult. Refresh: {props.cardData.ultRefreshMini.slice(11,)} Hs</IonText>
                    </IonRow>
                </IonCardContent>
            </IonCard>
        )
    }

    return (
        <>
            {
                returnMinCard()
            }
            {
                props.cardData.hasOwnProperty("cantValStandin") &&
                returnMinCardStandin()
            }
        </>
    )
}

export default ResolutoresMinCard;