import React, { useEffect } from "react";
import { IonCard, IonCardHeader, IonCardContent, IonIcon } from '@ionic/react';
import { construct } from "ionicons/icons";

import { setStyleMode } from "../../utils/StyleUtils";

import "./MaintenancePage.css";

const MaintenanceComponent: React.FC<any> = (): JSX.Element => {

    useEffect(() => {
        setStyleMode()
    }, [])

    return (
        <IonCard className="">
            <IonCardHeader className="maintenance-card-header">
                <IonIcon className="maintenance-card-icon" icon={construct} />
            </IonCardHeader>
            <IonCardContent className="maintenance-card-text">
                Disculpe las molestias, el Sistema se encuentra en mantenimiento.
            </IonCardContent>
            <IonCardContent className="maintenance-card-text">
                Por favor intente más tarde
            </IonCardContent>
        </IonCard>
    );
}

export default MaintenanceComponent;