import ApiRequestor from '../../api_access/ApiRequestor';
import {Dispatch} from 'redux';

import {
    GetABMUSERSDispatchTypes, 
    GET_ABMUSERS_FAIL, 
    GET_ABMUSERS_LOADING, 
    GET_ABMUSERS_SUCCESS} 
from './ABMUsersActionTypes';

export const GetABMUsers = () => async (dispatch: Dispatch<GetABMUSERSDispatchTypes>) => {
    try {
        dispatch({
            type: GET_ABMUSERS_LOADING
        })
        
        const response : any = await ApiRequestor.requestToApi("/user", "GET", null, "USER", false);
        dispatch({
            type: GET_ABMUSERS_SUCCESS,
            payload: response.data
        })

    } catch (error) {
        console.log(error);
        dispatch({
            type: GET_ABMUSERS_FAIL
        })
    }
}