import { albumsOutline, albumsSharp, analyticsOutline, analyticsSharp, appsOutline, appsSharp, arrowBackOutline, arrowBackSharp, barChartOutline, barChartSharp, buildOutline, buildSharp, cardOutline, cardSharp, chatboxEllipsesOutline, chatboxEllipsesSharp, documentTextOutline, documentTextSharp, idCardOutline, idCardSharp, keyOutline, keypadOutline, keypadSharp, keySharp, lockClosedOutline, lockClosedSharp, logOutOutline, logOutSharp, notificationsOutline, notificationsSharp, peopleCircleOutline, peopleCircleSharp, peopleOutline, peopleSharp, personAddOutline, personAddSharp, personCircleOutline, personCircleSharp, settingsOutline, settingsSharp, swapHorizontalOutline, swapHorizontalSharp, walletOutline, walletSharp } from "ionicons/icons";

export interface IconsMenuIn{
    title: string;
    iosIcon: string;
    mdIcon: string;
}

export interface IconsMenu{
    icons: IconsMenuIn[];
}

export const iconsMenu: IconsMenu = {
    icons: [
        {
            title:"APPS",
            iosIcon:appsOutline,
            mdIcon:appsSharp,
        },
        {
            title:"DOCUMENTTEXT",
            iosIcon:documentTextOutline,
            mdIcon:documentTextSharp,
        },
        {
            title:"CHATBOXELLIPSES",
            iosIcon:chatboxEllipsesOutline,
            mdIcon:chatboxEllipsesSharp,
        },
        {
            title:"PERSONCIRCLE",
            iosIcon:personCircleOutline,
            mdIcon:personCircleSharp,
        },
        {
            title:"LOGOUT",
            iosIcon:logOutOutline,
            mdIcon:logOutSharp,
        },
        {
            title:"IDCARD",
            iosIcon:idCardOutline,
            mdIcon:idCardSharp,
        },
        {
            title:"ARROWBACK",
            iosIcon:arrowBackOutline,
            mdIcon:arrowBackSharp,
        },
        {
            title:"KEY",
            iosIcon:keyOutline,
            mdIcon:keySharp,
        },
        {
            title:"KEYPAD",
            iosIcon:keypadOutline,
            mdIcon:keypadSharp,
        },
        {
            title:"CARD",
            iosIcon:cardOutline,
            mdIcon:cardSharp,
        },
        {
            title:"PEOPLE",
            iosIcon:peopleOutline,
            mdIcon:peopleSharp,
        },
        {
            title:"SWAPHORIZONTAL",
            iosIcon:swapHorizontalOutline,
            mdIcon:swapHorizontalSharp,
        },
        {
            title:"ALBUMS",
            iosIcon:albumsOutline,
            mdIcon:albumsSharp,
        },
        {
            title:"LOCKCLOSED",
            iosIcon:lockClosedOutline,
            mdIcon:lockClosedSharp,
        },
        {
            title:"WALLET",
            iosIcon:walletOutline,
            mdIcon:walletSharp,
        },
        {
            title:"PERSONADD",
            iosIcon:personAddOutline,
            mdIcon:personAddSharp,
        },
        {
            title:"PEOPLECIRCLE",
            iosIcon:peopleCircleOutline,
            mdIcon:peopleCircleSharp,
        },
        {
            title:"ANALYTICS",
            iosIcon:analyticsOutline,
            mdIcon:analyticsSharp,
        },
        {
            title:"SETTINGS",
            iosIcon:settingsOutline,
            mdIcon:settingsSharp,
        },
        {
            title:"NOTIFICATIONS",
            iosIcon:notificationsOutline,
            mdIcon:notificationsSharp,
        },
        {
            title:"BARCHART",
            iosIcon:barChartOutline,
            mdIcon:barChartSharp,
        },
        {
            title:"CHATBOXELLIPSES",
            iosIcon:chatboxEllipsesOutline,
            mdIcon:chatboxEllipsesSharp,
        },
        {
            title:"BUILD",
            iosIcon:buildOutline,
            mdIcon:buildSharp,
        }
    ]
}