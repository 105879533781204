//Define the actions to the trigger
export const GET_USERSPECIFYCARD_LOADING = "GET_USERSPECIFYCARD_LOADING";
export const GET_USERSPECIFYCARD_FAIL = "GET_USERSPECIFYCARD_FAIL";
export const GET_USERSPECIFYCARD_SUCCESS = "GET_USERSPECIFYCARD_SUCCESS";

export type UserSpecifyCardType = {
    bank_id: string;
    category: string;
    total:number;
    unverified:number;
    inactive:number;
    activeToday:number;
    activeYesterday:number;
    activeLastDaysWeb:number;
    activeLastDaysMobile:number;
}

export interface GetUserSpecifyCardLoading {
    type: typeof GET_USERSPECIFYCARD_LOADING
}

export interface GetUserSpecifyCardFail {
    type: typeof GET_USERSPECIFYCARD_FAIL
}

export interface GetUserSpecifyCardSuccess {
    type: typeof GET_USERSPECIFYCARD_SUCCESS,
    payload:UserSpecifyCardType[]
}

export type GetUserSpecifyCardDispatchTypes = GetUserSpecifyCardLoading | GetUserSpecifyCardFail | GetUserSpecifyCardSuccess;