import React from 'react';
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol } from '@ionic/react';
import { connect } from 'react-redux';

import { GetShowUmbrals } from '../../store/showUmbrals/showUmbralsActions';

import ApiRequestor from '../../api_access/ApiRequestor';
import ToggleUmbrals from './ToggleUmbrals';

import './Umbrals.css';

interface Props extends StateTypes, dispatchTypes{
    metric_id: number,
    metric_name: string,
    metric_title: string,
    metric_threshold: boolean,
    service_name: string,
    service_display_name: string,
    service_category: string | null
}

const Umbrals: React.FC<Props> = (props) => {

    const checkToggle = async (check: boolean) => {
        let data: any = {
            body: {
                metric_id: props.metric_id,
                metric_threshold: check,
                service_category: props.service_category
            }
        }
        ApiRequestor.requestToApi('/swt-admin/clients-threshold', "PATCH", data);
        props.getShowUmbrals();
    }

    return (
        <IonCol>
            <IonCard className="umbrals-card">
                <IonCardHeader className="umbrals-header">
                    <IonCardTitle className="umbrals-title"> 
                        {props.metric_title}
                    </IonCardTitle>
                </IonCardHeader>
                <IonCardContent className="umbrals-content-alignment" color="secondary">
                    <ToggleUmbrals
                        text='Mostrar Umbral'
                        check={props.metric_threshold}
                        parentCallback={checkToggle} 
                    />
                </IonCardContent>
            </IonCard>
        </IonCol>
    );
}

//Redux state and actions dispatchers
interface StateTypes {}

const mapStateToProps = (state: StateTypes) => {
    return {};
}

interface dispatchTypes {
    getShowUmbrals: () => void;
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getShowUmbrals: () => dispatch(GetShowUmbrals()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Umbrals);