import { ClientsInMaintenanceType, GetClientsInMaintenanceDispatchTypes, GET_CLIENTSINMAINTENANCE_FAIL, GET_CLIENTSINMAINTENANCE_LOADING, GET_CLIENTSINMAINTENANCE_SUCCESS } from './clientInMaintenanceActionsTypes';


export interface ClientsInMaintenanceDefaultStateI{
    loading: boolean,
    clientsInMaintenance?: ClientsInMaintenanceType[] | any
}

const defaultState: ClientsInMaintenanceDefaultStateI = {
    loading:false
}

const clientsInMaintenanceReducer = (state: ClientsInMaintenanceDefaultStateI = defaultState,action: GetClientsInMaintenanceDispatchTypes ): ClientsInMaintenanceDefaultStateI =>{
    switch (action.type) {
        case GET_CLIENTSINMAINTENANCE_FAIL:
            return {
                loading:false
            }
            break;
        case GET_CLIENTSINMAINTENANCE_LOADING:
            return {
                loading:true
            }
            break;
        case GET_CLIENTSINMAINTENANCE_SUCCESS:
            return {
                loading:false,
                clientsInMaintenance:action.payload
            }
            break;
        default:
            return state
            break;
    }
    return state;
}


export default clientsInMaintenanceReducer;