//DEFINE THE ACTIONS TO TRIGGER
    //TYPES FOR GRAPHICS INFO
export const GET_GRAPHICS_LOADING = 'GET_GRAPHICS_LOADING';
export const GET_GRAPHICS_FAIL = 'GET_GRAPHICS_FAIL';
export const GET_GRAPHICS_SUCCESS = 'GET_GRAPHICS_SUCCESS';

    //TYPES FOR CARDS INFO
export const GET_CARDS_LOADING = 'GET_CARDS_LOADING';
export const GET_CARDS_FAIL = 'GET_CARDS_FAIL';
export const GET_CARDS_SUCCESS = 'GET_CARDS_SUCCESS';

    //TYPES FOR MESSAGES PRISMA INFO
export const GET_MESSAGES_PRISMA_LOADING = 'GET_MESSAGES_PRISMA_LOADING';
export const GET_MESSAGES_PRISMA_FAIL = 'GET_MESSAGES_PRISMA_FAIL';
export const GET_MESSAGES_PRISMA_SUCCESS = 'GET_MESSAGES_PRISMA_SUCCESS';

    //TYPES FOR KPI GROUPS FOR MENU INFO
export const GET_KPI_GROUPS_MENU_LOADING = 'GET_KPI_GROUPS_MENU_LOADING';
export const GET_KPI_GROUPS_MENU_FAIL = 'GET_KPI_GROUPS_MENU_FAIL';
export const GET_KPI_GROUPS_MENU_SUCCESS = 'GET_KPI_GROUPS_MENU_SUCCESS';

    //TYPES FOR CLIENT IMAGE INFO
    export const GET_CLIENT_IMAGE_LOADING = 'GET_CLIENT_IMAGE_LOADING';
    export const GET_CLIENT_IMAGE_FAIL = 'GET_CLIENT_IMAGE_FAIL';
    export const GET_CLIENT_IMAGE_SUCCESS = 'GET_CLIENT_IMAGE_SUCCESS';

    //TYPES FOR CLIENT MAINTENANCE INFO
    export const GET_CLIENT_MAINTENANCE_LOADING = 'GET_CLIENT_MAINTENANCE_LOADING';
    export const GET_CLIENT_MAINTENANCE_FAIL = 'GET_CLIENT_MAINTENANCE_FAIL';
    export const GET_CLIENT_MAINTENANCE_SUCCESS = 'GET_CLIENT_MAINTENANCE_SUCCESS';

//GRAPHICS INFO TYPES
export type GraphicsType =  {
    kpi_id: number;
    kpi_name: string;
    kpi_host: string;
    kpi_field: string;
    kpi_type: string;
    kpi_transform: boolean;
    kpi_endpoint_id: string;
    kpi_group_id: number;
    kpi_title: string;
    kpi_title_key: string;
    kpi_time_gap: number;
    kpi_linechart_label: string;
    kpi_threshold: boolean;
    kpi_group_name: string;
    service_name: string;
    keys?: Date[]|any;
    values?: number[]|any;
    limit_values?: number[]|any;
    error: boolean;
};

export interface GetGraphicsLoading {
    type: typeof GET_GRAPHICS_LOADING
}

export interface GetGraphicsFail {
    type: typeof GET_GRAPHICS_FAIL
}

export interface GetGraphicsSuccess {
    type: typeof GET_GRAPHICS_SUCCESS,
    payload:GraphicsType[]
}

//CARDS INFO TYPES
type Row = {
    key: string;
    titleKey: string;
    alert: string;
    value: number;
    timeGap: number;
}

export type CardsType =  {
    bank_id: string;
    cardsInfo: Row[];
};

export interface GetCardsLoading {
    type: typeof GET_CARDS_LOADING
}

export interface GetCardsFail {
    type: typeof GET_CARDS_FAIL
}

export interface GetCardsSuccess {
    type: typeof GET_CARDS_SUCCESS,
    payload:CardsType[]
}

//MESSAGES PRISMA INFO TYPES
type Message = {
    message_swt_id: number;
    message_swt_message: string;
    message_swt_date: Date;
    message_swt_close: boolean;
}

export type MessagesPrismaType = {
    bank_id: string;
    messages: Message[];
}

export interface GetMessagesPrismaLoading {
    type: typeof GET_MESSAGES_PRISMA_LOADING
}

export interface GetMessagesPrismaFail {
    type: typeof GET_MESSAGES_PRISMA_FAIL
}

export interface GetMessagesPrismaSuccess {
    type: typeof GET_MESSAGES_PRISMA_SUCCESS,
    payload:MessagesPrismaType[]
}


//KPI GROUPS FOR MENU INFO

export type KpiGroupsMenuType = {
    menuinfo_id: number,
    menuinfo_name: string,
    menuinfo_title: string,
    menuinfo_url: string,
}

export interface GetKpiGroupsMenuLoading {
    type: typeof GET_KPI_GROUPS_MENU_LOADING
}

export interface GetKpiGroupsMenuFail {
    type: typeof GET_KPI_GROUPS_MENU_FAIL
}

export interface GetKpiGroupsMenuSuccess {
    type: typeof GET_KPI_GROUPS_MENU_SUCCESS,
    payload:KpiGroupsMenuType[]
}

//CLIENT IMAGE INFO
type ClientImage = {
    type: string;
    data: any;
}

export type ClientImageMenuType = {
    service_name: string;
    service_display_name:string;
    service_image: ClientImage;
    service_category:string;
}

export interface GetClientImageLoading {
    type: typeof GET_CLIENT_IMAGE_LOADING
}

export interface GetClientImageFail {
    type: typeof GET_CLIENT_IMAGE_FAIL
}

export interface GetClientImageSuccess {
    type: typeof GET_CLIENT_IMAGE_SUCCESS,
    payload:ClientImageMenuType[]
}


//CLIENT MAINTENANCE INFO
export type ClientMaintenanceType = {
    service_name: string;
    service_display_name: string;
    service_maintenance: boolean;
    service_category: string;
}

export interface GetClientMaintenanceLoading {
    type: typeof GET_CLIENT_MAINTENANCE_LOADING
}

export interface GetClientMaintenanceFail {
    type: typeof GET_CLIENT_MAINTENANCE_FAIL
}

export interface GetClientMaintenanceSuccess {
    type: typeof GET_CLIENT_MAINTENANCE_SUCCESS,
    payload:ClientMaintenanceType[]
}


export type GetMainInfoDispatchTypes = GetGraphicsLoading | GetGraphicsFail | 
            GetGraphicsSuccess | GetCardsLoading | GetCardsFail | GetCardsSuccess |
            GetMessagesPrismaLoading | GetMessagesPrismaFail | GetMessagesPrismaSuccess |
            GetKpiGroupsMenuLoading | GetKpiGroupsMenuFail | GetKpiGroupsMenuSuccess |
            GetClientImageLoading | GetClientImageFail | GetClientImageSuccess |
            GetClientMaintenanceLoading | GetClientMaintenanceFail | GetClientMaintenanceSuccess;