import ApiRequestor from "../../api_access/ApiRequestor";
import { Dispatch } from 'redux';
import { GetClientNotificationsDispatchTypes, GET_CLIENTNOTIFICATIONS_FAIL, GET_CLIENTNOTIFICATIONS_LOADING, GET_CLIENTNOTIFICATIONS_SUCCESS} from "./clientNotificationsActionTypes";

export const GetClientNotifications = () => async (dispatch: Dispatch<GetClientNotificationsDispatchTypes>) => {
    try {
        dispatch({
            type: GET_CLIENTNOTIFICATIONS_LOADING
        })
        const response : any = await ApiRequestor.requestToApi("/swt-admin/swt-messages", "GET");
        dispatch({
            type: GET_CLIENTNOTIFICATIONS_SUCCESS,
            payload: response.data
        })
    } catch(error){
        dispatch({
            type: GET_CLIENTNOTIFICATIONS_FAIL
        })
    }
}   
