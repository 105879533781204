import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IonCard, IonCardHeader, IonCardSubtitle, IonCol, IonIcon, IonRow, IonText } from "@ionic/react";
import { ellipse } from 'ionicons/icons';

import { InternosGraphicsDefaultStateI } from '../../../store/Internos/InternosGraphics/internosGraphicsReducer';

import PaystorePercentage from './PaystorePercentage';
import InternGraphics from '../../Internos/InternGraphics';

import '../../Internos/Intern.css';
import './Paystore.css'

type GraphicType = {
    intern_id: string;
    intern_name: string;
    intern_host: string;
    intern_type: string;
    intern_transform: boolean;
    intern_endpoint_id: string;
    intern_threshold: boolean;
    intern_title: string;
    intern_title_key: string;
    intern_time_gap: number;
    intern_linechart_label: string;
    intern_category: string;
    intern_graphic: boolean;
    service_name: string,
    keys: [],
    values: number[],
    limit_values: number[]
};

interface Props extends StateTypes, dispatchTypes {
    payStoreData: any;
    reversalData: any;
    pendienteData: any;
}

const PaystoreCard: React.FC<Props> = (props): JSX.Element => {
    const [getGraphics, setGetGraphics] = useState([])
    const [varUmbralTRX, setVarUmbralTRX] = useState(0)
    const [varUmbral, setVarUmbral] = useState(0)
    const [umbralPorcReversal, setUmbralPorcReversal] = useState(0)
    const [umbralPorcPendiente, setUmbralPorcPendiente] = useState(0)

    useEffect(() => {
        if (props.internGraphics.internosGraphics && props.internGraphics.loading === false) {
            let graphicsFilter: any = props.internGraphics.internosGraphics.filter((graph) => graph.service_name == 'POS' && graph.intern_title_key == props.payStoreData.titleKey)
            if (props.payStoreData.titleKey.includes("PHOEBUS")) {
                let reversalGraphics: any = props.internGraphics.internosGraphics.filter((graph) => graph.service_name == 'POS' && graph.intern_title_key.includes("REVERSO"))
                for (let i = 0; i < reversalGraphics.length; i++) {
                    graphicsFilter.push(reversalGraphics[i])
                }
                let pendienteGraphics: any = props.internGraphics.internosGraphics.filter((graph) => graph.service_name == 'POS' && graph.intern_title_key.includes("PENDIENTE"))
                for (let i = 0; i < pendienteGraphics.length; i++) {
                    graphicsFilter.push(pendienteGraphics[i])
                }
            }
            setGetGraphics(graphicsFilter);
        }
    }, [props.internGraphics.internosGraphics, props.internGraphics.loading, props.payStoreData])

    useEffect(() => {
        if (getGraphics.length > 0) {
            getGraphics.map((graphicData: any) => {
                if (!graphicData.intern_name.includes("REVERSAL") && !graphicData.intern_name.includes("PENDING")) {
                    if (graphicData.intern_title.includes("Aprobadas") ||
                        graphicData.intern_title.includes("APROBADAS")
                    ) {
                        const Umbral: any = graphicData.limit_values[graphicData.limit_values.length - 1];
                        setVarUmbral(Umbral);
                    } else {
                        const UmbralTRX: any = graphicData.limit_values[graphicData.limit_values.length - 1];
                        setVarUmbralTRX(UmbralTRX);
                    }
                } else if (graphicData.intern_name.includes("REVERSAL")) {
                    const Umbral: any = graphicData.limit_values[graphicData.limit_values.length - 1];
                    setUmbralPorcReversal(Umbral);
                } else if (graphicData.intern_name.includes("PENDING")) {
                    const UmbralPendiente: any = graphicData.limit_values[graphicData.limit_values.length - 1];
                    setUmbralPorcPendiente(UmbralPendiente);
                }
            })
        }
    }, [getGraphics])

    return (
        <IonCard className="paystore-card-content">
            <IonCardHeader className="intern-header">
                <IonRow>
                    <IonIcon className={props.payStoreData.cantAlert !== "0" ? "intern-icon-alerted" : "intern-icon"} icon={ellipse} />
                    <IonText>{props.payStoreData.titleKey}</IonText>
                </IonRow>
            </IonCardHeader>

            <IonRow className='ion-align-items-center'>
                <div className="intern-content-card">
                    <IonCol className="intern-group paystore-group-content" >
                        <IonCardSubtitle className="intern-approved">% Aprobadas</IonCardSubtitle>
                        <PaystorePercentage percentageCard={props.payStoreData.appVal} status={props.payStoreData.appAlert !== "0" ? true : false} />
                        <IonCardSubtitle className="intern-refresh">Últ. Refresh  {props.payStoreData.date.slice(11,)} Hs</IonCardSubtitle>
                    </IonCol>
                    <IonCol className="intern-group paystore-group-content" >
                        <IonRow className="ion-align-items-center intern-row-card">
                            <IonCol size="8" className="intern-subtitulos">Total TRX</IonCol>
                            <IonCol size="4" >{props.payStoreData.cantVal}</IonCol>
                        </IonRow>
                        <IonRow className="ion-align-items-center intern-row-card">
                            <IonCol size="8" className="intern-subtitulos">Total TRX Aprobadas</IonCol>
                            <IonCol size="4">{Math.round((props.payStoreData.cantVal * props.payStoreData.appVal) / 100)}</IonCol>
                        </IonRow>
                        <IonRow className="ion-align-items-center intern-row-card">
                            <IonCol size="8" className="intern-subtitulos">Umbral TRX</IonCol>
                            <IonCol size="4">{varUmbralTRX !== 0 ? varUmbralTRX : 0}</IonCol>
                        </IonRow>
                        <IonRow className="ion-align-items-center intern-row-card">
                            <IonCol size="8" className="intern-subtitulos">Umbral %</IonCol>
                            <IonCol size="4">{varUmbral !== 0 ? varUmbral : 0}%</IonCol>
                        </IonRow>

                        <div className='row-reversal-conteiner reversal-size'>
                            {props.payStoreData.titleKey.includes("PHOEBUS") &&
                                <><IonRow className={umbralPorcReversal < props.reversalData.appVal ? "ion-align-items-center ion-justify-content-center ion-margin-top row-reversal" : "ion-align-items-center ion-justify-content-center ion-margin-top row-reversal-alert"}>
                                    <IonRow className="ion-align-items-center">
                                        <IonCol size="12" className="intern-subtitulos paystore-extra">% Reversos</IonCol>
                                        <IonCol size="12" className="paystore-extra">{props.reversalData.appVal}%</IonCol>
                                        <IonCol size="12" className="intern-subtitulos paystore-extra">Cant. Reversos</IonCol>
                                        <IonCol size="12" className="paystore-extra">{props.reversalData.cantVal}</IonCol>
                                    </IonRow>
                                </IonRow>
                                    <IonRow className={umbralPorcPendiente < props.pendienteData.appVal ? "ion-align-items-center ion-justify-content-center ion-margin-top row-reversal" : "ion-align-items-center ion-justify-content-center ion-margin-top row-reversal-alert"}>
                                        <IonRow className="ion-align-items-center">
                                            <IonCol size="12" className="intern-subtitulos paystore-extra">% Pendientes</IonCol>
                                            <IonCol size="12" className="paystore-extra">{props.pendienteData.appVal}%</IonCol>
                                            <IonCol size="12" className="intern-subtitulos paystore-extra">Cant. Pendientes</IonCol>
                                            <IonCol size="12" className="paystore-extra">{props.pendienteData.cantVal}</IonCol>
                                        </IonRow>
                                    </IonRow>
                                </>
                            }
                        </div>
                    </IonCol>

                </div>
                <IonRow className='intern-group reversal-size-xl'>
                    {props.payStoreData.titleKey.includes("PHOEBUS") &&
                        <><IonCol className={umbralPorcReversal < props.reversalData.appVal ? "ion-align-items-center ion-justify-content-center ion-margin-top row-reversal" : "ion-align-items-center ion-justify-content-center ion-margin-top row-reversal-alert"}>
                            <IonRow className="ion-align-items-center">
                                <IonCol size="12" className="intern-subtitulos paystore-extra">% Reversos</IonCol>
                                <IonCol size="12" className="paystore-extra">{props.reversalData.appVal}%</IonCol>
                                <IonCol size="12" className="intern-subtitulos paystore-extra">Cant. Reversos</IonCol>
                                <IonCol size="12" className="paystore-extra">{props.reversalData.cantVal}</IonCol>
                            </IonRow>
                        </IonCol>
                            <IonCol className={umbralPorcPendiente < props.pendienteData.appVal ? "ion-align-items-center ion-justify-content-center ion-margin-top row-reversal" : "ion-align-items-center ion-justify-content-center ion-margin-top row-reversal-alert"}>
                                <IonRow className="ion-align-items-center">
                                    <IonCol size="12" className="intern-subtitulos paystore-extra">% Pendientes</IonCol>
                                    <IonCol size="12" className="paystore-extra">{props.pendienteData.appVal}%</IonCol>
                                    <IonCol size="12" className="intern-subtitulos paystore-extra">Cant. Pendientes</IonCol>
                                    <IonCol size="12" className="paystore-extra">{props.pendienteData.cantVal}</IonCol>
                                </IonRow>
                            </IonCol>
                        </>
                    }
                </IonRow>
                {
                    getGraphics.length > 0 && getGraphics.map((graph: GraphicType) => {
                        let statusCant = false;
                        let statusPorc = false;
                        if (props.payStoreData.appKey === graph.intern_title && props.payStoreData.appAlert !== "0") {
                            if(graph.intern_title.includes("APROBADAS"))  statusPorc = true 
                        }
                        if (props.payStoreData.cantKey === graph.intern_title && props.payStoreData.cantAlert !== "0") {
                            if(!graph.intern_title.includes("APROBADAS")) statusCant = true
                        }
                        return (
                            (graph.intern_name.includes("REVERSAL") || graph.intern_name.includes("PENDING")) ?
                                <div key={graph.intern_id + "paystore"} /> :
                                props.internGraphics.internosGraphics &&
                                <IonCol className="paystore-group-content" key={graph.intern_id + "paystore"} sizeLg="3.5" sizeMd="6" sizeSm="9" sizeXs="12">
                                    <InternGraphics
                                        internData={graph}
                                        id={graph.intern_title_key}
                                        title={window.screen.width >= 1920 && graph.intern_title.includes("APROBADAS") ? "APROBADAS" : window.screen.width >= 1920 && !graph.intern_title.includes("APROBADAS") ? "CANTIDAD" : graph.intern_title}
                                        alerted={graph.intern_title.includes("APROBADAS") ? statusPorc : statusCant} />
                                </IonCol>
                        )
                    })
                }
            </IonRow>
        </IonCard>
    )
}

//Redux state and actions dispatchers

interface StateTypes {
    internGraphics: InternosGraphicsDefaultStateI;
}

const mapStateToProps = (state: StateTypes) => {
    return {
        internGraphics: state.internGraphics,
    };
};

interface dispatchTypes {

}

const mapDispatchToProps = (dispatch: any) => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaystoreCard);