export const GET_INTERNOS_CARDS_LOADING = 'GET_INTERNOS_CARDS_LOADING';
export const GET_INTERNOS_CARDS_FAIL = 'GET_INTERNOS_CARDS_FAIL';
export const GET_INTERNOS_CARDS_SUCCESS = 'GET_INTERNOS_CARDS_SUCCESS';

export type InternosCardRow ={
    key: string,
    titleKey: string,
    alert: string,
    value: number,
    timeGap: number,
    date: string,
};

export type InternosCardsType =  {
    bank_id: string;
    cardsInfo: InternosCardRow[];
};

export interface GetInternosCardsLoading{
    type: typeof GET_INTERNOS_CARDS_LOADING
}
export interface GetInternosCardsFail{
    type: typeof GET_INTERNOS_CARDS_FAIL
}
export interface GetInternosCardsSuccess{
    type: typeof GET_INTERNOS_CARDS_SUCCESS,
    payload: InternosCardsType[] | undefined
}

export type GetInternosCardsDispatchTypes = GetInternosCardsLoading | GetInternosCardsFail | GetInternosCardsSuccess;