import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { IonCard, IonCardHeader, IonCardSubtitle, IonCol, IonIcon, IonRow, IonText } from "@ionic/react";
import { ellipse } from "ionicons/icons";

import NewpayPercentage from "./NewpayPercentage";
import InternGraphics from "../Internos/InternGraphics";

import { InternosGraphicsDefaultStateI } from "../../store/Internos/InternosGraphics/internosGraphicsReducer";

import '../Internos/Intern.css';
import './Newpay.css'

interface Props extends StateTypes, dispatchTypes {
    cardData: any;
    graphicsData: any;
}

const NewpayCard: React.FC<Props> = (props): JSX.Element => {

    const [cardGraphicInfo, setCardGraphicInfo] = useState<any>([])

    useEffect(() => {
        if (props.graphicsData.length > 0) {
            let graphicsFilter: any = props.graphicsData[0].filter((graphic: any) => graphic.intern_title_key === props.cardData.titleKey)
            setCardGraphicInfo(graphicsFilter)
        }
    }, [props.graphicsData, props.cardData])

    return (
        <>

            {(props.cardData.titleKey === 'PULL' || props.cardData.titleKey === 'PUSH') &&
                <>
                    <IonCard>
                        <IonCardHeader className='intern-header'>
                            <IonRow>
                                <IonIcon className={(props.cardData.cantAlert !== "0") ? "intern-icon-alerted" : "intern-icon"} icon={ellipse}  />
                                <IonText>{props.cardData.titleKey}</IonText>
                            </IonRow>
                        </IonCardHeader>
                        <IonRow className='ion-align-items-center'>
                            {!props.cardData.titleKey.includes('RECHAZO') &&
                                <IonCol className="intern-group newpay-group-content-percentage">
                                    <IonCardSubtitle className="intern-approved">% Aprobadas</IonCardSubtitle>
                                    <NewpayPercentage percentageCard={props.cardData.appVal} status={(props.cardData.appAlert !== "0") ? true : false} />
                                    <IonCardSubtitle className="intern-approved"></IonCardSubtitle>
                                </IonCol>}
                            {props.graphicsData && props.graphicsData.length > 0 &&
                                cardGraphicInfo.map((graph: any) => {
                                    let statusCant = false;
                                    let statusPorc = false;
                                    if (props.cardData.appKey === graph.intern_title && props.cardData.appAlert !== "0") {
                                        if (graph.intern_title.includes("APROBADAS")) statusPorc = true;
                                    }
                                    if (props.cardData.cantKey === graph.intern_title && props.cardData.cantAlert !== "0") {
                                        if (!graph.intern_title.includes("APROBADAS")) statusCant = true;
                                    }
                                    if (graph.intern_title_key == props.cardData.titleKey) {
                                        return (
                                            props.internGraphics.internosGraphics &&
                                            <IonCol className="newpay-graph-content-card" key={graph.intern_id + "newpay"} sizeXl="5" sizeLg="5" sizeMd="4" sizeSm="9" sizeXs="12">
                                                <InternGraphics
                                                    key={graph.intern_id}
                                                    internData={graph}
                                                    id={graph.intern_title_key}
                                                    alerted={graph.intern_title.includes("APROBADAS") ? statusPorc : statusCant}
                                                    title={graph.intern_title} />
                                            </IonCol>
                                        );
                                    }
                                })}
                        </IonRow>
                    </IonCard>
                </>}

            {(props.cardData.titleKey === 'ONHOST CITI' || props.cardData.titleKey === 'RECHAZOS 57' || props.cardData.titleKey === 'RECHAZOS 29') &&
                <>
                    <IonCard>
                        <IonCardHeader className='intern-header'>
                            <IonRow>
                                <IonIcon className={(props.cardData.cantAlert !== "0") ? "intern-icon-alerted" : "intern-icon"} icon={ellipse} />
                                <IonText>{props.cardData.titleKey}</IonText>
                            </IonRow>
                        </IonCardHeader>
                        <IonRow className='ion-align-items-center'>
                            {props.graphicsData && props.graphicsData.length > 0 &&
                                cardGraphicInfo.map((graph: any) => {
                                    let statusCant = false;
                                    let statusPorc = false;
                                    if (props.cardData.appKey === graph.intern_title && props.cardData.appAlert !== "0") {
                                        if (graph.intern_title.includes("APROBADAS")) statusPorc = true;
                                    }
                                    if (props.cardData.cantKey === graph.intern_title && props.cardData.cantAlert !== "0") {
                                        if (!graph.intern_title.includes("APROBADAS")) statusCant = true;
                                    }
                                    if (graph.intern_title_key == props.cardData.titleKey) {
                                        return (
                                            props.internGraphics.internosGraphics &&
                                            <IonCol key={graph.intern_id + "newpay"} sizeXl="12" sizeLg="12" sizeMd="12" sizeSm="12" sizeXs="12">
                                                <InternGraphics
                                                    key={graph.intern_id}
                                                    internData={graph}
                                                    id={graph.intern_title_key}
                                                    alerted={graph.intern_title.includes("APROBADAS") ? statusPorc : statusCant} />
                                            </IonCol>
                                        );
                                    }
                                })}
                        </IonRow>
                    </IonCard>
                </>}

        </>
    )
}

interface StateTypes {
    internGraphics: InternosGraphicsDefaultStateI;
}

const mapStateProps = (state: StateTypes) => {
    return {
        internGraphics: state.internGraphics,
    }
}

interface dispatchTypes { }

const mapDispatchToProps = (dispatch: any) => {
    return {}
}

export default connect(mapStateProps, mapDispatchToProps)(NewpayCard);