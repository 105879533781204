import { ABMInternType, GetABMINTERNDispatchTypes, GET_ABMINTERN_FAIL, GET_ABMINTERN_LOADING, GET_ABMINTERN_SUCCESS } from './ABMInternActionsTypes';

export interface ABMInternDefaultStateI {
    loading : boolean,
    abmintern? : ABMInternType[] | undefined
}

const defaultState : ABMInternDefaultStateI = 
{
    loading : false
};

const ABMInternReducer = (state: ABMInternDefaultStateI = defaultState, action:GetABMINTERNDispatchTypes): ABMInternDefaultStateI =>{

    switch (action.type) {
        case GET_ABMINTERN_FAIL:
            return {
                loading:false
            }
            break;
        case GET_ABMINTERN_LOADING:
            return {
                loading:true
            }
            break;
        case GET_ABMINTERN_SUCCESS:
            return {
                loading:false,
                abmintern:action.payload
            }
            break;
        default:
            return state
            break;
    }
    return state;
}

export default ABMInternReducer;