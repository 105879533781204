import ApiRequestor from "../../../api_access/ApiRequestor";

import { Dispatch } from "react";

import { GetUserStatisticsCardDispatchTypes, GET_USERSTATISTICSCARD_LOADING, GET_USERSTATISTICSCARD_SUCCESS, GET_USERSTATISTICSCARD_FAIL} from './userStatisticsCardsActionsTypes';



export const GetUserStatisticsCard = () => async (dispatch:Dispatch<GetUserStatisticsCardDispatchTypes>) =>{

    try{

        dispatch({
            type:GET_USERSTATISTICSCARD_LOADING
        })
        const response: any = await ApiRequestor.requestToApi("/user/general","GET");

        dispatch({
            type:GET_USERSTATISTICSCARD_SUCCESS,
            payload: response.data
        })
        
    } catch(error){
        dispatch({
            type:GET_USERSTATISTICSCARD_FAIL
        })

    }

}