import {GetMainInfoDispatchTypes, GET_MESSAGES_PRISMA_FAIL, GET_MESSAGES_PRISMA_LOADING, GET_MESSAGES_PRISMA_SUCCESS, MessagesPrismaType} from './mainInfoActionsTypes'

export interface MessagesPrismaDefaultStateI {
    loading:boolean,
    messagesPrisma?: MessagesPrismaType[]
}

const defaultState:MessagesPrismaDefaultStateI = {
    loading:false
};


const messagesPrismaReducer = (state: MessagesPrismaDefaultStateI = defaultState,action:GetMainInfoDispatchTypes):MessagesPrismaDefaultStateI =>{
    switch (action.type) {
        case GET_MESSAGES_PRISMA_FAIL:
            return {
                loading:false
            }
            break;
        case GET_MESSAGES_PRISMA_LOADING:
            return {
                loading:true
            }
            break;
        case GET_MESSAGES_PRISMA_SUCCESS:
            return {
                loading:false,
                messagesPrisma:action.payload
            }
            break;
        default:
            return state
            break;
    }
    return state;
}


export default messagesPrismaReducer;