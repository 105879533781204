import ApiRequestor from "../../../api_access/ApiRequestor";
import { Dispatch } from "react";
import {GET_INTERNOS_ALERTS_FAIL, GET_INTERNOS_ALERTS_LOADING, GET_INTERNOS_ALERTS_SUCCESS, GetInternosAlertsDispatchTypes} from './internosAlertsActionsTypes'

export const GetInternosAlerts = () => async (dispatch:Dispatch<GetInternosAlertsDispatchTypes>) =>{
    try{
        dispatch({
            type:GET_INTERNOS_ALERTS_LOADING
        })
        const response: any = await ApiRequestor.requestToApi("/intern/alerts/120","GET");

        dispatch({
            type:GET_INTERNOS_ALERTS_SUCCESS,
            payload:response.data
        })
    } catch(error){
        console.log(error);
        dispatch({
            type:GET_INTERNOS_ALERTS_FAIL
        })
    }
}