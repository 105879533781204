import ApiRequestor from "../../../api_access/ApiRequestor";
import {Dispatch} from "redux"
import {GetUserStatisticsClientDispatchTypes, GET_USERSTATISTICSCLIENT_FAIL, GET_USERSTATISTICSCLIENT_LOADING, GET_USERSTATISTICSCLIENT_SUCCESS} from './userStatisticsClientActionsTypes'


export const GetUserStatisticsClient = () => async (dispatch: Dispatch<GetUserStatisticsClientDispatchTypes>) => {
    try {
        dispatch({
            type: GET_USERSTATISTICSCLIENT_LOADING
        })
        const response: any = await ApiRequestor.requestToApi("/user/user-info","GET");
        dispatch({
            type: GET_USERSTATISTICSCLIENT_SUCCESS,
            payload: response.data
        })
    }
    
    catch(error){
        dispatch({
            type: GET_USERSTATISTICSCLIENT_FAIL
        })
    }
}
