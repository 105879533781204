import React, { useEffect, useRef, useState } from 'react';
import { IonCardTitle, IonToggle } from '@ionic/react';

import './Umbrals.css';

interface Props {
  text: string,
  check: boolean,
  parentCallback: (check: boolean) => void
}

const ToggleUmbrals: React.FC<Props> = ({ text, check, parentCallback }) => {
  const [checked, setChecked] = useState<boolean>(check)
  const prevChecked = usePrevious(checked)

  const handleChange = () => {
    setChecked(!checked)
  }

  if (prevChecked !== undefined && prevChecked !== checked) {
    parentCallback(checked)
  }

  return (
    <>
      <IonCardTitle className="toggle-umbrals-label">{text}</IonCardTitle>
      <IonToggle color="secondary" checked={checked} onIonChange={handleChange} slot="start" mode="ios" className="toggle-umbrals-size" />
    </>
  );
};

export default ToggleUmbrals;

function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}