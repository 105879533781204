import { InternosCardsType, GetInternosCardsDispatchTypes, GET_INTERNOS_CARDS_FAIL, GET_INTERNOS_CARDS_LOADING, GET_INTERNOS_CARDS_SUCCESS} from "./internosCardsActionsTypes";

export interface InternosCardsDefaultStateI{
    loading: boolean,
    internosCards? : InternosCardsType[] | undefined
}

const defaultState : InternosCardsDefaultStateI = {
    loading:false
}

const internosCardsReducer = (state:InternosCardsDefaultStateI = defaultState, action:GetInternosCardsDispatchTypes):
InternosCardsDefaultStateI =>{
    switch(action.type){
        case GET_INTERNOS_CARDS_FAIL:
            return{
                loading:false
            }
            break;
        case GET_INTERNOS_CARDS_LOADING:
            return{
                loading:true
            }
            break;
        case GET_INTERNOS_CARDS_SUCCESS:
            return{
                loading:false,
                internosCards:action.payload
            }
            break;
        default:
            return state
            break;
    }
    return state;
}

export default internosCardsReducer;