import ApiRequestor from "../../../api_access/ApiRequestor";
import { Dispatch } from "redux";
import {GetInternosGraphicsDispatchTypes, GET_INTERNOS_GRAPHICS_FAIL, GET_INTERNOS_GRAPHICS_LOADING, GET_INTERNOS_GRAPHICS_SUCCESS} from './internosGraphicsActionsTypes'

export const GetInternosGraphics = () => async (dispatch:Dispatch<GetInternosGraphicsDispatchTypes>) =>{
    try {
        dispatch({
            type: GET_INTERNOS_GRAPHICS_LOADING
        })
        const response : any = await ApiRequestor.requestToApi("/intern/graphics","GET");
        dispatch({
            type: GET_INTERNOS_GRAPHICS_SUCCESS,
            payload:response.data.graphics
        })
    } catch(error){
        console.log(error);
        dispatch({
            type: GET_INTERNOS_GRAPHICS_FAIL
        })
    }
}