import ApiRequestor from "../../api_access/ApiRequestor";
import {Dispatch} from "redux"
import { GetABMCLIENTSDispatchTypes, GET_ABMCLIENTS_LOADING, GET_ABMCLIENTS_SUCCESS, GET_ABMCLIENTS_FAIL} from './ABMClientsActionTypes';


export const GetABMClients = () => async (dispatch: Dispatch<GetABMCLIENTSDispatchTypes>) => {
    try {
        dispatch({
            type: GET_ABMCLIENTS_LOADING
        })
        const response : any = await ApiRequestor.requestToApi("/swt-admin/clients", "GET");
        dispatch({
            type: GET_ABMCLIENTS_SUCCESS,
            payload: response.data
        })
    } catch(error) {
        console.log(error);
        dispatch({
            type: GET_ABMCLIENTS_FAIL
        })
    }
   
}

