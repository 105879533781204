import ApiRequestor from "../../../api_access/ApiRequestor";
import { Dispatch } from "redux";
import {GetABMKPISGROUPSDispatchTypes, GET_ABMKPISGROUPS_LOADING, GET_ABMKPISGROUPS_SUCCESS, GET_ABMKPISGROUPS_FAIL} from './ABMKpisGroupsActionsTypes';

export const GetABMKpisGroups = () => async ( dispatch:Dispatch<GetABMKPISGROUPSDispatchTypes>) => {
    try{
        dispatch({
            type: GET_ABMKPISGROUPS_LOADING
        })
        const response : any = await ApiRequestor.requestToApi("/swt-admin/kpi-groups", "GET");
        dispatch({
            type: GET_ABMKPISGROUPS_SUCCESS,
            payload: response.data
        })
    } catch(error){
        console.log(error);
        dispatch({
            type: GET_ABMKPISGROUPS_FAIL
        })
    }
}