import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { IonCard, IonCol, IonContent, IonGrid, IonIcon, IonPage, IonRow, IonText, getPlatforms } from "@ionic/react";
import { notificationsCircle, notifications, arrowUpCircle, arrowUp } from "ionicons/icons";

import { SessionDefaultStateI } from "../../store/session/SessionReducer";
import { ClientMaintenanceDefaultStateI } from "../../store/mainInfo/clientMaintenanceReducer";
import { GetClientImage } from "../../store/mainInfo/mainInfoActions";
import { InternosCardsDefaultStateI } from "../../store/Internos/InternosCards/internosCardsReducer";
import { InternosGraphicsDefaultStateI } from "../../store/Internos/InternosGraphics/internosGraphicsReducer";
import { ChangeStateDefaultStateI } from "../../store/changeState/changeStateReducer";
import { GetChangeState } from "../../store/changeState/changeStateActions";

import Header from "../header/Header";
import Footer from "../Footer/Footer";
import Loading from "../Loading/Loading";
import NewpayCard from "./NewpayCard";
import SwtListClientsModal from "../SwtListClientsModal/SwtListClientsModal";
import MessagesPrisma from "../MessagesPrisma/MessagesPrisma";
import MaintenanceComponent from "../Maintenance/MaintenanceComponent";

import './Newpay.css';

interface Props extends StateTypes, dispatchTypes { }

const NewpayMainView: React.FC<Props> = (props): JSX.Element => {

    const [cardInfo, setCardInfo] = useState<any>([]);
    const [cardInfoCiti, setCardInfoCiti] = useState<any>([])
    const [cardInfoRechazos57, setCardInfoRechazos57] = useState<any>([])
    const [cardInfoRechazos29, setCardInfoRechazos29] = useState<any>([])
    const [graphicsInfo, setGraphicsInfo] = useState<any>([]);
    const [showModal, setShowModal] = useState(false);
    const [clientSelected, setClientSelected] = useState('');
    const [categorySelected, setCategorySelected] = useState('');
    const [hasMessages, setHasMessages] = useState(false);
    const [notifColor, setNotifColor] = useState(false);
    const [scrolling, setScrolling] = useState(false);
    const [maintenance, setMaintenance] = useState(false);
    const [inactive, setInactive] = useState(false);

    const bank_id_category = props.session.loginData.getIdToken().decodePayload()["custom:category"]
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    const [shouldRefresh, setShouldRefresh] = useState(false);
    const desktop = getPlatforms().includes("desktop")

    useEffect(() => {
        // se renderiza 2 veces
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
            setShouldRefresh(true);
        }
        if (desktop) {
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
    }, []);

    useEffect(() => {
        // se renderiza 2 veces

        if (shouldRefresh) {
        console.log('entra al if')
            setShouldRefresh(false);
            window.location.reload();
        }
    }, [shouldRefresh]);

    useEffect(() => {
        //se ejecuta 2 veces
        if (props.session.loading === false && props.session.loginData) {
            props.getChangeState();
            if (!sessionStorage.getItem("client")) {
                sessionStorage.setItem("client", '')
            }
            if (bank_id_category == "ADMIN") {
                props.getClients()
                if (sessionStorage.getItem("client") == '') {
                    setShowModal(true)
                }else{
                    setShowModal(false)
                }
            }
        }
    }, [])

    useEffect(() => {
        //renderiza 7 veces
        if (props.internCards.loading === false && props.internCards.internosCards) {
            props.internCards.internosCards.map((card) => {
                if (card.bank_id.includes("NEWPAY")) {

                    const cardData = card.cardsInfo.filter((cards: any) => {
                        return (!cards.key.includes("ONHOST") && !cards.key.includes("Rechazos"))
                    })
                    const cardDataCiti = card.cardsInfo.filter((cards: any) => {
                        return (cards.key.includes("ONHOST CITI"))
                    })
                    const cardDataRechazos57 = card.cardsInfo.filter((cards: any) => {
                        return (cards.key.includes("Rechazos 57 (Consulta de titularidad CITI)"))
                    })
                    const cardDataRechazos29 = card.cardsInfo.filter((cards: any) => {
                        return (cards.key.includes("Rechazos 29 (Transferencias PUSH)"))
                    })

                    const newpayCardData = groupValues(cardData)
                    const newpayCardDataCiti = groupValues(cardDataCiti)
                    const newpayCardDataRechazos57 = groupValues(cardDataRechazos57)
                    const newpayCardDataRechazos29 = groupValues(cardDataRechazos29)

                    setCardInfo(newpayCardData);
                    setCardInfoCiti(newpayCardDataCiti);
                    setCardInfoRechazos57(newpayCardDataRechazos57);
                    setCardInfoRechazos29(newpayCardDataRechazos29);
                }
            })
        }
    }, [props.internCards.loading, props.internCards.internosCards])

    useEffect(() => {
        if (bank_id_category !== 'ADMIN') {
            if (props.changeState.loading == false && props.changeState.changeState) {
                if (props.changeState.changeState[0].service_state == "inactive") {
                    setInactive(true)
                } else {
                    if (props.clientMaintenance.loading == false && props.clientMaintenance.clientMaintenance &&
                        props.clientMaintenance.clientMaintenance[0].service_maintenance) {
                        setMaintenance(true)
                    }
                }
            }
        } else {
            if (props.clientMaintenance.loading == false && props.clientMaintenance.clientMaintenance && sessionStorage.getItem("client") && sessionStorage.getItem("client") != '') {
                const maintenance = props.clientMaintenance.clientMaintenance.filter(client => client.service_name == sessionStorage.getItem("client"))[0].service_maintenance
                if (maintenance) {
                    setMaintenance(true)
                } else {
                    setMaintenance(false)
                }
            }
        }
    }, [props.changeState.loading, props.changeState.changeState, props.clientMaintenance.loading, props.clientMaintenance.clientMaintenance, sessionStorage.getItem("client")])

    useEffect(() => {
        if (props.internGraphics.loading === false && props.internGraphics.internosGraphics) {

            const filterGraphics = props.internGraphics.internosGraphics.filter((graphic) => {
                return (graphic.service_name === 'NEWPAY')
            })
            setGraphicsInfo([filterGraphics])
        }
    }, [props.internGraphics.loading, props.internGraphics.internosGraphics])

    if (inactive && bank_id_category !== 'ADMIN') {
        return <Redirect to="/page/inactive" />
    }

    if (maintenance && bank_id_category !== 'ADMIN') {
        return <Redirect to="/page/maintenance" />
    }

    const doHasMessages = (bool: boolean) => {
        setHasMessages(bool)
    }

    const handleOpenMessage = () => {
        setScrolling(false)
    }

    const notifIconColor = (bool: boolean) => {
        setNotifColor(bool)
    }

    const showIcon = (e: any) => {
        const iconElement: any = document.getElementById("icon-scrolling")
        const iconMsgElement: any = document.getElementById("icon-msg-scrolling")
        const iconElementCircle: any = document.getElementById("icon-scrolling-circle")
        const iconMsgElementCircle: any = document.getElementById("icon-msg-scrolling-circle")

        if (e.target.scrollTop < 100) {
            iconElement.style.display = "none"
            iconElementCircle.style.display = "none"
        } else {
            iconElement.style.display = "block"
            iconElementCircle.style.display = "block"
        }
        if (hasMessages) {
            if (e.target.scrollTop < 100) {
                iconMsgElement.style.display = "none"
                iconMsgElementCircle.style.display = "none"
            } else {
                iconMsgElement.style.display = "block"
                iconMsgElementCircle.style.display = "block"
            }
        } else {
            iconMsgElement.style.display = "none"
            iconMsgElementCircle.style.display = "none"
        }
    }


    const handleClickUp = () => {
        const contentElement = document.getElementById("ion-content-main")
        contentElement?.scrollIntoView({ behavior: "smooth" })
    }

    const showModalFunc = () => {
        setShowModal(true)
    }

    const closeModalFunc = () => {
        setShowModal(false)
    }

    const selectClient = (clientName: string, categoryName: string) => {
        sessionStorage.setItem("client", clientName)
        sessionStorage.setItem("category", categoryName)
        setClientSelected(clientName)
        setCategorySelected(categoryName)
    }

    const getCardByTitleKey = (cardsArray: any, element: any) => {
        let card = cardsArray.find(
            (arrayElement: any) => element.titleKey === arrayElement.titleKey
        );

        if (!card && element.titleKey) {
            card = { titleKey: element.titleKey };
        }
        return card;
    };

    const getCardIndex = (cardsArray: any, element: any) => {
        return cardsArray.findIndex(
            (arrayElement: any) => element.titleKey === arrayElement.titleKey
        );
    };

    const groupValues = (array: any[]): any => {
        const graphicsArray: any[] = [];
        array.forEach((element) => {

            try {
                let card = getCardByTitleKey(graphicsArray, element);
                let index = getCardIndex(graphicsArray, element);

                if (element.key.includes("Aprobadas") || element.key.includes("APROBADAS")) {
                    card.appKey = element.key;
                    card.appVal = element.value;
                    card.appAlert = element.alert;

                } else {
                    card.cantKey = element.key;
                    card.cantVal = element.value;
                    card.cantAlert = element.alert;
                }

                card.gap = element.timeGap;

                if (index === -1) {
                    graphicsArray.push(card);
                } else {
                    graphicsArray[index] = card;
                }
            } catch (err) {
                console.log(err)
            }
        });

        return graphicsArray;
    };

    return (
        <IonPage>
            <Header timer headerText='Newpay' parentCallback={(bank_id_category == "ADMIN") && showModalFunc} />
            {
                props.internCards && props.internGraphics && cardInfo && cardInfo.length > 0 && graphicsInfo && graphicsInfo.length > 0 ?
                    <IonContent fullscreen onScroll={(e) => { setScrolling(true); showIcon(e) }}>
                        <IonGrid id="ion-content-main">
                            {
                                (bank_id_category == "ADMIN") && window.screen.width < 768 &&
                                <IonRow>
                                    <IonCol className="ion-no-padding" onClick={() => showModalFunc()}>
                                        <IonCard className="ion-no-margin">
                                            <IonText><h5 className="ion-text-center ion-no-margin ion-padding">Seleccionar cliente</h5></IonText>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                            }
                            {
                                maintenance && (bank_id_category == "ADMIN") ?
                                    <MaintenanceComponent />
                                    :
                                    <IonCol>
                                        <MessagesPrisma scrolling={scrolling} hasMessages={doHasMessages} handleOpenMsg={handleOpenMessage} notifColor={notifIconColor} />

                                        <div className="newpay-card">
                                            {
                                                graphicsInfo && cardInfo && cardInfo.length > 0 && graphicsInfo.length > 0 &&
                                                cardInfo.map((cardData: any) => {
                                                    return (
                                                        <NewpayCard
                                                            key={cardData.titleKey}
                                                            cardData={cardData}
                                                            graphicsData={graphicsInfo}
                                                        />
                                                    )
                                                })
                                            }


                                        </div>
                                        <IonRow className="newpay-content-secundario">
                                            <IonCol className="ion-no-padding ">
                                                {
                                                    graphicsInfo && cardInfoCiti && cardInfoCiti.length > 0 && graphicsInfo.length > 0 &&
                                                    cardInfoCiti.map((cardData: any) => {
                                                        return (
                                                            <NewpayCard
                                                                key={cardData.titleKey}
                                                                cardData={cardData}
                                                                graphicsData={graphicsInfo}
                                                            />
                                                        )
                                                    })
                                                }
                                            </IonCol>

                                            <IonCol className="ion-no-padding">
                                                {
                                                    graphicsInfo && cardInfoRechazos57 && cardInfoRechazos57.length > 0 && graphicsInfo.length > 0 &&
                                                    cardInfoRechazos57.map((cardData: any) => {

                                                        return (
                                                            <NewpayCard
                                                                key={cardData.titleKey}
                                                                cardData={cardData}
                                                                graphicsData={graphicsInfo}
                                                            />
                                                        )
                                                    })
                                                }
                                            </IonCol>

                                            <IonCol className="ion-no-padding">
                                                {
                                                    graphicsInfo && cardInfoRechazos29 && cardInfoRechazos29.length > 0 && graphicsInfo.length > 0 &&
                                                    cardInfoRechazos29.map((cardData: any) => {
                                                        return (
                                                            <NewpayCard
                                                                key={cardData.titleKey}
                                                                cardData={cardData}
                                                                graphicsData={graphicsInfo}
                                                            />
                                                        )
                                                    })
                                                }
                                            </IonCol>
                                        </IonRow>
                                    </IonCol>
                            }
                        </IonGrid>

                        <IonIcon icon={notificationsCircle} className={scrolling ? "icon-msgs-up-circle" : "ion-hide"} id="icon-msg-scrolling-circle" onClick={() => { handleClickUp() }} />
                        <IonIcon icon={notifications} className={scrolling ? (notifColor ? "icon-msgs-up color-true" : "icon-msgs-up color-false") : "ion-hide"} id="icon-msg-scrolling" onClick={() => { handleClickUp() }} />
                        <IonIcon icon={arrowUpCircle} className={scrolling ? "icon-scroll-up-circle" : "ion-hide"} id="icon-scrolling-circle" onClick={() => { handleClickUp() }} />
                        <IonIcon icon={arrowUp} className={scrolling ? "icon-scroll-up" : "ion-hide"} id="icon-scrolling" onClick={() => { handleClickUp() }} />

                        <SwtListClientsModal parentCallback={selectClient} showModal={showModal} closeModalCallback={closeModalFunc} />

                    </IonContent>
                    : <Loading />
            }
            <Footer />
        </IonPage>
    )
}



interface StateTypes {
    session: SessionDefaultStateI;
    clientMaintenance: ClientMaintenanceDefaultStateI;
    internCards: InternosCardsDefaultStateI;
    internGraphics: InternosGraphicsDefaultStateI;
    changeState: ChangeStateDefaultStateI;
}

const mapStateProps = (state: StateTypes) => {
    return {
        session: state.session,
        clientMaintenance: state.clientMaintenance,
        internCards: state.internCards,
        internGraphics: state.internGraphics,
        changeState: state.changeState,
    }
}

interface dispatchTypes {
    getClients: () => void;
    getChangeState: () => void;
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getClients: () => dispatch(GetClientImage()),
        getChangeState: () => dispatch(GetChangeState())
    }
}

export default connect(mapStateProps, mapDispatchToProps)(NewpayMainView);