import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import {
  IonButton, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonInput, IonItem, IonPage,
  IonRow, IonTitle, IonCardHeader, IonCardTitle, IonImg, IonCardSubtitle, IonText, IonRouterLink, IonIcon, getPlatforms,
} from "@ionic/react";
import { arrowBackOutline } from "ionicons/icons";

import { setStyleMode } from "../../utils/StyleUtils";

import ApiRequestor from "../../api_access/ApiRequestor";
import prismaLogo from "../../assets/prismaLogo.png";
import prismaLogoDark from "../../assets/prismaLogoDark.png";
import Footer from "../Footer/Footer";

import "./LoginOverview.css";
import Modal from "../Modal/Modal";

interface AuthenticationData {
  username: string;
  code: string;
}

const initialAuthenticationData: AuthenticationData = {
  username: "",
  code: "",
};

const Authentication: React.FC<any> = (props): JSX.Element => {
  const [authenticationData, setAuthenticationData] = useState<AuthenticationData>(initialAuthenticationData);
  const [showModal, setShowModal] = useState(false);
  const [textModal, setTextModal] = useState("");
  const [authentication_invalid, setAuthenticationInvalid] = useState(false);
  const [username_invalid, setUsername_invalid] = useState({
    empty: false,
    long: false,
  });
  const [code_invalid, setCode_invalid] = useState({
    empty: false,
    long: false,
  });
  const [redirectData, setRedirectData] = useState(false)
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const desktop = getPlatforms().includes("desktop")

  useEffect(() => {

    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
      setShouldRefresh(true);
    }

    if(desktop){
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
    
  }, []);

  useEffect(() => {
    if (shouldRefresh) {
      setShouldRefresh(false);
      window.location.reload();
    }
  }, [shouldRefresh]);

  useEffect(() => {
    setStyleMode();
  }, []);

  const validUsername = () => {
    if (authenticationData.username.length < 2) {
      if (authenticationData.username.length == 0) {
        setUsername_invalid({ empty: true, long: true });
      } else {
        setUsername_invalid({ empty: false, long: true });
      }
    } else {
      setUsername_invalid({ empty: false, long: false });
    }
  };

  const validCode = () => {
    if (authenticationData.code.length !== 6) {
      if (authenticationData.code.length == 0) {
        setCode_invalid({ empty: true, long: true });
      } else {
        setCode_invalid({ empty: false, long: true });
      }
    } else {
      setCode_invalid({ empty: false, long: false });
    }
  };

  const validForm = () => {
    let valid = false;
    if (
      username_invalid.empty == false &&
      code_invalid.empty == false &&
      authenticationData.username.length > 0 &&
      authenticationData.code.length == 6
    ) {
      valid = true;
    }
    return valid;
  };

  const handleKey = (e: any) => {
    if (e.key == "Enter") {
      doModal()
    }
  }

  const doModal = async () => {
    if (validForm()) {
      const data: any = { body: { username: authenticationData.username, confirmationCode: authenticationData.code } }
      const response: any = await ApiRequestor.authRequestToUser('/auth/verify-user', "POST", data, "USER")
      if (!response.data.message) {
        setTextModal("Usuario Validado Correctamente")
        setShowModal(true);

      } else {
        if (response.data.message.includes("Invalid verification code provided, please try again.")) {
          setTextModal("Código de verificación incorrecto. Por favor intente nuevamente.")
          setShowModal(true)
        } else {
          if (response.data.message.includes("Username/client id combination not found.")) {
            setTextModal("No se encontro un usuario registrado con ese nombre.")
            setShowModal(true)
          } else {
            if (response.data.message.includes("User cannot be confirmed. Current status is CONFIRMED")) {
              setTextModal("El usuario ya ha sido confirmado.")
              setShowModal(true)
            } else {
              if (response.data.message.includes("Attempt limit exceeded, please try after some time.")) {
                setTextModal("No se pudo enviar la información, se alcanzó el número máximo de intentos. Por favor intente nuevamente en otro momento.")
                setShowModal(true)
              } else {
                setTextModal(response.data)
                setShowModal(true)
              }
            }
          }
        }
      }
    } else {
      validCode()
      validUsername()
    }
  }

  const onSuccess = () => {
    if (textModal.includes("Usuario Validado Correctamente") || (textModal.includes("El usuario ya ha sido confirmado."))) {
      setShowModal(false)
      setRedirectData(true)
    }
    setShowModal(false)
  }

  if (redirectData == true) {
    return (
      <Redirect to="/page/login" />
    )
  }

  const returnModalChildren = (): JSX.Element => {
    return (
      <>
        <IonText>
          <p className="ion-padding ion-text-center login-modal-text"> {textModal}</p>
        </IonText>
        <IonRow className="ion-text-center ion-padding-bottom">
          <IonCol size-lg="12" size-md="12">
            <IonButton className="login-button" fill="outline" size="small" onClick={onSuccess}>OK</IonButton>
          </IonCol>
        </IonRow>
      </>
    )
  }

  return (
    <IonPage id="loginPage">
      <IonContent className="ion-text-center">
        <IonGrid className="ion-no-padding">
          <IonRow>
            <IonCol size="12" className="colLogin colLoginForgot ion-padding-botton">
              <IonRouterLink href="/page/login">
                <IonIcon icon={arrowBackOutline} className="login-back-arrow" />
              </IonRouterLink>
              <IonTitle className="ion-padding ion-text-center login-title">
                <div className="ion-text-wrap ion-padding-bottom">
                  MONITOREO DE SERVICIOS
                </div>
              </IonTitle>
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center login-row">
            <IonCard className="cardLogin ion-text-center">
              <IonCardHeader className="">
                {localStorage.getItem("styleMode") == "dark" ?
                  <IonImg id="logoDark" className="login-image" src={prismaLogoDark} />
                  :
                  localStorage.getItem("styleMode") == "light" ?
                    <IonImg id="logoLight" className="login-image" src={prismaLogo} />
                    :
                    <IonImg />
                }
                <IonCardTitle className="login-card-title">
                  VALIDE SU USUARIO
                </IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                <IonRow className="ion-justify-content-center">
                  <IonCol size-xl="8" size-lg="8" size-md="8" size-sm="12" size-xs="12">
                    <IonCardSubtitle className="ion-text-start ion-margin-start">
                      Usuario
                    </IonCardSubtitle>
                    <IonItem className="login-item" lines="none">
                      <IonInput
                        className="inputLogin"
                        type="text"
                        name="username"
                        placeholder="Usuario"
                        autocomplete="new-password"
                        required
                        value={authenticationData.username}
                        onIonChange={event => { validUsername(); setAuthenticationData({ username: event.detail.value!, code: authenticationData.code }) }}
                        onKeyPress={handleKey} />
                    </IonItem>
                    <IonText>
                      <p className={username_invalid.empty ? "login-valid-text" : "ion-hide"}> El usuario está vacío</p>
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center">
                  <IonCol size-xl="8" size-lg="8" size-md="8" size-sm="12" size-xs="12">
                    <IonCardSubtitle className="ion-text-start ion-margin-start">
                      Código de Verificación
                    </IonCardSubtitle>
                    <IonItem className="login-item" lines="none">
                      <IonInput
                        className="inputLogin"
                        type="text"
                        name="code"
                        autocomplete="new-password"
                        placeholder="XXXXXX"
                        required
                        value={authenticationData.code}
                        onIonChange={(event) => {
                          validCode();
                          setAuthenticationData({
                            username: authenticationData.username,
                            code: event.detail.value!
                          })
                        }}
                        onKeyPress={handleKey} />
                    </IonItem>
                    <IonText>
                      <p className={code_invalid.empty ? "login-valid-text" : "ion-hide"}> El código está vacio</p>
                      <p className={code_invalid.long ? "login-valid-text" : "ion-hide"}> El código debe tener 6 caracteres</p>
                    </IonText>
                    <IonText>
                      <h5 className={authentication_invalid ? "login-invalid ion-text-wrap" : "ion-hide"}> USUARIO Y/O CÓDIGO INCORECTO</h5>
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" className="ion-padding">
                    <IonButton className="ion-margin-top login-button" fill="outline" onKeyPress={handleKey} onClick={doModal}>
                      Validar
                    </IonButton>
                  </IonCol>
                  <IonCol size="12" className="ion-padding"></IonCol>
                </IonRow>
              </IonCardContent>
            </IonCard>
          </IonRow>

          <Modal
            modalChildren={{
              id: "modal-reset-pass",
              styles: "login-modal",
              isOpen: showModal,
              onDidDismiss: () => setShowModal(false),
              children: returnModalChildren(), 
              swipeToClose: true
            }}

          />
          
        </IonGrid>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default Authentication;