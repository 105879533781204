//DEFINE THE ACTIONS TO TRIGGER
export const GET_ABMCLIENTS_LOADING = 'GET_ABMCLIENTS_LOADING';
export const GET_ABMCLIENTS_FAIL = 'GET_ABMCLIENTS_FAIL';
export const GET_ABMCLIENTS_SUCCESS = 'GET_ABMCLIENTS_SUCCESS';


export type ABMClientsType =  {
    service_id: number,
    service_name: string,
    service_display_name: string,
    service_maintenance: boolean,
    service_image: any,
    service_created_by: string,
    service_updated_by: string,
    service_category: string
};


export interface GetABMClientsLoading {
    type: typeof GET_ABMCLIENTS_LOADING
}

export interface GetABMClientsFail {
    type: typeof GET_ABMCLIENTS_FAIL
}

export interface GetABMClientsSuccess {
    type: typeof GET_ABMCLIENTS_SUCCESS,
    payload: ABMClientsType[] | undefined
}

export type GetABMCLIENTSDispatchTypes = GetABMClientsLoading | GetABMClientsFail | GetABMClientsSuccess;
