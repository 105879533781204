import { IonButton, IonCol, IonIcon, IonModal, IonRow, IonText } from "@ionic/react";
import './Modal.css'
import * as icons from 'ionicons/icons';


interface IconIndex {
    [key: string]: any;
}

interface modalChildren {
    id: string;
    styles: string;
    children: React.ReactNode;
    isOpen?: boolean;
    onDidDismiss?: () => void;
    backdropDimiss?: boolean;
    swipeToClose?: boolean;
}

interface modalConfirmation {
    onDidDimissConfirmModal?: () => void;
    onClickConfirmModal?: () => void;
    isOpenModalConfirm?: boolean;
    textConfirmation?: string;
    swipeToClose?: boolean;
}

interface modalPrincipal {
    textPrincipal?: string;
    isOpen?: boolean;
    onDidDismiss?: () => void;
    onClick?: () => void;
    onClickCloseModal?: () => void;
    nameIcon?: string;
    colorIcon?: string;
    swipeToClose?: boolean;
}

interface Props {
    modalPrincipal?: modalPrincipal;
    modalConfirmation?: modalConfirmation;
    modalChildren?: modalChildren;
}

const Modal: React.FC<Props> = (props): JSX.Element => {
    const iconsIndex: IconIndex = icons

    return (
        <>
            <IonModal backdropDismiss={props.modalChildren?.backdropDimiss} swipeToClose={props.modalChildren?.swipeToClose} isOpen={props.modalChildren?.isOpen!} onDidDismiss={props.modalChildren?.onDidDismiss} cssClass={props.modalChildren?.styles} id={props.modalChildren?.id}>
                {props.modalChildren?.children}
            </IonModal>

            <IonModal swipeToClose={props.modalPrincipal?.swipeToClose} isOpen={props.modalPrincipal?.isOpen!} onDidDismiss={props.modalPrincipal?.onDidDismiss} cssClass="modal ion-padding ion-justify-content-center ion-align-items-center">
                <IonRow className="ion-text-center ion-justify-content-center ion-align-items-center modal-container-icon" >
                    <IonIcon icon={iconsIndex[props.modalPrincipal?.nameIcon!]} color={props.modalPrincipal?.colorIcon} className="modal-icon" />
                </IonRow>
                <IonRow className="ion-text-center ion-justify-content-center ion-align-items-center" >
                    <IonCol>
                        <IonText className='modal-text'>
                            {props.modalPrincipal?.textPrincipal}
                        </IonText>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-align-self-center ion-justify-content-center ion-align-items-center modal-container-buttons">
                    <IonCol>
                        <IonButton onClick={props.modalPrincipal?.onClick} fill="clear" className='modal-ion-button'>
                            <IonIcon icon={iconsIndex["checkmarkCircleOutline"]} color="success" className="modal-ion-icon-button" />
                        </IonButton>
                    </IonCol>
                    <IonCol>
                        <IonButton onClick={props.modalPrincipal?.onClickCloseModal} fill="clear" className='modal-ion-button'>
                            <IonIcon icon={iconsIndex["closeCircleOutline"]} color="danger" className="modal-ion-icon-button" />
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonModal>

            <IonModal swipeToClose={props.modalConfirmation?.swipeToClose} isOpen={props.modalConfirmation?.isOpenModalConfirm!} onDidDismiss={props.modalConfirmation?.onDidDimissConfirmModal} cssClass="modal ion-padding ion-justify-content-center ion-align-items-center">
                <IonRow className="ion-text-center modal-container-text-confirmation">
                    <IonCol className="ion-margin-top">
                        <IonText className="modal-text">{props.modalConfirmation?.textConfirmation}</IonText>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-text-center ion-justify-content-center">
                    <IonCol className="ion-align-self-center ion-margin-bottom">
                        <IonButton onClick={props.modalConfirmation?.onClickConfirmModal} fill="clear" className='modal-ion-button'>
                            <IonIcon icon={iconsIndex["checkmarkCircleOutline"]} color="success" className="modal-ion-icon-button" />
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonModal>
        </>
    )


}

export default Modal

