import {GetAlertsDispatchTypes, GET_ALERTS_TWO_HOURS_FAIL, GET_ALERTS_TWO_HOURS_LOADING, GET_ALERTS_TWO_HOURS_SUCCESS, AlertsType} from './alertsActionsTypes'

export interface AlertsTwoHoursDefaultStateI {
    loading:boolean,
    alertsTwoHours?: AlertsType[]
}

const defaultState:AlertsTwoHoursDefaultStateI = {
    loading:false
};


const AlertsTwoHoursReducer = (state: AlertsTwoHoursDefaultStateI = defaultState,action:GetAlertsDispatchTypes):AlertsTwoHoursDefaultStateI =>{
    switch (action.type) {
        case GET_ALERTS_TWO_HOURS_FAIL:
            return {
                loading:false
            }
            break;
        case GET_ALERTS_TWO_HOURS_LOADING:
            return {
                loading:true
            }
            break;
        case GET_ALERTS_TWO_HOURS_SUCCESS:
            return {
                loading:false,
                alertsTwoHours:action.payload
            }
            break;
        default:
            return state
            break;
    }
    return state;
}


export default AlertsTwoHoursReducer;