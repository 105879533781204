//DEFINE THE ACTIONS TO TRIGGER
export const GET_USERSTATISTICSCLIENT_LOADING = 'GET_USERSTATISTICSCLIENT_LOADING';
export const GET_USERSTATISTICSCLIENT_FAIL = 'GET_USERSTATISTICSCLIENT_FAIL';
export const GET_USERSTATISTICSCLIENT_SUCCESS = 'GET_USERSTATISTICSCLIENT_SUCCESS';


export type UserStatisticsClientType =  {
    username: string;
    password: string;
    bank_id: string;
    first_name: string;
    last_name: string;
    phone_number: string;
    email: string;
    email_verified: string;
    created_date: string;
    last_connection?:any
};


export interface GetUserStatisticsClientLoading {
    type: typeof GET_USERSTATISTICSCLIENT_LOADING
}

export interface GetUserStatisticsClientFail {
    type: typeof GET_USERSTATISTICSCLIENT_FAIL
}

export interface GetUserStatisticsClientSuccess {
    type: typeof GET_USERSTATISTICSCLIENT_SUCCESS,
    payload:UserStatisticsClientType
}

export type GetUserStatisticsClientDispatchTypes = GetUserStatisticsClientLoading | GetUserStatisticsClientFail | GetUserStatisticsClientSuccess;