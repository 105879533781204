export const GET_ABMUSERS_LOADING = 'GET_ABMUSERS_LOADING';
export const GET_ABMUSERS_FAIL = 'GET_ABMUSERS_FAIL';
export const GET_ABMUSERS_SUCCESS = 'GET_ABMUSERS_SUCCESS';

export type ABMUsersType = {
    username: string,
    password: string,
    bank_id: string,
    first_name: string,
    last_name: string,
    phone_number: string,
    email: string,
    category:string,
    notifications: any
};

export interface GetABMUsersLoading {
    type: typeof GET_ABMUSERS_LOADING
}

export interface GetABMUsersFail {
    type: typeof GET_ABMUSERS_FAIL
}

export interface GetABMUsersSuccess {
    type: typeof GET_ABMUSERS_SUCCESS,
    payload: ABMUsersType[] | undefined
}

export type GetABMUSERSDispatchTypes = GetABMUsersLoading | GetABMUsersFail | GetABMUsersSuccess;