import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IonCard, IonCol, IonContent, IonGrid, IonIcon, IonPage, IonRow, IonButton, IonText, getPlatforms } from '@ionic/react';
import { personAddSharp, peopleCircle } from 'ionicons/icons';

import { GetABMUsers } from '../../store/ABMUsers/ABMUsersActions';
import { ABMUsersDefaultStateI } from '../../store/ABMUsers/ABMUsersReducer';
import { SessionDefaultStateI } from '../../store/session/SessionReducer';
import { GetClientImage, GetClientMaintenance } from '../../store/mainInfo/mainInfoActions';

import Footer from '../Footer/Footer';
import Header from '../header/Header';
import ABMUsersTable from './ABMUsersTable';
import AddABMUsers from './AddABMUsers';
import ModifyABMUsers from './ModifyABMUsers';
import ListClientsModal from './ListClientsModal'

import './ABMUsers.css';
import Loading from '../Loading/Loading';

interface Data {
    username: string,
    password: string,
    bank_id: string,
    first_name: string,
    last_name: string,
    phone_number: string,
    email: string,
    category: string,
    notifications?: any,
}

interface Props extends StateTypes, dispatchTypes { }

const ABMUsers: React.FC<Props> = (props): JSX.Element => {

    const [showModal, setShowModal] = useState(false);
    const [clientSelected, setClientSelected] = useState('');
    const [users, setUsers] = useState<any>([]);
    const [renderViews, setRenderViews] = useState<any>({
        showTable: true,
        showCreate: false,
        showModify: false
    });

    const [dataToModify, setDataToModify] = useState<Data>({
        username: '',
        password: '',
        bank_id: '',
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        category: '',
    })
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    const [shouldRefresh, setShouldRefresh] = useState(false);
    const desktop = getPlatforms().includes("desktop")

    useEffect(() => {

        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
            setShouldRefresh(true);
        }

        if (desktop) {
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }

    }, []);

    useEffect(() => {
        if (shouldRefresh) {
            setShouldRefresh(false);
            window.location.reload();
        }
    }, [shouldRefresh]);

    useEffect(() => {
        if (props.session.loading === false && props.session.loginData) {
            props.getClients()
            props.getClientsAdmin()
            props.getABMUsers();
            if (!sessionStorage.getItem("clientUsers")) {
                sessionStorage.setItem("clientUsers", '')
            }
            if (sessionStorage.getItem("clientUsers") == '') {
                setShowModal(true)
            }
        }
    }, []);

    useEffect(() => {
        if (sessionStorage.getItem('clientUsers') !== '') {
            setShowModal(false);
        }
    }, [sessionStorage.getItem("clientUsers")])

    useEffect(() => {
        if (props.ABMUsers.loading === false && props.ABMUsers.abmusers) {
            const abmusersData: any = props.ABMUsers.abmusers.filter(user => user.bank_id.toUpperCase() === sessionStorage.getItem('clientUsers'));
            setUsers(abmusersData);
        }
    }, [sessionStorage.getItem('clientUsers'), props.ABMUsers.loading, props.ABMUsers.abmusers])

    const selectClient = (clientName: string) => {
        sessionStorage.setItem("clientUsers", clientName)
        setClientSelected(clientName)
    }

    const showModalFunc = () => {
        setShowModal(true);
    }

    const closeModalFunc = () => {
        setShowModal(false);
    }

    const renderCreate = () => {
        setRenderViews({
            showTable: false,
            showCreate: true,
            showModify: false
        })
    }

    const renderModify = (data: Data) => {
        setRenderViews({
            showTable: false,
            showCreate: false,
            showModify: true
        })
        setDataToModify(data)
    }

    const backToTable = () => {
        setRenderViews({
            showTable: true,
            showCreate: false,
            showModify: false
        })
    }

    return (
        <IonPage>
            <Header headerText='ABM Usuarios' />
            {
                renderViews.showCreate ? <AddABMUsers backToTable={backToTable} client={sessionStorage.getItem('clientUsers')} /> :
                    renderViews.showModify ? <ModifyABMUsers userData={dataToModify} backToTable={backToTable} /> :
                        renderViews.showTable && sessionStorage.getItem('clientUsers') != ''?
                            <IonContent fullscreen>
                                <IonGrid id='ion-content-main' >
                                    <IonCard className="ion-padding ABMUsersTable">
                                        <IonRow className="ion-align-items-center ion-justify-content-between">
                                            <IonCol sizeXl='6' sizeLg='4'>
                                                <IonRow className="ion-align-items-center">
                                                    <IonButton title="Seleccionar cliente" fill="clear" type="button" className="client-icon" onClick={() => showModalFunc()}>
                                                        <IonIcon icon={peopleCircle} className="header-icon-select-client" />
                                                    </IonButton>
                                                    <IonText>
                                                        <h5 className='ABMUsersContainerTitle ion-no-margin'>
                                                            {sessionStorage.getItem("clientUsers")}
                                                        </h5>
                                                    </IonText>
                                                </IonRow>
                                            </IonCol>
                                            <IonCol sizeXl='1' sizeLg='1' sizeMd='1' sizeSm='1.5' sizeXs='3' className="container-add-user">
                                                <IonButton fill='clear' className="client-icon" onClick={renderCreate}>
                                                    <IonIcon icon={personAddSharp} color="secondary" className="ABMUsersHeaderIconAddUser" />
                                                </IonButton>
                                            </IonCol>
                                        </IonRow>
                                        <IonCard className="ABMUsersCardTable">
                                            <IonGrid className="ABMUsersGrid">
                                                <IonRow>
                                                    <IonCol className="ABMUsersCol ABMUsersColTitle" sizeLg="2">
                                                        <IonText>Username</IonText>
                                                    </IonCol>
                                                    <IonCol className="ABMUsersCol ABMUsersColTitle ABMUsersTableMobile-hidden ABMUsersReducedTableMobile-hidden" sizeLg="2">
                                                        <IonText>Nombre</IonText>
                                                    </IonCol>
                                                    <IonCol className="ABMUsersCol ABMUsersColTitle ABMUsersTableMobile-hidden ABMUsersReducedTableMobile-hidden" sizeLg="1.8">
                                                        <IonText>Apellido</IonText>
                                                    </IonCol>
                                                    <IonCol className="ABMUsersCol ABMUsersColTitle ABMUsersTableMobile-hidden ABMUsersReducedTableMobile-hidden hidden-col" sizeLg="2.7">
                                                        <IonText>Email</IonText>
                                                    </IonCol>
                                                    <IonCol className="ABMUsersCol ABMUsersColTitle ABMUsersTableMobile-hidden ABMUsersReducedTableMobile-hidden hidden-col" sizeLg="1.5">
                                                        <IonText>Número</IonText>
                                                    </IonCol>
                                                    <IonCol className="ABMUsersCol ABMUsersColTitle ABMUsersTableMobile-hidden" sizeLg="1">
                                                        <IonText>Modificar</IonText>
                                                    </IonCol>
                                                    <IonCol className="ABMUsersCol ABMUsersColTitle ABMUsersTableMobile-hidden" sizeLg="1">
                                                        <IonText>Eliminar</IonText>
                                                    </IonCol>
                                                </IonRow>
                                                {
                                                    users.map((user: any) => {
                                                        return (
                                                            <ABMUsersTable
                                                                key={user.username}
                                                                userData={user}
                                                                modifyCallback={renderModify} />
                                                        )
                                                    })
                                                }
                                            </IonGrid>
                                        </IonCard>
                                    </IonCard>
                                </IonGrid>
                            </IonContent>
                            : <Loading />
            }
            <ListClientsModal
                parentCallback={selectClient}
                showModal={showModal}
                closeModalCallback={closeModalFunc} />
            <Footer />
        </IonPage>
    );
}

interface StateTypes {
    session: SessionDefaultStateI;
    ABMUsers: ABMUsersDefaultStateI,
}

const mapStateProps = (state: StateTypes) => {
    return {
        ABMUsers: state.ABMUsers,
        session: state.session,
    }
}

interface dispatchTypes {
    getABMUsers: () => void;
    getClientsAdmin: () => void;
    getClients: () => void;
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getABMUsers: () => dispatch(GetABMUsers()),
        getClientsAdmin: () => dispatch(GetClientMaintenance()),
        getClients: () => dispatch(GetClientImage())
    };
}

export default connect(mapStateProps, mapDispatchToProps)(ABMUsers);