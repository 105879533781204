import React, { useState} from 'react';
import { connect } from 'react-redux';
import {
    IonButton, IonCol, IonIcon, IonImg, IonRow, IonSpinner, IonText, IonToggle
} from '@ionic/react';
import { pencil, trash } from 'ionicons/icons';

import { GetABMClients } from '../../store/ABMClients/ABMClientsActions';

import ApiRequestor from '../../api_access/ApiRequestor';

import './ABMClients.css';
import Modal from '../Modal/Modal';

interface Data {
    service_name: string;
    service_display_name: string;
    service_maintenance: boolean;
    service_image: any;
    service_created_by: string;
    service_updated_by: string;
    service_category: string;
}

interface Props extends StateTypes, dispatchTypes {
    serviceData: Data;
    modifyCallback: (data: any) => void
}

const ABMClientsTable: React.FC<Props> = (props): JSX.Element => {

    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalSuccessDelete, setShowModalSuccessDelete] = useState(false)
    const [showSipnner, setShowSpinner] = useState(false)

    const deleteClient = async (id: string) => {
        setShowModalDelete(false);
        setShowSpinner(true)
        let data: any = {
            params: [
                id
            ]
        }
        const response = await ApiRequestor.requestToApi("/swt-admin/clients", "DELETE", data);

        if (response && response.status == 200) {
            setShowSpinner(false)
            setShowModalSuccessDelete(true)
        }
    }

    const deleteClientModal = () => {
        setShowModalSuccessDelete(false);
        props.getABMClients()
    }

    return (
        <IonRow className="ion-align-items-center ion-justify-content-center">
            <IonCol className="ABMClientsCol" sizeLg='1.33'>
                <IonText>
                    {props.serviceData.service_display_name}
                </IonText>
            </IonCol>
            <IonCol className="ABMClientsCol mobile-hidden" sizeLg='1.33'>
                <IonText>
                    {props.serviceData.service_name}
                </IonText>
            </IonCol>
            <IonCol className="ABMClientsCol mobile-hidden" sizeLg='1.33'>
               
                    {
                        (props.serviceData.service_category == 'BANCOS') ?
                         <IonText> NEWPAY </IonText>
                         :
                         (props.serviceData.service_category == 'RETAIL') ?
                         <IonText> PAYWAY </IonText>
                         :
                         <IonText> PRISMA </IonText>
                    }
                
            </IonCol>
            <IonCol className="ABMClientsCol mobile-hidden " sizeLg='1.33'>
                {
                    props.serviceData.service_image !== null &&
                    <IonImg src={`data:${"image/png"};base64,${Buffer.from(props.serviceData.service_image).toString("base64")}`} className="ABMClientsTableImage" />
                }
            </IonCol>
            <IonCol className="ABMClientsCol mobile-hidden" sizeLg='1.33'>
                <IonToggle
                    checked={props.serviceData.service_maintenance}
                    color="secondary"
                    mode="ios"
                    className="ion-color-secondary ABMClientsTableToggle"
                    disabled>
                </IonToggle>
            </IonCol>
            <IonCol className="ABMClientsCol mobile-hidden col-hidden" sizeLg='1.33'>
                <IonText>
                    {props.serviceData.service_created_by}
                </IonText>
            </IonCol>
            <IonCol className="ABMClientsCol mobile-hidden col-hidden" sizeLg='1.33'>
                <IonText>
                    {props.serviceData.service_updated_by != "" ? props.serviceData.service_updated_by : "-"}
                </IonText>
            </IonCol>
            <IonCol className="ABMClientsCol" sizeLg='1.33'>
                <IonButton className="ABMClientsTableButtonContainer" fill="clear" onClick={() => props.modifyCallback(props.serviceData)} id={props.serviceData.service_display_name + "Modificar"}>
                    <IonIcon icon={pencil} color="secondary" className="ABMClientsTableButton" />
                </IonButton>
            </IonCol>
            <IonCol className="ABMClientsCol" sizeLg='1.33'>
                <IonButton className="ABMClientsTableButtonContainer" type="button" fill="clear" onClick={() => setShowModalDelete(true)} id={props.serviceData.service_display_name + "Eliminar"}>
                    <IonIcon icon={trash} color="danger" className="ABMClientsTableButton" />
                </IonButton>
            </IonCol>

            <Modal
                modalPrincipal={
                    {
                        textPrincipal: `¿Deseas eliminar el cliente ${props.serviceData.service_display_name}?`,
                        isOpen: showModalDelete,
                        onDidDismiss: () => setShowModalDelete(false),
                        onClickCloseModal: () => setShowModalDelete(false),
                        onClick: () => deleteClient(props.serviceData.service_name),
                        nameIcon: "warningOutline",
                        colorIcon: "warning"
                    }
                }
                modalConfirmation={
                    {
                        textConfirmation: 'Cliente Eliminado con Éxito',
                        onDidDimissConfirmModal: () => deleteClientModal(),
                        isOpenModalConfirm: showModalSuccessDelete,
                        onClickConfirmModal: () => deleteClientModal(),
                    }
                }
            />

            {showSipnner &&
                <IonCol size="12" className="ion-padding">
                    <IonSpinner color="tertiary" name="lines" style={{ position: "fixed", top: "50%", left: "50%" }} />
                </IonCol>
            }
        </IonRow>
    )
}

//Redux state and actions dispatchers
interface StateTypes {

}

const mapStatetoProps = (state: StateTypes) => {
    return {

    }
};
interface dispatchTypes {
    getABMClients: () => void;
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getABMClients: () => dispatch(GetABMClients())
    };
}

export default connect(mapStatetoProps, mapDispatchToProps)(ABMClientsTable);



