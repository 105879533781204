import ApiRequestor from "../../../api_access/ApiRequestor";
import { Dispatch } from "react";
import { GetInternosCardsDispatchTypes, GET_INTERNOS_CARDS_LOADING, GET_INTERNOS_CARDS_SUCCESS, GET_INTERNOS_CARDS_FAIL} from './internosCardsActionsTypes';

export const GetInternosCards = () => async (dispatch:Dispatch<GetInternosCardsDispatchTypes>) =>{
    try{
        dispatch({
            type:GET_INTERNOS_CARDS_LOADING
        })
        const response: any = await ApiRequestor.requestToApi("/intern/cards","GET");
        dispatch({
            type:GET_INTERNOS_CARDS_SUCCESS,
            payload:response.data.cards
        })
    } catch(error){
        console.log(error);
        dispatch({
            type:GET_INTERNOS_CARDS_FAIL
        })
    }
}