import ApiRequestor from "../../api_access/ApiRequestor";
import { Dispatch } from 'redux';
import { GET_CHANGESTATE_FAIL, GET_CHANGESTATE_LOADING, GET_CHANGESTATE_SUCCESS, GetChangeStateDispatchTypes } from './changeStateActionsTypes';

export const GetChangeState = () => async (dispatch: Dispatch<GetChangeStateDispatchTypes>) => {
    try {
        dispatch({
            type:GET_CHANGESTATE_LOADING
        })
        const response : any = await ApiRequestor.requestToApi("/swt-admin/clients-state", "GET");
        dispatch({
            type: GET_CHANGESTATE_SUCCESS,
            payload: response.data
        })
    } catch(error){
        dispatch({
            type: GET_CHANGESTATE_FAIL
        })
    }
}   