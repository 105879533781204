import { useState, useEffect } from "react";
import { IonIcon } from "@ionic/react";
import { sunny, moon } from "ionicons/icons";

const StyleSwitch = () => {
  const [style, setStyle]: any = useState(null);

  useEffect(() => {
    const userPref: any = localStorage.getItem("styleMode");
    setStyle(userPref);
  }, []);

  const toggleDarkModeHandler = () => {
    window.location.reload();

    //Get user's current preference
    const userPref: any = localStorage.getItem("styleMode");
    const newStyle = userPref === "dark" ? "light" : "dark";
    setStyle(newStyle);
    localStorage.setItem("styleMode", newStyle);
    
    setTimeout(() => {
      document.body.classList.replace(userPref, newStyle);
      hideShow(newStyle);
    }, 1000);
  };

  const hideShow = (style: string) => {
    const isoDark = document.getElementById("isoDark");
    const isoLight = document.getElementById("isoLight");
    const logoDark = document.getElementById("logoDark");
    const logoLight = document.getElementById("logoLight");

    if (isoDark && isoLight && logoDark && logoLight) {
      if (style === "dark") {
        isoLight.style.display = "none";
        logoLight.style.display = "none";
        isoDark.style.display = "block";
        logoDark.style.display = "block";
      } else {
        isoLight.style.display = "block";
        logoLight.style.display = "block";
        isoDark.style.display = "none";
        logoDark.style.display = "none";
      }
    }
  };

  return style === "dark" ? (
    <IonIcon icon={sunny} id="styleIcon" onClick={toggleDarkModeHandler} />
  ) : (
    <IonIcon icon={moon} id="styleIcon" onClick={toggleDarkModeHandler} />
  );
};

export default StyleSwitch;