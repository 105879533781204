import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    IonButton, IonCard, IonCardContent, IonCardHeader, IonContent,
    IonGrid, IonIcon, IonPage, IonRow, IonText, getPlatforms
} from "@ionic/react";
import { peopleCircle } from "ionicons/icons";

import { GetShowUmbrals } from "../../store/showUmbrals/showUmbralsActions";
import { ShowUmbralsDefaultStateI } from "../../store/showUmbrals/showUmbralsReducer";
import { SessionDefaultStateI } from "../../store/session/SessionReducer";
import { GetClientImage } from "../../store/mainInfo/mainInfoActions";

import SwtListClientsModal from "../SwtListClientsModal/SwtListClientsModal";
import Header from "../header/Header";
import Umbrals from './Umbrals';
import Footer from "../Footer/Footer";

import './Umbrals.css';
import Loading from "../Loading/Loading";

interface Props extends StateTypes, dispatchTypes { }

const ShowUmbralsView: React.FC<Props> = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [clientSelected, setClientSelected] = useState('');
    const [categorySelected, setCategorySelected] = useState('');
    const [umbrals, setUmbrals] = useState<any>([]);
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
      });
      const [shouldRefresh, setShouldRefresh] = useState(false);
      const desktop = getPlatforms().includes("desktop")

      useEffect(() => {
    
        function handleResize() {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight
          });
          setShouldRefresh(true);
        }
    
        if(desktop){
          window.addEventListener('resize', handleResize);
          return () => window.removeEventListener('resize', handleResize);
        }
        
      }, []);
    
      useEffect(() => {
        if (shouldRefresh) {
          setShouldRefresh(false);
          window.location.reload();
        }
      }, [shouldRefresh]);

    useEffect(() => {
        if (props.session.loading === false && props.session.loginData) {
            props.getClients();
            props.getShowUmbrals();
            if (!sessionStorage.getItem("client")) {
                sessionStorage.setItem("client", '');
            }
            if (sessionStorage.getItem("client") == '') {
                setShowModal(true);
            }
        }
    }, []);

    useEffect(() => {
        if (sessionStorage.getItem("client") !== '') {
            setShowModal(false)
        }
    }, [sessionStorage.getItem("client"), props.showUmbrals.showUmbrals])

    useEffect(() => {
        if (props.showUmbrals.loading === false && props.showUmbrals.showUmbrals) {
            if (sessionStorage.getItem("category") === "INTERNO") {
                const umbralsData: any = props.showUmbrals.showUmbrals.metricIntern.filter((client: any) => {
                    if (client.service_name === sessionStorage.getItem("client")) {
                        if (client.service_name == "DEBIN") {
                            return !(client.metric_title.includes("COELSA") || client.metric_title.includes("REVERSOS") || client.metric_title.includes("REVERSADAS"))
                        } else {
                            return client.service_name === sessionStorage.getItem("client")
                        }
                    }
                });
                setUmbrals(umbralsData);
            } else {
                const umbralsData: any = props.showUmbrals.showUmbrals.metricKpi.filter(client => client.service_name === sessionStorage.getItem("client"));
                setUmbrals(umbralsData);
            }
        }
    }, [sessionStorage.getItem("client"), props.showUmbrals.loading, props.showUmbrals.showUmbrals])

    const selectClient = (clientName: string, categoryName: string) => {
        sessionStorage.setItem("client", clientName)
        sessionStorage.setItem("category", categoryName)
        setClientSelected(clientName)
        setCategorySelected(categoryName)
    }

    const showModalFunc = () => {
        setShowModal(true)
    }

    const closeModalFunc = () => {
        setShowModal(false)
    }

    return (
        <IonPage>
            <Header headerText='Mostrar Umbrales' />
            {
                umbrals.length > 0 ?
                <IonContent fullscreen>
                    <IonCard className="umbrals-container">
                        <IonCardHeader className="umbrals-card-container-header">
                            <IonButton title="Seleccionar cliente" fill="clear" className="umbrals-header-button" onClick={() => showModalFunc()} >
                                <IonIcon icon={peopleCircle} className="umbrals-icon-select-client" />
                            </IonButton>
                            <IonText>
                                <h5 className="umbrals-container-title">{umbrals.length > 0 && umbrals[0].service_display_name}</h5>
                            </IonText>
                        </IonCardHeader>
                        <IonCardContent>
                            <IonGrid>
                                <IonRow className="grid-umbrals-layout">
                                    {
                                        umbrals.map((client: any) => {
                                            return (
                                                <Umbrals
                                                    key={client.metric_id}
                                                    metric_id={client.metric_id}
                                                    metric_name={client.metric_name}
                                                    metric_threshold={client.metric_threshold}
                                                    service_name={client.service_name}
                                                    service_display_name={client.service_display_name}
                                                    metric_title={client.metric_title}
                                                    service_category={sessionStorage.getItem("category")} />
                                            );
                                        })
                                    }
                                </IonRow>
                            </IonGrid>
                        </IonCardContent>
                    </IonCard>
                    <SwtListClientsModal parentCallback={selectClient} showModal={showModal} closeModalCallback={closeModalFunc} />
                </IonContent>
                : <Loading />
            }
            <Footer />
        </IonPage>
    );
}

//Redux state and actions dispatchers
interface StateTypes {
    session: SessionDefaultStateI;
    showUmbrals: ShowUmbralsDefaultStateI;
}

const mapStateToProps = (state: StateTypes) => {
    return {
        session: state.session,
        showUmbrals: state.showUmbrals,
    };
}

interface dispatchTypes {
    getShowUmbrals: () => void;
    getClients: () => void;
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getShowUmbrals: () => dispatch(GetShowUmbrals()),
        getClients: () => dispatch(GetClientImage()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowUmbralsView);