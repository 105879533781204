import {
    ABMUsersType, 
    GetABMUSERSDispatchTypes, 
    GET_ABMUSERS_FAIL, 
    GET_ABMUSERS_LOADING, 
    GET_ABMUSERS_SUCCESS} 
from './ABMUsersActionTypes';

export interface ABMUsersDefaultStateI {
    loading: boolean,
    abmusers? : ABMUsersType[] | undefined
}

const defaultState : ABMUsersDefaultStateI = {
    loading: false
};

const ABMUsersReducer = (state: ABMUsersDefaultStateI = defaultState, action:GetABMUSERSDispatchTypes): ABMUsersDefaultStateI => {
    switch(action.type) {
        case GET_ABMUSERS_FAIL:
            return {
                loading: false
            }
            break;
        case GET_ABMUSERS_LOADING:
            return {
                loading: true
            }
            break;
        case GET_ABMUSERS_SUCCESS:
            return {
                loading: false,
                abmusers: action.payload
            }
            break
        default:
            return state
            break;
    }
    return state;
}

export default ABMUsersReducer;