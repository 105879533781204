import React, { useState } from 'react';
import { connect } from 'react-redux';
import ApiRequestor from '../../api_access/ApiRequestor';
import { IonButton, IonCol, IonIcon, IonRow, IonSpinner, IonText } from '@ionic/react';
import { checkmarkCircleOutline, closeCircleOutline, pencil, personRemove, trash } from 'ionicons/icons';

import { GetABMUsers } from '../../store/ABMUsers/ABMUsersActions';

import './ABMUsers.css';
import Modal from '../Modal/Modal';

interface Data {
    username: string,
    bank_id: string,
    first_name: string,
    last_name: string,
    phone_number: string,
    email: string,
    notifications?: any,
}

interface Props extends StateTypes, dispatchTypes {
    userData: Data;
    modifyCallback: (data: any) => void
}

const ABMUsersTable: React.FC<Props> = (props): JSX.Element => {

    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalSuccessDelete, setShowModalSuccessDelete] = useState(false)
    const [showSpinner, setShowSpinner] = useState(false)

    const deleteUser = async (username: any) => {
        setShowModalDelete(false);
        setShowSpinner(true)

        const response: any = await ApiRequestor.requestToApi(`/user/${username}`, "DELETE", null, "USER");

        if (response && response.status == 200) {
            setShowSpinner(false);
            setShowModalSuccessDelete(true);
        }
    }

    const deleteUserModal = () => {
        props.getABMUsers()
        setShowModalSuccessDelete(false);
    }

    return (
        <IonRow className="ion-align-items-center ion-justify-content-center ion-justify-content-between ABMUsersRow">
            <IonCol className="ABMUsersCol" sizeLg="2" sizeMd="1.7" sizeSm='1' sizeXs='4'>
                <IonText>
                    {props.userData.username}
                </IonText>
            </IonCol>
            <IonCol className="ABMUsersCol ABMUsersTableMobile-hidden ABMUsersReducedTableMobile-hidden" sizeLg="2" sizeMd="1.7" sizeSm='1'>
                <IonText>
                    {props.userData.first_name}
                </IonText>
            </IonCol>
            <IonCol className="ABMUsersCol ABMUsersTableMobile-hidden ABMUsersReducedTableMobile-hidden" sizeLg="1.8" sizeMd="1.7" sizeSm='1'>
                <IonText>
                    {props.userData.last_name}
                </IonText>
            </IonCol>
            <IonCol className="ABMUsersCol ABMUsersTableMobile-hidden ABMUsersReducedTableMobile-hidden hidden-col" sizeLg="2.7" sizeMd="1.7" sizeSm='1'>
                <IonText>
                    {props.userData.email}
                </IonText>
            </IonCol>
            <IonCol className="ABMUsersCol ABMUsersTableMobile-hidden ABMUsersReducedTableMobile-hidden hidden-col" sizeLg="1.5" sizeMd="1.7" sizeSm='1'>
                <IonText>
                    {props.userData.phone_number}
                </IonText>
            </IonCol>
            <IonCol className="ABMUsersCol" sizeLg="1" sizeMd="1.7" sizeSm='1'>
                <IonButton fill='clear' onClick={() => props.modifyCallback(props.userData)} id={props.userData.username + "Modificar"}>
                    <IonIcon icon={pencil} color="secondary" className="ABMUsersTableButton" />
                </IonButton>
            </IonCol>
            <IonCol className="ABMUsersCol" sizeLg="1" sizeMd="1.7" sizeSm='1'>
                <IonButton type="button" fill="clear" onClick={() => setShowModalDelete(true)} id={props.userData.username + "Eliminar"}>
                    <IonIcon icon={trash} color="danger" className="ABMUsersTableButton" />
                </IonButton>
            </IonCol>


            <Modal
                modalPrincipal={{
                    textPrincipal: `¿Deseas eliminar el usuario ${props.userData.username}?`,
                    isOpen: showModalDelete,
                    onDidDismiss: () => setShowModalDelete(false),
                    onClickCloseModal: () => setShowModalDelete(false),
                    onClick: () => deleteUser(props.userData.username),
                    nameIcon: "personRemove",
                    colorIcon: "danger",
                }}

                modalConfirmation={
                    {
                        textConfirmation: 'Usuario Eliminado con Éxito',
                        onDidDimissConfirmModal: () => deleteUserModal(),
                        isOpenModalConfirm: showModalSuccessDelete,
                        onClickConfirmModal: () => setShowModalSuccessDelete(false),
                    }
                }
            />
            {showSpinner &&
                <IonCol size='12' className="ion-padding">
                    <IonSpinner color="tertiary" name='lines' style={{ position: "fixed", top: "50%", left: "50%" }} />
                </IonCol>
            }
        </IonRow>
    )
}

interface StateTypes {
}

const mapStateProps = (state: StateTypes) => {
    return {}
}

interface dispatchTypes {
    getABMUsers: () => void;
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getABMUsers: () => dispatch(GetABMUsers())
    };
}

export default connect(mapStateProps, mapDispatchToProps)(ABMUsersTable);