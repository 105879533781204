import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { IonCard, IonCardHeader, IonCardSubtitle, IonCol, IonIcon, IonRow, IonText } from "@ionic/react";
import { ellipse } from "ionicons/icons";

import DebinPercentage from "./DebinPercentage";
import InternGraphics from "../Internos/InternGraphics";

import { InternosGraphicsDefaultStateI } from "../../store/Internos/InternosGraphics/internosGraphicsReducer";

import '../Internos/Intern.css';
import './Debin.css'

type GraphicType = {
    intern_id?: string;
    intern_name?: string;
    intern_host?: string;
    intern_type?: string;
    intern_transform?: boolean;
    intern_endpoint_id?: string;
    intern_threshold?: boolean;
    intern_title?: string;
    intern_title_key?: string;
    intern_time_gap?: number;
    intern_linechart_label?: string;
    intern_category?: string;
    intern_graphic?: boolean;
    service_name?: string,
    keys?: [],
    values?: number[],
    valuesReverse?: number[],
    limit_values?: number[]
};

interface Props extends StateTypes, dispatchTypes{
    cardData: any;
    graphicsData: any;
    reversalData: any;
}

const DebinCard: React.FC<Props> = (props): JSX.Element => {

    const [cardGraphicInfo, setCardGraphicInfo] = useState<any>([])
    const [reversal, setReversal] = useState<any>(0)
    const [varUmbralTRX, setVarUmbralTRX] = useState<any>(0)

    const graphicRefactor = (graphicsFilter: any) => {

        const graphicsArray: any[] = [];

        props.graphicsData[0].forEach((element: any) => {

            graphicsFilter.map((graphic: any) => {
                let graphValuesReverse: GraphicType = {};
                if (element.intern_title.includes("REVERSADAS") && graphic.intern_title.includes("APROBADAS") && (graphic.intern_title_key == element.intern_title_key)) {
                    graphValuesReverse.intern_id = graphic.intern_id;
                    graphValuesReverse.intern_title = graphic.intern_title;
                    graphValuesReverse.intern_title_key = graphic.intern_title_key;
                    graphValuesReverse.keys = graphic.keys;
                    graphValuesReverse.intern_type = graphic.intern_type;
                    graphValuesReverse.intern_threshold = graphic.intern_threshold;
                    graphValuesReverse.limit_values = graphic.limit_values;
                    graphValuesReverse.intern_linechart_label = graphic.intern_linechart_label;
                    graphValuesReverse.values = graphic.values;
                    graphValuesReverse.valuesReverse = element.values;
                    graphicsArray.push(graphValuesReverse)
                } else if (element.intern_title.includes("REVERSADAS") && !graphic.intern_title.includes("APROBADAS") && (graphic.intern_title_key == element.intern_title_key)) {
                    graphValuesReverse.intern_id = graphic.intern_id;
                    graphValuesReverse.intern_title = graphic.intern_title;
                    graphValuesReverse.intern_title_key = graphic.intern_title_key;
                    graphValuesReverse.keys = graphic.keys;
                    graphValuesReverse.intern_type = graphic.intern_type;
                    graphValuesReverse.intern_threshold = graphic.intern_threshold;
                    graphValuesReverse.limit_values = graphic.limit_values;
                    graphValuesReverse.intern_linechart_label = graphic.intern_linechart_label;
                    graphValuesReverse.values = graphic.values;
                    graphValuesReverse.valuesReverse = element.values;

                    graphicsArray.push(graphValuesReverse)
                }
            })
        });

        return graphicsArray;
    }

    useEffect(() => {
        if (props.graphicsData.length > 0) {
            let graphicsFilter: any = props.graphicsData[0].filter((graphic: any) => graphic.intern_title_key == props.cardData.titleKey)
            const graphicsModify = graphicRefactor(graphicsFilter)
            setCardGraphicInfo(graphicsModify)
        }
    }, [props.graphicsData])


    useEffect(() => {

        if (cardGraphicInfo && cardGraphicInfo.length > 0) {
            cardGraphicInfo.map((graphic: any) => {
                const umbralTRX = graphic.limit_values[graphic.limit_values.length - 1]
                setVarUmbralTRX(umbralTRX)
            })
        }
    }, [cardGraphicInfo])

    useEffect(() => {
        props.reversalData.map((reversal: any) => {
            if (reversal.titleKey == props.cardData.titleKey || props.cardData.cantKey == reversal.cantKey) {
                setReversal(reversal.cantVal)
            }
        })
    }, [props.reversalData])


    return (
        <IonCard>
            <IonCardHeader className='intern-header'>
                <IonRow>
                        <IonIcon className={(props.cardData.cantAlert !== "0") ? "intern-icon-alerted" : "intern-icon"} icon={ellipse} />
                        <IonText >{props.cardData.titleKey}</IonText>
                </IonRow>
            </IonCardHeader>
            <IonRow className='ion-align-items-center debin-columnas-contenido'>
                <IonCol className="intern-group debin-group-content-percentage" >
                    <IonCardSubtitle className="intern-approved">% Aprobadas</IonCardSubtitle>
                    <DebinPercentage percentageCard={props.cardData.appVal} status={(props.cardData.appAlert !== "0") ? true : false} />
                </IonCol>
                <IonCol className="intern-group debin-group-content" >
                    <IonRow className="ion-align-items-center intern-row-card">
                        <IonCol size="8" className="intern-subtitulos">Total TRX últimos 5'</IonCol>
                        <IonCol size="4" >{props.cardData.cantVal}</IonCol>
                    </IonRow>
                    <IonRow className="ion-align-items-center intern-row-card">
                        <IonCol size="8" className="intern-subtitulos">Total TRX Aprobadas</IonCol>
                        <IonCol size="4">{Math.round((props.cardData.cantVal * props.cardData.appVal) / 100)}</IonCol>
                    </IonRow>
                    <IonRow className="ion-align-items-center intern-row-card">
                        <IonCol size="8" className="intern-subtitulos">Umbral TRX</IonCol>
                        <IonCol size="4">{varUmbralTRX}</IonCol>
                    </IonRow>
                    <IonRow className="ion-align-items-center intern-row-card">
                        <IonCol size="8" className="intern-subtitulos">Reverso</IonCol>
                        <IonCol size="4">{reversal}</IonCol>
                    </IonRow>
                </IonCol>
                {
                    props.graphicsData && props.graphicsData.length > 0 &&
                    cardGraphicInfo.map((graph: any) => {
                        let statusCant = false;
                        let statusPorc = false;
                        if (props.cardData.appKey === graph.intern_title && props.cardData.appAlert !== "0") {
                            if(graph.intern_title.includes("APROBADAS")) statusPorc = true
                        }
                        if (props.cardData.cantKey === graph.intern_title && props.cardData.cantAlert !== "0") {
                            if(!graph.intern_title.includes("APROBADAS")) statusCant = true
                        }
                        if ((graph.intern_title_key == props.cardData.titleKey) && !graph.intern_title.includes("REVERSADAS")) {
                            return (
                                props.internGraphics.internosGraphics &&
                                <IonCol className="debin-graph-content-card" key={graph.intern_id + "debin"} sizeXl="3.5" sizeLg="3.5" sizeMd="6" sizeSm="9" sizeXs="12">
                                    <InternGraphics
                                        key={graph.intern_id}
                                        internData={graph}
                                        id={graph.intern_title_key}
                                        alerted={graph.intern_title.includes("APROBADAS") ? statusPorc : statusCant}
                                        title={window.screen.width >= 1920 && graph.intern_title.includes("APROBADAS") ? "APROBADAS" : window.screen.width >= 1920 && !graph.intern_title.includes("APROBADAS") ? "CANTIDAD" : graph.intern_title}
                                        type={graph.intern_title.includes("APROBADAS") ? "" : "lineReverse"}
                                    />
                                </IonCol>
                            )
                        }
                    })
                }
            </IonRow>
        </IonCard>
    )
}

//Redux state and actions dispatchers
interface StateTypes {
    internGraphics: InternosGraphicsDefaultStateI;
}

const mapStateProps = (state: StateTypes) => {
    return {
        internGraphics: state.internGraphics,
    }
}

interface dispatchTypes {}

const mapDispatchToProps = (dispatch: any) => {
    return {}
}

export default connect(mapStateProps, mapDispatchToProps)(DebinCard);