export const GET_INTERNOS_ALERTS_LOADING = 'GET_INTERNOS_ALERTS_LOADING';
export const GET_INTERNOS_ALERTS_FAIL = 'GET_INTERNOS_ALERTS_FAIL';
export const GET_INTERNOS_ALERTS_SUCCESS = 'GET_INTERNOS_ALERTS_SUCCESS';

export type InternosGraphicInfo = {
    internos_id: string;
    internos_name: string;
    internos_host: string;
    internos_type: string;
    internos_transform: boolean;
    internos_endpoint_id: string;
    internos_threshold: boolean;
    internos_title: string;
    internos_title_key: string;
    internos_time_gap: number;
    internos_linechart_label: string;
    service_name: string;
    date_alert: string;
    timeGap_alert: number,
    keys?:[],
    values?:number[],
    limit_values?:number[]
};

export type InternosAlerts = {
    timestamp: Date;
    name: string;
    value: number;
    graphic: InternosGraphicInfo[];
};

export type InternosAlertsType = {
    bank_id: string;
    alertsInfo: InternosAlerts[];
};


export interface GetInternosAlertsLoading {
    type: typeof GET_INTERNOS_ALERTS_LOADING
}

export interface GetInternosAlertsFail {
    type: typeof GET_INTERNOS_ALERTS_FAIL
}

export interface GetInternosAlertsSuccess {
    type: typeof GET_INTERNOS_ALERTS_SUCCESS,
    payload: InternosAlertsType[] | undefined
}

export type GetInternosAlertsDispatchTypes = GetInternosAlertsLoading | GetInternosAlertsFail | GetInternosAlertsSuccess;