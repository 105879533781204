import {InternosGraphicsType, GetInternosGraphicsDispatchTypes, GET_INTERNOS_GRAPHICS_FAIL, GET_INTERNOS_GRAPHICS_LOADING, GET_INTERNOS_GRAPHICS_SUCCESS} from './internosGraphicsActionsTypes'

export interface InternosGraphicsDefaultStateI{
    loading: boolean,
    internosGraphics? : InternosGraphicsType[] | undefined
}

const defaultState : InternosGraphicsDefaultStateI = {
    loading:false
}

const internosGraphicsReducer = (state: InternosGraphicsDefaultStateI = defaultState, action:GetInternosGraphicsDispatchTypes):
InternosGraphicsDefaultStateI => {
    switch(action.type){
        case GET_INTERNOS_GRAPHICS_FAIL:
            return{
                loading:false
            }
            break;
        case GET_INTERNOS_GRAPHICS_LOADING:
            return{
                loading:true
            }
            break;
        case GET_INTERNOS_GRAPHICS_SUCCESS:
            return{
                loading:false,
                internosGraphics:action.payload
            }
            break;
        default:
            return state
            break;
    }
    return state;
}

export default internosGraphicsReducer;