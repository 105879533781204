import React, { useState } from 'react';
import { connect } from 'react-redux';
import { IonButton, IonCard, IonCardHeader, IonCol, IonContent, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonRow, IonSelect, IonSelectOption, IonText, IonTitle } from '@ionic/react';
import { checkmarkCircleOutline, closeCircleOutline } from 'ionicons/icons';

import { GetABMIntern } from '../../store/ABMMetric/ABMIntern/ABMInternActions';

import ApiRequestor from '../../api_access/ApiRequestor';

import './ABMMetric.css';
import Modal from '../Modal/Modal';

interface InternMetric {
    intern_id: number;
    intern_name: string;
    intern_host: string;
    intern_field: string;
    intern_type: string;
    intern_transform: boolean;
    intern_endpoint_id: string;
    intern_threshold: boolean;
    intern_title: string;
    intern_title_key: string;
    intern_time_gap: number;
    intern_linechart_label: string;
    intern_category: string;
    intern_graphic: boolean;
    intern_created_by: string,
    intern_updated_by: string
}

interface Props extends StateTypes, dispatchTypes {
    internData: InternMetric
    backToTable: () => void;
    service_name: string;
}

const ModifyABMIntern: React.FC<Props> = (props): JSX.Element => {

    const [internDataModify, setInternDataModify] = useState({
        intern_name: props.internData.intern_name,
        intern_host: props.internData.intern_host,
        intern_field: props.internData.intern_field,
        intern_type: props.internData.intern_type,
        intern_transform: props.internData.intern_transform,
        intern_endpoint_id: props.internData.intern_endpoint_id,
        intern_title: props.internData.intern_title,
        intern_title_key: props.internData.intern_title_key,
        intern_time_gap: props.internData.intern_time_gap,
        intern_linechart_label: props.internData.intern_linechart_label,
        intern_graphic: props.internData.intern_graphic,
        intern_category: props.internData.intern_category
    })
    const [showModalAdd, setShowModalAdd] = useState(false);
    const [internInvalid, setInternInvalid] = useState({
        intern_name: false, intern_host: false, intern_field: false, intern_type: false, intern_transform: false, intern_endpoint_id: false,
        intern_title: false, intern_title_key: false, intern_time_gap: false, intern_linechart_label: false, intern_graphic: false, intern_category: false
    })
    const [invalid, setInvalid] = useState(0)

    const saveData = (e: any) => {
        setInternDataModify({
            ...internDataModify,
            [e.target.name]: e.detail.value!
        })
    }

    const validateForm = () => {
        Object.keys(internDataModify).forEach((key) => {

            if ((internDataModify as any)[key].length < 1) {
                (internInvalid as any)[key] = true
                setInvalid(Math.random())
            } else {
                (internInvalid as any)[key] = false
                setInvalid(Math.random())
            }
        })
    }

    const modifyIntern = async () => {

        validateForm()
        if (internDataModify.intern_host.length > 0 && internDataModify.intern_name.length > 0 && internDataModify.intern_endpoint_id.length > 0
            && internDataModify.intern_field.length > 0 && internDataModify.intern_linechart_label.length > 0 && internDataModify.intern_title.length > 0
            && internDataModify.intern_title_key.length > 0 && internDataModify.intern_transform.toString().length > 0 &&
            internDataModify.intern_type.length > 0 && internDataModify.intern_time_gap.toString().length > 0) {

            let data: any = {
                body: {
                    intern_id: props.internData.intern_id,
                    intern_name: internDataModify.intern_name,
                    intern_host: internDataModify.intern_host,
                    intern_field: internDataModify.intern_field,
                    intern_type: internDataModify.intern_type,
                    intern_transform: internDataModify.intern_transform,
                    intern_endpoint_id: internDataModify.intern_endpoint_id,
                    intern_threshold: props.internData.intern_threshold,
                    intern_title: internDataModify.intern_title,
                    intern_title_key: internDataModify.intern_title_key,
                    intern_time_gap: internDataModify.intern_time_gap,
                    intern_linechart_label: internDataModify.intern_linechart_label,
                    intern_category: internDataModify.intern_category,
                    intern_graphic: internDataModify.intern_graphic,
                }
            }

            const response = await ApiRequestor.requestToApi('/swt-admin/intern', 'PUT', data)
            if (response && response.status == 200) {
                setShowModalAdd(true)
            }
        } else {
            validateForm()
        }
    }

    const onDismissModal = () => {
        setShowModalAdd(false)
        props.getSeeABMIntern()
        props.backToTable()
    }

    return (
        <IonContent fullscreen >
            <IonCard className="ABMKpisCardForm">
                <IonCardHeader>
                    <IonTitle className="ABMKpisTitleKpi">MODIFICACIÓN DE MÉTRICA</IonTitle>
                </IonCardHeader>
                <IonGrid className='ABMKpisADD'>
                    <IonRow>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Cliente</IonLabel>
                            <IonItem lines='none'>
                                <IonInput
                                    className="ABMKpisInputKpis"
                                    type="text"
                                    placeholder="Cliente"
                                    value={props.service_name}
                                    disabled />
                            </IonItem>
                        </IonCol>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Nombre</IonLabel>
                            <IonItem lines='none'>
                                <IonInput
                                    className="ABMKpisInputKpis"
                                    type="text"
                                    name='intern_name'
                                    placeholder="Nombre"
                                    value={internDataModify.intern_name}
                                    onIonChange={(e) => saveData(e)} />
                            </IonItem>
                            <IonText>
                                <p className={internInvalid.intern_name ? "login-valid-text" : "ion-hide"}> No puede estar vacío</p>
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Host</IonLabel>
                            <IonItem lines='none'>
                                <IonInput
                                    className="ABMKpisInputKpis"
                                    type="text"
                                    name='intern_host'
                                    placeholder="Host"
                                    value={internDataModify.intern_host}
                                    onIonChange={(e) => saveData(e)} />
                            </IonItem>
                            <IonText>
                                <p className={internInvalid.intern_host ? "login-valid-text" : "ion-hide"}> No puede estar vacío</p>
                            </IonText>
                        </IonCol>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Título</IonLabel>
                            <IonItem lines='none'>
                                <IonInput
                                    className="ABMKpisInputKpis"
                                    type="text"
                                    name='intern_title'
                                    placeholder="Título"
                                    value={internDataModify.intern_title}
                                    onIonChange={(e) => saveData(e)} />
                            </IonItem>
                            <IonText>
                                <p className={internInvalid.intern_title ? "login-valid-text" : "ion-hide"}> No puede estar vacío</p>
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Key Título</IonLabel>
                            <IonItem lines='none'>
                                <IonInput
                                    className="ABMKpisInputKpis"
                                    type="text"
                                    name='intern_title_key'
                                    placeholder="Key Título"
                                    value={internDataModify.intern_title_key}
                                    onIonChange={(e) => saveData(e)} />
                            </IonItem>
                            <IonText>
                                <p className={internInvalid.intern_title_key ? "login-valid-text" : "ion-hide"}> No puede estar vacío</p>
                            </IonText>
                        </IonCol>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Campo</IonLabel>
                            <IonItem lines='none'>
                                <IonInput
                                    className="ABMKpisInputKpis"
                                    type="text"
                                    name='intern_field'
                                    placeholder="Campo"
                                    value={internDataModify.intern_field}
                                    onIonChange={(e) => saveData(e)} />
                            </IonItem>
                            <IonText>
                                <p className={internInvalid.intern_field ? "login-valid-text" : "ion-hide"}> No puede estar vacío</p>
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Type</IonLabel>
                            <IonItem lines='none'>
                                <IonSelect
                                    interface="popover"
                                    placeholder="Seleccione el tipo"
                                    className="ABMKpisSelectKpis"
                                    name='intern_type'
                                    value={internDataModify.intern_type}
                                    onIonChange={(e) => saveData(e)}>
                                    <IonSelectOption value="QUANTITY">
                                        QUANTITY
                                    </IonSelectOption>
                                    <IonSelectOption value="PERCENTAGE">
                                        PERCENTAGE
                                    </IonSelectOption>
                                </IonSelect>
                            </IonItem>
                            <IonText>
                                <p className={internInvalid.intern_type ? "login-valid-text" : "ion-hide"}> Debe seleccionar uno</p>
                            </IonText>
                        </IonCol>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Transform</IonLabel>
                            <IonItem lines='none'>
                                <IonSelect
                                    interface="popover"
                                    placeholder="Seleccione"
                                    className="ABMKpisSelectKpis"
                                    name='intern_transform'
                                    value={internDataModify.intern_transform}
                                    onIonChange={(e) => saveData(e)}>
                                    <IonSelectOption value={true}>
                                        TRUE
                                    </IonSelectOption>
                                    <IonSelectOption value={false}>
                                        FALSE
                                    </IonSelectOption>
                                </IonSelect>
                            </IonItem>
                            <IonText>
                                <p className={internInvalid.intern_transform ? "login-valid-text" : "ion-hide"}>Debe seleccionar uno</p>
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Endpoint</IonLabel>
                            <IonItem lines='none'>
                                <IonSelect
                                    interface="popover"
                                    placeholder="Seleccione"
                                    className="ABMKpisSelectKpis"
                                    name='intern_endpoint_id'
                                    value={internDataModify.intern_endpoint_id}
                                    onIonChange={(e) => saveData(e)}>
                                    <IonSelectOption value="prod">
                                        PROD
                                    </IonSelectOption>
                                    <IonSelectOption value="dev">
                                        DEV
                                    </IonSelectOption>
                                </IonSelect>
                            </IonItem>
                            <IonText>
                                <p className={internInvalid.intern_endpoint_id ? "login-valid-text" : "ion-hide"}> Debe seleccionar uno</p>
                            </IonText>
                        </IonCol>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Tiempo de Recarga(min)</IonLabel>
                            <IonItem lines='none'>
                                <IonInput
                                    className="ABMKpisInputKpis"
                                    type="number"
                                    name='intern_time_gap'
                                    placeholder="Tiempo de Recarga(min)"
                                    value={internDataModify.intern_time_gap}
                                    onIonChange={(e) => saveData(e)} />
                            </IonItem>
                            <IonText>
                                <p className={internInvalid.intern_time_gap ? "login-valid-text" : "ion-hide"}> Debe ser un número</p>
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Linechart Label</IonLabel>
                            <IonItem lines='none'>
                                <IonInput
                                    className="ABMKpisInputKpis"
                                    type="text"
                                    name='intern_linechart_label'
                                    placeholder="Linechart Label"
                                    value={internDataModify.intern_linechart_label}
                                    onIonChange={(e) => saveData(e)} />
                            </IonItem>
                            <IonText>
                                <p className={internInvalid.intern_linechart_label ? "login-valid-text" : "ion-hide"}> No puede estar vacío</p>
                            </IonText>
                        </IonCol>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Gráfico</IonLabel>
                            <IonItem lines='none'>
                                <IonSelect
                                    interface="popover"
                                    placeholder="Seleccione"
                                    className="ABMKpisSelectKpis"
                                    name='intern_graphic'
                                    value={internDataModify.intern_graphic}
                                    onIonChange={(e) => saveData(e)}>
                                    <IonSelectOption value={true}>
                                        TRUE
                                    </IonSelectOption>
                                    <IonSelectOption value={false}>
                                        FALSE
                                    </IonSelectOption>
                                </IonSelect>
                            </IonItem>
                            <IonText>
                                <p className={internInvalid.intern_graphic ? "login-valid-text" : "ion-hide"}>Debe seleccionar uno</p>
                            </IonText>
                        </IonCol>
                    </IonRow>
                    {
                        sessionStorage.getItem("client") == "DECIDIR" &&
                        <IonRow>
                            <IonCol sizeXl="6" sizeXs='12'>
                                <IonLabel className="ABMKpisLabelKpis">Categoría</IonLabel>
                                <IonItem lines='none'>
                                    <IonSelect
                                        interface="popover"
                                        placeholder="Seleccione"
                                        className="ABMKpisSelectKpis"
                                        name='intern_category'
                                        value={internDataModify.intern_category}
                                        onIonChange={(e) => saveData(e)}>
                                        <IonSelectOption value={"GENERAL"}>
                                            GENERAL
                                        </IonSelectOption>
                                        <IonSelectOption value={"MEDIOS DE PAGO"}>
                                            MEDIOS DE PAGO
                                        </IonSelectOption>
                                        <IonSelectOption value={"COMERCIOS"}>
                                            COMERCIOS
                                        </IonSelectOption>
                                    </IonSelect>
                                </IonItem>
                                <IonText>
                                    <p className={internInvalid.intern_category ? "login-valid-text" : "ion-hide"}>Debe seleccionar uno</p>
                                </IonText>
                            </IonCol>
                        </IonRow>
                    }
                    <IonRow className="ion-justify-content-center containerButtonKpis">
                        <IonButton type="submit" fill="clear" className='ABMKpisZoom' onClick={modifyIntern}>
                            <IonIcon icon={checkmarkCircleOutline} color="success" className="ABMKpisButton" />
                        </IonButton>
                        <IonButton fill="clear" className='ABMKpisZoom' onClick={props.backToTable}>
                            <IonIcon icon={closeCircleOutline} color="danger" className="ABMKpisButton" />
                        </IonButton>
                    </IonRow>
                </IonGrid>
            </IonCard>

            <Modal
                modalConfirmation={{
                    textConfirmation: "Métrica Modificada Correctamente",
                    onDidDimissConfirmModal: onDismissModal,
                    isOpenModalConfirm: showModalAdd,
                    onClickConfirmModal: onDismissModal,
                }}
            />

        </IonContent>
    )

}

//Redux state and actions dispatchers

interface StateTypes {
}

const mapStatetoProps = (state: StateTypes) => {
    return {
    }
}

interface dispatchTypes {
    getSeeABMIntern: () => void;
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getSeeABMIntern: () => dispatch(GetABMIntern())
    };
}

export default connect(mapStatetoProps, mapDispatchToProps)(ModifyABMIntern);