import ApiRequestor from "../../api_access/ApiRequestor";
import {Dispatch} from "redux"
import{GetLastMessagesDispatchTypes, GET_LAST_MESSAGES_FAIL, GET_LAST_MESSAGES_LOADING, GET_LAST_MESSAGES_SUCCESS} from './lastMessagesActionsTypes'



export const GetLastMessagesPrisma = () => async (dispatch: Dispatch<GetLastMessagesDispatchTypes>) => {
    try {
        dispatch({
            type: GET_LAST_MESSAGES_LOADING
        })
        const response:any = await ApiRequestor.requestToApi("/main/last-messages", "GET")
        dispatch({
            type: GET_LAST_MESSAGES_SUCCESS,
            payload: response.data
        })
     
      
    }
    catch(error){
        dispatch({
            type: GET_LAST_MESSAGES_FAIL
        })
    }
}
