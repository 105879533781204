import { IonContent, IonIcon, IonImg, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle } from '@ionic/react';
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { iconsMenu } from './IconData';
import { KpiGroupsMenuDefaultStateI } from '../../store/mainInfo/kpiGroupsForMenuReducer';
import { GetKpiGroupsMenu } from '../../store/mainInfo/mainInfoActions';
import { SessionDefaultStateI } from '../../store/session/SessionReducer';
import { useLocation } from 'react-router-dom';
 
import prismaIsoDark from '../../assets/prismaIsoDark.png';
import prismaIso from '../../assets/prismaIso.png';
import prismaLogo from '../../assets/prismaLogo.png';
import prismaLogoDark from '../../assets/prismaLogoDark.png';
import newpayIso from '../../assets/newpayIconoColor.png';
import newpayIsoDark from '../../assets/newpayIconoDark.png';
import newpayLogo from '../../assets/newpayLogoColor.png';
import newpayLogoDark from '../../assets/newpayLogoDark.png';
import paywayIso from '../../assets/paywayIconoColor.png';
import paywayIsoDark from '../../assets/paywayIconoDark.png';
import paywayLogo from '../../assets/paywayLogoColor.png';
import paywayLogoDark from '../../assets/paywayLogoDark.png';
 
import './Menu.css';
 
interface Props extends StateTypes, dispatchTypes { }
 
const Menu: React.FC<Props> = (props): JSX.Element => {
  const location = useLocation();
  const [menuData, setMenuData] = useState<any>([]);
  const [imagen, setImagen] = useState<string>('');
  const [imagenIso, setImagenIso] = useState<string>('');
  const lastCategory = useRef<string | null>(null);
  const lastStyle = useRef<string | null>(null);
 
  const updateImages = () => {
    const styleSelected = localStorage.getItem("styleMode");
    const category = sessionStorage.getItem("category");
 
    if (category !== lastCategory.current || styleSelected !== lastStyle.current) {
      if (category === 'BANCOS') {
        setImagenIso(styleSelected === 'dark' ? newpayIsoDark : newpayIso);
        setImagen(styleSelected === 'dark' ? newpayLogoDark : newpayLogo);
      } else if (category === 'RETAIL') {
        setImagenIso(styleSelected === 'dark' ? paywayIsoDark : paywayIso);
        setImagen(styleSelected === 'dark' ? paywayLogoDark : paywayLogo);
      } else {
        setImagenIso(styleSelected === 'dark' ? prismaIsoDark : prismaIso);
        setImagen(styleSelected === 'dark' ? prismaLogoDark : prismaLogo);
      }
 
      lastCategory.current = category;
      lastStyle.current = styleSelected;
    }
  };
 
  useEffect(() => {
    updateImages(); 
    const intervalId = setInterval(updateImages, 1000); 
    return () => clearInterval(intervalId); 
  }, []);
 
  useEffect(() => {
    if (props.session.loading === false && props.session.loginData) {
      props.getMenuInfo();
    }
  }, [props.session.loading, props.session.loginData]);
 
  useEffect(() => {
    if (props.kpiGroupsMenu.loading === false && props.kpiGroupsMenu.kpiGroupsMenu) {
      const updatedMenuData = props.kpiGroupsMenu.kpiGroupsMenu.map((info: any) => {
        const iconsFilter = iconsMenu.icons.find((icon: any) => icon.title === info.menuinfo_name);
        return {
          ...info,
          iosIcon: iconsFilter?.iosIcon,
          mdIcon: iconsFilter?.mdIcon
        };
      });
      setMenuData(updatedMenuData);
    }
  }, [props.kpiGroupsMenu.loading, props.kpiGroupsMenu.kpiGroupsMenu]);
 
  const renderMenuItems = () => {
    return (
<IonList id="inbox-list">
<IonListHeader className="menu-title-small">
<IonImg id="imagenIso" className="menu-image" src={imagenIso} />
</IonListHeader>
<IonListHeader className="menu-title-large">
<IonImg id="imagenLogo" className="menu-image" src={imagen} />
</IonListHeader>
<IonMenuToggle autoHide={false} className="menu-toggle">
          {menuData.map((menuItem: any, index: number) => {
            const path = location.pathname.replace(/\/$/, '');
            return (
<IonItem 
                key={index} 
                id={`menu-item-${index}`} 
                className={path === menuItem.menuinfo_url ? 'selected' : ''} 
                routerLink={menuItem.menuinfo_url} 
                routerDirection="none" 
                lines="none" 
                detail={false}
>
<IonIcon className="menu-icon" slot="start" ios={menuItem.iosIcon} md={menuItem.mdIcon} />
<IonLabel className="ionLabelMenu">{menuItem.menuinfo_title}</IonLabel>
</IonItem>
            );
          })}
</IonMenuToggle>
</IonList>
    );
  };
 
  return (
<IonMenu 
      menuId="main-menu" 
      contentId="main" 
      className={window.screen.width < 992 ? "menu-menu" : "meu-menu split-pane-side menu-pane-visible"} 
      type="overlay"
>
<IonContent className="menu-content">
        {renderMenuItems()}
</IonContent>
</IonMenu>
  );
};
 
interface StateTypes {
  session: SessionDefaultStateI,
  kpiGroupsMenu: KpiGroupsMenuDefaultStateI
}
 
const mapStateToProps = (state: StateTypes) => ({
  session: state.session,
  kpiGroupsMenu: state.kpiGroupsMenu
});
 
interface dispatchTypes {
  getMenuInfo: () => void
}
 
const mapDispatchToProps = (dispatch: any) => ({
  getMenuInfo: () => dispatch(GetKpiGroupsMenu()),
});
 
export default connect(mapStateToProps, mapDispatchToProps)(Menu);