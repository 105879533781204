import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonPage, IonRow, IonText, getPlatforms } from "@ionic/react";

import { SessionDefaultStateI } from "../../store/session/SessionReducer";
import { AlertsTwoHoursDefaultStateI } from "../../store/alerts/alertsTwoHoursReducer";
import { GetTwoHoursAlerts } from "../../store/alerts/alertsActions";
import { GetCards, GetClientImage } from "../../store/mainInfo/mainInfoActions";
import { AlertsType } from "../../store/alerts/alertsActionsTypes";
import { ClientMaintenanceDefaultStateI } from "../../store/mainInfo/clientMaintenanceReducer";
import { GetInternosAlerts } from "../../store/Internos/InternosAlerts/internosAlertsActions";
import { InternosAlertsDefaultStateI } from "../../store/Internos/InternosAlerts/internosAlertsReducer";

import Header from "../header/Header";
import LineChart from "../LineChart/LineChart";
import Cards from "../Card/Cards";
import SwtListClientsModal from "../SwtListClientsModal/SwtListClientsModal";
import MaintenanceComponent from "../Maintenance/MaintenanceComponent";
import Footer from "../Footer/Footer";
import InternGraphics from "../Internos/InternGraphics";

import "./Reports.css";
import Loading from "../Loading/Loading";
import { CardsDefaultStateI } from "../../store/mainInfo/cardsReducer";

interface Props extends dispatchTypes, StateTypes { }

const Reports: React.FC<Props> = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [clientSelected, setClientSelected] = useState('')
  const [categorySelected, setCategorySelected] = useState('');
  const [categoryClientSelected, setCategoryClientSelected] = useState<any>();
  const [reportsInfo, setReportsInfo] = useState<any>([])
  const [maintenance, setMaintenance] = useState(false)
  const bank_id = props.session.loginData.getIdToken().decodePayload()["custom:bank_id"].toUpperCase()
  const bank_id_category = props.session.loginData.getIdToken().decodePayload()["custom:category"]
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const desktop = getPlatforms().includes("desktop")

  useEffect(() => {

    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
      setShouldRefresh(true);
    }

    if(desktop){
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
    
  }, []);
  
  useEffect(() => {
    if (shouldRefresh) {
      setShouldRefresh(false);
      window.location.reload();
    }
  }, [shouldRefresh]);

  useEffect(() => {
    if (!sessionStorage.getItem("client")) {
      sessionStorage.setItem("client", '')
    }
    if (bank_id_category !== 'INTERNO') {
      props.getTwoHoursAlerts();
    }
    if (bank_id_category == "ADMIN") {
      props.getClients()
      if (sessionStorage.getItem("client") == '') {
        setShowModal(true)
      }
    }
    if (!props.cards.cards){
      props.getCards()
    }
  }, []);


  useEffect(() => {
    if (sessionStorage.getItem("client") !== '') {
      setShowModal(false)
    }
  }, [sessionStorage.getItem("client"), props.alertsTwoHours.alertsTwoHours, props.internAlerts.internosAlerts, props.internAlerts.internosAlerts, props.internAlerts.internosAlerts])

  useEffect(() => {
    if (bank_id_category !== 'INTERNO' && sessionStorage.getItem("category") !== 'INTERNO') {
      let reportsData: any = []
      if (bank_id_category !== 'ADMIN') {
        if (props.alertsTwoHours.loading == false && props.alertsTwoHours.alertsTwoHours && props.alertsTwoHours.alertsTwoHours.length > 0) {
          reportsData = props.alertsTwoHours.alertsTwoHours[0].alertsInfo
        }
      } else {
        if (props.alertsTwoHours.loading == false && props.alertsTwoHours.alertsTwoHours && props.alertsTwoHours.alertsTwoHours.length > 0 && sessionStorage.getItem("client") && sessionStorage.getItem("client") !== '') {
          let reports = props.alertsTwoHours.alertsTwoHours.filter((alert: AlertsType) => alert.bank_id == sessionStorage.getItem("client"))
          reportsData = reports[0].alertsInfo
        }
      }
      setReportsInfo(reportsData)
    }
  }, [sessionStorage.getItem("client"), props.alertsTwoHours.alertsTwoHours, props.alertsTwoHours.loading])

  useEffect(() => {
    if (bank_id_category == 'INTERNO' || sessionStorage.getItem("category") == 'INTERNO') {
      let reportsData: any = []
      if (bank_id_category !== 'ADMIN') {
        if (props.internAlerts.loading == false && props.internAlerts.internosAlerts && props.internAlerts.internosAlerts.length > 0) {
          const filterName = props.internAlerts.internosAlerts[0].alertsInfo.filter((alert: any) => !alert.name.includes("REVERSO") && !alert.name.includes("MINI"))
          reportsData = filterName
        }
      } else {
        if (props.internAlerts.loading == false && props.internAlerts.internosAlerts && props.internAlerts.internosAlerts.length > 0 && sessionStorage.getItem("client") && sessionStorage.getItem("client") !== '') {
          let reports = props.internAlerts.internosAlerts.filter((alert: any) => alert.bank_id == sessionStorage.getItem("client"))
          const filterName = reports[0].alertsInfo.filter((alert: any) => !alert.name.includes("REVERSO") && !alert.name.includes("MINI"))
          reportsData = filterName
        }
      }
      setReportsInfo(reportsData)
    }
  }, [sessionStorage.getItem("client"), props.internAlerts.internosAlerts, props.internAlerts.loading])

  useEffect(() => {
    if (bank_id_category !== 'ADMIN') {
      if (props.clientMaintenance.loading == false && props.clientMaintenance.clientMaintenance &&
        props.clientMaintenance.clientMaintenance[0].service_maintenance) {
        setMaintenance(true)
      }
    } else {
      if (props.clientMaintenance.loading == false && props.clientMaintenance.clientMaintenance && sessionStorage.getItem("client") && sessionStorage.getItem("client") != '') {
        const maintenance = props.clientMaintenance.clientMaintenance.filter(client => client.service_name == sessionStorage.getItem("client"))[0].service_maintenance
        if (maintenance) {
          setMaintenance(true)
        } else {
          setMaintenance(false)
        }
      }
    }
  }, [props.clientMaintenance.loading, props.clientMaintenance.clientMaintenance, sessionStorage.getItem("client")])

  useEffect(() => {
    if (sessionStorage.getItem("category") !== null) {
      const categoryClient = sessionStorage.getItem("category")
      setCategoryClientSelected(categoryClient)
    }
  }, [sessionStorage.getItem("category")])

  if (maintenance && bank_id_category !== 'ADMIN') {
    return <Redirect to="/page/maintenance" />
  }

  const showModalFunc = () => {
    setShowModal(true)
  }

  const closeModalFunc = () => {
    setShowModal(false)
  }

  const selectClient = (clientName: string, categoryName: string) => {
    sessionStorage.setItem("client", clientName)
    sessionStorage.setItem("category", categoryName)
    setClientSelected(clientName)
    setCategorySelected(categoryName)
  }

  const switchClass = (id: string) => {
    const el = document.getElementById(id);
    if (el?.classList.contains("alert-card-content-active")) {
      el?.classList.replace("alert-card-content-active", "alert-card-content");
    } else {
      el?.classList.replace("alert-card-content", "alert-card-content-active");
    }
  };

  const renderAlerts = () => {
    return (
      <IonCard className="main-reports-card">
        <IonCardHeader className="reports-list-header">
          <IonCardTitle className="reports-list-title">
            Alertas de las últimas 2 horas
          </IonCardTitle>
        </IonCardHeader>
        {reportsInfo.length > 0 ? (
          <IonCardContent>
            <IonRow >
              {reportsInfo.map(
                (each: any, index: any) => (
                  <IonCol key={`${each.timestamp}.${each.name}`} sizeLg="4" sizeMd="6" sizeSm="12" sizeXs="12" >
                    <IonCard id={`card${index}`} className="report-card">
                      <IonCardContent id={`alertc${index}${each.name}`} className="alert-card-content" onClick={() => { switchClass(`alertc${index}${each.name}`); }} >
                        <div className="alert-timestamp">{each.timestamp}</div>
                        <div className="alert-name" id={`alert${index}`}>{each.name}</div>
                        {
                          bank_id == 'POS' || sessionStorage.getItem("client") == 'POS' ?
                            <InternGraphics
                              internData={each.graphic}
                              alerted={true}
                              graphId={`alert${index}${each.name}`} 
                              type={'reports'}/>
                            :
                            bank_id == 'DECIDIR' || sessionStorage.getItem("client") == 'DECIDIR' ?
                              <InternGraphics
                                internData={each.graphic}
                                alerted={true}
                                graphId={`alert${index}${each.name}`} 
                                type={'reports'}/>
                              :
                              bank_id == 'RESOLUTORES' || sessionStorage.getItem("client") == 'RESOLUTORES' ?
                                <InternGraphics
                                  internData={each.graphic}
                                  alerted={true}
                                  graphId={`alert${index}${each.name}`} 
                                  type={'reports'}/>
                                :
                                bank_id == 'DEBIN' || sessionStorage.getItem("client") == 'DEBIN' ?
                                  <InternGraphics
                                    internData={each.graphic}
                                    alerted={true}
                                    graphId={`alert${index}${each.name}`}
                                    type={'reports'} />
                                  :
                                  <LineChart
                                    chartData={each.graphic}
                                    alerted={true}
                                    graphId={`alert${index}${each.name}`}
                                    type={'reports'} />
                        }
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                )
              )}
            </IonRow>
          </IonCardContent>
        ) : (
          <h5 className="ion-text-center">No hay alertas para mostrar</h5>
        )}
      </IonCard>
    );
  };
  return (
    <IonPage>
      <Header headerText="Reportes" timer parentCallback={(bank_id_category == "ADMIN") && showModalFunc} />
      {
        props.internAlerts.loading == false || props.alertsTwoHours.loading == false ?
          <IonContent fullscreen>
            <IonGrid>
              {(bank_id_category == "ADMIN") && window.screen.width < 768 &&
                <IonRow>
                  <IonCol className="ion-no-padding" onClick={() => showModalFunc()}>
                    <IonCard className="ion-no-margin">
                      <IonText><h5 className="ion-text-center ion-no-margin ion-padding">Seleccionar cliente</h5></IonText>
                    </IonCard>
                  </IonCol>
                </IonRow>
              }
              {maintenance && (bank_id_category == "ADMIN") ?
                <MaintenanceComponent />
                :
                <IonCol>
                  {
                    bank_id_category !== "INTERNO" && categoryClientSelected !== "INTERNO" &&
                    <IonRow>
                      <Cards collapsed clientSelected={sessionStorage.getItem("client")} />
                    </IonRow>
                  }
                  <IonRow>{renderAlerts()}</IonRow>
                </IonCol>
              }
            </IonGrid>
            <SwtListClientsModal parentCallback={selectClient} showModal={showModal} closeModalCallback={closeModalFunc} />
          </IonContent>
          :
          <Loading />
      }
      <Footer />
    </IonPage>
  );
};

//Redux state and actions dispatchers

interface StateTypes {
  alertsTwoHours: AlertsTwoHoursDefaultStateI;
  session: SessionDefaultStateI;
  cards: CardsDefaultStateI;
  clientMaintenance: ClientMaintenanceDefaultStateI;
  internAlerts: InternosAlertsDefaultStateI;
}

const mapStateToProps = (state: StateTypes) => {
  return {
    alertsTwoHours: state.alertsTwoHours,
    session: state.session,
    cards: state.cards,
    clientMaintenance: state.clientMaintenance,
    internAlerts: state.internAlerts
  };
};

interface dispatchTypes {
  getTwoHoursAlerts: () => void;
  getClients: () => void;
  getCards: () => void;
  getInternAlerts: () => void;

}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getTwoHoursAlerts: () => dispatch(GetTwoHoursAlerts()),
    getClients: () => dispatch(GetClientImage()),
    getCards: () => dispatch(GetCards()),
    getInternAlerts: () => dispatch(GetInternosAlerts())

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);