//DEFINE THE ACTIONS TO TRIGGER
export const GET_ABMINTERN_LOADING = 'GET_ABMINTERN_LOADING';
export const GET_ABMINTERN_FAIL = 'GET_ABMINTERN_FAIL';
export const GET_ABMINTERN_SUCCESS = 'GET_ABMINTERN_SUCCESS';


export type ABMInternType =  {
    intern_id: number;
    intern_name: string;
    intern_host: string;
    intern_field: string;
    intern_type: string;
    intern_transform: boolean;
    intern_endpoint_id: string;
    intern_threshold: boolean;
    intern_title: string;
    intern_title_key: string;
    intern_time_gap: number;
    intern_linechart_label: string;
    intern_category: string;
    intern_graphic: boolean;
    intern_created_by: string,
    intern_updated_by: string,
    service_name: string;
};

export interface GetABMInternLoading {
    type: typeof GET_ABMINTERN_LOADING
}

export interface GetABMInternFail {
    type: typeof GET_ABMINTERN_FAIL
}

export interface GetABMInternSuccess {
    type: typeof GET_ABMINTERN_SUCCESS,
    payload: ABMInternType[] | undefined
}

export type GetABMINTERNDispatchTypes = GetABMInternLoading | GetABMInternFail | GetABMInternSuccess;