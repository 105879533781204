import {GetUserStatisticsCardDispatchTypes, GET_USERSTATISTICSCARD_FAIL, GET_USERSTATISTICSCARD_LOADING, GET_USERSTATISTICSCARD_SUCCESS, UserStatisticsCardRow } from "./userStatisticsCardsActionsTypes";



export interface UserStatisticsCardDefaultStateI {
    loading: boolean,
    userStatisticsCard?: UserStatisticsCardRow

}


const defaultState: UserStatisticsCardDefaultStateI = {
    loading: false
}


const userStatisticsCardReducer = (state: UserStatisticsCardDefaultStateI = defaultState, action: GetUserStatisticsCardDispatchTypes):

    UserStatisticsCardDefaultStateI => {

    switch (action.type) {

        case GET_USERSTATISTICSCARD_FAIL:
            return {

                loading: false
            }
            break;

        case GET_USERSTATISTICSCARD_LOADING:
            return {

                loading: true
            }
            break;

        case GET_USERSTATISTICSCARD_SUCCESS:
            return {
                loading: false,
                userStatisticsCard: action.payload
            }
            break;

        default:
            return state
            break;
    }

    return state;

}



export default userStatisticsCardReducer;