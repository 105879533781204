import ApiRequestor from "../../../api_access/ApiRequestor";
import {Dispatch} from "redux"
import { GetABMINTERNDispatchTypes, GET_ABMINTERN_LOADING, GET_ABMINTERN_SUCCESS, GET_ABMINTERN_FAIL } from './ABMInternActionsTypes';


export const GetABMIntern = () => async (dispatch: Dispatch<GetABMINTERNDispatchTypes>) => {
    try {
        dispatch({
            type: GET_ABMINTERN_LOADING
        })
        const response : any = await ApiRequestor.requestToApi("/swt-admin/intern", "GET");
        dispatch({
            type: GET_ABMINTERN_SUCCESS,
            payload: response.data
        })
    } catch(error) {
        console.log(error);
        dispatch({
            type: GET_ABMINTERN_FAIL
        })
    }
   
}
