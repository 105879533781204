// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.

import { Redirect, Route } from 'react-router-dom';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { SessionDefaultStateI } from '../../store/session/SessionReducer';
import { DoLogin, GetLoggedIn } from '../../store/session/SessionActions';

interface Props extends StateTypes, dispatchTypes {
  path: string,
  component: any,
}

const PrivateRoute: React.FC<Props> = (props): JSX.Element => {
  useEffect(() => {
    if (props.session.loggedIn === false) {
      props.GetLoggedIn()
    }
  }, [])

  return (
    <Route path={props.path} exact={true}>
      {props.session.loggedIn ? <props.component /> : <Redirect to="/page/login" />}
    </Route>
  )
}

//Redux state and actions dispatchers

interface StateTypes {
  session: SessionDefaultStateI
}

const mapStateToProps = (state: StateTypes) => {
  return {
    session: state.session
  };
};

interface dispatchTypes {
  doLogin: (username: string, password: string) => void,
  GetLoggedIn: () => void
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    doLogin: (username: string, password: string) => dispatch(DoLogin(username, password)),
    GetLoggedIn: () => dispatch(GetLoggedIn())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)