import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import {
  IonButton, IonIcon, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonInput, IonItem,
  IonList, IonPage, IonRouterLink, IonRow, IonTitle, IonCardHeader, IonCardTitle, IonImg,
  IonCardSubtitle, IonText, getPlatforms
} from '@ionic/react';
import { arrowBackOutline } from 'ionicons/icons';

import { SessionDefaultStateI } from '../../store/session/SessionReducer';
import { setStyleMode } from '../../utils/StyleUtils';

import ApiRequestor from '../../api_access/ApiRequestor';
import prismaLogo from '../../assets/prismaLogo.png';
import prismaLogoDark from '../../assets/prismaLogoDark.png';
import Footer from '../Footer/Footer';

import './LoginOverview.css';
import Modal from '../Modal/Modal';

interface Props extends StateTypes { }

const ForgotPass: React.FC<Props> = (props): JSX.Element => {
  const [usernameData, setUsernameData] = useState('')
  const [username_invalid, setUsername_invalid] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [textModal, setTextModal] = useState("")
  const [redirectData, setRedirectData] = useState(false)
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const desktop = getPlatforms().includes("desktop")

  useEffect(() => {

    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
      setShouldRefresh(true);
    }

    if(desktop){
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
    
  }, []);

  useEffect(() => {
    if (shouldRefresh) {
      setShouldRefresh(false);
      window.location.reload();
    }
  }, [shouldRefresh]);

  useEffect(() => {
    setStyleMode()
  }, [])

  useEffect(() => {
    const enterKey = (e: any) => {
      if (e.key == "Enter") {
        setShowModal(false)
      }
    }
    const modalForgotPass = document.getElementById("modal-forgot-pass")
    modalForgotPass?.addEventListener('keypress', enterKey)
  }, [showModal])

  if (props.session.loggedIn) {
    return <Redirect to="/page/kpigroup/services" />
  }

  const handleKey = (e: any) => {
    if (e.key == "Enter") {
      onSubmit()
    }
  }

  const returnModalChildren = (): JSX.Element => {
    return (
      <>
        <IonText>
          <p className="ion-padding ion-text-center login-modal-text"> {textModal}</p>
        </IonText>
        <IonRow className="ion-text-center ion-padding-bottom">
          <IonCol size-lg="12" size-md="12">
            <IonButton className="login-button" fill="outline" size="small" onClick={() => { onSuccess() }}>Aceptar</IonButton>
          </IonCol>
        </IonRow>
      </>
    )
  }

  const onSubmit = async () => {
    if (validForm()) {
      const data: any = { body: { username: usernameData } }
      const response: any = await ApiRequestor.authRequestToUser('/auth/forgot-pass', "POST", data, "USER")
      if (!response.data.message) {
        setTextModal("Se ha enviado un mail a su dirección de correo electrónico registrada con el código necesario para realizar el cambio de contraseña")
        setShowModal(true)
      } else {
        if (response.data.message.includes("Username/client id combination not found.")) {
          setTextModal("No se encontro un usuario registrado con ese nombre")
          setShowModal(true)
        } else {
          if (response.data.message.includes("Attempt limit exceeded, please try after some time.")) {
            setTextModal("No se pudo enviar la información, se alcanzó el número máximo de intentos. Por favor intente nuevamente en otro momento.")
            setShowModal(true)
          } else {
            setTextModal(response.data.message)
            setShowModal(true)
          }
        }
      }
    } else {
      validUsername()
    }
  }

  const onSuccess = () => {
    if (textModal.includes("correo electrónico")) {
      setShowModal(false)
      localStorage.setItem("username", usernameData)
      setRedirectData(true)
    }
    setShowModal(false)
  }

  const validUsername = () => {
    if (usernameData.length <= 0) {
      setUsername_invalid(true)
    } else {
      setUsername_invalid(false)
    }
  }

  const validForm = () => {
    let valid = false
    if (username_invalid == false && usernameData.length > 0) {
      valid = true
    }
    return valid
  }

  if (redirectData == true) {
    return <Redirect to="/page/reset-pass" />
  }

  return (
    <IonPage id="loginPage">
      <IonContent className="ion-text-center">
        <IonGrid className="ion-no-padding">
          <IonRow>
            <IonCol size="12" className="colLogin colLoginForgot ion-padding-bottom">
              <IonRouterLink href="/page/login">
                <IonIcon icon={arrowBackOutline} className="login-back-arrow" />
              </IonRouterLink>
              <IonTitle className="ion-padding ion-text-center login-title">
                <div className="ion-text-wrap ion-padding-bottom"> MONITOREO DE SERVICIOS</div>
              </IonTitle>
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center login-row">
            <IonCard className="cardLogin ion-text-center">
              <IonCardHeader className="">
                <IonImg id="logoDark" className="login-image" src={prismaLogoDark} />
                <IonImg id="logoLight" className="login-image" src={prismaLogo} />
                <IonImg id="isoDark" className="ion-hide" />
                <IonImg id="isoLight" className="ion-hide" />
                <IonCardTitle className="login-card-title">CAMBIO DE CONTRASEÑA</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                <IonList className="">
                  <IonRow className="ion-justify-content-center">
                    <IonCol size-xl="8" size-lg="8" size-md="8" size-sm="12" size-xs="12">
                      <IonCardSubtitle className="ion-text-start ion-margin-start">Nombre de usuario</IonCardSubtitle>
                      <IonItem className="login-item" lines="none">
                        <IonInput className="inputLogin"
                          type='text'
                          name="username"
                          autocomplete="new-password"
                          placeholder="user_ejemplo"
                          required
                          value={usernameData}
                          onIonChange={event => { validUsername(); setUsernameData(event.detail.value!) }}
                          onKeyPress={handleKey} />
                      </IonItem>
                      <IonText>
                        <p className={username_invalid ? "login-valid-text" : "ion-hide"}> El nombre de usuario está vacío</p>
                      </IonText>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="12" className="ion-padding">
                      <IonButton className="ion-margin-top login-button" fill="outline" onClick={onSubmit}>Enviar</IonButton>
                    </IonCol>
                  </IonRow>
                </IonList>
              </IonCardContent>
            </IonCard>
          </IonRow>


          <Modal
            modalChildren={{
              id: "modal-forgot-pass",
              styles: "login-modal",
              isOpen: showModal,
              onDidDismiss: () => setShowModal(false),
              children: returnModalChildren(),
            }}

          />

        </IonGrid>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

//Redux state and actions dispatchers

interface StateTypes {
  session: SessionDefaultStateI
}

const mapStateToProps = (state: StateTypes) => {
  return {
    session: state.session
  };
};

export default connect(mapStateToProps)(ForgotPass);