import React, { useEffect } from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { IonPage } from '@ionic/react';

import { DoLogout } from '../../store/session/SessionActions';
import { SessionDefaultStateI } from '../../store/session/SessionReducer';

interface Props extends StateTypes, dispatchTypes { }

const LogoutOverview: React.FC<Props> = (props): JSX.Element => {

  useEffect(() => {
    props.doLogout()
    window.location.reload()
    sessionStorage.removeItem("client")
  }, [])

  return (
    <IonPage >
      <Redirect to="/page/login" />
    </IonPage>
  );
};

//Redux state and actions dispatchers

interface StateTypes {
  session: SessionDefaultStateI
}

const mapStateToProps = (state: StateTypes) => {
  return {
    session: state.session
  };
};

interface dispatchTypes {
  doLogout: () => void;
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    doLogout: () => dispatch(DoLogout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogoutOverview);