import React, { useState } from 'react';
import { connect } from 'react-redux';
import { IonButton, IonCard, IonCardHeader, IonCol, IonContent, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonRow, IonSelect, IonSelectOption, IonText, IonTitle } from '@ionic/react';
import { checkmarkCircleOutline, closeCircleOutline } from 'ionicons/icons';

import { GetABMKpis } from '../../store/ABMMetric/ABMKpis/ABMKpisActions';

import ApiRequestor from '../../api_access/ApiRequestor';

import './ABMMetric.css';
import Modal from '../Modal/Modal';

interface Kpi {
    kpi_id: number,
    kpi_name: string,
    kpi_host: string,
    kpi_field: string,
    kpi_type: string,
    kpi_transform: boolean,
    kpi_endpoint_id: string,
    kpi_group_id: number,
    kpi_threshold: boolean,
    kpi_title: string,
    kpi_title_key: string,
    kpi_time_gap: number,
    kpi_linechart_label: string,
    kpi_created_by: string,
    kpi_updated_by: string,
    kpi_group_name: string,
    service_name: string
}

interface Props extends StateTypes, dispatchTypes {
    kpiData: Kpi
    backToKpisTable: () => void
}

const ModifyABMKpis: React.FC<Props> = (props): JSX.Element => {

    const [kpiData, setKpiData] = useState({
        kpi_name: props.kpiData.kpi_name,
        kpi_host: props.kpiData.kpi_host,
        kpi_field: props.kpiData.kpi_field,
        kpi_type: props.kpiData.kpi_type,
        kpi_transform: props.kpiData.kpi_transform,
        kpi_endpoint_id: props.kpiData.kpi_endpoint_id,
        kpi_title: props.kpiData.kpi_title,
        kpi_title_key: props.kpiData.kpi_title_key,
        kpi_time_gap: props.kpiData.kpi_time_gap,
        kpi_linechart_label: props.kpiData.kpi_linechart_label
    })
    const [showModal, setShowModal] = useState(false);
    const [kpiInvalid, setKpiInvalid] = useState({
        kpi_name: false, kpi_host: false, kpi_field: false, kpi_type: false, kpi_transform: false,
        kpi_endpoint_id: false, kpi_title: false, kpi_title_key: false, kpi_time_gap: false, kpi_linechart_label: false
    })
    const [invalid, setInvalid] = useState(0)

    const saveData = (e: any) => {
        setKpiData({
            ...kpiData,
            [e.target.name]: e.detail.value!
        })
    }

    const validateForm = () => {
        Object.keys(kpiData).forEach((key) => {

            if ((kpiData as any)[key].length < 1) {
                (kpiInvalid as any)[key] = true
                setInvalid(Math.random())
            } else {
                (kpiInvalid as any)[key] = false
                setInvalid(Math.random())
            }
        })
    }

    const modifyKpi = async () => {

        validateForm()

        if (kpiData.kpi_host.length > 0 && kpiData.kpi_name.length > 0 && kpiData.kpi_endpoint_id.length > 0
            && kpiData.kpi_field.length > 0 && kpiData.kpi_linechart_label.length > 0 && kpiData.kpi_title.length > 0
            && kpiData.kpi_title_key.length > 0 && kpiData.kpi_transform.toString().length > 0 &&
            kpiData.kpi_type.length > 0 && kpiData.kpi_time_gap.toString().length > 0) {

            let data: any = {
                body: {
                    kpi_id: props.kpiData.kpi_id,
                    kpi_name: kpiData.kpi_name,
                    kpi_host: kpiData.kpi_host,
                    kpi_field: kpiData.kpi_field,
                    kpi_type: kpiData.kpi_type,
                    kpi_transform: kpiData.kpi_transform,
                    kpi_endpoint_id: kpiData.kpi_endpoint_id,
                    kpi_group_id: props.kpiData.kpi_group_id,
                    kpi_threshold: props.kpiData.kpi_threshold,
                    kpi_title: kpiData.kpi_title,
                    kpi_title_key: kpiData.kpi_title_key,
                    kpi_time_gap: kpiData.kpi_time_gap,
                    kpi_linechart_label: kpiData.kpi_linechart_label
                }
            }

            const response = await ApiRequestor.requestToApi('/swt-admin/kpis', 'PUT', data)
            if (response && response.status == 200) {
                setShowModal(true)
            }
        } else {
            validateForm()
        }

    }

    const onDismissModal = () => {
        setShowModal(false)
        props.getSeeABMKpis()
        props.backToKpisTable()
    }

    return (
        <IonContent fullscreen >
            <IonCard className="ABMKpisCardForm">
                <IonCardHeader>
                    <IonTitle className="ABMKpisTitleKpi">MODIFICACIÓN DE KPI</IonTitle>
                </IonCardHeader>
                <IonGrid className='ABMKpisADD'>
                    <IonRow>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Cliente</IonLabel>
                            <IonItem lines='none'>
                                <IonInput
                                    className="ABMKpisInputKpis"
                                    type="text"
                                    placeholder="Cliente"
                                    value={props.kpiData.service_name}
                                    disabled />
                            </IonItem>
                        </IonCol>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Nombre del Grupo de Kpis</IonLabel>
                            <IonItem lines='none'>
                                <IonInput
                                    className="ABMKpisInputKpis"
                                    type="text"
                                    placeholder="Grupo de kpis"
                                    value={props.kpiData.kpi_group_name}
                                    disabled />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Host</IonLabel>
                            <IonItem lines='none'>
                                <IonInput
                                    className="ABMKpisInputKpis"
                                    type="text"
                                    placeholder="Host"
                                    name='kpi_host'
                                    value={kpiData.kpi_host}
                                    onIonChange={(e) => saveData(e)} />
                            </IonItem>
                            <IonText>
                                <p className={kpiInvalid.kpi_host ? "login-valid-text" : "ion-hide"}> No puede estar vacío</p>
                            </IonText>
                        </IonCol>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Nombre</IonLabel>
                            <IonItem lines='none'>
                                <IonInput
                                    className="ABMKpisInputKpis"
                                    type="text"
                                    placeholder="Nombre"
                                    name='kpi_name'
                                    value={kpiData.kpi_name}
                                    onIonChange={(e) => saveData(e)} />
                            </IonItem>
                            <IonText>
                                <p className={kpiInvalid.kpi_name ? "login-valid-text" : "ion-hide"}> No puede estar vacío</p>
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Título</IonLabel>
                            <IonItem lines='none'>
                                <IonInput
                                    className="ABMKpisInputKpis"
                                    type="text"
                                    placeholder="Título"
                                    name='kpi_title'
                                    value={kpiData.kpi_title}
                                    onIonChange={(e) => saveData(e)} />
                            </IonItem>
                            <IonText>
                                <p className={kpiInvalid.kpi_title ? "login-valid-text" : "ion-hide"}> No puede estar vacío</p>
                            </IonText>
                        </IonCol>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Key Título</IonLabel>
                            <IonItem lines='none'>
                                <IonInput
                                    className="ABMKpisInputKpis"
                                    type="text"
                                    placeholder="Key Título"
                                    name='kpi_title_key'
                                    value={kpiData.kpi_title_key}
                                    onIonChange={(e) => saveData(e)} />
                            </IonItem>
                            <IonText>
                                <p className={kpiInvalid.kpi_title_key ? "login-valid-text" : "ion-hide"}> No puede estar vacío</p>
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Campo</IonLabel>
                            <IonItem lines='none'>
                                <IonInput
                                    className="ABMKpisInputKpis"
                                    type="text"
                                    placeholder="Campo"
                                    name='kpi_field'
                                    value={kpiData.kpi_field}
                                    onIonChange={(e) => saveData(e)} />
                            </IonItem>
                            <IonText>
                                <p className={kpiInvalid.kpi_field ? "login-valid-text" : "ion-hide"}> No puede estar vacío</p>
                            </IonText>
                        </IonCol>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Type</IonLabel>
                            <IonItem lines='none'>
                                <IonSelect
                                    interface="popover"
                                    placeholder="Seleccione el tipo"
                                    className="ABMKpisSelectKpis"
                                    name='kpi_type'
                                    value={kpiData.kpi_type}
                                    onIonChange={(e) => saveData(e)}>
                                    <IonSelectOption value="QUANTITY">
                                        QUANTITY
                                    </IonSelectOption>
                                    <IonSelectOption value="PERCENTAGE">
                                        PERCENTAGE
                                    </IonSelectOption>
                                </IonSelect>
                            </IonItem>
                            <IonText>
                                <p className={kpiInvalid.kpi_type ? "login-valid-text" : "ion-hide"}> Debe seleccionar uno</p>
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Transform</IonLabel>
                            <IonItem lines='none'>
                                <IonSelect
                                    interface="popover"
                                    placeholder="Seleccione"
                                    className="ABMKpisSelectKpis"
                                    name='kpi_transform'
                                    value={kpiData.kpi_transform}
                                    onIonChange={(e) => saveData(e)}>
                                    <IonSelectOption value={true}>
                                        TRUE
                                    </IonSelectOption>
                                    <IonSelectOption value={false}>
                                        FALSE
                                    </IonSelectOption>
                                </IonSelect>
                            </IonItem>
                            <IonText>
                                <p className={kpiInvalid.kpi_transform ? "login-valid-text" : "ion-hide"}>Debe seleccionar uno</p>
                            </IonText>
                        </IonCol>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Endpoint</IonLabel>
                            <IonItem lines='none'>
                                <IonSelect
                                    interface="popover"
                                    placeholder="Seleccione"
                                    className="ABMKpisSelectKpis"
                                    name='kpi_endpoint_id'
                                    value={kpiData.kpi_endpoint_id}
                                    onIonChange={(e) => saveData(e)}>
                                    <IonSelectOption value="prod">
                                        PROD
                                    </IonSelectOption>
                                    <IonSelectOption value="dev">
                                        DEV
                                    </IonSelectOption>
                                </IonSelect>
                            </IonItem>
                            <IonText>
                                <p className={kpiInvalid.kpi_endpoint_id ? "login-valid-text" : "ion-hide"}> Debe seleccionar uno</p>
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Tiempo de Recarga(min)</IonLabel>
                            <IonItem lines='none'>
                                <IonInput
                                    className="ABMKpisInputKpis"
                                    type="number"
                                    placeholder="Tiempo de Recarga(min)"
                                    name='kpi_time_gap'
                                    value={kpiData.kpi_time_gap}
                                    onIonChange={(e) => saveData(e)} />
                            </IonItem>
                            <IonText>
                                <p className={kpiInvalid.kpi_time_gap ? "login-valid-text" : "ion-hide"}> Debe ser un número</p>
                            </IonText>
                        </IonCol>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Linechart Label</IonLabel>
                            <IonItem lines='none'>
                                <IonInput
                                    className="ABMKpisInputKpis"
                                    type="text"
                                    placeholder="Linechart Label"
                                    name='kpi_linechart_label'
                                    value={kpiData.kpi_linechart_label}
                                    onIonChange={(e) => saveData(e)} />
                            </IonItem>
                            <IonText>
                                <p className={kpiInvalid.kpi_linechart_label ? "login-valid-text" : "ion-hide"}> No puede estar vacío</p>
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center containerButtonKpis">
                        <IonButton type="submit" fill="clear" className='ABMKpisZoom' onClick={modifyKpi}>
                            <IonIcon icon={checkmarkCircleOutline} color="success" className="ABMKpisButton" />
                        </IonButton>
                        <IonButton fill="clear" className='ABMKpisZoom' onClick={props.backToKpisTable}>
                            <IonIcon icon={closeCircleOutline} color="danger" className="ABMKpisButton" />
                        </IonButton>
                    </IonRow>
                </IonGrid>
            </IonCard>


            <Modal
                modalConfirmation={{
                    textConfirmation: "Kpi Modificado Correctamente",
                    onDidDimissConfirmModal: onDismissModal,
                    isOpenModalConfirm: showModal,
                    onClickConfirmModal: onDismissModal,
                }}
            />

        </IonContent>
    )
}

//Redux state and actions dispatchers

interface StateTypes {

}

const mapStatetoProps = (state: StateTypes) => {
    return {
    }
}

interface dispatchTypes {
    getSeeABMKpis: () => void;
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getSeeABMKpis: () => dispatch(GetABMKpis())
    };
}

export default connect(mapStatetoProps, mapDispatchToProps)(ModifyABMKpis);