import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { IonCard, IonCardContent, IonCardHeader, IonCol, IonContent, IonGrid, IonIcon, IonPage, IonRow, IonText, getPlatforms } from "@ionic/react";
import { arrowUp, arrowUpCircle, chevronDownOutline, chevronUpOutline, notifications, notificationsCircle } from "ionicons/icons";

import { SessionDefaultStateI } from "../../store/session/SessionReducer";
import { ClientMaintenanceDefaultStateI } from "../../store/mainInfo/clientMaintenanceReducer";
import { GetClientImage } from "../../store/mainInfo/mainInfoActions";
import { InternosGraphicsDefaultStateI } from "../../store/Internos/InternosGraphics/internosGraphicsReducer";
import { InternosCardsDefaultStateI } from "../../store/Internos/InternosCards/internosCardsReducer";
import { Redirect } from "react-router";

import Header from "../header/Header";
import Footer from "../Footer/Footer";
import DecidirCards from "./DecidirCards";
import DecidirPrisma from "./DecidirPrisma";
import SwtListClientsModal from "../SwtListClientsModal/SwtListClientsModal";
import MessagesPrisma from "../MessagesPrisma/MessagesPrisma";
import MaintenanceComponent from "../Maintenance/MaintenanceComponent";
import InternGraphics from "../Internos/InternGraphics";

import './Decidir.css';
import { GetChangeState } from "../../store/changeState/changeStateActions";
import { ChangeStateDefaultStateI } from "../../store/changeState/changeStateReducer";
import Loading from "../Loading/Loading";

interface Props extends StateTypes, dispatchTypes { }

const DecidirMainView: React.FC<Props> = (props): JSX.Element => {

    const [cardInfo, setCardInfo] = useState<any>([]);
    const [graphicsInfo, setGraphicsInfo] = useState<any>([]);
    const [graphicsEvolution, setGraphicsEvolution] = useState<any>([]);
    const [graphicsMP, setGraphicsMP] = useState<any>([]);
    const [graphicsC, setGraphicsC] = useState<any>([]);
    const [graphicsOpenMP, setGraphicsOpenMP] = useState(false);
    const [graphicsOpenC, setGraphicsOpenC] = useState(false);
    const [graphicGeneral, setGraphicGeneral] = useState<any>([])
    const [graphicsAdq, setGraphicsAdq] = useState<any>([])
    const [cardGeneral, setCardGeneral] = useState<any>([])
    const [cardsAdq, setCardsAdq] = useState<any>([])
    const [cardsMP, setCardsMP] = useState<any>([])
    const [cardsC, setCardsC] = useState<any>([])
    const [cardPersistor, setCardPersistor] = useState<any>({})
    const [maintenance, setMaintenance] = useState(false)
    const [inactive, setInactive] = useState(false)
    const [scrolling, setScrolling] = useState(false)
    const [hasMessages, setHasMessages] = useState(false)
    const [notifColor, setNotifColor] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const [clientSelected, setClientSelected] = useState('')
    const [categorySelected, setCategorySelected] = useState('');

    const bank_id_category = props.session.loginData.getIdToken().decodePayload()["custom:category"]
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
      });
      const [shouldRefresh, setShouldRefresh] = useState(false);
      const desktop = getPlatforms().includes("desktop")

      useEffect(() => {
    
        function handleResize() {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight
          });
          setShouldRefresh(true);
        }
    
        if(desktop){
          window.addEventListener('resize', handleResize);
          return () => window.removeEventListener('resize', handleResize);
        }
        
      }, []);
    
      useEffect(() => {
        if (shouldRefresh) {
          setShouldRefresh(false);
          window.location.reload();
        }
      }, [shouldRefresh]);


    useEffect(() => {
        if (props.session.loading === false && props.session.loginData) {
            if(!props.changeState.changeState){
                props.getChangeState()
            }
            if (!sessionStorage.getItem("client")) {
                sessionStorage.setItem("client", '')
            }
            if (bank_id_category == "ADMIN") {
                props.getClients()
                if (sessionStorage.getItem("client") == '') {
                    setShowModal(true)
                }else{
                    setShowModal(false)
                }
            }
        }
    }, [])

    useEffect(() => {
        if (props.internCards.loading === false && props.internCards.internosCards) {
            props.internCards.internosCards.filter((card: any) => {
                if (card.bank_id.includes("DECIDIR")) {
                    const decidirCardData = groupValues(card.cardsInfo)
                    setCardInfo(decidirCardData);
                }
            })
        }
    }, [props.internCards.loading, props.internCards.internosCards])

    useEffect(() => {
        if (bank_id_category !== 'ADMIN') {
            if (props.changeState.loading == false && props.changeState.changeState) {
                if (props.changeState.changeState[0].service_state == "inactive") {
                    setInactive(true)
                } else {
                    if (props.clientMaintenance.loading == false && props.clientMaintenance.clientMaintenance &&
                        props.clientMaintenance.clientMaintenance[0].service_maintenance) {
                        setMaintenance(true)
                    }
                }
            }
        } else {
            if (props.clientMaintenance.loading == false && props.clientMaintenance.clientMaintenance && sessionStorage.getItem("client") && sessionStorage.getItem("client") != '') {
                const maintenance = props.clientMaintenance.clientMaintenance.filter(client => client.service_name == sessionStorage.getItem("client"))[0].service_maintenance
                if (maintenance) {
                    setMaintenance(true)
                } else {
                    setMaintenance(false)
                }
            }

        }
    }, [props.changeState.loading, props.changeState.changeState, props.clientMaintenance.loading, props.clientMaintenance.clientMaintenance, sessionStorage.getItem("client")])

    useEffect(() => {
        let decidirGraphicsData = [];
        if (props.internGraphics.loading === false && props.internGraphics.internosGraphics) {
            const filterGraphicDecidir = props.internGraphics.internosGraphics.filter((graphic: any) => { return graphic.service_name == "DECIDIR" })
            decidirGraphicsData = props.internGraphics.internosGraphics;
            setGraphicsInfo(filterGraphicDecidir);
        }
    }, [props.internGraphics.loading, props.internGraphics.internosGraphics])

    useEffect(() => {
        if (graphicsInfo.length > 0) {
            let MPgraphic: any = []
            let Cgraphic: any = []
            graphicsInfo.map((graphicsData: any) => {
                //Guarda el gráfico de evolución principal
                if (graphicsData.intern_category === "GENERAL" && graphicsData.intern_title_key === "DECIDIR TOTAL GENERAL") {
                    if (graphicsData.intern_title.includes("Aprobadas") || graphicsData.intern_title.includes("APROBADAS")) {
                        setGraphicsEvolution([graphicsData]);
                    }
                }
                //Guarda los gráficos para medios de pago
                if (graphicsData.intern_category === "MEDIOS DE PAGO") {
                    MPgraphic.push(graphicsData);
                }
                //Guarda los gráficos para comercios
                if (graphicsData.intern_category === "COMERCIOS") {
                    Cgraphic.push(graphicsData);
                }
            })
            setGraphicsMP(MPgraphic)
            setGraphicsC(Cgraphic)
        }
    }, [sessionStorage.getItem("client"), graphicsInfo])

    useEffect(() => {
        if (graphicsInfo.length > 0 && cardInfo.length > 0) {

            //Guarda la card de total general y los gráficos correspondientes
            const mainCard = cardInfo.filter((card: any) => card.titleKey.includes("DECIDIR TOTAL GENERAL"))
            const mainGraphics = graphicsInfo.filter((graphic: any) => graphic.intern_title_key.includes("DECIDIR TOTAL GENERAL"))
            setCardGeneral(mainCard)
            setGraphicGeneral(mainGraphics)

            //Guarda las cards y gráficos de ADQ
            const adqCards = cardInfo.filter((card: any) => card.titleKey.includes("DECIDIR ADQ"))
            const adqGraphics = graphicsInfo.filter((graphic: any) => graphic.intern_title.includes("DECIDIR ADQ"))
            setCardsAdq(adqCards)
            setGraphicsAdq(adqGraphics)

            //Guarda la card para Persistor
            const persistorInfo = cardInfo.filter((card: any) => card.titleKey.includes("DECIDIR PERSISTOR"))
            setCardPersistor(persistorInfo[0])
        }
    }, [sessionStorage.getItem("client"), graphicsInfo, cardInfo])

    useEffect(() => {
        if (graphicsMP.length > 0 && graphicsC.length > 0 && cardInfo.length > 0) {
            let cardsDataMP: any = []
            let cardsDataC: any = []
            //Guarda las cards para medios de pago y comercios
            cardInfo.forEach((card: any) => {
                graphicsMP.forEach((graphic: any) => {
                    if (card.cantKey == graphic.intern_title) {
                        cardsDataMP.push(card)
                    }
                })
                graphicsC.forEach((graphic: any) => {
                    if (card.cantKey == graphic.intern_title) {
                        cardsDataC.push(card)
                    }
                })
            })
            setCardsMP(cardsDataMP)
            setCardsC(cardsDataC)
        }
    }, [sessionStorage.getItem("client"), cardInfo, graphicsC, graphicsMP])

    if (inactive && bank_id_category !== 'ADMIN') {
        return <Redirect to="/page/inactive" />
    }

    if (maintenance && bank_id_category !== 'ADMIN') {
        return <Redirect to="/page/maintenance" />
    }


    const getCardByTitleKey = (cardsArray: any, element: any) => {
        let card = cardsArray.find(
            (arrayElement: any) => element.titleKey === arrayElement.titleKey
        );

        if (!card && element.titleKey) {
            card = { titleKey: element.titleKey };
        }
        return card;
    };

    const getCardIndex = (cardsArray: any, element: any) => {
        return cardsArray.findIndex(
            (arrayElement: any) => element.titleKey === arrayElement.titleKey
        );
    };

    const groupValues = (array: any[]): any => {
        const graphicsArray: any[] = [];
        array.forEach((element) => {

            try {
                let card = getCardByTitleKey(graphicsArray, element);
                let index = getCardIndex(graphicsArray, element);

                if (element.key.includes("Aprobadas") || element.key.includes("APROBADAS")) {
                    card.appKey = element.key;
                    card.appVal = element.value;
                    card.appAlert = element.alert;

                } else {
                    card.cantKey = element.key;
                    card.cantVal = element.value;
                    card.cantAlert = element.alert;
                }

                card.gap = element.timeGap;

                if (index === -1) {
                    graphicsArray.push(card);
                } else {
                    graphicsArray[index] = card;
                }
            } catch (err) {
                console.log(err)
            }
        });

        return graphicsArray;
    };

    const doHasMessages = (bool: boolean) => {
        setHasMessages(bool)
    }

    const handleOpenMessage = () => {
        setScrolling(false)
    }

    const notifIconColor = (bool: boolean) => {
        setNotifColor(bool)
    }

    const showIcon = (e: any) => {
        const iconElement: any = document.getElementById("icon-scrolling")
        const iconMsgElement: any = document.getElementById("icon-msg-scrolling")
        const iconElementCircle: any = document.getElementById("icon-scrolling-circle")
        const iconMsgElementCircle: any = document.getElementById("icon-msg-scrolling-circle")


        if (e.target.scrollTop < 100) {
            iconElement.style.display = "none"
            iconElementCircle.style.display = "none"
        } else {
            iconElement.style.display = "block"
            iconElementCircle.style.display = "block"
        }
        if (hasMessages) {
            if (e.target.scrollTop < 100) {
                iconMsgElement.style.display = "none"
                iconMsgElementCircle.style.display = "none"
            } else {
                iconMsgElement.style.display = "block"
                iconMsgElementCircle.style.display = "block"
            }
        } else {
            iconMsgElement.style.display = "none"
            iconMsgElementCircle.style.display = "none"
        }
    }

    const handleClickUp = () => {
        const contentElement = document.getElementById("ion-content-main")
        contentElement?.scrollIntoView({ behavior: "smooth" })
    }

    const showModalFunc = () => {
        setShowModal(true)
    }

    const closeModalFunc = () => {
        setShowModal(false)
    }

    const selectClient = (clientName: string, categoryName: string) => {
        sessionStorage.setItem("client", clientName)
        sessionStorage.setItem("category", categoryName)
        setClientSelected(clientName)
        setCategorySelected(categoryName)
    }


    const renderGraphics = (graphics: any, graphicOpen: boolean) => {
        return (
            <IonCardContent
                className={graphicOpen ? `decidir-graphics-content` : `decidir-graphics-content-hidden`}>
                {graphicsInfo.length > 0 && graphics.map((graph: any, index: any) => {
                    let status = false
                    cardInfo &&
                        cardInfo.forEach((card: any) => {
                            if ((card.titleKey === graph.intern_title) && (card.cantAlert !== "0")) {
                                status = true;
                            } else if ((card.appKey === graph.intern_title) && (card.appAlert !== "0")) {
                                status = true;
                            }
                        })
                    return (
                        <div className="decidir-graphics" key={graph.intern_id}>
                            {props.internGraphics.internosGraphics &&
                                <InternGraphics key={graph.intern_id} internData={graph} id={graph.intern_title_key} alerted={status} title={graph.intern_title} />}
                        </div>
                    )
                })}
            </IonCardContent>
        )
    }

    return (
        <IonPage>
            <Header headerText="Decidir" parentCallback={(bank_id_category == "ADMIN") && showModalFunc} timer />
            {graphicsInfo.length > 0 && cardInfo.length > 0 && graphicsEvolution.length > 0 ?
                <IonContent className="decidir-container" onScroll={(e) => { setScrolling(true); showIcon(e) }}>
                    <IonGrid className="ion-justifiy-content-center" id="ion-content-main">
                        {
                            (bank_id_category == "ADMIN") && window.screen.width < 768 &&
                            <IonRow>
                                <IonCol className="ion-no-padding" onClick={() => showModalFunc()}>
                                    <IonCard className="ion-no-margin">
                                        <IonText><h5 className="ion-text-center ion-no-margin ion-padding">Seleccionar cliente</h5></IonText>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        }
                        {maintenance && (bank_id_category == "ADMIN") ?
                            <MaintenanceComponent />
                            :
                            <IonRow className="decidir-container">
                                <IonRow className="messagesPrisma">
                                    <MessagesPrisma scrolling={scrolling} hasMessages={doHasMessages} handleOpenMsg={handleOpenMessage} notifColor={notifIconColor} />
                                </IonRow>
                                <IonCol className="totalesDecidir">
                                    {cardGeneral.length > 0 && graphicGeneral.length > 0 &&
                                        <DecidirPrisma key={cardGeneral[0].titleKey} cardData={cardGeneral} graphicsData={graphicGeneral} cardDataPersistor={cardPersistor} />}
                                </IonCol>
                                <IonCol className="cardGraphicEvolution">
                                    <IonCard className="decidir-evolucion">
                                        <IonCardHeader className='intern-header ion-justifiy-content-center'>
                                            <IonText>Evolución % aprob. Últ. 30' </IonText>
                                        </IonCardHeader>
                                        <IonCardContent className="content-evolution">
                                            {props.internGraphics.internosGraphics &&
                                                <InternGraphics type="evolution" internData={graphicsEvolution[0]} id={graphicsEvolution[0].intern_title_key} alerted={false} />
                                            }
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol className="cardResolutores">
                                    <IonCard className="decidir-card">
                                        <IonCardHeader className='intern-header '>RESOLUTORES</IonCardHeader>
                                        <IonCardContent className="decidir-categories-grid-resolutores">
                                            {
                                                cardsAdq.length > 0 && graphicsAdq.length > 0 &&
                                                cardsAdq.map((card: any) => {
                                                    return (
                                                        <DecidirCards key={card.titleKey} cardData={card} graphicsData={graphicsAdq} />
                                                    )
                                                })
                                            }
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>


                                <IonCol className="cardComercios">
                                    <IonCard className='decidir-card'>
                                        <IonCardHeader className="intern-header">COMERCIOS</IonCardHeader>
                                        <IonCardContent className="decidir-categories-grid-comercios">
                                            {
                                                cardsC.length > 0 && graphicsC.length > 0 &&
                                                cardsC.map((card: any) => {
                                                    return (
                                                        <DecidirCards key={card.titleKey} cardData={card} graphicsData={graphicsC} />
                                                    )
                                                })
                                            }
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                                <IonCol className="cardMediosdePago">
                                    <IonCard className="decidir-card">
                                        <IonCardHeader className='intern-header '> MEDIOS DE PAGO </IonCardHeader>
                                        <IonCardContent className="decidir-categories-grid-mp-cm">
                                            {
                                                cardsMP.length > 0 && graphicsMP.length > 0 &&
                                                cardsMP.map((card: any) => {
                                                    return (
                                                        <DecidirCards key={card.titleKey} cardData={card} graphicsData={graphicsMP} />
                                                    )
                                                })
                                            }
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>


                                <IonCol className="cardGraphicsMediosDePago">
                                    <IonCard className="decidir-card">
                                        <IonCardHeader className='intern-header'
                                            onClick={() => setGraphicsOpenMP(!graphicsOpenMP)}>
                                            GRÁFICOS MEDIOS DE PAGO
                                            <IonIcon icon={graphicsOpenMP ? chevronUpOutline : chevronDownOutline} className="displayGraphics-icon" />
                                        </IonCardHeader>
                                        {
                                            graphicsInfo.length > 0 && graphicsMP.length > 0 && cardInfo.length > 0 &&
                                            renderGraphics(graphicsMP, graphicsOpenMP)
                                        }

                                    </IonCard>
                                </IonCol>
                                <IonCol className="decidir-space cardGraphicsComercios">
                                    <IonCard className="decidir-card">
                                        <IonCardHeader className='intern-header'
                                            onClick={() => setGraphicsOpenC(!graphicsOpenC)}>
                                            GRÁFICOS COMERCIOS
                                            <IonIcon icon={graphicsOpenC ? chevronUpOutline : chevronDownOutline} className="displayGraphics-icon" />
                                        </IonCardHeader>
                                        {
                                            graphicsInfo.length > 0 && graphicsC.length > 0 && cardInfo.length > 0 &&
                                            renderGraphics(graphicsC, graphicsOpenC)
                                        }
                                    </IonCard>
                                </IonCol>

                            </IonRow>
                        }
                    </IonGrid>

                    <IonIcon icon={notificationsCircle} className={scrolling ? "icon-msgs-up-circle" : "ion-hide"} id="icon-msg-scrolling-circle" onClick={() => { handleClickUp() }} />
                    <IonIcon icon={notifications} className={scrolling ? (notifColor ? "icon-msgs-up color-true" : "icon-msgs-up color-false") : "ion-hide"} id="icon-msg-scrolling" onClick={() => { handleClickUp() }} />
                    <IonIcon icon={arrowUpCircle} className={scrolling ? "icon-scroll-up-circle" : "ion-hide"} id="icon-scrolling-circle" onClick={() => { handleClickUp() }} />
                    <IonIcon icon={arrowUp} className={scrolling ? "icon-scroll-up" : "ion-hide"} id="icon-scrolling" onClick={() => { handleClickUp() }} />
                    <SwtListClientsModal parentCallback={selectClient} showModal={showModal} closeModalCallback={closeModalFunc} />
                </IonContent>
                :
                <Loading />
            }
            <Footer />
        </IonPage>

    );
};

//Redux state and actions dispatchers
interface StateTypes {
    session: SessionDefaultStateI;
    internCards: InternosCardsDefaultStateI;
    internGraphics: InternosGraphicsDefaultStateI;
    clientMaintenance: ClientMaintenanceDefaultStateI;
    changeState: ChangeStateDefaultStateI;
}

const mapStateProps = (state: StateTypes) => {
    return {
        session: state.session,
        internCards: state.internCards,
        internGraphics: state.internGraphics,
        clientMaintenance: state.clientMaintenance,
        changeState: state.changeState,
    }
}

interface dispatchTypes {
    getClients: () => void;
    getChangeState: () => void;
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getClients: () => dispatch(GetClientImage()),
        getChangeState: () => dispatch(GetChangeState())
    }
}

export default connect(mapStateProps, mapDispatchToProps)(DecidirMainView);