export const SESSION_LOADING = "SESSION_LOADING";
export const SESSION_FAIL = "SESSION_FAIL";
export const SESSION_SUCCESS = "SESSION_SUCCESS";

export type SessionAbility = {
    ability:{
        name:string,
        url:string
    }
}

export type SessionSprites = {
    front_default:string
}

export type SessionStat = {
    base_stat:number,
    stat:{
        name:string
    }
}

export interface SessionLoading {
    type: typeof SESSION_LOADING
}

export interface SessionFail {
    type: typeof SESSION_FAIL
}

export interface SessionSuccess {
    type: typeof SESSION_SUCCESS,
    payload:any,
    loggedIn:boolean
}

export type SessionDispatchTypes = SessionLoading | SessionFail | SessionSuccess;

