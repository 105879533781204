import React, { useState } from "react";
import { IonButton, IonIcon, IonRow, IonText } from "@ionic/react";
import { syncOutline } from "ionicons/icons";

import "./expSession.css";
import Modal from "../Modal/Modal";

const ExpSessionModal: React.FC = (): JSX.Element => {
    const [showModal, setShowModal] = useState(true);

    const redirectFunction = () => {
        setShowModal(false)
        window.location.href = "/page/login"
    }

    const returnChildrenModal = (): JSX.Element => {
        return (
            <>
                <IonRow className="exp-session-content exp-session-text">
                    <IonText className="">Sesión Expirada</IonText>
                </IonRow>
                <IonRow className="exp-session-content exp-session-icon">
                    <IonIcon icon={syncOutline} />
                </IonRow>
                <IonRow className="exp-session-content">
                    <IonButton className="exp-session-button" fill="outline" onClick={redirectFunction}>
                        Iniciar Sesión</IonButton>
                </IonRow>
            </>
        )
    }

    return (

        <Modal
            modalChildren={
                {
                    id: "",
                    isOpen: showModal,
                    styles: "exp-session-modal",
                    children: returnChildrenModal()
                }
            }
        />

    )
}

export default ExpSessionModal;

