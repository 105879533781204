import { IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import '@aws-amplify/ui/dist/style.css';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { getPlatforms } from '@ionic/core';
import { SessionDefaultStateI } from './store/session/SessionReducer';
import { GetLoggedIn } from './store/session/SessionActions';
import { ClientMaintenanceDefaultStateI } from './store/mainInfo/clientMaintenanceReducer';
import { GetClientMaintenance } from './store/mainInfo/mainInfoActions';
import { setStyleMode } from './utils/StyleUtils';

import Menu from './components/Menu/Menu';
import LoginOverview from './components/Login/LoginOverview';
import ForgotPass from './components/Login/ForgotPass';
import ResetPass from './components/Login/ResetPass';
import Authentication from './components/Login/Authentication';
import LogoutOverview from './components/Logout/LogoutOverview'
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import MainOverview from './components/MainOverview/MainOverview'
import Reports from './components/Reports/Reports'
import MessagesPrismaHistorial from './components/MessagesPrismaHistorial/MessagesPrismaHistorial';
import UserProfile from './components/UserProfile/UserProfile';
import ABMUsers from './components/ABMUsers/ABMUsers';
import ClientsInMaintenance from './components/ClientsInMaintenance/ClientsInMaintenance';
import ShowUmbralsView from './components/ShowUmbralsView/ShowUmbralsView';
import ABMClients from './components/ABMClients/ABMClients';
import AddABMPrismaMessages from './components/ABMPrismaMessages/AddABMPrismaMessages'
import InactivePage from './components/Inactive/InactivePage';
import MaintenancePage from './components/Maintenance/MaintenancePage';
import UserStatisticsMainView from './components/userStatistics/userStatisticsMainView';
import ExpSessionModal from './components/expSession/expSessionModal';
import Interno from './components/Internos/Interno';
import ABMMetricMainView from './components/ABMMetric/ABMMetricMainView';
import ChangeState from './components/ChangeState/ChangeState';

import ApiRequestor from './api_access/ApiRequestor';
import { ChangeStateDefaultStateI } from './store/changeState/changeStateReducer';
import { GetChangeState } from './store/changeState/changeStateActions';


interface Props extends StateTypes, dispatchTypes { }

const Routes: React.FC<Props> = (props): JSX.Element => {

    const [isAdmin, setIsAdmin] = useState(false)
    const [internoUser, setInternoUser] = useState(false)
    const [isLoggedIn, setIsLoggedIn] = useState(true)
    const [renderModal, setRenderModal] = useState(false)

    useEffect(() => {
        //se renderiza 1
        if (props.session.loggedIn === false) {
            props.getLoggedIn()
        }
    }, [])

    useEffect(() => {
        //se renderiza 2
        if (props.session.loggedIn === true && props.session.loginData) {
            const mobile = getPlatforms().includes("mobile")
            const mobileWeb = getPlatforms().includes("mobileweb")
            const desktop = getPlatforms().includes("desktop")
            let platForms = ""

            if (mobile && !mobileWeb) {
                platForms = "mobile"
            }
            if (mobileWeb || desktop) {
                platForms = "web"
            }

            const updateConnection = async () => {
                const username = props.session.loginData.getIdToken().decodePayload()["cognito:username"]
                let data: any = { body: { platform: platForms } }
                await ApiRequestor.requestToApi("/user/last-connection/" + username, "PUT", data, "USER", false)
            }
            updateConnection()
                .catch(console.error)
        }
    }, [props.session.loggedIn])

    useEffect(() => {
        //renderiza 2
        if (props.session.loggedIn === true) {
            if(!props.clientMaintenance.clientMaintenance){
                props.getClientMaintenance()
            }
            if(!props.changeState.changeState){
                props.getChangeState()
            }
            //hace los get 1
            const bank_id = props.session.loginData.getIdToken().decodePayload()["custom:bank_id"].toUpperCase()
            const bank_id_category = props.session.loginData.getIdToken().decodePayload()["custom:category"]
            if (bank_id_category == "ADMIN") {
                setIsAdmin(true)
            } else {
                setIsAdmin(false)
            }
            if (bank_id == "POS" || bank_id == "DECIDIR" || bank_id == "RESOLUTORES" || bank_id == "DEBIN" || bank_id == "NEWPAY" ) {
                setInternoUser(true)
            }
        }
    }, [props.session.loggedIn])

    useEffect(() => {
        setStyleMode();
    }, []);


    setInterval(() => {
        let isLogged: number = 0
        for (let i = 0; i < localStorage.length; i++) {
            if (localStorage.key(i)?.includes("Cognito")) {
                isLogged++
            }
        }

        if (isLogged == 0) {
            setIsLoggedIn(false)
        } else {
            setIsLoggedIn(true)
        }
    }, 60000)

    useEffect(() => {
        //se renderiza 1
        if (!isLoggedIn && !window.location.href.includes("login") && !window.location.href.includes("forgot-pass") && !window.location.href.includes("authenticate")) {
            setRenderModal(true)
        }
    }, [isLoggedIn])


    const checkRenderMenu = () => {
        if ((props.session.loggedIn && props.changeState.loading == false && props.changeState.changeState && !isAdmin)) {
            if (props.changeState.changeState[0].service_state == "inactive") {
                return false
            } else {
            if(props.clientMaintenance.loading == false && props.clientMaintenance.clientMaintenance) {
                    if ((props.clientMaintenance.clientMaintenance[0].service_maintenance == false && !isAdmin) || isAdmin) {
                        return true
                    }
                } 
            }
        } else if (props.session.loggedIn && isAdmin){          
                        return true
        }
    }

    return (
        <IonReactRouter>
            <IonSplitPane contentId="main">
                {checkRenderMenu()
                    && <Menu />}
                {renderModal && <ExpSessionModal />}
                <IonRouterOutlet id="main" className="ionRouterOutlet-class">
                    <Route path="/page/login/">
                        <LoginOverview />
                    </Route>
                    <Route path="/page/forgot-pass" exact={true}>
                        <ForgotPass />
                    </Route>
                    <Route path="/page/reset-pass" exact={true}>
                        <ResetPass />
                    </Route>
                    <Route path="/page/authenticate" exact={true}>
                        <Authentication />
                    </Route>
                    <PrivateRoute path="/page/logout" component={LogoutOverview} />
                    <PrivateRoute path="/page/kpigroup/:kpigroup" component={MainOverview} />
                    <PrivateRoute path="/page/reports" component={Reports} />
                    <PrivateRoute path="/page/messagesHistorial" component={MessagesPrismaHistorial} />
                    <PrivateRoute path="/page/profile" component={UserProfile} />
                    <PrivateRoute path="/page/users" component={ABMUsers} />
                    <PrivateRoute path="/page/maintain" component={ClientsInMaintenance} />
                    <PrivateRoute path="/page/umbral" component={ShowUmbralsView} />
                    <PrivateRoute path="/page/clients" component={ABMClients} />
                    <PrivateRoute path="/page/metrics" component={ABMMetricMainView} />
                    <PrivateRoute path="/page/prismaMessages" component={AddABMPrismaMessages} />
                    <PrivateRoute path="/page/maintenance" component={MaintenancePage} />
                    <PrivateRoute path="/page/inactive" component={InactivePage} />
                    <PrivateRoute path="/page/usersStatistics" component={UserStatisticsMainView} />
                    <PrivateRoute path='/page/changeState' component={ChangeState} />
                    <PrivateRoute path="/page/intern" component={Interno} />
                    <Route path="/" exact={true}>
                        {
                            internoUser ?
                                <Redirect to="/page/intern" /> :
                                <Redirect to="/page/kpigroup/services" />
                        }
                    </Route>
                </IonRouterOutlet>
            </IonSplitPane>
        </IonReactRouter>
    );
};

interface StateTypes {
    session: SessionDefaultStateI,
    clientMaintenance: ClientMaintenanceDefaultStateI,
    changeState: ChangeStateDefaultStateI;
}

const mapStateToProps = (state: StateTypes) => {
    return {
        session: state.session,
        clientMaintenance: state.clientMaintenance,
        changeState: state.changeState,
    };
};

interface dispatchTypes {
    getLoggedIn: () => void
    getClientMaintenance: () => void
    getChangeState: () => void;
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getLoggedIn: () => dispatch(GetLoggedIn()),
        getClientMaintenance: () => dispatch(GetClientMaintenance()),        
        getChangeState: () => dispatch(GetChangeState())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
