import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ApiRequestor from '../../api_access/ApiRequestor';
import {
    IonButton, IonCard, IonCardHeader, IonCardTitle, IonCheckbox, IonCol, IonContent, IonGrid, IonIcon,
    IonInput, IonItem, IonRow, IonText, IonTextarea
} from '@ionic/react';
import { checkmarkCircleOutline, closeCircleOutline } from 'ionicons/icons';

import { SessionDefaultStateI } from '../../store/session/SessionReducer';
import { ClientsInMaintenanceDefaultStateI } from '../../store/clientsInMaintenance/clientInMaintenanceReducer';
import { GetClientsInMaintenance } from '../../store/clientsInMaintenance/clientInMaintenanceActions';
import { DateTime } from 'luxon';

import TogglePrismaMessages from './TogglePrismaMessages';

import './ABMPrismaMessages.css';
import Modal from '../Modal/Modal';

interface Message {
    message_swt_id: number;
    message_swt_message: string;
    message_swt_services: string[];
    message_swt_date: string;
    message_swt_close: boolean
}

interface Props extends StateTypes, dispatchTypes {
    messageData: Message
    backToTable: () => void
}

interface dataMessage {
    message: string,
    services: any[],
    date: string,
    hour: string,
    closeOption: boolean,
    id: number
}
const ModifyABMPrismaMessages: React.FC<Props> = (props): JSX.Element => {
    const [dataMessage, setDataMessage] = useState<dataMessage>({
        message: '',
        services: [],
        date: "",
        hour: '',
        closeOption: false,
        id: 1,
    });
    const [servSelected, setServSelected] = useState<string[]>([])
    const [checkAll, setCheckAll] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [textModal, setTextModal] = useState('')
    const [nameInvalid, setNameInvalid] = useState(false);

    useEffect(() => {
        const formattedDate = (DateTime.fromISO(props.messageData.message_swt_date).toFormat('dd-MM-yyyy HH:mm')).toString();
        let servicesArray: any = [];
        props.messageData.message_swt_services.map((service: string) => {
            servicesArray.push({
                name: service,
                toggle: true
            })
        })

        setDataMessage({
            message: props.messageData.message_swt_message,
            services: servicesArray,
            date: props.messageData.message_swt_date.slice(0, 10),
            hour: formattedDate.slice(11, 16) + ":00",
            closeOption: props.messageData.message_swt_close,
            id: props.messageData.message_swt_id,
        })
        setServSelected(props.messageData.message_swt_services)

    }, []);

    useEffect(() => {
        if (props.session.loading === false && props.session.loginData) {
            if(!props.clientsInMaintenance.clientsInMaintenance){
                props.getClientsInMaintenance();
            }
        }
    }, [])

    const toggleSelectorAll = (check: boolean, id: string) => {
        props.clientsInMaintenance.clientsInMaintenance?.map((clientData: any) => {
            const filteredArrayAll: any = dataMessage.services.filter((client: any) => {
                return (
                    client.name == clientData.service_name
                )
            })
            if (filteredArrayAll.length > 0) {
                filteredArrayAll[0].toggle = check
            } else {
                dataMessage.services.push({ name: clientData.service_name, toggle: check })
            }
        })
        setCheckAll(check);
    }
    const getDataForm = (e: any) => {
        setDataMessage({
            ...dataMessage,
            [e.target.name]: e.target.value!
        })
    }
    const getCheckedForm = (e: any) => {
        setDataMessage({
            message: dataMessage.message,
            services: dataMessage.services,
            date: dataMessage.date,
            hour: dataMessage.hour,
            closeOption: !dataMessage.closeOption,
            id: dataMessage.id
        })
    }
    const getToggleForm = (check: boolean, id: string) => {
        const filteredArray: any = dataMessage.services.filter((client: any) => {
            return (
                client.name == id
            )
        })
        if (filteredArray.length < 1) {
            dataMessage.services.push({ name: id, toggle: check })
        } else {
            filteredArray[0].toggle = check
        }
    }

    const validationName = () => {
        if (dataMessage.message.length <= 1) {
            setNameInvalid(true);
        } else {
            setNameInvalid(false);
        }
    }

    const modifyMessage = async (e: any) => {
        e.preventDefault();

        let valid = false;
        if (dataMessage.message.length > 0) {
            valid = true;
        }
        if (valid) {
            let services: string[] = [];

            dataMessage.services.forEach((element: any) => {
                if (element.toggle) {
                    services.push(element.name)
                }
            });

            if (services.length < 1) {
                setTextModal('Debe seleccionar al menos un cliente para enviar el mensaje')
                setShowModal(true)
                return;
            }
            let dateTime: any = `${dataMessage.date} ${dataMessage.hour}`
            let dateConf: any = (DateTime.fromFormat(dateTime, 'yyyy-MM-dd HH:mm:ss')).toString()
            let dateToday: any = DateTime.now().toISO()

            if (dateConf < dateToday) {
                setTextModal('Debe seleccionar una fecha y hora posterior a la actual')
                setShowModal(true)
                return;
            }

            dateTime = DateTime.fromFormat(dateTime, 'yyyy-MM-dd HH:mm:ss').setZone('utc')
            dateTime = dateTime.toString()

            let data: any = {
                body: {
                    "message_swt_message": dataMessage.message,
                    "message_swt_services": services,
                    "message_swt_date": dateTime,
                    "message_swt_close": dataMessage.closeOption,
                    "message_swt_id": dataMessage.id
                }
            };
            const response: any = await ApiRequestor.requestToApi("/swt-admin/swt-messages", "PUT", data);
            if (response && response.status == 200) {
                setTextModal('Mensaje Modificado Correctamente')
                setShowModal(true)
            }
        } else {
            validationName();
        }
    }
    const onDismissModal = () => {
        if (textModal.includes('cliente') || textModal.includes('fecha')) {
            setShowModal(false)
        } else {
            setShowModal(false)
            props.backToTable()
        }
    }
    return (
        <IonContent fullscreen className="ion-padding">
            {dataMessage.message.length > 0 &&
                <IonCard className="ABMPrismaMessagesFormCard">
                    <IonRow className="ion-align-items-center ion-padding-top">
                        <IonCol>
                            <IonCardTitle className="ABMPrismaMessagesFormTitle ion-text-center ion-no-margin ion-align-self-center">
                                Mensajes de Prisma
                            </IonCardTitle>
                        </IonCol>
                    </IonRow>
                    <IonGrid className="ion-align-self-center">
                        <IonCard className="ABMPrismaMessagesCardClients">
                            <IonCardHeader>
                                <IonRow className="ion-align-items-center">
                                    <TogglePrismaMessages
                                        text='Seleccionar Todos'
                                        id='SelectAll'
                                        check={checkAll}
                                        parentCallback={toggleSelectorAll} />
                                </IonRow>
                            </IonCardHeader>
                            <IonGrid>
                                <IonRow>
                                    {
                                        props.clientsInMaintenance.clientsInMaintenance &&
                                        props.clientsInMaintenance.loading === false &&
                                        props.clientsInMaintenance.clientsInMaintenance.map((client: any) => {
                                            return (
                                                <IonCol
                                                    key={client.service_name}
                                                    sizeXl='4'
                                                    sizeLg='4'
                                                    sizeXs='6'
                                                    className="ion-text-center ion-no-padding">
                                                    {servSelected.length > 0 &&
                                                        servSelected.includes(client.service_name)
                                                        ?
                                                        <TogglePrismaMessages
                                                            text={client.service_display_name}
                                                            id={client.service_name}
                                                            check={true}
                                                            parentCallback={getToggleForm}
                                                            disabled={checkAll} />
                                                        :
                                                        <TogglePrismaMessages
                                                            text={client.service_display_name}
                                                            id={client.service_name}
                                                            check={false}
                                                            parentCallback={getToggleForm}
                                                            disabled={checkAll} />
                                                    }
                                                </IonCol>
                                            )
                                        })
                                    }
                                </IonRow>
                            </IonGrid>
                        </IonCard>
                        <IonRow>
                            <IonCol sizeXl='4' sizeXs='12' className='AMBPrismaMessagesContainer'>
                                <IonText className="ABMPrismaMessagesInputTitles">
                                    Fecha de Expiración del Mensaje
                                </IonText>
                                <IonItem className="ABMPrismaMessagesItem" lines='none'>
                                    <IonInput
                                        type="date"
                                        name='date'
                                        className="ABMPrismaMessagesInput ion-padding-left"
                                        value={dataMessage.date}
                                        onIonChange={(e) => getDataForm(e)} />
                                </IonItem>
                            </IonCol>
                            <IonCol sizeXl='4' sizeXs='12' className='AMBPrismaMessagesContainer'>
                                <IonText className="ABMPrismaMessagesInputTitles">
                                    Hora de Expiración del Mensaje
                                </IonText>
                                <IonItem className="ABMPrismaMessagesItem" lines='none'>
                                    <IonInput
                                        className="ABMPrismaMessagesInput"
                                        mode='ios'
                                        type='time'
                                        name='hour'
                                        value={dataMessage.hour}
                                        onIonChange={(e) => getDataForm(e)} />
                                </IonItem>
                            </IonCol>
                            <IonCol sizeXl='4' sizeXs='12' className='AMBPrismaMessagesContainerDescartar'>
                                <IonText className="ABMPrismaMessagesInputTitles">
                                    Permitir Descartar
                                </IonText>
                                <div className="ABMPrismaMessagesCheckbox">
                                <IonCheckbox
                                    mode='md'
                                    color="secondary"
                                    name='close'
                                    checked={dataMessage.closeOption}
                                    onIonChange={(e) => getCheckedForm(e)} />
                                </div>
                            </IonCol>
                        </IonRow>
                        <IonText className="ion-text-left ion-margin ABMPrismaMessagesInputTitles">
                            Mensaje
                        </IonText>
                        <IonItem
                            className="ABMPrismaMessagesItemTextarea"
                            lines='none'>
                            <IonTextarea
                                className="ABMPrismaMessagesTextarea"
                                placeholder="Hasta 140 Caracteres"
                                maxlength={140}
                                name='message'
                                value={dataMessage.message}
                                onIonChange={(e) => getDataForm(e)} />
                            <IonText>
                                <p className={nameInvalid ? "ABMPrismaMessagesValidText" : "ion-hide"}> Longitud menor a 2 caracteres</p>
                            </IonText>
                        </IonItem>
                        <IonRow className="ion-margin-horizontal ion-margin-top ion-no-margin-bottom ion-justify-content-center">
                            <IonButton type="submit" fill="clear" onClick={modifyMessage}>
                                <IonIcon icon={checkmarkCircleOutline} color="success" className="ion-padding ion-no-padding-bottom ABMPrismaMessagesIcon" />
                            </IonButton>
                            <IonButton fill="clear" onClick={props.backToTable}>
                                <IonIcon icon={closeCircleOutline} color="danger" className="ion-padding ion-no-padding-bottom ABMPrismaMessagesIcon" />
                            </IonButton>
                        </IonRow>
                    </IonGrid>
                </IonCard>}

            <Modal
                modalConfirmation={
                    {
                        isOpenModalConfirm: showModal,
                        onDidDimissConfirmModal: () => onDismissModal(),
                        textConfirmation: textModal,
                        onClickConfirmModal: () => onDismissModal(),
                    }
                }
            />

        </IonContent>
    )
}
//Redux state and actions dispatchers
interface StateTypes {
    session: SessionDefaultStateI,
    clientsInMaintenance: ClientsInMaintenanceDefaultStateI
}

const mapStateToProps = (state: StateTypes) => {
    return {
        session: state.session,
        clientsInMaintenance: state.clientsInMaintenance,
    };
};

interface dispatchTypes {
    getClientsInMaintenance: () => void;
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getClientsInMaintenance: () => dispatch(GetClientsInMaintenance())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModifyABMPrismaMessages);