import { ABMKpisGroupsType, GetABMKPISGROUPSDispatchTypes, GET_ABMKPISGROUPS_FAIL, GET_ABMKPISGROUPS_LOADING, GET_ABMKPISGROUPS_SUCCESS } from './ABMKpisGroupsActionsTypes';

export interface ABMKpisGroupsDefaultStateI {
    loading: boolean,
    abmkpisgroups? : ABMKpisGroupsType[] | undefined
}

const defaultState: ABMKpisGroupsDefaultStateI =
{
    loading: false
};

const ABMKpisGroupsReducer = (state: ABMKpisGroupsDefaultStateI = defaultState, action: GetABMKPISGROUPSDispatchTypes):
ABMKpisGroupsDefaultStateI =>{
    switch (action.type){
        case GET_ABMKPISGROUPS_FAIL:
            return{
                loading:false
            }
            break;
        case GET_ABMKPISGROUPS_LOADING:
            return{
                loading:true
            }
            break;
        case GET_ABMKPISGROUPS_SUCCESS:
            return {
                loading : false,
                abmkpisgroups:action.payload
            }
            break;
        default:
            return state
            break;
    }
    return state;
}

export default ABMKpisGroupsReducer;