import React from "react";
import { IonCol, IonRow } from "@ionic/react";

import { DateTime } from "luxon";

import "./userStatistics.css"

interface Data {
    username: string;
    password: string;
    bank_id: string;
    first_name: string;
    last_name: string;
    phone_number: string;
    email: string;
    email_verified: string;
    created_date: string;
    last_connection?: any
}

interface Props {
    userClientData: Data;
}

const UsersClientTable: React.FC<Props> = (props): JSX.Element => {
    const LastConnection = (props.userClientData.last_connection == "No Verificado" || props.userClientData.last_connection == "Inactivo") ? props.userClientData.last_connection : DateTime.fromISO(props.userClientData.last_connection.split("T")[0],{zone: "America/Argentina/Buenos_Aires"}).toFormat("dd/MM/yyyy")
    const createDate = DateTime.fromISO(props.userClientData.created_date.split("T")[0], {zone: "America/Argentina/Buenos_Aires"}).toFormat("dd/MM/yyyy")

    return (
        <IonRow className='user-table-rows'>
            <IonCol sizeXl="2" sizeLg="2" sizeMd="6">{props.userClientData.username}</IonCol>
            <IonCol className="mobile-hidden" sizeXl="1.5" sizeLg="1.5" sizeMd="1.5">{props.userClientData.first_name}</IonCol>
            <IonCol className="mobile-hidden" sizeXl="1.5" sizeLg="1.5" sizeMd="1.5">{props.userClientData.last_name}</IonCol>
            <IonCol className="mobile-hidden" sizeXl="4" sizeLg="4" sizeMd="4">{props.userClientData.email}</IonCol>
            <IonCol className="mobile-hidden" sizeXl="1.5" sizeLg="1.5" sizeMd="1.5">{createDate}</IonCol>
            <IonCol sizeXl="1.5" sizeLg="1.5" sizeMd="6">{LastConnection}</IonCol>
        </IonRow>
    )
}

export default UsersClientTable;