import { SessionDispatchTypes, SESSION_FAIL, SESSION_LOADING, SESSION_SUCCESS } from "./SessionActionTypes";

export interface SessionDefaultStateI {
    loading:boolean,
    loginData?: any,
    loggedIn:boolean,
}

const defaultState:SessionDefaultStateI = {
    loading:false,
    loggedIn:false,
};

const sessionReducer = (state: SessionDefaultStateI = defaultState,action:SessionDispatchTypes):SessionDefaultStateI =>{
    switch (action.type) {
        case SESSION_FAIL:
            return {
                loading:false,
                loggedIn:false,
            }
        case SESSION_LOADING:
            return {
                loading:true,
                loggedIn:false,
            }
        case SESSION_SUCCESS:
            return {
                loading:false,
                loginData:action.payload,
                loggedIn:action.loggedIn,
            }
        default:
            return state
    }
}


export default sessionReducer;

