export const GET_INTERNOS_GRAPHICS_LOADING = 'GET_INTERNOS_GRAPHICS_LOADING';
export const GET_INTERNOS_GRAPHICS_FAIL = 'GET_INTERNOS_GRAPHICS_FAIL';
export const GET_INTERNOS_GRAPHICS_SUCCESS = 'GET_INTERNOS_GRAPHICS_SUCCESS';

export type InternosGraphicsType = { 
    intern_id: string;
    intern_name: string;
    intern_host: string;
    intern_type: string;
    intern_transform: boolean;
    intern_endpoint_id: string;
    intern_threshold: boolean;
    intern_title: string;
    intern_title_key: string;
    intern_time_gap: number;
    intern_linechart_label: string;
    intern_category: string;
    intern_graphic: boolean;
    service_name: string,
    keys:[],
    values:number[],
    limit_values:number[]
};

export interface GetInternosGraphicsLoading {
    type: typeof GET_INTERNOS_GRAPHICS_LOADING
}

export interface GetInternosGraphicsFail {
    type: typeof GET_INTERNOS_GRAPHICS_FAIL
}

export interface GetInternosGraphicsSuccess {
    type: typeof GET_INTERNOS_GRAPHICS_SUCCESS,
    payload: InternosGraphicsType[] | undefined
}

export type GetInternosGraphicsDispatchTypes = GetInternosGraphicsLoading | GetInternosGraphicsFail | GetInternosGraphicsSuccess;