export interface dataGraphic {
    data: Array<any>,
    transpareciaColor: string
}

export interface colors {
    gradient: string,
    border: string
}

export const colorsGraphics: { [title: string]: { [title: string]: colors } } = {
    coelsa: {
        "TIMED OUT LAPP-PRPM588": {
            gradient: "--cust-color-umbral-rgb",
            border: "--cust-color-umbral-rgb"
        },
        "TIMED OUT LAPP-PRPM589": {
            gradient: "--cust-color-alerted-rgb",
            border: "--cust-color-alerted-rgb"
        },
        "TIMED OUT LAPP-PRPM1105": {
            gradient: "--custom-color-coelsa-bordo-rgb",
            border: "--custom-color-coelsa-bordo-rgb"
        },
        "TIMED OUT LAPP-PRPM1106": {
            gradient: "--custom-color-coelsa-rgb",
            border: "--custom-color-coelsa-rgb"
        }
    },
    tiempoJPOS: {
        "AVG-TIME-150001": {
            gradient: "--cust-color-quantity-rgb",
            border: "--cust-color-quantity-rgb"
        },
        "AVG-TIME-150100": {
            gradient: "--cust-color-percentage-rgb",
            border: "--cust-color-percentage-rgb"
        },
        "AVG-TIME-151100": {
            gradient: "--custom-color-avg-time-1-rgb",
            border: "--custom-color-avg-time-1-rgb"
        },
        "Umbral": {
            gradient: "--cust-color-quantity-rgb",
            border: "--cust-color-umbral-rgb"
        }
    },
    lifeSpan: {
        "LIFESPAN TOTAL 588": {
            gradient: '--cust-color-quantity-rgb',
            border: '--cust-color-quantity-rgb'
        },
        "LIFESPAN TOTAL 589": {
            gradient: '--cust-color-percentage-rgb',
            border: '--cust-color-percentage-rgb',
        },
        'Umbral': {
            gradient: '--cust-color-quantity-rgb',
            border: '--cust-color-umbral-rgb'
        }
    },
    tiempoApache: {
        "Umbral": {
            gradient: "--cust-color-umbral-rgb",
            border: "--cust-color-umbral-rgb"
        }
    },
    reversedGeneral: {
        'no-title': {
            gradient: '--cust-color-alerted-rgb',
            border: '--cust-color-alerted-rgb'
        }
    },
    lineReverse: {
        'Reverso': {
            gradient: '--cust-color-alerted-rgb',
            border: '--cust-color-alerted-rgb'
        }
    },

}
