import React, { useState } from "react";
import { IonCard, IonCardContent, IonCardHeader, IonCol, IonIcon, IonRow } from "@ionic/react";
import { chevronDownOutline, chevronUpOutline } from "ionicons/icons";

import { DateTime } from "luxon";

import "./userStatistics.css";

interface Data {
    username: string;
    service_name: string;
    deleted_by: string;
    deleted_at: string;
}

interface Props {
    userDeleteData: Data[];
}

const UserDeleteClient: React.FC<Props> = (props): JSX.Element => {
    const [deleteCardOpen, setDeleteCardOpen] = useState(true);

    const returnDeleteClient = () => {
        if (props.userDeleteData.length === 0) {
            return (
                < IonRow className='user-table-rows'>
                    <IonCol>No hay Usuarios Eliminados</IonCol>
                </IonRow>
            )
        } else {
            return props.userDeleteData.map((user: any) => {
                const deleteDate = DateTime.fromISO(user.deleted_at.split("T")[0],{zone: "America/Argentina/Buenos_Aires"}).toFormat("dd/MM/yyyy")

                return (
                    < IonRow className='user-table-rows' key={user.username}>
                        <IonCol>{user.username}</IonCol>
                        <IonCol>{user.service_name}</IonCol>
                        <IonCol>{user.deleted_by}</IonCol>
                        <IonCol>{deleteDate}</IonCol>
                    </IonRow>
                )
            })
        }
    }

    return (
        <IonCard className="mobile-hidden">
            <IonCardHeader className='user-card-header' onClick={() => setDeleteCardOpen(!deleteCardOpen)}>Usuarios Eliminados
                <IonIcon icon={deleteCardOpen ? chevronUpOutline : chevronDownOutline} className="display-user-icon"/>
            </IonCardHeader>
            <IonCardContent className={deleteCardOpen ? `user-table-tables user-table-card user-table-margin-card` : `user-hidden`}>
                <IonRow className='user-table-rows'>
                    <IonCol className='user-table-title-tables'>Username</IonCol>
                    <IonCol className='user-table-title-tables'>Cliente</IonCol>
                    <IonCol className='user-table-title-tables'>Eliminado por</IonCol>
                    <IonCol className='user-table-title-tables'>Fecha de eliminación</IonCol>
                </IonRow>
                {
                    returnDeleteClient()
                }
            </IonCardContent>
        </IonCard >
    )
}

export default UserDeleteClient;