import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { IonButton, IonIcon, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonInput, IonItem, IonList, IonPage, IonRouterLink, IonRow, IonTitle, IonCardHeader, IonCardTitle, IonImg, IonCardSubtitle, IonText } from '@ionic/react';
import { arrowBackOutline, eye, eyeOff } from 'ionicons/icons';

import { SessionDefaultStateI } from '../../store/session/SessionReducer';
import { setStyleMode } from '../../utils/StyleUtils';

import ApiRequestor from '../../api_access/ApiRequestor';
import prismaLogo from '../../assets/prismaLogo.png';
import prismaLogoDark from '../../assets/prismaLogoDark.png';
import Footer from '../Footer/Footer';

import './LoginOverview.css';
import Modal from '../Modal/Modal';

interface Props extends StateTypes { }

const ResetPass: React.FC<Props> = (props): JSX.Element => {

  const [formData, setFormData] = useState({
    username: '',
    code: '',
    pass: '',
    passRep: ''
  })
  const [username_invalid, setUsername_invalid] = useState(false)
  const [code_invalid, setCode_invalid] = useState({ empty: false, long: false })
  const [pass_invalid, setPass_invalid] = useState({ empty: false, long: false })
  const [passRep_invalid, setPassRep_invalid] = useState({ equal: false, long: false })
  const [showModal, setShowModal] = useState(false)
  const [textModal, setTextModal] = useState("")
  const [showPass, setShowPass] = useState<boolean>(false)
  const [showPassRep, setShowPassRep] = useState<boolean>(false)
  const [redirectData, setRedirectData] = useState(false)

  useEffect(() => {
    setStyleMode()
  }, [])

  useEffect(() => {
    const enterKey = (e: any) => {
      if (e.key == "Enter") {
        setShowModal(false)
      }
    }
    const modalResetPass = document.getElementById("modal-reset-pass")
    modalResetPass?.addEventListener('keypress', enterKey)
  }, [showModal])

  useEffect(() => {
    if (localStorage.getItem("username")) {
      let usernameData = localStorage.getItem("username")
      if (typeof usernameData == 'string') {
        setFormData({
          username: usernameData,
          code: formData.code,
          pass: formData.pass,
          passRep: formData.passRep
        })
      }
    }
  }, [])

  if (props.session.loggedIn) {
    return <Redirect to="/page/kpigroup/services" />
  }

  const handlePasswordVisibility = () => {
    setShowPass(showPass ? false : true)
  }

  const handlePasswordRepVisibility = () => {
    setShowPassRep(showPassRep ? false : true)
  }

  const validUsername = () => {
    if (formData.username.length <= 0) {
      setUsername_invalid(true)
    } else {
      setUsername_invalid(false)
    }
  }

  const validCode = () => {
    if (formData.code.length !== 6) {
      if (formData.code.length == 0) {
        setCode_invalid({ empty: true, long: true })
      } else {
        setCode_invalid({ empty: false, long: true })
      }
    } else {
      setCode_invalid({ empty: false, long: false })
    }
  }

  const validPass = () => {
    if (formData.pass.length < 8) {
      if (formData.pass.length == 0) {
        setPass_invalid({ empty: true, long: true })
      } else {
        setPass_invalid({ empty: false, long: true })
      }
    } else {
      setPass_invalid({ empty: false, long: false })
    }
  }

  const validPassRep = () => {
    if (formData.passRep.length < 8) {
      if (formData.passRep !== formData.pass) {
        setPassRep_invalid({ equal: true, long: true })
      } else {
        setPassRep_invalid({ equal: false, long: true })
      }
    } else {
      if (formData.passRep !== formData.pass) {
        setPassRep_invalid({ equal: true, long: false })
      } else {
        setPassRep_invalid({ equal: false, long: false })

      }
    }
  }

  const validForm = () => {
    let valid = false
    if (formData.username.length > 0 && formData.code.length == 6 && formData.pass.length >= 8 && formData.pass == formData.passRep) {
      valid = true
    }
    return valid
  }

  const handleKey = (e: any) => {
    if (e.key == "Enter") {
      onSubmit()
    }
  }

  const onSubmit = async () => {
    if (validForm()) {
      const data: any = { body: { username: formData.username, code: formData.code, password: formData.pass } }
      const response: any = await ApiRequestor.authRequestToUser('/auth/reset-pass', "POST", data, "USER")
      if (!response.data.message) {
        setTextModal("Se ha modificado correctamente su contraseña")
        setShowModal(true)
      } else {
        if (response.data.message.includes("Username/client id combination not found.")) {
          setTextModal("No se encontro un usuario registrado con ese nombre")
          setShowModal(true)
        } else {
          if (response.data.message.includes("Invalid code provided, please request a code again.")) {
            setTextModal("Código de confirmación incorrecto. Por favor intente nuevamente.")
            setShowModal(true)
          } else {
            if (response.data.message.includes("Attempt limit exceeded, please try after some time.")) {
              setTextModal("No se pudo resetear la contraseña, se alcanzó el número máximo de intentos. Por favor intente nuevamente en otro momento.")
              setShowModal(true)
            } else {
              setTextModal(response.data.message)
              setShowModal(true)
            }
          }
        }
      }
    } else {
      validUsername()
      validCode()
      validPass()
      validPassRep()
    }
  }

  const onSuccess = () => {
    if (textModal.includes("Se ha modificado correctamente su contraseña")) {
      setShowModal(false)
      localStorage.removeItem("username")
      setRedirectData(true)
    }
    setShowModal(false)
  }

  if (redirectData == true) {
    return <Redirect to="/page/login" />
  }

  const returnModalChildren = (): JSX.Element => {
    return (
      <>
        <IonText>
          <p className="ion-padding ion-text-center login-modal-text"> {textModal}</p>
        </IonText>
        <IonRow className="ion-text-center ion-padding-bottom">
          <IonCol size-lg="12" size-md="12">
            <IonButton className="login-button" fill="outline" size="small" onClick={() => { onSuccess() }}>Aceptar</IonButton>
          </IonCol>
        </IonRow>
      </>
    )
  }


  return (
    <IonPage >
      <IonContent className="ion-text-center">
        <IonGrid className="ion-no-padding">
          <IonRow>
            <IonCol size="12" className="colLogin colLoginForgot ion-padding-bottom">
              <IonRouterLink href="/page/forgot-pass">
                <IonIcon icon={arrowBackOutline} className="login-back-arrow" />
              </IonRouterLink>
              <IonTitle className="ion-padding ion-text-center login-title">
                <div className="ion-text-wrap ion-padding-bottom"> MONITOREO DE SERVICIOS</div>
              </IonTitle>
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center login-row">
            <IonCard className="cardLogin ion-text-center">
              <IonCardHeader className="">
                <IonImg id="logoDark" className="login-image" src={prismaLogoDark} />
                <IonImg id="logoLight" className="login-image" src={prismaLogo} />
                <IonImg id="isoDark" className="ion-hide" />
                <IonImg id="isoLight" className="ion-hide" />
                <IonCardTitle className="login-card-title">RECUPERACIÓN DE CONTRASEÑA</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                <IonList className="">
                  <IonRow className="ion-justify-content-center">
                    <IonCol size-xl="8" size-lg="8" size-md="8" size-sm="12" size-xs="12">
                      <IonCardSubtitle className="ion-text-start ion-margin-start">Nombre de usuario</IonCardSubtitle>
                      <IonItem className="login-item" lines="none">
                        <IonInput className="inputLogin"
                          type='text'
                          name="username"
                          autocomplete="new-password"
                          placeholder="Email"
                          required
                          value={formData.username}
                          onIonChange={event => { validUsername(); setFormData({ username: event.detail.value!, code: formData.code, pass: formData.pass, passRep: formData.passRep }) }}
                          onKeyPress={handleKey} />
                      </IonItem>
                      <IonText>
                        <p className={username_invalid ? "login-valid-text" : "ion-hide"}> El nombre de usuario está vacío</p>
                      </IonText>
                    </IonCol>
                  </IonRow>
                  <IonRow className="ion-justify-content-center">
                    <IonCol size-xl="8" size-lg="8" size-md="8" size-sm="12" size-xs="12">
                      <IonCardSubtitle className="ion-text-start ion-margin-start">Código de confirmación</IonCardSubtitle>
                      <IonItem className="login-item" lines="none">
                        <IonInput className="inputLogin"
                          type='number'
                          name="code"
                          autocomplete="new-password"
                          placeholder="XXXXXX"
                          required
                          value={formData.code}
                          onIonChange={event => { validCode(); setFormData({ username: formData.username, code: event.detail.value!, pass: formData.pass, passRep: formData.passRep }) }}
                          onKeyPress={handleKey} />
                      </IonItem>
                      <IonText>
                        <p className={code_invalid.empty ? "login-valid-text" : "ion-hide"}> El código está vacío</p>
                      </IonText>
                      <IonText>
                        <p className={code_invalid.long ? "login-valid-text" : "ion-hide"}> El código debe tener 6 caracteres</p>
                      </IonText>
                    </IonCol>
                  </IonRow>
                  <IonRow className="ion-justify-content-center">
                    <IonCol size-xl="8" size-lg="8" size-md="8" size-sm="12" size-xs="12">
                      <IonCardSubtitle className="ion-text-start ion-margin-start">Nueva contraseña</IonCardSubtitle>
                      <IonItem className="login-item" lines="none">
                        <IonInput className="inputLogin"
                          type={showPass ? 'text' : 'password'}
                          name="password"
                          autocomplete="new-password"
                          placeholder="Mínimo 8 caracteres"
                          required
                          value={formData.pass}
                          onIonChange={event => { validPass(); setFormData({ username: formData.username, code: formData.code, pass: event.detail.value!, passRep: formData.passRep }) }}
                          onKeyPress={handleKey} />
                        <IonIcon icon={showPass ? eye : eyeOff} onClick={handlePasswordVisibility} className="loginEye" />
                      </IonItem>
                      <IonText>
                        <p className={pass_invalid.empty ? "login-valid-text" : "ion-hide"}> La contraseña está vacía</p>
                      </IonText>
                      <IonText>
                        <p className={pass_invalid.long ? "login-valid-text" : "ion-hide"}> Longitud menor a 8 caracteres</p>
                      </IonText>
                    </IonCol>
                  </IonRow>
                  <IonRow className="ion-justify-content-center">
                    <IonCol size-xl="8" size-lg="8" size-md="8" size-sm="12" size-xs="12">
                      <IonCardSubtitle className="ion-text-start ion-margin-start">Repetir nueva contraseña</IonCardSubtitle>
                      <IonItem className="login-item" lines="none">
                        <IonInput className="inputLogin"
                          type={showPassRep ? 'text' : 'password'}
                          name="passwordRep"
                          autocomplete="new-password"
                          placeholder="Mínimo 8 caracteres"
                          required
                          value={formData.passRep}
                          onIonChange={event => { validPassRep(); setFormData({ username: formData.username, code: formData.code, pass: formData.pass, passRep: event.detail.value! }) }}
                          onKeyPress={handleKey} />
                        <IonIcon icon={showPassRep ? eye : eyeOff} onClick={handlePasswordRepVisibility} className="loginEye" />
                      </IonItem>
                      <IonText>
                        <p className={passRep_invalid.long ? "login-valid-text" : "ion-hide"}> Longitud menor a 8 caracteres</p>
                      </IonText>
                      <IonText>
                        <p className={passRep_invalid.equal ? "login-valid-text" : "ion-hide"}> Las contraseñas no coinciden</p>
                      </IonText>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="12" className="ion-padding">
                      <IonButton className="ion-margin-top login-button" fill="outline" onClick={onSubmit}>Validar</IonButton>
                    </IonCol>
                  </IonRow>
                </IonList>
              </IonCardContent>
            </IonCard>
          </IonRow>

          <Modal
            modalChildren={{
              id: "modal-reset-pass",
              styles: "login-modal",
              isOpen: showModal,
              onDidDismiss: () => setShowModal(false),
              children: returnModalChildren(),
            }}

          />

        </IonGrid>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

//Redux state and actions dispatchers

interface StateTypes {
  session: SessionDefaultStateI
}

const mapStateToProps = (state: StateTypes) => {
  return {
    session: state.session
  };
};

export default connect(mapStateToProps)(ResetPass);